import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../sidebar/Sidebar";
import { AuthPropType, useAuth } from "../providers/AuthProvider";
import { SidebarNew } from "../sidebar/SidebarNew";

const PrivateRoutes = () => {
  const { user }: AuthPropType = useAuth();

  return user ? (
    <>
      {/* <Sidebar /> */}
      <SidebarNew />
      <Outlet />
    </>
  ) : (
    <>
      {/* <Sidebar /> */}
      <SidebarNew />
      <Outlet />
    </>
  );
};

export default PrivateRoutes;
