import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ClaimPropsInterface, FetchClaimHistoryInitialInterface } from '../../../interfaces/patientInterface';
import { getClaimHistoryAPIRequest } from '../../../api/patient/patientClaim';

const initialState: FetchClaimHistoryInitialInterface = {
  getClaimHistoryLoading: false,
  getClaimHistoryData: [],
  getClaimHistoryError: null,
  getClaimHistoryStatus: 'IDLE',
};

export const getClaimHistoryRequest: any = createAsyncThunk(
  'claim-history/get',
  async (searchParams: ClaimPropsInterface, thunkAPI: any) => {
    try {
      const response: any = await getClaimHistoryAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getClaimHistorySlice = createSlice({
  name: 'fetchClaimHistory',
  initialState,
  reducers: {
    clearFetchClaimHistoryResponse: (state) => {
      state.getClaimHistoryLoading = false;
      state.getClaimHistoryStatus = 'IDLE';
      state.getClaimHistoryData = [];
      state.getClaimHistoryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimHistoryRequest.pending, (state, action) => {
        state.getClaimHistoryLoading = true;
        state.getClaimHistoryStatus = 'PENDING';
      })
      .addCase(getClaimHistoryRequest.fulfilled, (state, action) => {
        state.getClaimHistoryLoading = false;
        state.getClaimHistoryStatus = 'SUCCESS';
        state.getClaimHistoryData = action.payload;
      })
      .addCase(getClaimHistoryRequest.rejected, (state, action) => {
        state.getClaimHistoryLoading = false;
        state.getClaimHistoryError = action.payload;
        state.getClaimHistoryStatus = 'FAILED';
      });
  },
});

export const { clearFetchClaimHistoryResponse } = getClaimHistorySlice.actions;

export const getClaimHistorySelector = (state: RootState) => state.GetClaimHistory;

export default getClaimHistorySlice.reducer;
