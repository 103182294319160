export const loginValidation = ({ username, password }: any) => {
  let errObj: any = {};
  if (!username && !password) {
    errObj['username'] = true;
    errObj['password'] = true;
  } else if (!username) {
    errObj['username'] = true;
  } else if (!password) {
    errObj['password'] = true;
  }

  return errObj;
};
