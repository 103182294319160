import { usStates } from '../../../constants/systemConstants';
import { AddPracticesFormPropsInterface } from '../../../interfaces/PracticesInterface';
import { Checkbox } from '../../atoms/Checkbox';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';

export const AddPracticesForm: React.FC<AddPracticesFormPropsInterface> = ({
  onChange,
  practicesFormAddData,
  errorObject,
}) => {
  return (
    <>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Name"
          name="name"
          onChange={onChange}
          defaultValue={practicesFormAddData?.name ?? undefined}
          error={errorObject && errorObject?.name}
          errorMessage={errorObject && errorObject?.name}
          required={true}
        />
        <InputField
          label="Tax ID"
          name="taxId"
          onChange={onChange}
          defaultValue={practicesFormAddData?.taxId ?? undefined}
          error={errorObject && errorObject?.taxId}
          errorMessage={errorObject && errorObject?.taxId}
          required={true}
        />
        <InputField
          label="NPI"
          name="npi"
          onChange={onChange}
          defaultValue={practicesFormAddData?.npi ?? undefined}
          error={errorObject && errorObject?.npi}
          errorMessage={errorObject && errorObject?.npi}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Address 1"
          name="address1"
          onChange={onChange}
          defaultValue={practicesFormAddData?.address1 ?? undefined}
          error={errorObject && errorObject?.address1}
          errorMessage={errorObject && errorObject?.address1}
          required={true}
        />
        <InputField
          label="Address 2"
          name="address2"
          onChange={onChange}
          defaultValue={practicesFormAddData?.address2 ?? undefined}
          error={errorObject && errorObject?.address2}
          errorMessage={errorObject && errorObject?.address2}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="City"
          name="city"
          onChange={onChange}
          defaultValue={practicesFormAddData?.city ?? undefined}
          error={errorObject && errorObject?.city}
          errorMessage={errorObject && errorObject?.city}
          required={true}
        />
        <SelectInput
          label="State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="state"
          onChange={onChange}
          defaultValue={practicesFormAddData?.state ?? undefined}
          error={errorObject && errorObject?.state}
          errorMessage={errorObject && errorObject?.state}
          required={true}
        />
        <InputField
          label="Zip Code"
          name="zipCode"
          onChange={onChange}
          defaultValue={practicesFormAddData?.zipCode ?? undefined}
          error={errorObject && errorObject?.zipCode}
          errorMessage={errorObject && errorObject?.zipCode}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Phone"
          name="phone"
          onChange={onChange}
          defaultValue={practicesFormAddData?.phone ?? undefined}
          error={errorObject && errorObject?.phone}
          errorMessage={errorObject && errorObject?.phone}
        />
        <InputField
          label="Fax"
          name="fax"
          onChange={onChange}
          defaultValue={practicesFormAddData?.fax ?? undefined}
          error={errorObject && errorObject?.fax}
          errorMessage={errorObject && errorObject?.fax}
        />
      </div>
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <Checkbox
          label="isPayToAddress"
          onChange={onChange}
          name="isPayToAddress"
          defaultChecked={practicesFormAddData?.isPayToAddress}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Payto Address 1"
          name="payToAddress1"
          onChange={onChange}
          defaultValue={practicesFormAddData?.payToAddress1 ?? undefined}
          error={errorObject && errorObject?.payToAddress1}
          errorMessage={errorObject && errorObject?.payToAddress1}
        />
        <InputField
          label="Payto Address 2"
          name="payToAddress2"
          onChange={onChange}
          defaultValue={practicesFormAddData?.payToAddress2 ?? undefined}
          error={errorObject && errorObject?.payToAddress2}
          errorMessage={errorObject && errorObject?.payToAddress2}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Payto City"
          name="payToCity"
          onChange={onChange}
          defaultValue={practicesFormAddData?.payToCity ?? undefined}
          error={errorObject && errorObject?.payToCity}
          errorMessage={errorObject && errorObject?.payToCity}
        />
        <SelectInput
          label="Payto State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="payToState"
          onChange={onChange}
          defaultValue={practicesFormAddData?.payToState ?? undefined}
          error={errorObject && errorObject?.payToState}
          errorMessage={errorObject && errorObject?.payToState}
        />
        <InputField
          label="Payto Zip Code"
          name="payToZipCode"
          onChange={onChange}
          defaultValue={practicesFormAddData?.payToZipCode ?? undefined}
          error={errorObject && errorObject?.payToZipCode}
          errorMessage={errorObject && errorObject?.payToZipCode}
        />
      </div>
    </>
  );
};
