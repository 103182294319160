import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { RightSheetLarge } from '../../../rightSheet/RightSheetLarge';
import { CommonAlert } from '../../../atoms/Alert';
import { TableContent } from '../../../table/TableContent';
import { CommonButton } from '../../../atoms/CommonButton';
import { PatientTransactionInterface } from '../../../../interfaces/patientInterface';
import { getPatientsByIdSelector } from '../../../../redux/slices/patient/getPatientByIdSlice';
import {
  addPatientPaymentRequest,
  addPatientPaymentSelector,
  clearAddPatientPaymentResponse,
} from '../../../../redux/slices/patient/addTransactionPaymentSlice';
import {
  getPaymentListRequest,
  getPaymentListSelector,
  clearFetchPaymentListResponse,
} from '../../../../redux/slices/patient/getPaymentListSlice';
import {
  getTransactionsSumamryRequest,
  getTransactionsSumamrySelector,
  clearFetchTransactionsSummaryResponse,
} from '../../../../redux/slices/patient/getTransactionsSummarySlice';
import {
  editTransactionResponsibilityRequest,
  editTransactionResponsibilitySelector,
  clearEditTransactionResponsibility,
} from '../../../../redux/slices/patient/editTransactionResponsibilitySlice';
import { PaymentList } from './PaymentList';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import moment from 'moment';
import { AddPayment } from './AddPayment';
import { formatCurrency } from '../../../../utils/commonFunctions';

const columns = [
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'Claim ID',
    dataIndex: 'claimId',
    key: 'claimId',
    render: (text: string, record: any) => {
      return record.claimType ? `${text} ${record.claimType}` : text;
    },
  },
  {
    title: 'DOS',
    dataIndex: 'dos',
    key: 'dos',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'CPT',
    dataIndex: 'cpts',
    key: 'cpts',
    render: (text: any, record: any) => {
      return record.cpts ? record.cpts.split(',').join(', ') : '';
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Provider',
    dataIndex: 'providerName',
    key: 'providerName',
  },
  {
    title: 'ICDs',
    dataIndex: 'icds',
    key: 'icds',
    render: (text: any, record: any) => {
      return record.icds ? record.icds.split(',').join(', ') : '';
    },
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    id: '45239 P',
    visitId: '62553',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    responsibility: 'Primary',
    date: '12/19/2023',
  },
  {
    id: '45239 P',
    visitId: '62554',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    responsibility: '',
    date: '12/19/2023',
  },
  {
    id: '45239 P',
    visitId: '62555',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    responsibility: 'Secondary',
    date: '12/19/2023',
  },
  {
    id: '45239 P',
    visitId: '62556',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    date: '12/19/2023',
  },
];

const billColumns = [
  {
    title: 'Total Billed',
    dataIndex: 'totalBilled',
    key: 'totalBilled',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Total Payment',
    dataIndex: 'totalPayment',
    key: 'totalPayment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Total Adjustments',
    dataIndex: 'totalAdjustments',
    key: 'totalAdjustments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Insurance Balance',
    dataIndex: 'insuranceBalance',
    key: 'insuranceBalance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Patient Balance',
    dataIndex: 'patientBalance',
    key: 'patientBalance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Last Statement Sent Date',
    dataIndex: 'lastStatementSentDate',
    key: 'lastStatementSentDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : 'N/A';
    },
  },
  {
    title: 'Total Outstanding Balance',
    dataIndex: 'totalOutStandingBalance',
    key: 'totalOutStandingBalance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const billDataSource = [
  {
    totalBilled: '682.00',
    totalPayment: '256.32',
    totalAdjustments: '171.68',
    insuranceBalanace: '254.00',
    patientBalance: '0.00',
    lastStatementSentDate: 'N/A',
    totalOutStandingBalance: '254.00',
  },
];

const PAYMENT_LIST_PAGE_SIZE = 5;

export const Transaction: React.FC<PatientTransactionInterface> = ({
  mode,
  selectedId,
  fetchDataLoading,
  fetchPatientById,
}) => {
  const dispatch = useAppDispatch();

  const { fetchPatientByIdStatus, fetchPatientByIdData } = useAppSelector(getPatientsByIdSelector);
  const { addTransactionPaymentStatus, addTransactionPaymentData } = useAppSelector(addPatientPaymentSelector);
  const { fetchPaymentListStatus, fetchPaymentListData } = useAppSelector(getPaymentListSelector);
  const { fetchTransactionSummaryStatus, fetchTransactionSummaryData } = useAppSelector(getTransactionsSumamrySelector);
  const { editTransactionResponsibilityStatus } = useAppSelector(editTransactionResponsibilitySelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [summaryDataSource, setSummaryDataSource] = useState<any[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [visibleAddPaymentModel, setVisibleAddPaymentModel] = useState<boolean>(false);
  const [visiblePaymentList, setVisiblePaymentList] = useState<boolean>(false);
  const [paymentListData, setPaymentListData] = useState<any>([]);
  const [selectedToViewVisitId, setSelectedToViewVisitId] = useState<any>({});
  const [selectedTransactionId, setSelectedTransactionId] = useState<any>({});
  const [addPaymentFormData, setAddPaymentFormData] = useState<any>({});
  const [selectedClaimId, setSelectedClaimId] = useState<any>();
  const [claimFormData, setClaimFormData] = useState<any>({});
  const [errorObj, setErrorObj] = useState<any>({});
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [paymentListCurrentPage, setPaymentListCurrentPage] = useState<number>(0);
  const [paymentListtotalPages, setpaymentListtotalPages] = useState<number>(0);

  useEffect(() => {
    fetchTransactionsSummary();
  }, []);

  useEffect(() => {
    if (fetchPatientByIdStatus === 'SUCCESS') {
      setDataSource(fetchPatientByIdData);
    }
  }, [fetchPatientByIdStatus]);

  useEffect(() => {
    if (addTransactionPaymentStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'transaction');
      fetchTransactionsSummary();
      setVisibleAddPaymentModel(false);
      setAlertObj({
        color: 'success',
        message: 'Payment Added Successfully !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearAddPatientPaymentResponse());
    } else if (addTransactionPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddPatientPaymentResponse());
    }
  }, [addTransactionPaymentStatus]);

  useEffect(() => {
    if (fetchPaymentListStatus === 'SUCCESS') {
      setPaymentListData(fetchPaymentListData);
      dispatch(clearFetchPaymentListResponse());
    } else if (fetchPaymentListStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchPaymentListResponse());
    }
  }, [fetchPaymentListStatus]);

  useEffect(() => {
    if (fetchTransactionSummaryStatus === 'SUCCESS') {
      setSummaryDataSource([fetchTransactionSummaryData]);
      dispatch(clearFetchTransactionsSummaryResponse());
    } else if (fetchTransactionSummaryStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong when fetching transaction summary!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchTransactionsSummaryResponse());
    }
  }, [fetchTransactionSummaryStatus]);

  useEffect(() => {
    if (editTransactionResponsibilityStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'transaction');
      fetchTransactionsSummary();
      setAlertObj({
        color: 'success',
        message: 'Responsiiblity successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditTransactionResponsibility());
    } else if (editTransactionResponsibilityStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditTransactionResponsibility());
    }
  }, [editTransactionResponsibilityStatus]);

  const fetchTransactionsSummary = async () => {
    dispatch(getTransactionsSumamryRequest({ patientId: selectedId }));
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    // const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    // setMode('EDIT');
    // setSelectedToEdit(dataSet);
    // setVisibleEditForm(true);
  };

  const onAdd = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedClaimId(dataSet?.claimId);
    setSelectedTransactionId(dataSet?.id);
    setAddPaymentFormData({ claimId: dataSet?.claimId, complete: false });
    setVisibleAddPaymentModel(true);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataset = dataSource[rowIndex];
    setSelectedToViewVisitId(dataset?.visitId);
    setSelectedTransactionId(dataset?.id);
    getPaymentList();
    setVisiblePaymentList(true);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const onSubmit = () => {
    // addressFormData['state'] = 'FL';
    // addressFormData['country'] = 'US';
    // let obj = {
    //   userId: selectedId,
    //   addressData: addressFormData,
    // };
    // dispatch(editAddressRequest(obj));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleAddNewModal = () => {
    setVisibleModal(true);
  };

  const onCloseModal = () => {
    setVisibleModal(false);
  };

  const handleOnClosePaymentList = () => {
    setVisiblePaymentList(false);
    setSelectedTransactionId({});
    setSelectedToViewVisitId({});
  };

  const handleOnCloseAddPaymentModel = () => {
    setAddPaymentFormData({});
    setSelectedTransactionId({});
    setSelectedClaimId({});
    setVisibleAlert(false);
    setErrorObj({});
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setVisibleAddPaymentModel(false);
  };

  const handleResponsibilityChange = (responsibilityId: any, rowIndex: any) => {
    // console.log('CLICKED ID', responsibilityId);
    const dataset = dataSource[rowIndex];
    // console.log('Dataset', dataset);

    dispatch(
      editTransactionResponsibilityRequest({
        transactionId: dataset?.id,
        responsibility: responsibilityId,
      })
    );
  };

  const handlePaymentFormChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }

    const convertedValue = type === 'checkbox' ? checked : value;

    setAddPaymentFormData((prev: any) => ({
      ...prev,
      [name]: convertedValue,
    }));
  };

  const handlePaymentDateChange = (name: string, value: Date) => {
    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }
    setAddPaymentFormData((prev: any) => ({
      ...prev,
      [name]: moment(value).format('YYYY-MM-DD'),
    }));
  };

  const handleAddPayment = () => {
    let obj = {
      transactionId: selectedTransactionId,
      paymentData: addPaymentFormData,
    };

    dispatch(addPatientPaymentRequest(obj));
  };

  const getPaymentList = async (pageNumber = paymentListCurrentPage) => {
    dispatch(
      getPaymentListRequest({
        // patientId: selectedId,
        // visitId: selectedToViewVisitId,
        transactionId: selectedTransactionId,
        page: pageNumber,
        size: PAYMENT_LIST_PAGE_SIZE,
      })
    );
  };

  const handlePaymentListPageChange = (page: any) => {
    setPaymentListCurrentPage(page - 1);
    getPaymentList(page - 1);
  };

  const handleClaimFormChange = (e: any) => {
    setClaimFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // console.log('form data', addPaymentFormData);
  return (
    <>
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className=" flex w-3/6 ml-auto space-x-3">
        {/* {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            disabled
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            onClick={handleAddNewModal}
          />
        )} */}
        {mode !== 'VIEW' && (
          <CommonButton
            label="Walkout Receipt"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            // onClick={handleAddNewModal}
          />
        )}
        {mode !== 'VIEW' && (
          <CommonButton
            label="Print Statement"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            // onClick={handleAddNewModal}
          />
        )}
        {mode !== 'VIEW' && (
          <CommonButton
            label="Send Statement"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            // onClick={handleAddNewModal}
          />
        )}
      </div>
      {dataSource && dataSource?.length > 0 ? (
        <>
          <TableContent
            enableActions={true}
            columns={columns}
            dataSource={dataSource}
            enableAdd={true}
            enableView={true}
            enableResponsibilityButton={false}
            // onView={onView}
            // onHandleAdd={onAdd}
            onHandleResponsibility={handleResponsibilityChange}
          />

          <TableContent
            enableActions={false}
            columns={billColumns}
            dataSource={summaryDataSource}
            enableDelete={false}
            enableEdit={false}
            enableView={false}
          />
        </>
      ) : (
        <EmptyContent mode="VIEW" enableCreateButton={false} />
      )}

      {visibleAddPaymentModel && (
        <AddPayment
          visibleAddPaymentModel={visibleAddPaymentModel}
          handleOnClose={handleOnCloseAddPaymentModel}
          addPaymentFormData={addPaymentFormData}
          onChange={handlePaymentFormChange}
          onDateChange={handlePaymentDateChange}
          onSubmit={handleAddPayment}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          handleAlertClose={handleAlertClose}
        />
      )}

      {visiblePaymentList && (
        <RightSheetLarge
          title="Payment/Adjustment List"
          children={
            <PaymentList
              odataSource={paymentListData}
              onPageChange={handlePaymentListPageChange}
              totalPages={paymentListtotalPages}
              currentPage={paymentListCurrentPage}
              fetchPatientById={fetchPatientById}
              selectedId={selectedId}
              setVisiblePaymentList={setVisiblePaymentList}
            />
          }
          enableStepper={false}
          enableFooterButtons={false}
          onClose={handleOnClosePaymentList}
        />
      )}
    </>
  );
};
