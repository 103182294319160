import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AddFacilitiesInterface, AddFacilityInitialInterface } from '../../../interfaces/systemInterfaces';
import { addFacilitiesAPIRequest } from '../../../api/facility/facility';

const initialState: AddFacilityInitialInterface = {
  addFacilityLoading: false,
  addFacilityData: {},
  addFacilityError: null,
  success: false,
  addFacilityStatus: 'IDLE',
};

export const addFacilityRequest: any = createAsyncThunk(
  'facility/add',
  async (facilityData: AddFacilitiesInterface, thunkAPI: any) => {
    try {
      const response: any = await addFacilitiesAPIRequest(facilityData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addFacilitySlice = createSlice({
  name: 'addFacility',
  initialState,
  reducers: {
    clearAddFacilityResponse: (state) => {
      state.addFacilityLoading = false;
      state.addFacilityStatus = 'IDLE';
      state.addFacilityData = {};
      state.addFacilityError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFacilityRequest.pending, (state, action) => {
        state.addFacilityLoading = true;
        state.addFacilityStatus = 'PENDING';
      })
      .addCase(addFacilityRequest.fulfilled, (state, action) => {
        state.addFacilityData = action.payload;
        state.addFacilityStatus = 'SUCCESS';
        state.addFacilityLoading = false;
      })
      .addCase(addFacilityRequest.rejected, (state, action) => {
        state.addFacilityLoading = false;
        state.addFacilityError = action.payload;
        state.addFacilityStatus = 'FAILED';
      });
  },
});

export const { clearAddFacilityResponse } = addFacilitySlice.actions;

export const addFacilitySelector = (state: RootState) => state.AddFacility;

export default addFacilitySlice.reducer;
