import {
  ProviderAddInterface,
  ProviderFetchByIdInterface,
  ProviderFetchInterface,
  ProviderProfileUpdateInterface,
} from '../../interfaces/providersInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getProvidersAPIRequest = async (searchParams: ProviderFetchInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/search?page=${searchParams?.page}&size=${searchParams?.size}`,
    'get',
    {
      data: {},
    }
  );
};

export const addProviderAPIRequest = async (provider: ProviderAddInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/provider/`, 'post', {
    data: { ...provider },
  });
};

export const getProviderByIdAPIRequest = async (provider: ProviderFetchByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${provider?.userId}/${provider?.section}`,
    'get',
    {
      data: {},
    }
  );
};

export const updateProviderProfileAPIRequest = async (provider: ProviderProfileUpdateInterface) => {
  // console.log('== provider ==', provider);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${provider?.providerId}/profile`,
    'put',
    {
      data: { ...provider?.profileData },
    }
  );
};
