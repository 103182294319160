import { PaginationPropsInterface } from '../../interfaces/commonInterfaces';
import './pagination.css';
import { PaginationButtonProps, Pagination as PaginationComponent } from 'flowbite-react';

const PAGE_SIZE = 10;

export const Pagination: React.FC<PaginationPropsInterface> = ({
  currentPage = 0,
  totalItems,
  totalPages = 0,
  onPageChange,
}) => {
  return (
    <>
      <PaginationComponent
        currentPage={currentPage + 1}
        onPageChange={onPageChange}
        totalPages={totalPages}
        className="pb-3"
        layout="pagination"
        theme={{
          pages: {
            selector: {
              active:
                'bg-primaryDefault text-white enabled:hover:bg-primaryDefault enabled:hover:text-white dark:bg-gray-700 dark:text-white',
            },
          },
        }}
      />
      {/* <nav aria-label="Page navigation example" className="text-right">
        <ul className="pagination">
          <li>
            <a href="#" className="pagination-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-3 h-3"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </a>
          </li>
          <li>
            <a href="#" aria-current="page" className="pagination-link active">
              1
            </a>
          </li>
          <li>
            <a href="#" className="pagination-link">
              2
            </a>
          </li>
          <li>
            <a href="#" className="pagination-link">
              3
            </a>
          </li>
          <li>
            <a href="#" className="pagination-link">
              4
            </a>
          </li>
          <li>
            <a href="#" className="pagination-link">
              5
            </a>
          </li>
          <li>
            <a href="#" className="pagination-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-3 h-3"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </a>
          </li>
        </ul>
      </nav> */}
    </>
  );
};
