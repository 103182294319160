import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchARCategoryListInitialInterface,
  FetchCategoryListInitialInterface,
} from '../../../interfaces/systemInterfaces';
import { getARCategoryListAPIRequest, getCategoryListAPIRequest } from '../../../api/system/patient';

const initialState: FetchARCategoryListInitialInterface = {
  arCategoryListLoading: false,
  arCategoryListData: [],
  arCategoryListError: null,
  arCategoryListStatus: 'IDLE',
};

export const getARCategoryListRequest: any = createAsyncThunk('patient/ar-category/get', async (thunkAPI: any) => {
  try {
    const response: any = await getARCategoryListAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getARCategoryListSlice = createSlice({
  name: 'fetchCategoryAR',
  initialState,
  reducers: {
    clearFetchARCategoryListResponse: (state) => {
      state.arCategoryListLoading = false;
      state.arCategoryListStatus = 'IDLE';
      state.arCategoryListData = [];
      state.arCategoryListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getARCategoryListRequest.pending, (state, action) => {
        state.arCategoryListLoading = true;
        state.arCategoryListStatus = 'PENDING';
      })
      .addCase(getARCategoryListRequest.fulfilled, (state, action) => {
        state.arCategoryListLoading = false;
        state.arCategoryListData = action.payload;
        state.arCategoryListStatus = 'SUCCESS';
      })
      .addCase(getARCategoryListRequest.rejected, (state, action) => {
        state.arCategoryListLoading = false;
        state.arCategoryListError = action.payload;
        state.arCategoryListStatus = 'FAILED';
      });
  },
});

export const { clearFetchARCategoryListResponse } = getARCategoryListSlice.actions;

export const getARCategoryListSelector = (state: RootState) => state.GetARCategoryList;

export default getARCategoryListSlice.reducer;
