import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FacilityFetchInitialInterface,
  SystemVisitStatusFetchInitialInterface,
} from '../../../interfaces/systemInterfaces';
import { getSystemFacilitiesAPIRequest } from '../../../api/system/facility';
import { getSystemVisitStatusAPIRequest } from '../../../api/system/status';

const initialState: SystemVisitStatusFetchInitialInterface = {
  systemVisitLoading: false,
  systemVisitData: [],
  systemVisitError: null,
  systemVisitStatus: 'IDLE',
};

export const getSystemVisitStatusRequest: any = createAsyncThunk('system/visit-status/get', async (thunkAPI: any) => {
  try {
    const response: any = await getSystemVisitStatusAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getSystemVisitStatusSlice = createSlice({
  name: 'fetchSystemVisitStatus',
  initialState,
  reducers: {
    clearFetchSystemVisitStatusResponse: (state) => {
      state.systemVisitLoading = false;
      state.systemVisitStatus = 'IDLE';
      state.systemVisitData = [];
      state.systemVisitError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemVisitStatusRequest.pending, (state, action) => {
        state.systemVisitLoading = true;
        state.systemVisitStatus = 'PENDING';
      })
      .addCase(getSystemVisitStatusRequest.fulfilled, (state, action) => {
        // console.log('=== fac - system action.payload ===', action.payload);

        state.systemVisitLoading = false;
        state.systemVisitData = action.payload;
        state.systemVisitStatus = 'SUCCESS';
      })
      .addCase(getSystemVisitStatusRequest.rejected, (state, action) => {
        state.systemVisitLoading = false;
        state.systemVisitError = action.payload;
        state.systemVisitStatus = 'FAILED';
      });
  },
});

export const { clearFetchSystemVisitStatusResponse } = getSystemVisitStatusSlice.actions;

export const getSystemVisitStatusSelector = (state: RootState) => state.GetSystemVisitStatus;

export default getSystemVisitStatusSlice.reducer;
