import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchReasonListInitialInterface } from '../../../interfaces/systemInterfaces';
import { getReasonListAPIRequest } from '../../../api/system/patient';

const initialState: FetchReasonListInitialInterface = {
  reasonListLoading: false,
  reasonListData: [],
  reasonListError: null,
  reasonListStatus: 'IDLE',
};

export const getReasonListRequest: any = createAsyncThunk('patient/reasons/get', async (thunkAPI: any) => {
  try {
    const response: any = await getReasonListAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getReasonListSlice = createSlice({
  name: 'fetchReasons',
  initialState,
  reducers: {
    clearFetchReasonListResponse: (state) => {
      state.reasonListLoading = false;
      state.reasonListStatus = 'IDLE';
      state.reasonListData = [];
      state.reasonListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReasonListRequest.pending, (state, action) => {
        state.reasonListLoading = true;
        state.reasonListStatus = 'PENDING';
      })
      .addCase(getReasonListRequest.fulfilled, (state, action) => {
        state.reasonListLoading = false;
        state.reasonListData = action.payload;
        state.reasonListStatus = 'SUCCESS';
      })
      .addCase(getReasonListRequest.rejected, (state, action) => {
        state.reasonListLoading = false;
        state.reasonListError = action.payload;
        state.reasonListStatus = 'FAILED';
      });
  },
});

export const { clearFetchReasonListResponse } = getReasonListSlice.actions;

export const getReasonListSelector = (state: RootState) => state.GetReasonList;

export default getReasonListSlice.reducer;
