import { useEffect, useState } from 'react';
import { TableContent } from '../../../table/TableContent';
import { CommonButton } from '../../../atoms/CommonButton';
import { CommonAlert } from '../../../atoms/Alert';
import { Spinner } from '../../../atoms/Spinner';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import moment from 'moment';

const columns = [
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'File',
    dataIndex: 'file',
    key: 'file',
  },
  {
    title: 'Uploaded At',
    dataIndex: 'uploadedTime',
    key: 'uploadedTime',
    render: (text: any) => (moment(text).isValid() ? moment(text).format('MM-DD-YYYY HH:mm:ss') : ''),
  },
];

export const ProviderDocuments = ({ mode }: any) => {
  const dispatch = useAppDispatch();

  const [visibleModal, setVisibleModal] = useState(false);

  const handleAddNewModal = () => {
    setVisibleModal(true);
  };

  return (
    <>
      <div className="w-64 ml-auto">
        {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            onClick={handleAddNewModal}
          />
        )}
      </div>
      <TableContent columns={columns} enableActions={mode === 'VIEW' ? false : true}></TableContent>
    </>
  );
};
