import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { triggerGlobalLogout } from './authUtils';

// let refreshTokenPromise: Promise<string> | null = null;

// async function refreshToken(): Promise<any> {
//     // Code to refresh the token. For demonstration purposes, this code simply
//     // returns a resolved Promise with a new token.
//     const url = `${process.env.REACT_APP_KEYCLOAK_API_URL}/realms/uni-stage/protocol/openid-connect/token`

//     try {
//         const response = await axios.post(url,
//             "client_id=" + process.env.REACT_APP_CLIENT_ID +
//             "&refresh_token=" + GetToken().refresh_token +
//             "&grant_type=refresh_token"

//             , {
//                 headers: {
//                     'Content-Type': 'application/x-www-form-urlencoded'
//                 }

//             });
//         return response.data;
//     } catch (error) {
//         localStorage.removeItem("user")
//         return Promise.reject(error);
//     }

// }

export function GetToken() {
  const userData = JSON.parse(localStorage.getItem('user') as string);
  return userData;
}

export async function authenticatedRequest<T>(
  url: string,
  method: 'get' | 'post' | 'put' | 'delete',
  config?: AxiosRequestConfig,
  retryCount: number = 3
): Promise<T> {
  try {
    const response: AxiosResponse<T> = await axios.request<T>({
      method,
      url,
      headers: {
        Authorization: `Bearer ${GetToken().token}`,
        ContentType: 'application/json',
        email: btoa(GetToken().user.email),
        // 'uniId': process.env.REACT_APP_BASE_ID as string,
        ...config?.headers,
      },
      ...config,
    });
    // console.log('config', config)
    return response?.data;
    // @ts-ignore
  } catch (error: AxiosError) {
    if (error.response && error.response.status === 401) {
      triggerGlobalLogout();

      // if (!refreshTokenPromise) {
      //     refreshTokenPromise = refreshToken();
      // }

      // const newToken = await refreshTokenPromise;
      // localStorage.setItem("user", JSON.stringify(newToken));
      // refreshTokenPromise = null;

      // if (retryCount > 0) {
      //   return authenticatedRequest<T>(url, method, config, retryCount - 1);
      // } else {
      //   localStorage.removeItem('user');
      // }
    }

    return Promise.reject(error);
  }
}

export async function nonAuthenticatedRequest<T>(
  url: string,
  method: 'get' | 'post' | 'put' | 'delete',
  config?: AxiosRequestConfig
): Promise<T> {
  try {
    const response: AxiosResponse<T> = await axios.request<T>({
      method,
      url,
      ...config,
    });
    return response?.data;
  } catch (error: any) {
    console.error('Error>>', error);
    return Promise.reject(error?.response?.data?.error_description || error?.response?.data?.message);
  }
}
