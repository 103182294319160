import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientInsuranceFetchByIdInitialPropsInterface,
  PatientInsuranceGetByIdInterface,
} from '../../../interfaces/patientInterface';
import { getPatientInsuranceByIdAPIRequest } from '../../../api/patient/patientInsurance';

const initialState: PatientInsuranceFetchByIdInitialPropsInterface = {
  fetchPatientInsuranceByIdLoading: false,
  fetchPatientInsuranceByIdData: {},
  fetchPatientInsuranceByIdStatus: 'IDLE',
  fetchPatientInsuranceByIdError: null,
};

export const getPatientInsuranceByIdRequest: any = createAsyncThunk(
  'patients/get/insurance/id',
  async (searchParams: PatientInsuranceGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getPatientInsuranceByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchPatientInsuranceByIdSlice = createSlice({
  name: 'fetchPatientInsuranceById',
  initialState,
  reducers: {
    clearFetchPatientInsuranceByIdResponse: (state) => {
      state.fetchPatientInsuranceByIdLoading = false;
      state.fetchPatientInsuranceByIdData = {};
      state.fetchPatientInsuranceByIdStatus = 'IDLE';
      state.fetchPatientInsuranceByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientInsuranceByIdRequest.pending, (state, action) => {
        state.fetchPatientInsuranceByIdLoading = true;
        state.fetchPatientInsuranceByIdStatus = 'PENDING';
      })
      .addCase(getPatientInsuranceByIdRequest.fulfilled, (state, action) => {
        state.fetchPatientInsuranceByIdLoading = false;
        state.fetchPatientInsuranceByIdStatus = 'SUCCESS';
        state.fetchPatientInsuranceByIdData = action.payload;
      })
      .addCase(getPatientInsuranceByIdRequest.rejected, (state, action) => {
        state.fetchPatientInsuranceByIdLoading = false;
        state.fetchPatientInsuranceByIdError = action.payload;
        state.fetchPatientInsuranceByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchPatientInsuranceByIdResponse } = fetchPatientInsuranceByIdSlice.actions;

export const getPatientInsuranceByIdSelector = (state: RootState) => state.FetchPatientInsuranceById;

export default fetchPatientInsuranceByIdSlice.reducer;
