import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchCPTCodesInitialInterface,
  FetchModifierByIdInitialInterface,
  FetchModifiersInitialInterface,
  GetICDCodesInterface,
  GetModifierByIdInterface,
} from '../../../interfaces/systemInterfaces';
import { getCPTCodesAPIRequest, getModifiersAPIRequest, getModifierByIdAPIRequest } from '../../../api/system/code';

const initialState: FetchModifierByIdInitialInterface = {
  modifierByIdLoading: false,
  modifierByIdData: {},
  modifierByIdError: null,
  modifierByIdStatus: 'IDLE',
};

export const getModifierByIdRequest: any = createAsyncThunk(
  'codes/modifier/get/id',
  async (code: GetModifierByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getModifierByIdAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getModifierByIdSlice = createSlice({
  name: 'fetchModifierById',
  initialState,
  reducers: {
    clearFetchModifierByIdResponse: (state) => {
      state.modifierByIdLoading = false;
      state.modifierByIdStatus = 'IDLE';
      state.modifierByIdData = {};
      state.modifierByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModifierByIdRequest.pending, (state, action) => {
        state.modifierByIdLoading = true;
        state.modifierByIdStatus = 'PENDING';
      })
      .addCase(getModifierByIdRequest.fulfilled, (state, action) => {
        state.modifierByIdLoading = false;
        state.modifierByIdData = action.payload;
        state.modifierByIdStatus = 'SUCCESS';
      })
      .addCase(getModifierByIdRequest.rejected, (state, action) => {
        state.modifierByIdLoading = false;
        state.modifierByIdError = action.payload;
        state.modifierByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchModifierByIdResponse } = getModifierByIdSlice.actions;

export const getModifierByIdSelector = (state: RootState) => state.GetModifierCodeById;

export default getModifierByIdSlice.reducer;
