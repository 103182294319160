import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { Modal } from 'flowbite-react';
import { InputField } from '../../../atoms/InputField';
import { Calendar } from '../../../atoms/Calendar';
import { CommonButton } from '../../../atoms/CommonButton';
import { CommonAlert } from '../../../atoms/Alert';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';
import { CustomSearchButton } from '../../patients/visit/AddCPTModal';
import { ProviderFacilitiesAssignModal } from './ProviderFacilitiesAssignmodal';

import {
  editProviderFacilitiesRequest,
  editProviderFacilitiesSelector,
  clearEditProviderFacilities,
} from '../../../../redux/slices/provider/editProviderFacilitySlice';
import { formatDate } from '../../../../utils/commonFunctions';

export const ProviderFacilitiesEditModal = ({
  editfacilityData,
  isFacilityEditModalVisible,
  onCloseEditModal,
  onChangeFacilityEdit,
  onChangeFacilityEditDate,
  assignFacilityDataSource,
  facilityData,
  dataLoading,
  facilityDataFetchStatus,
  providerId,
  facilitiesMode,
  selectedFacilityId,
  fetchProviderById,
}: any) => {
  const dispatch = useAppDispatch();
  const { editProviderFacilitiesStatus } = useAppSelector(editProviderFacilitiesSelector);

  const [visibleAssignFacilityModal, setVisibleAssignFacilityModal] = useState<boolean>(false);

  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (editProviderFacilitiesStatus === 'SUCCESS') {
      fetchProviderById(providerId, 'facility');
      setAlertObj({
        color: 'success',
        message: 'Successfully Added!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        onCloseEditModal();
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearEditProviderFacilities());
    } else if (editProviderFacilitiesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearEditProviderFacilities());
    }
  }, [editProviderFacilitiesStatus]);

  const onCloseAssignFacilityModal = () => {
    setVisibleAssignFacilityModal(false);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleSubmit = () => {
    const obj = {
      facilityId: editfacilityData?.facilityItem?.id,
      startDate: editfacilityData?.startDate ? editfacilityData?.startDate : '',
      endDate: editfacilityData?.endDate ? editfacilityData?.endDate : '',
    };

    dispatch(
      editProviderFacilitiesRequest({
        providerId: providerId,
        facilitiesId: selectedFacilityId,
        facilitiesData: obj,
      })
    );
  };

  return (
    <>
      <Modal show={isFacilityEditModalVisible} onClose={onCloseEditModal}>
        <Modal.Header>Update Facility</Modal.Header>
        <Modal.Body>
          {visibleAlert && (
            <CommonAlert
              color={alertObj.color}
              message={alertObj.message}
              onClose={handleAlertClose}
              alertClassName="w-5/12"
            />
          )}
          <div className="grid md:grid-cols-3">
            <div className="col-span-2">
              <InputField
                label="Facility: "
                name="facility"
                disabled
                value={editfacilityData?.facilityItem?.name ?? undefined}
                // onChange={onChangeFacilityEdit}
              />
            </div>
            <div className="col-span-1 flex items-end">
              <CustomSearchButton
                onClick={() => {
                  setVisibleAssignFacilityModal(true);
                }}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 mt-6">
            {/* <Calendar
              label="Start Date:"
              name="startDate"
              // defaultDate={editfacilityData?.startDate ? new Date(editfacilityData?.startDate) : undefined}
              defaultDate={formatDate(editfacilityData?.startDate)}
              onSelectedDateChanged={(date) => {
                onChangeFacilityEditDate('startDate', date);
              }}
            ></Calendar> */}
            <DatePickerField
              label="Start Date:"
              name="startDate"
              selectedDate={formatDatePickerDate(editfacilityData?.startDate)}
              onChange={(date: Date | null) => {
                if (date) {
                  onChangeFacilityEditDate('startDate', date);
                }
              }}
            />
          </div>
          <div className="grid md:grid-cols-2 mt-6">
            {/* <Calendar
              label="End Date:"
              name="endDate"
              // defaultDate={editfacilityData?.endDate ? new Date(editfacilityData?.endDate) : undefined}
              defaultDate={formatDate(editfacilityData?.endDate)}
              onSelectedDateChanged={(date) => {
                onChangeFacilityEditDate('endDate', date);
              }}
            ></Calendar> */}
            <DatePickerField
              label="End Date:"
              name="endDate"
              selectedDate={formatDatePickerDate(editfacilityData?.endDate)}
              onChange={(date: Date | null) => {
                if (date) {
                  onChangeFacilityEditDate('endDate', date);
                }
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CommonButton type="button" label={'Close'} buttonType="secondary" onClick={onCloseEditModal} />
          <CommonButton type="button" label={'Submit'} buttonType="primary" onClick={handleSubmit} />
        </Modal.Footer>
      </Modal>
      {visibleAssignFacilityModal && (
        <ProviderFacilitiesAssignModal
          isFacilityAssignModalVisible={visibleAssignFacilityModal}
          onCloseModal={onCloseAssignFacilityModal}
          dataSource={assignFacilityDataSource}
          facilityData={facilityData}
          dataLoading={dataLoading}
          facilityDataFetchStatus={facilityDataFetchStatus}
          providerId={providerId}
          onChangeFacilityEdit={onChangeFacilityEdit}
          facilitiesMode={facilitiesMode}
        />
      )}
    </>
  );
};
