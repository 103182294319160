import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GetCPTCodesByIdInterface, GetFeeInterface } from '../../../interfaces/systemInterfaces';
import { getFeeAmountAPIRequest, getModifierListAPIRequest } from '../../../api/system/code';
import { FeeAmountGetPropsInterface } from '../../../interfaces/codesInterfaces';

const initialState: FeeAmountGetPropsInterface = {
  getFeeAmountLoading: false,
  getFeeAmountData: [],
  getFeeAmountError: null,
  getFeeAmountStatus: 'IDLE',
};

export const getFeeAmountRequest: any = createAsyncThunk(
  'codes/feeAmount/get/list',
  async (code: GetFeeInterface, thunkAPI: any) => {
    try {
      const response: any = await getFeeAmountAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getFeeAmountSlice = createSlice({
  name: 'fetchFeeAmount',
  initialState,
  reducers: {
    clearGetFeeAmountResponse: (state) => {
      state.getFeeAmountLoading = false;
      state.getFeeAmountStatus = 'IDLE';
      state.getFeeAmountData = [];
      state.getFeeAmountError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeeAmountRequest.pending, (state, action) => {
        state.getFeeAmountLoading = true;
        state.getFeeAmountStatus = 'PENDING';
      })
      .addCase(getFeeAmountRequest.fulfilled, (state, action) => {
        state.getFeeAmountLoading = false;
        state.getFeeAmountData = action.payload;
        state.getFeeAmountStatus = 'SUCCESS';
      })
      .addCase(getFeeAmountRequest.rejected, (state, action) => {
        state.getFeeAmountLoading = false;
        state.getFeeAmountError = action.payload;
        state.getFeeAmountStatus = 'FAILED';
      });
  },
});

export const { clearGetFeeAmountResponse } = getFeeAmountSlice.actions;

export const getFeeAmountSelector = (state: RootState) => state.GetFeeAmount;

export default getFeeAmountSlice.reducer;
