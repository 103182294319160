import { CodeFormPropsInterface } from '../../../interfaces/userInterface';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';

export const ICDRightSheetForm: React.FC<CodeFormPropsInterface> = ({
  onChange,
  codeData,
  mode,
  selectedId,
  errorObject,
  versions,
}) => {
  return (
    <>
      <>
        <div className="grid md:grid-cols-3 gap-4 mt-6">
          <InputField
            label="ICD Code"
            name="code"
            onChange={onChange}
            defaultValue={codeData?.code ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            required={true}
            error={errorObject && errorObject?.code}
            errorMessage={errorObject && errorObject?.code}
          />
          {/* <InputField
            label="Version"
            name="version"
            onChange={onChange}
            defaultValue={codeData?.version ?? undefined}
            disabled={mode === "VIEW" ? true : false}
            // error={errorObject && errorObject?.firstName}
            // errorMessage={errorObject && errorObject?.firstName}
          /> */}
          <SelectInput
            label="Version"
            options={versions}
            enableDefaultPlaceholder={true}
            name="version"
            onChange={onChange}
            defaultValue={codeData?.version}
            error={errorObject && errorObject?.version}
            errorMessage={errorObject && errorObject?.version}
          />
        </div>

        <div className="grid md:grid-cols-1 gap-4 mt-6">
          <InputField
            label="Short Description"
            name="shortDesc"
            onChange={onChange}
            defaultValue={codeData?.shortDesc ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            required={true}
            error={errorObject && errorObject?.shortDesc}
            errorMessage={errorObject && errorObject?.shortDesc}
          />
        </div>

        <div className="grid md:grid-cols-1 gap-4 mt-6">
          <InputField
            label="Long Descripion"
            name="longDesc"
            onChange={onChange}
            defaultValue={codeData?.longDesc ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            required={false}
            error={errorObject && errorObject?.longDesc}
            errorMessage={errorObject && errorObject?.longDesc}
          />
        </div>
      </>
    </>
  );
};
