import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';

import { TableContent } from '../../../table/TableContent';
import { Pagination } from '../../../pagination/Pagination';
import { ConfirmModal } from '../visit/ConfirmModal';
import { CommonAlert } from '../../../atoms/Alert';
import { AddPayment } from './AddPayment';

import {
  getPaymentByIdRequest,
  getPaymentByIdSelector,
  clearFetchPaymentByIdResponse,
} from '../../../../redux/slices/patient/getPaymentByIdSlice';
import {
  editPatientPaymentRequest,
  editPatientPaymentSelector,
  clearEditPatientPaymentResponse,
} from '../../../../redux/slices/patient/editPaymentSlice';
import {
  deletePatientPaymentRequest,
  deletePatientPaymentSelector,
  clearDeletePatientPaymentResponse,
} from '../../../../redux/slices/patient/deletePaymentSlice';

import moment from 'moment';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Payment Date',
    dataIndex: 'paymentDate',
    key: 'paymentDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Billing Code',
    dataIndex: 'billingCode',
    key: 'billingCode',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text: string) => `$${text}`,
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
  {
    title: 'Who Paid',
    dataIndex: 'whoPaid',
    key: 'whoPaid',
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
];

const dummyDataSource = [
  {
    id: '1001',
    paymentDate: '2023-09-15',
    billingCode: '302',
    amount: '250.00',
    checkNumber: 'CHK-10234',
    whoPaid: 'John Doe',
    creator: 'Admin',
    createdAt: '2023-09-01',
  },
  {
    id: '1002',
    paymentDate: '2023-09-18',
    billingCode: 'BC-002',
    amount: '320.50',
    checkNumber: 'CHK-10235',
    whoPaid: 'Jane Smith',
    creator: 'Admin',
    createdAt: '2023-09-02',
  },
  {
    id: '1003',
    paymentDate: '2023-09-20',
    billingCode: 'BC-003',
    amount: '400.75',
    checkNumber: 'CHK-10236',
    whoPaid: 'Robert Brown',
    creator: 'Admin',
    createdAt: '2023-09-03',
  },
  {
    id: '1004',
    paymentDate: '2023-09-25',
    billingCode: 'BC-004',
    amount: '150.00',
    checkNumber: 'CHK-10237',
    whoPaid: 'Emily Davis',
    creator: 'Admin',
    createdAt: '2023-09-04',
  },
  {
    id: '1005',
    paymentDate: '2023-09-30',
    billingCode: 'BC-005',
    amount: '500.00',
    checkNumber: 'CHK-10238',
    whoPaid: 'Michael Clark',
    creator: 'Admin',
    createdAt: '2023-09-05',
  },
];

export const PaymentList = ({
  dataSource,
  fetchPatientById,
  setVisiblePaymentList,
  selectedId,
  onPageChange,
  totalPages,
  currentPage,
}: any) => {
  const dispatch = useAppDispatch();
  const { deleteTransactionPaymentStatus } = useAppSelector(deletePatientPaymentSelector);
  const { editTransactionPaymentStatus, editTransactionPaymentData } = useAppSelector(editPatientPaymentSelector);
  const { fetchTransactionPaymentByIdStatus, fetchTransactionPaymentByIdData } = useAppSelector(getPaymentByIdSelector);

  const [selectedPaymentId, setSelectedPaymentId] = useState<any>();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [visibleEditModal, setVisibleEditModal] = useState<boolean>(false);
  const [editPaymentFormData, setEditPaymentFormData] = useState<any>({});
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [errorObj, setErrorObj] = useState<any>({});
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (editTransactionPaymentStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (editTransactionPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearEditPatientPaymentResponse());
  }, [editTransactionPaymentStatus]);

  useEffect(() => {
    if (deleteTransactionPaymentStatus === 'SUCCESS') {
      setOpenConfirmModal(false);
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisiblePaymentList(false);
        fetchPatientById(selectedId, 'transaction');
      }, 3000);
    } else if (deleteTransactionPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearDeletePatientPaymentResponse());
  }, [deleteTransactionPaymentStatus]);

  useEffect(() => {
    if (fetchTransactionPaymentByIdStatus === 'SUCCESS') {
      setEditPaymentFormData(fetchTransactionPaymentByIdData);
    }
    dispatch(clearFetchPaymentByIdResponse());
  }, [fetchTransactionPaymentByIdStatus]);

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataset = dummyDataSource[rowIndex];
    setSelectedPaymentId(dataset?.id);
    getPaymentByIdRequest({ paymentId: dataset?.id });
    // setEditPaymentFormData(dataset);
    setVisibleEditModal(true);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataset = dummyDataSource[rowIndex];
    setSelectedPaymentId(dataset?.id);
    setOpenConfirmModal(true);
  };

  const confirmDelete = () => {
    // console.log('Deleted');
    dispatch(deletePatientPaymentRequest({ paymentId: selectedPaymentId }));
  };

  const onCloseConfirm = () => {
    setOpenConfirmModal(false);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleEditPayment = () => {
    let obj = {
      paymentId: selectedPaymentId,
      paymentData: editPaymentFormData,
    };

    dispatch(editPatientPaymentRequest(obj));
  };

  const handlePaymentFormChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }

    const convertedValue = type === 'checkbox' ? checked : value;

    setEditPaymentFormData((prev: any) => ({
      ...prev,
      [name]: convertedValue,
    }));
  };

  const handlePaymentDateChange = (name: string, value: Date) => {
    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }
    setEditPaymentFormData((prev: any) => ({
      ...prev,
      [name]: moment(value).format('YYYY-MM-DD'),
    }));
  };

  const handleOnCloseAddPaymentModel = () => {
    setEditPaymentFormData({});
    setVisibleAlert(false);
    setErrorObj({});
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setVisibleEditModal(false);
  };

  return (
    <>
      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <TableContent
        columns={columns}
        dataSource={dummyDataSource}
        enableActions={true}
        enableEdit={true}
        enableDelete={true}
        onDelete={onDelete}
        onEdit={onEdit}
      />
      <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />

      {openConfirmModal && (
        <ConfirmModal
          openConfirm={openConfirmModal}
          setOpenConfirm={onCloseConfirm}
          title="Delete Payment"
          content="Are you sure you want to delete this payment?"
          submitButtonTitle="Delete"
          cancelButtonTitle="Cancel"
          handleSubmit={confirmDelete}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          alertClassName="w-1/2"
          handleAlertClose={handleAlertClose}
        />
      )}

      {visibleEditModal && (
        <AddPayment
          visibleAddPaymentModel={visibleEditModal}
          addPaymentFormData={editPaymentFormData}
          onChange={handlePaymentFormChange}
          onDateChange={handlePaymentDateChange}
          handleOnClose={handleOnCloseAddPaymentModel}
          onSubmit={handleEditPayment}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          handleAlertClose={handleAlertClose}
        />
      )}
    </>
  );
};
