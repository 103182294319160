import { feeAmountClass } from '../../../constants/patientConstants';
import { usStates } from '../../../constants/systemConstants';
import { formatDatePickerDate } from '../../../utils/commonFunctions';
import { CommonAlert } from '../../atoms/Alert';
import { CommonButton } from '../../atoms/CommonButton';
import { DatePickerField } from '../../atoms/DatePickerField';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';
import { Modal } from 'flowbite-react';

export const FeeListForm = ({
  onChange,
  mode,
  feeData,
  errorObject,
  onChangeDate,
  feeFormOpen,
  handleOnClose,
  onAddFee,
  alertObj,
  visibleAlert,
  handleAlertClose,
}: any) => {
  return (
    <Modal show={feeFormOpen} onClose={handleOnClose}>
      <Modal.Header>Add fee for CPT code</Modal.Header>
      <Modal.Body>
        {visibleAlert && !alertObj?.error && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-1/2"
          />
        )}
        <div className="grid md:grid-cols-2 gap-4 mt-6">
          <SelectInput
            label="Type"
            options={feeAmountClass}
            enableDefaultPlaceholder={true}
            name="type"
            onChange={onChange}
            defaultValue={feeData?.type ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
          />
          <InputField
            label="Amount"
            name="amount"
            onChange={onChange}
            defaultValue={feeData?.amount ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            error={errorObject && errorObject?.amount}
            errorMessage={errorObject && errorObject?.code}
          />
        </div>
        <div className="grid md:grid-cols-2 gap-4 mt-6">
          <SelectInput
            label="State"
            options={usStates}
            enableDefaultPlaceholder={true}
            name="state"
            onChange={onChange}
            defaultValue={feeData?.state ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
          />
          <InputField
            label="Locality"
            name="locality"
            onChange={onChange}
            defaultValue={feeData?.locality ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            error={errorObject && errorObject?.locality}
            errorMessage={errorObject && errorObject?.code}
            tooltip="Character length must be 2"
          />
        </div>
        <div className="grid md:grid-cols-2 gap-4 mt-6">
          <DatePickerField
            label="Effective From"
            name="effectiveFrom"
            selectedDate={formatDatePickerDate(feeData?.effectiveFrom)}
            onChange={(date) => onChangeDate(date, 'effectiveFrom')}
          />
          <DatePickerField
            label="To"
            name="effectiveTo"
            selectedDate={formatDatePickerDate(feeData?.effectiveTo)}
            onChange={(date) => onChangeDate(date, 'effectiveTo')}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CommonButton type="button" label={'Close'} buttonType="secondary" onClick={handleOnClose} />
        <CommonButton type="button" label={'Submit'} buttonType="primary" onClick={onAddFee} />
      </Modal.Footer>
    </Modal>
  );
};
