import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchVisitApplyNoteHistoryInitialPropsInterface,
  FetchVisitsApplyNoteHistoryInterface,
} from '../../../interfaces/patientInterface';
import { getClaimApplyNoteHistoryAPIRequest } from '../../../api/patient/patientClaim';

const initialState: FetchVisitApplyNoteHistoryInitialPropsInterface = {
  loadingApplyNoteHistory: false,
  applyNoteHistoryData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  errorApplyNoteHistory: null,
  statusApplyNoteHistory: 'IDLE',
};

export const getClaimApplyNoteHistoryRequest: any = createAsyncThunk(
  'claim/apply-note-history/get',
  async (searchParams: FetchVisitsApplyNoteHistoryInterface, thunkAPI: any) => {
    try {
      const response: any = await getClaimApplyNoteHistoryAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getClaimApplyNoteHistorySlice = createSlice({
  name: 'fetchClaimNoteHistory',
  initialState,
  reducers: {
    clearFetchVisitApplyNoteHistoryResponse: (state) => {
      state.loadingApplyNoteHistory = false;
      state.statusApplyNoteHistory = 'IDLE';
      state.applyNoteHistoryData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.errorApplyNoteHistory = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimApplyNoteHistoryRequest.pending, (state, action) => {
        state.loadingApplyNoteHistory = true;
        state.statusApplyNoteHistory = 'PENDING';
      })
      .addCase(getClaimApplyNoteHistoryRequest.fulfilled, (state, action) => {
        state.loadingApplyNoteHistory = false;
        state.statusApplyNoteHistory = 'SUCCESS';
        state.applyNoteHistoryData = action.payload;
      })
      .addCase(getClaimApplyNoteHistoryRequest.rejected, (state, action) => {
        state.loadingApplyNoteHistory = false;
        state.errorApplyNoteHistory = action.payload;
        state.statusApplyNoteHistory = 'FAILED';
      });
  },
});

export const { clearFetchVisitApplyNoteHistoryResponse } = getClaimApplyNoteHistorySlice.actions;

export const getClaimApplyNoteHistorySelector = (state: RootState) => state.FetchClaimApplyNoteHistory;

export default getClaimApplyNoteHistorySlice.reducer;
