import { useState, useEffect } from 'react';
import { BatchTable } from './BatchTable';

const dummyDataSource = [
  {
    id: 375,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
  {
    id: 2,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
  {
    id: 3,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
  {
    id: 4,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
  {
    id: 375,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
  {
    id: 2,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
  {
    id: 3,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
  {
    id: 4,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
];

const onView = (data: any) => {};

const onEdit = (data: any) => {};

export const AllBatches = ({ allBatchesData, onEdit, onView, onApply, onLock, fetchBatchPayments }: any) => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    setDataSource(allBatchesData?.items);
    setCurrentPage(allBatchesData?.currentPage);
    setTotalPages(allBatchesData?.totalPages);
  }, [allBatchesData]);

  const handlePageChange = (page: number) => {
    // setCurrentPage(page - 1);
    // fetchBatchPayments('all', page - 1);
  };

  return (
    <>
      <BatchTable
        dataSource={dummyDataSource}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onView={onView}
        onEdit={onEdit}
        onApply={onApply}
      />
    </>
  );
};
