import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  ProviderProfessionalEditPropsInterface,
  ProviderProfessionalUpdateInterface,
} from "../../../interfaces/providersInterface";
import { updateProviderProfessionalAPIRequest } from "../../../api/provider/providerProfessional";

const initialState: ProviderProfessionalEditPropsInterface = {
  editProviderProfessionalLoading: false,
  editProviderProfessionalData: {},
  editProviderProfessionalError: null,
  editProviderProfessionalStatus: "IDLE",
};

export const editProviderProfessionalRequest: any = createAsyncThunk(
  "provider/professional/edit",
  async (provider: ProviderProfessionalUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updateProviderProfessionalAPIRequest(
        provider
      );
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editProviderProfessionalSlice = createSlice({
  name: "editProviderProfessional",
  initialState,
  reducers: {
    clearEditProviderProfessionalResponse: (state) => {
      state.editProviderProfessionalLoading = false;
      state.editProviderProfessionalStatus = "IDLE";
      state.editProviderProfessionalData = {};
      state.editProviderProfessionalError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editProviderProfessionalRequest.pending, (state, action) => {
        state.editProviderProfessionalLoading = true;
        state.editProviderProfessionalStatus = "PENDING";
      })
      .addCase(editProviderProfessionalRequest.fulfilled, (state, action) => {
        state.editProviderProfessionalData = action.payload;
        state.editProviderProfessionalStatus = "SUCCESS";
        state.editProviderProfessionalLoading = false;
      })
      .addCase(editProviderProfessionalRequest.rejected, (state, action) => {
        state.editProviderProfessionalLoading = false;
        state.editProviderProfessionalError = action.payload;
        state.editProviderProfessionalStatus = "FAILED";
      });
  },
});

export const { clearEditProviderProfessionalResponse } =
  editProviderProfessionalSlice.actions;

export const editProviderProfessionalalSelector = (state: RootState) =>
  state.EditProviderProfessional;

export default editProviderProfessionalSlice.reducer;
