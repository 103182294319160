import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { addPatientAPIRequest } from '../../../api/patient/patient';
import {
  AddPatientInitialInterface,
  AddVisitCPTCodePropsInterface,
  CPTCodeUpdateInterface,
  PatientAddInterface,
  PatientAddressAddInterface,
  PatientAddressAddPropsInterface,
} from '../../../interfaces/patientInterface';
import { addPatientAddressAPIRequest } from '../../../api/patient/patientAddress';
import { addVisitCPTCodeAPIRequest } from '../../../api/patient/patientVisit';

const initialState: AddVisitCPTCodePropsInterface = {
  addVisitCPTCodeLoading: false,
  addVisitCPTCodeData: {},
  addVisitCPTCodeError: null,
  addVisitCPTCodeStatus: 'IDLE',
};

export const addVisitCPTCodeRequest: any = createAsyncThunk(
  'add/visit/cpt',
  async (patient: CPTCodeUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await addVisitCPTCodeAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addVisitCPTCodeSlice = createSlice({
  name: 'addVisitCPTCode',
  initialState,
  reducers: {
    clearAddVisitCPTCodeResponse: (state) => {
      state.addVisitCPTCodeLoading = false;
      state.addVisitCPTCodeStatus = 'IDLE';
      state.addVisitCPTCodeData = {};
      state.addVisitCPTCodeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addVisitCPTCodeRequest.pending, (state, action) => {
        state.addVisitCPTCodeLoading = true;
        state.addVisitCPTCodeStatus = 'PENDING';
      })
      .addCase(addVisitCPTCodeRequest.fulfilled, (state, action) => {
        state.addVisitCPTCodeData = action.payload;
        state.addVisitCPTCodeStatus = 'SUCCESS';
        state.addVisitCPTCodeLoading = false;
      })
      .addCase(addVisitCPTCodeRequest.rejected, (state, action) => {
        state.addVisitCPTCodeLoading = false;
        state.addVisitCPTCodeError = action.payload;
        state.addVisitCPTCodeStatus = 'FAILED';
      });
  },
});

export const { clearAddVisitCPTCodeResponse } = addVisitCPTCodeSlice.actions;

export const addVisitCPTCodeSelector = (state: RootState) => state.AddVisitCPTCode;

export default addVisitCPTCodeSlice.reducer;
