import {
  CreateClaimInterface,
  EditClaimInterface,
  FetchClaimsInterface,
  SendClaimsInterface,
} from '../../interfaces/billingInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getClaimsAPIRequest = async (claimData: FetchClaimsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/claim/search?page=${claimData?.page}&size=${claimData?.size}`,
    'post',
    {
      data: claimData?.filters ?? {},
    }
  );
};

export const sendClaimAPIRequest = async (claimData: SendClaimsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/claim/${claimData?.claimId}/send-claim`,
    'post',
    {
      data: {},
    }
  );
};

export const createClaimAPIRequest = async (claimData: CreateClaimInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${claimData?.patientId}/visit/${claimData?.visitId}/create-claim`,
    'post',
    {
      data: {},
    }
  );
};

export const getClaimByIdAPIRequest = async (claimData: SendClaimsInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/claim/${claimData?.claimId}`, 'get');
};

export const editClaimAPIRequest = async (claimData: EditClaimInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/claim/${claimData?.claimId}`, 'post', {
    data: claimData?.claimEdit,
  });
};

export const sendBulkClaimsAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/claim/send-claim-list`, 'post', {
    data: {},
  });
};
