import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { InsuranceUpdateInterface, InsuranceEditPropsInterface } from '../../../interfaces/insuranceInterface';

const initialState: InsuranceEditPropsInterface = {
  editInsuranceLoading: false,
  editInsuranceData: {},
  editInsuranceStatus: 'IDLE',
  editInsuranceError: null,
};

export const editInsuranceRequest: any = createAsyncThunk(
  'insurance/edit',
  async (insurance: InsuranceUpdateInterface, thunkAPI: any) => {
    try {
      //   const response: any = await editInsuranceAPIRequest(insurance);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editInsuranceSlice = createSlice({
  name: 'editInsurance',
  initialState,
  reducers: {
    clearEditInsuranceResponse: (state) => {
      state.editInsuranceLoading = false;
      state.editInsuranceStatus = 'IDLE';
      state.editInsuranceData = {};
      state.editInsuranceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editInsuranceRequest.pending, (state, action) => {
        state.editInsuranceLoading = true;
        state.editInsuranceStatus = 'PENDING';
      })
      .addCase(editInsuranceRequest.fulfilled, (state, action) => {
        state.editInsuranceLoading = false;
        state.editInsuranceStatus = 'SUCCESS';
        state.editInsuranceData = action.payload;
      })
      .addCase(editInsuranceRequest.rejected, (state, action) => {
        state.editInsuranceLoading = false;
        state.editInsuranceStatus = 'FAILED';
        state.editInsuranceError = action.payload;
      });
  },
});

export const { clearEditInsuranceResponse } = editInsuranceSlice.actions;

export const editInsuranceSelector = (state: RootState) => state.EditInsurance;

export default editInsuranceSlice.reducer;
