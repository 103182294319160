import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientElFetchByVisitIdInitialPropsInterface,
  PatientVisitFetchByIdInitialPropsInterface,
  PatientVisitGetByIdInterface,
} from '../../../interfaces/patientInterface';
import { getPatientElByVisitIdAPIRequest, getPatientVisitByIdAPIRequest } from '../../../api/patient/patientVisit';

const initialState: PatientElFetchByVisitIdInitialPropsInterface = {
  fetchPatientElByVisitIdLoading: false,
  fetchPatientElByVisitIdData: {},
  fetchPatientElByVisitIdStatus: 'IDLE',
  fetchPatientElByVisitIdError: null,
};

export const getPatientElByVisitIdRequest: any = createAsyncThunk(
  'patients/get/visit/id/eligibility',
  async (searchParams: PatientVisitGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getPatientElByVisitIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchPatientElByVisitIdSlice = createSlice({
  name: 'fetchPatientElByVisitId',
  initialState,
  reducers: {
    clearFetchPatientElByVisitIdResponse: (state) => {
      state.fetchPatientElByVisitIdLoading = false;
      state.fetchPatientElByVisitIdData = {};
      state.fetchPatientElByVisitIdStatus = 'IDLE';
      state.fetchPatientElByVisitIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientElByVisitIdRequest.pending, (state, action) => {
        state.fetchPatientElByVisitIdLoading = true;
        state.fetchPatientElByVisitIdStatus = 'PENDING';
      })
      .addCase(getPatientElByVisitIdRequest.fulfilled, (state, action) => {
        state.fetchPatientElByVisitIdLoading = false;
        state.fetchPatientElByVisitIdStatus = 'SUCCESS';
        state.fetchPatientElByVisitIdData = action.payload;
      })
      .addCase(getPatientElByVisitIdRequest.rejected, (state, action) => {
        state.fetchPatientElByVisitIdLoading = false;
        state.fetchPatientElByVisitIdError = action.payload;
        state.fetchPatientElByVisitIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchPatientElByVisitIdResponse } = fetchPatientElByVisitIdSlice.actions;

export const getPatientElByVisitIdSelector = (state: RootState) => state.FetchPatientElByVisitId;

export default fetchPatientElByVisitIdSlice.reducer;
