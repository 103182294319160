import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';

import { Modal } from 'flowbite-react';
import { InputField } from '../../../atoms/InputField';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { CommonButton } from '../../../atoms/CommonButton';
import { SelectInput } from '../../../atoms/SelectInput';
import { Checkbox } from '../../../atoms/Checkbox';
import { TextArea } from '../../../atoms/TextArea';
import { CommonAlert } from '../../../atoms/Alert';

import { billingCodes } from '../../../../constants/patientConstants';
import { whoPaid } from '../../../../constants/patientConstants';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';

export const AddPayment = ({
  visibleAddPaymentModel,
  handleOnClose,
  mode,
  addPaymentFormData,
  onChange,
  onDateChange,
  onSubmit,
  alertObj,
  visibleAlert,
  handleAlertClose,
}: any) => {
  return (
    <Modal show={visibleAddPaymentModel} onClose={handleOnClose} size="5xl">
      <Modal.Header>Add Payment/Adjustment</Modal.Header>
      <Modal.Body>
        {visibleAlert && !alertObj?.error && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-1/2"
          />
        )}
        <div className="grid md:grid-cols-4 gap-4 mt-6">
          <InputField
            label="Claim ID"
            name="claimId"
            onChange={onChange}
            defaultValue={addPaymentFormData?.claimId ?? undefined}
            disabled
          />
          <DatePickerField
            label="Payment Date"
            name="paymentDate"
            selectedDate={formatDatePickerDate(addPaymentFormData?.paymentDate)}
            onChange={(date: Date | null) => {
              if (date) {
                onDateChange('paymentDate', date);
              }
            }}
            // required={true}
          />
          <SelectInput
            label="Billing Code"
            options={billingCodes}
            enableDefaultPlaceholder={true}
            name="billingCode"
            onChange={onChange}
            value={addPaymentFormData?.billingCode ?? undefined}
            // required={true}
          />
          <SelectInput
            label="Who Paid"
            options={whoPaid}
            enableDefaultPlaceholder={true}
            name="whoPaid"
            onChange={onChange}
            value={addPaymentFormData?.whoPaid ?? undefined}
            // required={true}
          />
        </div>
        <div className="grid md:grid-cols-4 gap-4 mt-6">
          <InputField
            label="Amount"
            name="amount"
            onChange={onChange}
            defaultValue={addPaymentFormData?.amount ?? undefined}
          />
          <InputField
            label="Allowed Amount"
            name="allowedAmount"
            onChange={onChange}
            defaultValue={addPaymentFormData?.allowedAmount ?? undefined}
          />
          <InputField
            label="Check Number"
            name="checkNumber"
            onChange={onChange}
            defaultValue={addPaymentFormData?.checkNumber ?? undefined}
          />
          <InputField
            label="Reason Code"
            name="reasonCode"
            onChange={onChange}
            defaultValue={addPaymentFormData?.reasonCode ?? undefined}
          />
        </div>
        <div className="grid md:grid-cols-4 gap-4 mt-6">
          <div className="col-span-2">
            <TextArea
              label="Description"
              name="description"
              onChange={onChange}
              defaultValue={addPaymentFormData?.description ?? undefined}
            />
          </div>

          <DatePickerField
            label="Bank Posting Date"
            name="bankPostingDate"
            selectedDate={formatDatePickerDate(addPaymentFormData?.bankPostingDate)}
            onChange={(date: Date | null) => {
              if (date) {
                onDateChange('bankPostingDate', date);
              }
            }}
            // required={true}
          />
          <Checkbox
            label="Complete"
            name="complete"
            onChange={onChange}
            defaultChecked={addPaymentFormData?.complete ?? undefined}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="grid md:grid-cols-2">
        <div className="col-span-1"></div>
        <div className="col-span-1 grid md:grid-cols-2 gap-2">
          <CommonButton type="button" label={'Close'} buttonType="secondary" onClick={handleOnClose} />
          <CommonButton type="button" label={'Submit'} buttonType="primary" onClick={onSubmit} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
