import { CodeFormPropsInterface } from "../../../interfaces/userInterface";
import { Checkbox } from "../../atoms/Checkbox";
import { InputField } from "../../atoms/InputField";
import { SelectInput } from "../../atoms/SelectInput";

export const PaymentRightSheetForm: React.FC<CodeFormPropsInterface> = ({
  onChange,
  codeData,
  mode,
  selectedId,
  errorObject,
  types,
}) => {
  // console.log('=== personalData ===', personalData);

  return (
    <>
      <>
        <div className="grid md:grid-cols-3 gap-4 mt-6">
          <InputField
            label="Code"
            name="paymentCode"
            onChange={onChange}
            defaultValue={codeData?.paymentCode ?? undefined}
            disabled={mode === "VIEW" ? true : false}
            required={true}
            error={errorObject && errorObject?.paymentCode}
            errorMessage={errorObject && errorObject?.paymentCode}
          />

          <SelectInput
            label="Type"
            options={types}
            enableDefaultPlaceholder={true}
            name="type"
            onChange={onChange}
            defaultValue={codeData?.type}
            error={errorObject && errorObject?.type}
            errorMessage={errorObject && errorObject?.type}
          />

          <Checkbox
            label="Active"
            onChange={onChange}
            name="active"
            value={codeData?.active}
            defaultChecked={codeData?.active}
          />
        </div>

        <div className="grid md:grid-cols-1 gap-4 mt-6">
          <InputField
            label="Description"
            name="description"
            onChange={onChange}
            defaultValue={codeData?.description ?? undefined}
            disabled={mode === "VIEW" ? true : false}
            // error={errorObject && errorObject?.firstName}
            // errorMessage={errorObject && errorObject?.firstName}
          />
        </div>

        <div className="grid md:grid-cols-1 gap-4 mt-6">
          <Checkbox
            label="Display in Statement"
            onChange={onChange}
            name="displayStatement"
            value={codeData?.displayStatement}
            defaultChecked={codeData?.displayStatement}
          />
        </div>
      </>
    </>
  );
};
