import { PageTopicInterface } from '../../interfaces/commonInterfaces';
import { CommonButton } from '../atoms/CommonButton';
import './pageTopic.css';

export const PageTopic: React.FC<PageTopicInterface> = ({
  onClick,
  mainTitle,
  primaryButtonTitle = 'Add New',
  secondaryButtonTitle,
  optionalButtonTitle,
  enablePrimaryButton = true,
  enableSecondaryButton = false,
  enableOptionalButton = false,
  onClickSecondary,
  onClickOption,
  primaryButtonIcon,
  secondaryButtonIcon,
  optionalButtonIcon,
  breadCrumbArr,
}) => {
  return (
    <>
      <div className="main-content__page-topic">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 xl-heading dark:text-white">{mainTitle}</h1>
          <div className="breadcrumb mt-2" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              {breadCrumbArr?.map((item: any, key: any) => {
                return (
                  <li className="breadcrumb__list-item" key={key}>
                    <a
                      href={breadCrumbArr?.length - 1 != key && item?.link}
                      className={`${
                        item?.status === 'active'
                          ? 'breadcrumb__list-item-link'
                          : 'breadcrumb__list-inactive-item--text'
                      }`}
                    >
                      {item?.label}
                    </a>{' '}
                    {breadCrumbArr?.length - 1 != key ? (
                      <span className="breadcrumb__list-inactive-item--text">/</span>
                    ) : (
                      ''
                    )}
                  </li>
                );
              })}
              {/* <li className="breadcrumb__list-item">
                <a href="dashboard.html" className="breadcrumb__list-item-link">
                  Dashboard
                </a>
              </li> */}
              {/* <li aria-current="page">
                <div className="flex items-center breadcrumb__list-inactive-item">
                  <svg
                    className="w-3 h-3 mx-1 text-gray-400 rtl:rotate-180 dark:text-gray6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <span className="breadcrumb__list-inactive-item--text">Users</span>
                </div>
              </li> */}
            </ol>
          </div>
        </div>

        <div>
          <div className="main-content__page-topic-filter">
            {enableSecondaryButton && (
              <CommonButton
                label={secondaryButtonTitle}
                icon={secondaryButtonIcon}
                buttonType="secondary"
                onClick={onClickSecondary}
              />
            )}
            {enablePrimaryButton && (
              <CommonButton
                label={primaryButtonTitle}
                icon={primaryButtonIcon}
                buttonType="detailed"
                onClick={onClick}
              />
            )}
            {enableOptionalButton && (
              <CommonButton
                label={optionalButtonTitle}
                icon={optionalButtonIcon}
                buttonType="detailed"
                onClick={onClickOption}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
