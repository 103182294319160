import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { NestedTableContent } from '../../components/table/NestedTableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { VisitBilledCountAdvanceSearch } from '../../components/tableSearch/VisitBilledCountAdvanceSearch';

import {
  getVisitBilledCountsRequest,
  getVisitBilledCountsSelector,
  clearFetchVisitBilledCountsResponse,
} from '../../redux/slices/management/visitBilledCount/getVisitBilledCountSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';
import {
  getSystemFacilityRequest,
  getSystemFacilitiesSelector,
} from '../../redux/slices/system/getSystemFacilitiesSlice';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Month',
    dataIndex: 'month',
    key: 'month',
  },
  {
    title: 'Facility',
    dataIndex: 'facility',
    key: 'facility',
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'Month to Date',
    key: 'monthToDate',
    children: [
      { title: 'Visits Created', dataIndex: 'monthlyVisitsCreated', key: 'monthlyVisitsCreated' },
      { title: 'Visits Billed', dataIndex: 'monthlyVisitsBilled', key: 'monthlyVisitsBilled' },
    ],
  },
  {
    title: 'Year to Date',
    key: 'yearToDate',
    children: [
      { title: 'Visits Created', dataIndex: 'yearlyVisitsCreated', key: 'yearlyVisitsCreated' },
      { title: 'Visits Billed', dataIndex: 'yearlyVisitsBilled', key: 'yearlyVisitsBilled' },
    ],
  },
];

const dummyDataSource = [
  {
    month: 'October 2024',
    facility: 'Medicare Solutions',
    provider: 'Brian Jane',
    monthlyVisitsCreated: 30,
    monthlyVisitsBilled: 18,
    yearlyVisitsCreated: 89,
    yearlyVisitsBilled: 65,
  },
  {
    month: 'September 2024',
    facility: 'HealthCare Plus',
    provider: 'Sara Smith',
    monthlyVisitsCreated: 25,
    monthlyVisitsBilled: 20,
    yearlyVisitsCreated: 95,
    yearlyVisitsBilled: 75,
  },
  {
    month: 'August 2024',
    facility: 'Care Solutions',
    provider: 'John Doe',
    monthlyVisitsCreated: 40,
    monthlyVisitsBilled: 30,
    yearlyVisitsCreated: 120,
    yearlyVisitsBilled: 90,
  },
  {
    month: 'July 2024',
    facility: 'Care Plus',
    provider: 'Emily Johnson',
    monthlyVisitsCreated: 35,
    monthlyVisitsBilled: 25,
    yearlyVisitsCreated: 110,
    yearlyVisitsBilled: 85,
  },
  {
    month: 'June 2024',
    facility: 'Pediatric Health',
    provider: 'Jessica Williams',
    monthlyVisitsCreated: 50,
    monthlyVisitsBilled: 40,
    yearlyVisitsCreated: 150,
    yearlyVisitsBilled: 100,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'visitBilledCount', label: 'Visit Billed Count', status: 'active', link: 'visit-billed-count' },
];

export const VisitBilledCountPage = () => {
  const dispatch = useAppDispatch();

  const { fetchVisitBilledCountListStatus, fetchVisitBilledCountListData, fetchVisitBilledCountListLoading } =
    useAppSelector(getVisitBilledCountsSelector);
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const currentYear = moment().year();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({ year: currentYear.toString() });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (systemFacilityData?.length === 0 || systemProvidersProviderData?.length === 0) {
      fetchSystemFacilities();
      fetchSystemProviders();
    }
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchVisitBilledCountList();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchVisitBilledCountListStatus === 'SUCCESS') {
      setDataSource(fetchVisitBilledCountListData?.items);
      setCurrentPage(fetchVisitBilledCountListData?.currentPage);
      setTotalPages(fetchVisitBilledCountListData?.totalPages);
    } else if (fetchVisitBilledCountListStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchVisitBilledCountsResponse());
    }
  }, [fetchVisitBilledCountListStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchVisitBilledCountList(page - 1);
  };

  const fetchVisitBilledCountList = async (pageNumber = currentPage) => {
    dispatch(getVisitBilledCountsRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const fetchSystemFacilities = async () => {
    dispatch(getSystemFacilityRequest());
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerType || updatedData?.providerType === '') {
      delete updatedData.providerType;
    } else {
      updatedData.providerType = parseInt(updatedData?.providerType, 10);
    }

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.facilityId || updatedData?.facilityId === '') {
      delete updatedData.facilityId;
    } else {
      updatedData.facilityId = parseInt(updatedData?.facilityId, 10);
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Visit Billed Count" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
      <VisitBilledCountAdvanceSearch onSubmit={handleSearch} />
      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchVisitBilledCountListLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <NestedTableContent columns={columns} dataSource={dummyDataSource} />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
