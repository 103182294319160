import { useEffect, useState } from 'react';
import { MainHeader } from '../components/mainHeader/MainHeader';
import { PageTopic } from '../components/pageTopic/PageTopic';
import { Pagination } from '../components/pagination/Pagination';
import { TableContent } from '../components/table/TableContent';
import { VisitsTableAdvanceSearch } from '../components/tableSearch/VisitsTableAdvanceSearch';
import { EmptyContent } from '../components/emptyContent/EmptyContent';
import { VisitsTableActionArea } from '../components/tableActionArea/VisitsTableActionArea';
import { useAppDispatch, useAppSelector } from '../hooks/storeHooks/hooks';
import { getVisitRequest, getVisitsSelector } from '../redux/slices/billing/getVisitsSlice';
import { CommonAlert } from '../components/atoms/Alert';
import { Spinner } from '../components/atoms/Spinner';
import { getCPTCodeListRequest, getCPTListSelector } from '../redux/slices/patient/getCPTCodeListSlice';
import {
  clearFetchICDResponse,
  getICDCodeListRequest,
  getICDListSelector,
} from '../redux/slices/patient/getICDCodeListSlice';
import {
  clearFetchVisitNoteHistoryResponse,
  getVisitNoteHistoryRequest,
  getVisitNoteHistorySelector,
} from '../redux/slices/patient/getVisitNoteHistorySlice';
import { addVisitNoteSelector, clearAddVisitNoteResponse } from '../redux/slices/patient/addVisitNoteSlice';
import {
  clearFetchVisitStatusHistoryResponse,
  getVisitStatusHistoryRequest,
  getVisitStatusHistorySelector,
} from '../redux/slices/patient/getVisitStatusHistorySlice';
import { clearFetchICDCodesResponse, getICDCodesSelector } from '../redux/slices/system/getICDCodesSlice';
import { clearFetchCPTCodesResponse, getCPTCodesSelector } from '../redux/slices/system/getCPTCodesSlice';
import { getModifiersSelector } from '../redux/slices/system/getModifiersSlice';
import { getPatientsByIdSelector } from '../redux/slices/patient/getPatientByIdSlice';
import { addPatientVisitSelector } from '../redux/slices/patient/addPatientVisitSlice';
import {
  clearFetchPatientVisitByIdResponse,
  getPatientVisitByIdRequest,
  getPatientVisitByIdSelector,
} from '../redux/slices/patient/getPatientVisitByIdSlice';
import { deletePatientVisitByIdSelector } from '../redux/slices/patient/deletePatientVisitSlice';
import {
  clearEditPatientVisitResponse,
  editPatientVisitRequest,
  editPatientVisitSelector,
} from '../redux/slices/patient/editPatientVisitSlice';
import {
  clearUpdatePatientVisitEIByIdResponse,
  updatePatientVisitEIByIdRequest,
  updatePatientVisitEIByIdSelector,
} from '../redux/slices/patient/updateEligibilityInquirySlice';
import {
  getVisitsStatsRequest,
  getVisitsStatsSelector,
  clearFetchVisitsStatsResponse,
} from '../redux/slices/billing/getVisitsStatsSlice';
import { createViewEligibilityObject, validDateFormat } from '../utils/commonFunctions';
import { RightSheetLarge } from '../components/rightSheet/RightSheetLarge';
import { VisitEditForm } from '../components/list/patients/visit/VisitEditForm';
import moment from 'moment';
import { convertDate } from '../utils/commonFunctions';
import { ViewEligibilityInquiry } from '../components/list/patients/visit/ViewEligibilityInquiry';
import { EditVisitStatus } from '../components/list/patients/visit/EditVisitStatusModal';
import { NotesModal } from '../components/list/patients/visit/NotesModal';
import { StatusHistoryModal } from '../components/list/patients/visit/StatusHistoryModal';
import { RightSheet } from '../components/rightSheet/RightSheet';
import { EligibilityForm } from '../components/list/patients/visit/EligibilityForm';
import { createPatientVisitEIValidation } from '../utils/patientValidation';
import {
  clearFetchPatientElByVisitIdResponse,
  getPatientElByVisitIdRequest,
  getPatientElByVisitIdSelector,
} from '../redux/slices/patient/getPatientEligibilityDataByVisitIdSlice';
import {
  clearFetchPatientInsuranceByTypeResponse,
  getPatientInsuranceByTypeRequest,
  getPatientInsuranceByTypeSelector,
} from '../redux/slices/patient/getPatientInsuranceByTypeSlice';
import { claimStatus } from '../constants/patientConstants';
import {
  clearFetchViewEIByIdResponse,
  getViewEIByIdRequest,
  getViewEIByIdSelector,
} from '../redux/slices/patient/getPatientViewEIByIdSlice';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import { createClaimRequest, createClaimSelector } from '../redux/slices/billing/createClaimSlice';
import { clearCreateClaimsResponse } from '../redux/slices/patient/createClaimsSlice';
import { getIPDataRequest } from '../redux/slices/system/getIPDataSlice';
import { getSystemVisitStatusRequest } from '../redux/slices/system/getSystemVisitStatusSlice';
import { getSystemFacilitiesSelector, getSystemFacilityRequest } from '../redux/slices/system/getSystemFacilitiesSlice';
import { getSystemInsuranceRequest, getSystemInsuranceSelector } from '../redux/slices/system/getSystemInsuranceSlice';
import { getSystemProvidersRequest, getSystemProvidersSelector } from '../redux/slices/system/getSystemProvidersSlice';

const PAGE_SIZE = 10;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const columns = [
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'CPT',
    dataIndex: 'cpt',
    key: 'cpt',
  },
  {
    title: 'Patient',
    dataIndex: 'patient',
    key: 'patient',
    render: (text: any, row: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href={`patients?id=${row?.patientId}`}>
        {/* {console.log('=== index ===', row)} */}
        {text}
      </a>
    ),
  },
  {
    title: 'DOB',
    dataIndex: 'dob',
    key: 'dob',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
    render: (text: any, row: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href={`providers?id=${row?.providerId}`}>
        {/* {console.log('=== index ===', row)} */}
        {text}
      </a>
    ),
  },
  {
    title: 'Carrier',
    dataIndex: 'carrier',
    key: 'carrier',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const dottedActionsArr = [
  {
    id: 'ELIGIBILITY_FORM',
    title: 'Eligibility Inquiry',
  },
  {
    id: 'VIEW_ELIGIBILITY',
    title: 'View Eligibility',
  },
  {
    id: 'EDIT_ELIGIBILITY',
    title: 'Edit Eligibility Status',
  },
];

const settingsActionArr = [
  {
    id: 'EDIT',
    title: 'Edit',
    enable: true,
  },
  {
    id: 'VIEW',
    title: 'View',
    enable: true,
  },
  {
    id: 'EDIT_STATUS',
    title: 'Edit Status',
    enable: true,
  },
  {
    id: 'NOTES',
    title: 'Notes',
    enable: true,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'visit', label: 'Patient Visit', status: 'active', link: 'billing' },
];

export const BillingVisits = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const getId = query.get('id');
  const getPatientId = query.get('patientId');

  const { fetchPatientByIdData, fetchPatientByIdStatus } = useAppSelector(getPatientsByIdSelector);
  const { addPatientVisitStatus, addPatientVisitLoading } = useAppSelector(addPatientVisitSelector);
  const { deletePatientVisitByIdLoading, deletePatientVisitByIdStatus } =
    useAppSelector(deletePatientVisitByIdSelector);
  const { fetchPatientVisitByIdData, fetchPatientVisitByIdStatus, fetchPatientVisitByIdError } =
    useAppSelector(getPatientVisitByIdSelector);
  const { editPatientVisitError, editPatientVisitStatus } = useAppSelector(editPatientVisitSelector);
  const { visitsStatsData } = useAppSelector(getVisitsStatsSelector);

  const {
    updatePatientVisitEIByIdData,
    updatePatientVisitEIByIdLoading,
    updatePatientVisitEIByIdError,
    updatePatientVisitEIByIdStatus,
  } = useAppSelector(updatePatientVisitEIByIdSelector);
  const { visitLoading, visitData, visitStatus } = useAppSelector(getVisitsSelector);
  const { cptData, statusCPT, errorCPT } = useAppSelector(getCPTListSelector);
  const { icdData, statusICD, errorICD } = useAppSelector(getICDListSelector);
  const { noteHistoryData, statusNoteHistory, errorNoteHistory } = useAppSelector(getVisitNoteHistorySelector);
  const { addVisitNoteData, addVisitNoteStatus, addVisitNoteError } = useAppSelector(addVisitNoteSelector);
  const { historyStatus, statusHistoryData, errorStatusHistory } = useAppSelector(getVisitStatusHistorySelector);
  const { icdCodesData, icdCodesStatus, icdCodesError } = useAppSelector(getICDCodesSelector);
  const { cptCodesData, cptCodesStatus, cptCodesError } = useAppSelector(getCPTCodesSelector);
  const { modifiersData, modifiersStatus, modifiersError } = useAppSelector(getModifiersSelector);
  const { fetchPatientElByVisitIdData, fetchPatientElByVisitIdStatus, fetchPatientElByVisitIdError } =
    useAppSelector(getPatientElByVisitIdSelector);
  const { fetchPatientInsuranceByTypeStatus, fetchPatientInsuranceByTypeData, fetchPatientInsuranceByTypeError } =
    useAppSelector(getPatientInsuranceByTypeSelector);
  const { fetchViewEIByIdStatus, fetchViewEIByIdData, fetchViewEIByIdError } = useAppSelector(getViewEIByIdSelector);
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);
  const { systemInsuranceInsuranceData } = useAppSelector(getSystemInsuranceSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const { claimCreateStatus, claimCreateError } = useAppSelector(createClaimSelector);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [selectedVisitId, setSelectedVisitId] = useState<number>();
  const [errorObj, setErrorObj] = useState<any>({});

  const [visibleEligibilityForm, setVisibleEligibilityForm] = useState<boolean>(false);
  const [eligibilityFormEditData, setVisibleEligibilityFormData] = useState<any>({});
  const [visibleEInquiryView, setVisibleEInquiryView] = useState<boolean>(false);
  const [viewEIDetails, setViewEIDetails] = useState<any>({});

  const [cptDataSource, setCPTDataSource] = useState<any>([]);
  const [icdDataSource, setICDDataSource] = useState<any>([]);

  const [visibleEditStatus, setVisibleEditStatus] = useState<boolean>(false);

  const [visibleNotesModal, setVisibleNotesModal] = useState<boolean>(false);
  const [noteHistoryDataSource, setNoteHistoryDataSource] = useState<any>([]);

  const [visibleStatusHistory, setVisibleStatusHistoty] = useState<boolean>(false);
  const [statusHistoryDataSource, setStatusHistoryDataSource] = useState<any>([]);

  const [icdCodesDataSource, setICDCodesDataSource] = useState<any>([]);
  const [cptCodesDataSource, setCPTCodesDataSource] = useState<any>([]);
  const [modifiersDataSource, setModifiersDataSource] = useState<any>([]);

  const [patientVisitFormData, setPatientVisitFormData] = useState<any>({});
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [childMode, setChildMode] = useState('CREATE');
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [patientInsuranceList, setPatientInsuranceList] = useState<any>([]);
  const [selectedPatient, setSelectedPatient] = useState<any>();
  const [filterObject, setFilterObject] = useState<any>({});

  const [patientIdForEI, setPatientIdForEI] = useState<number>();
  const [selectedMenu, setSelectedMenu] = useState<string>('');
  const [dottedActionClicked, setDottedActionClicked] = useState<boolean>(false);
  const [settingActionClicked, setSettingActionClicled] = useState<boolean>(false);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchVisits(page - 1);
  };

  useEffect(() => {
    if (getId && getPatientId) {
      onEditQueryParams(getId, getPatientId);
    }
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setDottedActionClicked(false);
    setSettingActionClicled(false);
    setVisibleEligibilityForm(false);
    dispatch(getIPDataRequest());
    getVisitStatus();

    if (
      systemFacilityData?.length === 0 ||
      systemInsuranceInsuranceData?.length === 0 ||
      systemProvidersProviderData?.length === 0
    ) {
      fetchFacilities();
      fetchInsurance();
      fetchSystemProviders();
    }
  }, []);

  useEffect(() => {
    if (visitStatus === 'SUCCESS') {
      setDataSource(transformInput(visitData));
      setTotalPages(visitData?.totalPages);
      setCurrentPage(visitData?.currentPage);
    }
  }, [visitStatus]);
  // console.log('===fetchPatientVisitByIdStatus ===', fetchPatientVisitByIdStatus);

  useEffect(() => {
    if (fetchPatientVisitByIdStatus === 'SUCCESS') {
      setPatientVisitFormData(fetchPatientVisitByIdData);
      setVisibleEditModal(true);
    } else if (fetchPatientVisitByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: fetchPatientVisitByIdError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchPatientVisitByIdResponse());
  }, [fetchPatientVisitByIdStatus]);

  useEffect(() => {
    if (editPatientVisitStatus === 'SUCCESS') {
      // fetchVisits();
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      if (selectedVisitId && selectedPatient) {
        getVisitById(selectedVisitId, selectedPatient);
        getICDCodeList(selectedVisitId);
        getCPTCodeList(selectedVisitId);
        getPatientInsurance(selectedPatient);
      }
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setErrorObj({});
        // setPatientVisitFormData({});
        // setChildMode('CREATE');
      }, 3000);
      dispatch(clearEditPatientVisitResponse());
    } else if (editPatientVisitStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: editPatientVisitError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditPatientVisitResponse());
    }
  }, [editPatientVisitStatus]);

  useEffect(() => {
    if (updatePatientVisitEIByIdStatus === 'SUCCESS') {
      fetchVisits();
      // console.log('=== updatePatientVisitEIByIdData ===', updatePatientVisitEIByIdData);

      if (updatePatientVisitEIByIdData?.eligible === true) {
        setVisibleEligibilityFormData({});
        let handledObject = createViewEligibilityObject(updatePatientVisitEIByIdData?.edi271DecodeData);
        setViewEIDetails(handledObject);
      } else if (updatePatientVisitEIByIdData?.eligible === false) {
        setAlertObj({
          color: 'failure',
          message: updatePatientVisitEIByIdData?.errorMessage ?? 'Something went wrong !',
          error: true,
        });
        setVisibleAlert(true);
        setTimeout(() => {
          setAlertObj({
            color: '',
            message: '',
            error: false,
          });
          setVisibleAlert(false);
        }, 3000);
      }
    } else if (updatePatientVisitEIByIdStatus === 'FAILED') {
      // console.log('=== updatePatientVisitEIByIdError ===', updatePatientVisitEIByIdError);
      setAlertObj({
        color: 'failure',
        message: updatePatientVisitEIByIdError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [updatePatientVisitEIByIdStatus]);

  useEffect(() => {
    // console.log('=== viewEIDetails ===', viewEIDetails, Object.keys(viewEIDetails)?.length > 0);

    if (Object.keys(viewEIDetails)?.length > 0) {
      setVisibleEligibilityForm(false);
      setVisibleEInquiryView(true);
    }
  }, [viewEIDetails]);

  useEffect(() => {
    if (statusCPT === 'SUCCESS') {
      setCPTDataSource(cptData);
    } else if (statusCPT === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: errorCPT?.message ?? 'Something went wrong in CPT Data Source!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [statusCPT]);

  useEffect(() => {
    if (statusICD === 'SUCCESS') {
      setICDDataSource(icdData);
    } else if (statusICD === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: errorICD?.message ?? 'Something went wrong in ICD Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchICDResponse());
  }, [statusICD]);

  useEffect(() => {
    if (statusNoteHistory === 'SUCCESS') {
      // console.log('=== noteHistoryData ===', noteHistoryData);
      setNoteHistoryDataSource(noteHistoryData);
      setVisibleNotesModal(true);
    } else if (statusNoteHistory === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: errorNoteHistory?.message ?? 'Something went wrong in Notes History Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchVisitNoteHistoryResponse());
  }, [statusNoteHistory]);

  useEffect(() => {
    if (addVisitNoteStatus === 'SUCCESS') {
      setVisibleNotesModal(false);
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (addVisitNoteStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addVisitNoteError?.message ?? 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearAddVisitNoteResponse());
  }, [addVisitNoteStatus]);

  useEffect(() => {
    if (historyStatus === 'SUCCESS') {
      setStatusHistoryDataSource(statusHistoryData);
      if (selectedMenu === 'STATUS_HISTORY') {
        setVisibleStatusHistoty(true);
      } else if (selectedMenu === 'EDIT_STATUS') {
        setVisibleEditStatus(true);
      }
    } else if (historyStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: errorStatusHistory?.message ?? 'Something went wrong in Status History Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchVisitStatusHistoryResponse());
  }, [historyStatus]);

  useEffect(() => {
    // console.log('===icdCodesStatus =====', icdCodesStatus);
    if (icdCodesStatus === 'SUCCESS') {
      // console.log('===icdCodesData =====', icdCodesData);

      setICDCodesDataSource(icdCodesData?.items);
    } else if (icdCodesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: icdCodesError?.message ?? 'Something went wrong in ICD Codes Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchICDCodesResponse());
  }, [icdCodesStatus]);

  useEffect(() => {
    // console.log('===icdCodesStatus =====', icdCodesStatus);
    if (cptCodesStatus === 'SUCCESS') {
      // console.log('===icdCodesData =====', icdCodesData);

      setCPTCodesDataSource(cptCodesData?.items);
    } else if (cptCodesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: cptCodesError?.message ?? 'Something went wrong in CPT Codes Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchCPTCodesResponse());
  }, [cptCodesStatus]);

  useEffect(() => {
    if (modifiersStatus === 'SUCCESS') {
      setModifiersDataSource(modifiersData?.items);
    } else if (modifiersStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: modifiersError?.message ?? 'Something went wrong in Modifier Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [modifiersStatus]);

  useEffect(() => {
    if (fetchPatientElByVisitIdStatus === 'SUCCESS') {
      setVisibleEligibilityFormData(fetchPatientElByVisitIdData);
      if (dottedActionClicked) {
        setVisibleEligibilityForm(true);
      }
    } else if (fetchPatientElByVisitIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: fetchPatientElByVisitIdError?.message ?? 'Something went wrong in Patient Eligibility data!',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleEligibilityForm(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchPatientElByVisitIdResponse());
  }, [fetchPatientElByVisitIdStatus]);

  useEffect(() => {
    if (fetchPatientInsuranceByTypeStatus === 'SUCCESS') {
      setPatientInsuranceList(fetchPatientInsuranceByTypeData);
    } else if (fetchPatientInsuranceByTypeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: fetchPatientInsuranceByTypeError?.message ?? 'Something went wrong in Patient Insurance data!',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchPatientInsuranceByTypeResponse());
  }, [fetchPatientInsuranceByTypeStatus]);

  useEffect(() => {
    if (filterObject) {
      fetchVisits();
      getVisitsStats();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchViewEIByIdStatus === 'SUCCESS') {
      // console.log('=== view EI ===', fetchViewEIByIdData);
      let handledObject = createViewEligibilityObject(fetchViewEIByIdData);
      setViewEIDetails(handledObject);
    } else if (fetchViewEIByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: fetchViewEIByIdError?.message ?? 'Something went wrong in Patient Eligibility data!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchViewEIByIdResponse());
  }, [fetchViewEIByIdStatus]);

  useEffect(() => {
    if (claimCreateStatus === 'SUCCESS') {
      fetchVisits();
      setAlertObj({
        color: 'success',
        message: 'Successfully created the claim !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (claimCreateStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: claimCreateError?.message ?? 'Something went wrong in Creating Claim!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearCreateClaimsResponse());
  }, [claimCreateStatus]);

  const fetchFacilities = async () => {
    dispatch(getSystemFacilityRequest());
  };

  const fetchInsurance = async () => {
    dispatch(getSystemInsuranceRequest());
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const getVisitStatus = () => {
    dispatch(getSystemVisitStatusRequest());
  };

  const getVisitsStats = () => {
    dispatch(getVisitsStatsRequest(filterObject));
  };

  const getPatientInsurance = (patientId: number) => {
    dispatch(getPatientInsuranceByTypeRequest({ patientId, type: 'ALL' }));
  };

  const getVisitById = (visitId: number, patientId: number) => {
    dispatch(getPatientVisitByIdRequest({ patientId: patientId, visitId: visitId }));
  };

  const getICDCodeList = (visitId: number) => {
    dispatch(getICDCodeListRequest({ visitId }));
  };

  const getCPTCodeList = (visitId: number) => {
    dispatch(getCPTCodeListRequest({ visitId }));
  };

  const getNoteHistory = (visitId: number) => {
    dispatch(getVisitNoteHistoryRequest({ visitId }));
  };

  const getStatusHistory = (visitId: number) => {
    dispatch(getVisitStatusHistoryRequest({ visitId }));
  };

  const fetchVisits = async (page = currentPage) => {
    dispatch(getVisitRequest({ size: PAGE_SIZE, page: page ?? currentPage, filters: filterObject }));
  };

  const getEligibilityDataByVisit = (visitId: number, patientId: number) => {
    dispatch(getPatientElByVisitIdRequest({ patientId: patientId, visitId: visitId }));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  function transformInput(input: any): any {
    return [
      ...input.items.map((item: any) => ({
        visitId: item.visitId,
        visitDate: item.visitDate,
        cpt: item.cpt,
        patient: item.patient.name,
        dob: item.dob,
        provider: item.provider.name,
        carrier: item.carrier.name,
        status: item.status,
        patientId: item.patient.id,
        providerId: item?.provider?.id,
        eligibilityId: item?.eligibilityId,
      })),
    ];
  }

  const getViewEligibilityData = (visitId: number, eligibilityId: number, patientId: number) => {
    dispatch(getViewEIByIdRequest({ visitId: visitId, patientId: patientId, eligibilityId: eligibilityId }));
  };

  const handleDottedActions = (id: any, rowIndex: any) => {
    setDottedActionClicked(true);
    setSettingActionClicled(false);
    const dataSet = dataSource[rowIndex];
    if (id === 'ELIGIBILITY_FORM') {
      // setVisibleEligibilityForm(true);
      getPatientInsurance(dataSet?.patientId);
      setSelectedVisitId(dataSet?.visitId);
      getEligibilityDataByVisit(dataSet?.visitId, dataSet?.patientId);
      setPatientIdForEI(dataSet?.patientId);
    } else if (id === 'VIEW_ELIGIBILITY') {
      setSelectedVisitId(dataSet?.visitId);
      setPatientIdForEI(dataSet?.patientId);
      getViewEligibilityData(dataSet?.visitId, dataSet?.eligibilityId, dataSet?.patientId);
    }
  };

  const handleSettingsAction = (id: any, rowIndex: any) => {
    setSettingActionClicled(true);
    setDottedActionClicked(false);
    setErrorObj({});
    const dataSet = dataSource[rowIndex];
    // console.log('=== dataSet ===', dataSet);
    if (id === 'EDIT') {
      getEditViewData('EDIT', dataSet);
    } else if (id === 'EDIT_STATUS') {
      setSelectedMenu('EDIT_STATUS');
      setSelectedVisitId(dataSet?.visitId);
      setSelectedToEdit(dataSet);
      getStatusHistory(dataSet?.visitId);
    } else if (id === 'NOTES') {
      setSelectedVisitId(dataSet?.visitId);
      getNoteHistory(dataSet?.visitId);
      setSelectedToEdit(dataSet);
    } else if (id === 'VIEW') {
      getEditViewData('VIEW', dataSet);
    }
  };

  const getEditViewData = (type: string, dataSet: any) => {
    setChildMode(type);
    setSelectedToEdit(dataSet);
    setSelectedVisitId(dataSet?.visitId); //dataSet?.visitId
    setSelectedPatient(dataSet?.patientId);
    getVisitById(dataSet?.visitId, dataSet?.patientId); //dataSet?.visitId dataSet?.patientId
    getICDCodeList(dataSet?.visitId); //dataSet?.visitId
    getCPTCodeList(dataSet?.visitId); //dataSet?.visitId
    getPatientInsurance(dataSet?.patientId);
  };

  const onEditQueryParams = (visitId: any, patientId: any) => {
    setChildMode('EDIT');
    setSelectedVisitId(visitId);
    setSelectedPatient(patientId);
    getVisitById(visitId, patientId);
    getICDCodeList(visitId);
    getCPTCodeList(visitId);
    getPatientInsurance(patientId);
  };

  const onViewHistory = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedMenu('STATUS_HISTORY');
    setSelectedVisitId(dataSource[rowIndex]?.visitId);
    getStatusHistory(dataSource[rowIndex]?.visitId);
  };

  const onCloseStatusHistoryModal = (data: boolean) => {
    setVisibleStatusHistoty(data);
  };

  const handleVisitFormChange = (e: any) => {
    setPatientVisitFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeVisitDate = (date: Date) => {
    setPatientVisitFormData((prev: any) => ({ ...prev, visitDate: moment(date).format('YYYY-MM-DD') }));
  };

  const onCloseModal = () => {
    fetchVisits();
    setPatientVisitFormData({});
    setErrorObj({});
    setChildMode('CREATE');
    setVisibleModal(false);
    setVisibleEditModal(false);
  };

  const onSaveEdit = () => {
    // console.log('===patientVisitFormData - onSubmit ==', patientVisitFormData);
    let dataObj = {
      patientVisitStatus: patientVisitFormData?.patientVisitStatus ?? patientVisitFormData?.status,
      caseId: patientVisitFormData?.caseId ? parseInt(patientVisitFormData?.caseId) : null,
      financialClass: patientVisitFormData?.visitFinancialClass ?? '',
      attendingProvider: patientVisitFormData?.attendingProviderName ?? patientVisitFormData?.attendingProvider?.name,
      primaryInsuranceId: patientVisitFormData?.primaryInsuranceId
        ? parseInt(patientVisitFormData?.primaryInsuranceId)
        : null,
      secondaryInsuranceId: patientVisitFormData?.secondaryInsuranceId
        ? parseInt(patientVisitFormData?.secondaryInsuranceId)
        : null,
      tertiaryInsuranceId: patientVisitFormData?.tertiaryInsuranceId
        ? parseInt(patientVisitFormData?.tertiaryInsuranceId)
        : null,
      noteToBiller: patientVisitFormData?.noteToBiller ?? '',
      facility: patientVisitFormData?.facilityName ?? patientVisitFormData?.facility?.name,
      assProvider: patientVisitFormData?.assProvider ?? '',
      refProvider: patientVisitFormData?.refProvider ?? '',
    };
    // console.log('===patientVisitFormData - dataObj ==', dataObj);

    const dispatchObj = {
      patientId: selectedPatient,
      visitId: selectedVisitId,
      visitData: dataObj,
    };

    dispatch(editPatientVisitRequest(dispatchObj));
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const onCloseEIModal = () => {
    setVisibleEligibilityFormData({});
    setVisibleEligibilityForm(false);
    setErrorObj({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const onSubmitEI = () => {
    const validationDetails = createPatientVisitEIValidation(eligibilityFormEditData);

    if (Object.keys(validationDetails?.newErrors)?.length > 0) {
      setErrorObj(validationDetails?.newErrors);
      const errorMessages = Object.values(validationDetails.newErrors);
      setVisibleAlert(true);
      setAlertObj({
        color: 'failure',
        message: (
          <div>
            {errorMessages.map((msg: any, index) => (
              <div key={index} className="flex items-center">
                <IoIosInformationCircleOutline />
                <span style={{ marginLeft: '8px' }}>{msg}</span>
              </div>
            ))}
          </div>
        ),
        error: true,
      });
    } else {
      let enquiryData = {
        lastName: eligibilityFormEditData.lastName ?? '',
        firstName: eligibilityFormEditData.firstName ?? '',
        policy: eligibilityFormEditData.policy ?? '',
        group: eligibilityFormEditData.group ?? '',
        insuranceId: eligibilityFormEditData.insuranceId ? parseInt(eligibilityFormEditData.insuranceId) : '',
        payerId: eligibilityFormEditData.payerId ?? '',
        receiverFirstName: eligibilityFormEditData.receiverFirstName ?? '',
        receiverLastNameOrOrganizationName: eligibilityFormEditData.receiverLastNameOrOrganizationName ?? '',
        npiType: eligibilityFormEditData.npiType ?? '',
        npi: eligibilityFormEditData.npi ?? '',
        patientDoB: eligibilityFormEditData.patientDoB ?? '',
        dateOfService: eligibilityFormEditData.dateOfService ?? '',
      };
      let obj = {
        patientId: patientIdForEI,
        visitId: selectedVisitId,
        enquiryData: enquiryData,
      };
      dispatch(updatePatientVisitEIByIdRequest(obj));
      // console.log('=== eligibilityFormEditData ===', eligibilityFormEditData);
    }
  };

  const handlePatientEIFormChange = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setVisibleEligibilityFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onEligibilityFormDates = (date: any, key: any) => {
    if (errorObj?.hasOwnProperty(key)) {
      delete errorObj[key];
    }
    setVisibleEligibilityFormData((prev: any) => ({ ...prev, [key]: moment(date).format('YYYY-MM-DD') }));
  };

  const onCloseEInquiry = (data: boolean) => {
    setVisibleEInquiryView(data);
    setViewEIDetails({});
    dispatch(clearUpdatePatientVisitEIByIdResponse());
  };

  const onCloseEditStatusModal = (data: boolean) => {
    setVisibleEditStatus(data);
  };

  const onCloseNotesModal = (data: boolean) => {
    setVisibleNotesModal(data);
  };

  const handleSearch = (data: any) => {
    // console.log('=== filters ===', data);
    if (data?.name === '') {
      delete data.name;
    }

    if (data?.visitId) {
      if (data?.visitId === '' || Number.isNaN(data.visitId)) {
        delete data.visitId;
      } else {
        data.visitId = parseInt(data?.visitId);
      }
    }

    if (data?.visitStatusId) {
      if (data?.visitStatusId === '') {
        delete data.visitStatusId;
      } else {
        data.visitStatusId = parseInt(data?.visitStatusId);
      }
    }

    if (data?.providerId) {
      if (data?.providerId === '') {
        delete data.providerId;
      } else {
        data.providerId = parseInt(data?.providerId);
      }
    }

    if (data?.facilityId) {
      // console.log('=== data?.facilityId ===', data?.facilityId);
      if (data?.facilityId === '') {
        delete data.facilityId;
      } else {
        data.facilityId = parseInt(data?.facilityId);
      }
    }
    setCurrentPage(0);
    setFilterObject(data);
  };

  const handleRemoveCPTCodeDataSource = () => {
    setCPTCodesDataSource([]);
  };

  const handleRemoveICDCodeDataSource = () => {
    setICDCodesDataSource([]);
  };

  const handleCreateClaim = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedVisitId(dataSet?.visitId); //dataSet?.visitId
    setSelectedPatient(dataSet?.patientId);
    dispatch(createClaimRequest({ patientId: dataSet?.patientId, visitId: dataSet?.visitId }));
  };

  return (
    <>
      <div className="main-content">
        <MainHeader />
        <PageTopic enablePrimaryButton={false} mainTitle="Patients Visit" breadCrumbArr={breadCrumbArr} />
        <VisitsTableAdvanceSearch onSubmit={handleSearch} />
        {/* <VisitsTableActionArea totalVisits={`${visitData.totalItems ?? 0}`} totalCharges={`$ ${0.0}`} /> */}
        <VisitsTableActionArea
          totalVisits={`${visitsStatsData.totalVisitCount ?? 0}`}
          totalCharges={`$ ${
            visitsStatsData?.totalAmount != null
              ? Number.isInteger(visitsStatsData?.totalAmount)
                ? visitsStatsData?.totalAmount.toFixed(1)
                : visitsStatsData?.totalAmount
              : '0.0'
          }`}
        />
        {visibleAlert && !alertObj?.error && (
          <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
        )}
        {visitLoading ? (
          <Spinner />
        ) : (
          <>
            {!visitLoading && dataSource?.length > 0 ? (
              <>
                <TableContent
                  enableActions={true}
                  enableDots={true}
                  enableSettings={true}
                  enableHistory={true}
                  enableCreate={true}
                  columns={columns}
                  dataSource={dataSource}
                  dottedActions={dottedActionsArr}
                  settingActions={settingsActionArr}
                  enableOrdering={false}
                  onHandleDottedAction={handleDottedActions}
                  onHandleSettingsAction={handleSettingsAction}
                  onHandleHistory={onViewHistory}
                  onHandleCreate={handleCreateClaim}
                />

                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              </>
            ) : (
              <EmptyContent />
            )}
          </>
        )}

        {visibleEditModal && (
          <RightSheetLarge
            children={
              <VisitEditForm
                onChange={handleVisitFormChange}
                errorObject={errorObj}
                onChangeVisitData={onChangeVisitDate}
                visitFormEditData={patientVisitFormData}
                onSaveEdit={onSaveEdit}
                visitId={selectedVisitId}
                cptDataSource={cptDataSource}
                icdDataSource={icdDataSource}
                icdCodesDataSource={icdCodesDataSource}
                cptCodesDataSource={cptCodesDataSource}
                modifiersDataSource={modifiersDataSource}
                getICDCodeList={getICDCodeList}
                getCPTCodeList={getCPTCodeList}
                patientInsurance={patientInsuranceList}
                handleRemoveCPTCodeDataSource={handleRemoveCPTCodeDataSource}
                handleRemoveICDCodeDataSource={handleRemoveICDCodeDataSource}
                mode={childMode}
              />
            }
            enableAlert={visibleAlert}
            alertDetails={alertObj}
            enableStepper={false}
            onClose={onCloseModal}
            enableFooterButtons={false}
          />
        )}

        {visibleEligibilityForm && (
          <RightSheet
            title="Eligibility Inquiry"
            submitButtonTitle="Submit"
            cancelButtonTitle="Cancel"
            enableAlert={visibleAlert}
            alertDetails={alertObj}
            onClose={onCloseEIModal}
            loading={updatePatientVisitEIByIdLoading}
            onSubmit={onSubmitEI}
            alertOnClose={errorAlertOnClose}
            children={
              <EligibilityForm
                onChange={handlePatientEIFormChange}
                errorObject={errorObj}
                onEligibilityFormDates={onEligibilityFormDates}
                eligibilityFormEditData={eligibilityFormEditData}
                patientInsurance={patientInsuranceList}
              />
            }
          />
        )}
        {visibleEInquiryView ? (
          <ViewEligibilityInquiry
            openModal={visibleEInquiryView}
            setOpenModal={onCloseEInquiry}
            details={viewEIDetails}
          />
        ) : (
          <></>
        )}
        {visibleEditStatus && (
          <EditVisitStatus
            editVisitStatusOpen={visibleEditStatus}
            setOpenEditVisitStatus={onCloseEditStatusModal}
            visitId={selectedVisitId}
            getStatusHistory={getStatusHistory}
            statusHistoryDataSource={statusHistoryDataSource}
            fetchVisits={fetchVisits}
          />
        )}
        {visibleNotesModal && (
          <NotesModal
            visibleEditModal={visibleNotesModal}
            visitId={selectedVisitId}
            setVisibleEditModal={onCloseNotesModal}
            noteHistoryDataSource={noteHistoryDataSource}
          />
        )}

        {visibleStatusHistory && (
          <StatusHistoryModal
            visibleHistoryModal={visibleStatusHistory}
            setVisibleHistoryModal={onCloseStatusHistoryModal}
            dataSource={statusHistoryDataSource}
            visitId={selectedVisitId}
          />
        )}
      </div>
    </>
  );
};
