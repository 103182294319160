import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { updateProviderProfileAPIRequest } from '../../../api/provider/provider';
import {
  ProviderProfileEditPropsInterface,
  ProviderProfileUpdateInterface,
} from '../../../interfaces/providersInterface';

const initialState: ProviderProfileEditPropsInterface = {
  editProviderProfileLoading: false,
  editProviderProfileData: {},
  editProviderProfileError: null,
  editProviderProfileStatus: 'IDLE',
};

export const editProviderProfileRequest: any = createAsyncThunk(
  'provider/profile/edit',
  async (patient: ProviderProfileUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updateProviderProfileAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editProviderProfileSlice = createSlice({
  name: 'editProviderProfile',
  initialState,
  reducers: {
    clearEditProviderProfileResponse: (state) => {
      state.editProviderProfileLoading = false;
      state.editProviderProfileStatus = 'IDLE';
      state.editProviderProfileData = {};
      state.editProviderProfileError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editProviderProfileRequest.pending, (state, action) => {
        state.editProviderProfileLoading = true;
        state.editProviderProfileStatus = 'PENDING';
      })
      .addCase(editProviderProfileRequest.fulfilled, (state, action) => {
        state.editProviderProfileData = action.payload;
        state.editProviderProfileStatus = 'SUCCESS';
        state.editProviderProfileLoading = false;
      })
      .addCase(editProviderProfileRequest.rejected, (state, action) => {
        state.editProviderProfileLoading = false;
        state.editProviderProfileError = action.payload;
        state.editProviderProfileStatus = 'FAILED';
      });
  },
});

export const { clearEditProviderProfileResponse } = editProviderProfileSlice.actions;

export const editProviderProfileSelector = (state: RootState) => state.EditProviderProfile;

export default editProviderProfileSlice.reducer;
