import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { TableContent } from '../../components/table/TableContent';
import { RightSheetLarge } from '../../components/rightSheet/RightSheetLarge';
import { Demographics } from '../../components/list/patients/Demographic';
import { RightSheet } from '../../components/rightSheet/RightSheet';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import {
  addPatientRequest,
  addPatientSelector,
  clearAddPatientResponse,
} from '../../redux/slices/patient/addPatientSlice';
import { getPatientsRequest, getPatientsSelector } from '../../redux/slices/patient/getPatientSlice';
import moment from 'moment';
import { CommonAlert } from '../../components/atoms/Alert';
import {
  clearFetchPatientByIdResponse,
  getPatientByIdRequest,
  getPatientsByIdSelector,
} from '../../redux/slices/patient/getPatientByIdSlice';
import { PatientAddress } from '../../components/list/patients/address/PatientAddress';
import { Insurance } from '../../components/list/patients/insurance/Insurance';
import { Case } from '../../components/list/patients/case/Case';
import { Visit } from '../../components/list/patients/visit/Visit';
import { Claim } from '../../components/list/patients/claim/Claim';
import { Transaction } from '../../components/list/patients/transaction/Transaction';
import { convertDate, validDateFormat } from '../../utils/commonFunctions';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { PatientTableAdvanceSearch } from '../../components/tableSearch/PatientTableAdvanceSearch';
import { Spinner } from '../../components/atoms/Spinner';
import {
  clearEditPatientDemographicResponse,
  editPatientDemographicRequest,
  editPatientDemographicSelector,
} from '../../redux/slices/patient/editPatientDemographicSlice';
import { createPatientValidation } from '../../utils/patientValidation';
import { getPatientCaseOptionsRequest } from '../../redux/slices/patient/getPatientCaseOptionSlice';
import { getPatientByIdDemoRequest } from '../../redux/slices/patient/getPatientByIdDemoSlice';
import {
  getSystemFacilitiesSelector,
  getSystemFacilityRequest,
} from '../../redux/slices/system/getSystemFacilitiesSlice';
import {
  getSystemInsuranceRequest,
  getSystemInsuranceSelector,
} from '../../redux/slices/system/getSystemInsuranceSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { NotesModal } from '../../components/list/patients/NotesModal';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'DOB',
    dataIndex: 'dob',
    key: 'dob',
    // render: (text: string) => moment(text).format(validDateFormat),
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Last visit date',
    dataIndex: 'lastVisitDate',
    key: 'lastVisitDate',
    // render: (text: string) => moment(text).format(validDateFormat),
  },
];

const dataSource = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    dob: '08/11/1935',
    phone: '2198872303',
    lastVisitDate: '11/19/2020',
  },
  {
    id: 2,
    firstName: 'John',
    lastName: 'Doe',
    dob: '08/11/1935',
    phone: '2198872303',
    lastVisitDate: '11/19/2020',
  },
  {
    id: 3,
    firstName: 'John',
    lastName: 'Doe',
    dob: '08/11/1935',
    phone: '2198872303',
    lastVisitDate: '11/19/2020',
  },
  {
    id: 4,
    firstName: 'John',
    lastName: 'Doe',
    dob: '08/11/1935',
    phone: '2198872303',
    lastVisitDate: '11/19/2020',
  },
];

const stepperData = [
  { id: 1, label: 'Demographics', requestParam: 'demographics' },
  { id: 2, label: 'Address', requestParam: 'address' },
  { id: 3, label: 'Insurances', requestParam: 'insurance' },
  { id: 4, label: 'Case', requestParam: 'case' },
  { id: 5, label: 'Visit', requestParam: 'visit' },
  { id: 6, label: 'Claim', requestParam: 'claim' },
  { id: 7, label: 'Transaction', requestParam: 'transaction' },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'patient', label: 'Patients', status: 'active', link: 'patients' },
];

export const PatientsPage = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const getId = query.get('id');
  // console.log('=== query ===', getId);

  const { status, patientData, loading } = useAppSelector(getPatientsSelector);
  const { addPatientStatus, addPatientLoading } = useAppSelector(addPatientSelector);
  const { fetchPatientByIdData, fetchPatientByIdStatus, fetchPatientByIdLoading } =
    useAppSelector(getPatientsByIdSelector);
  const { editPatientDemographicStatus } = useAppSelector(editPatientDemographicSelector);
  const { systemFacilityData, systemFacilityStatus } = useAppSelector(getSystemFacilitiesSelector);
  const { systemInsuranceStatus, systemInsuranceInsuranceData } = useAppSelector(getSystemInsuranceSelector);
  const { systemProvidersStatus, systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const [visibleModal, setVisibleModal] = useState(false);
  const [createFormData, setCreateFormData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterObject, setFilterObject] = useState<any>({});
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [visibleEditForm, setVisibleEditForm] = useState(false);
  const [selectedChild, setSelectedChild] = useState(1);
  const [mode, setMode] = useState('CREATE');
  const [selectedToEdit, setSelectedToEdit] = useState<any>();
  const [selectedSection, setSelectedSection] = useState('');
  const [stepperId, setStepperId] = useState(1);
  const [selectedPatientId, setSelectedPatientId] = useState<any>(null);
  const [errorObj, setErrorObj] = useState<any>({});

  const [demographicDataNew, setDemographicDataNew] = useState<any>({});
  const [selectedPatientsName, setSelectedPatientsName] = useState<string>('');

  const [visibleNotesModal, setVisibleNoteModal] = useState<boolean>(false);

  useEffect(() => {
    // fetchPatients();
    if (getId) {
      onEditQueryParams(getId);
    }
    setVisibleModal(false);
    setVisibleEditForm(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setErrorObj({});
    if (
      systemFacilityData?.length === 0 ||
      systemInsuranceInsuranceData?.length === 0 ||
      systemProvidersProviderData?.length === 0
    ) {
      fetchFacilities();
      fetchInsurance();
      fetchSystemProviders();
    }
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchPatients();
    }
  }, [filterObject]);

  useEffect(() => {
    if (status === 'SUCCESS' && !loading) {
      const convertedDataSet = convertDataSet(patientData?.items);
      setDataSource(convertedDataSet);
      setCurrentPage(patientData?.currentPage);
      setTotalItems(patientData?.totalItems);
      setTotalPages(patientData?.totalPages);
    }
  }, [status]);

  useEffect(() => {
    if (addPatientStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      fetchPatients();
      setTimeout(() => {
        setMode('CREATE');
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setCreateFormData({});
      }, 3000);
    } else if (addPatientStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      // setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }

    dispatch(clearAddPatientResponse());
  }, [addPatientStatus]);

  useEffect(() => {
    if (fetchPatientByIdStatus === 'SUCCESS') {
      if (selectedSection === 'demographics') {
        setCreateFormData(fetchPatientByIdData);
        setDemographicDataNew(fetchPatientByIdData);
      }
      setVisibleEditForm(true);
      setSelectedToEdit(fetchPatientByIdData);
      dispatch(clearFetchPatientByIdResponse());
    } else if (fetchPatientByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong in fetching patient data!',
        error: true,
      });
      setVisibleAlert(true);

      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        dispatch(clearFetchPatientByIdResponse());
      }, 3000);
    }
  }, [fetchPatientByIdStatus]);

  // console.log('=== fetchPatientByIdStatus ===', fetchPatientByIdStatus);

  useEffect(() => {
    if (editPatientDemographicStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setMode('CREATE');
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditPatientDemographicResponse());
    } else if (editPatientDemographicStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);

      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditPatientDemographicResponse());
    }
  }, [editPatientDemographicStatus]);

  const fetchFacilities = async () => {
    dispatch(getSystemFacilityRequest());
  };

  const fetchInsurance = async () => {
    dispatch(getSystemInsuranceRequest());
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const convertDataSet = (dataSet: any) => {
    let newArr = [];

    for (let index = 0; index < dataSet.length; index++) {
      const element: any = dataSet[index];
      let obj = {
        firstName: element?.firstName,
        dob: moment(element?.dob).isValid() ? moment(element?.dob).format(validDateFormat) : '',
        lastName: element?.lastName,
        id: element?.id,
        phone: element?.phone,
        lastVisitDate: moment(element?.lastVisitDate).isValid()
          ? moment(element?.lastVisitDate).format(validDateFormat)
          : '',
      };
      newArr[newArr.length] = obj;
    }

    return newArr;
  };

  const fetchPatients = async (pageSize = PAGE_SIZE, pageNumber = currentPage) => {
    dispatch(getPatientsRequest({ size: pageSize, page: pageNumber, filters: filterObject }));
  };

  const fetchPatientById = async (id = null, section = '') => {
    dispatch(
      getPatientByIdRequest({
        userId: id ?? selectedPatientId,
        section: section ?? selectedSection,
      })
    );
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (updatedData?.patientId === '' || isNaN(Number(updatedData.patientId))) {
      delete updatedData.patientId;
    } else {
      updatedData.patientId = parseInt(updatedData.patientId, 10);
    }

    if (updatedData?.firstName === '') {
      delete updatedData.firstName;
    }

    if (updatedData?.lastName === '') {
      delete updatedData.lastName;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleModalVisible = () => {
    setCreateFormData({});
    setMode('CREATE');
    setVisibleModal(true);
  };

  const handleOnClose = () => {
    setCreateFormData({});
    setMode('CREATE');
    setVisibleModal(false);
    setVisibleEditForm(false);
    setSelectedPatientId(null);
    setErrorObj({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedChild(1);
    const dataSet = dataSource[rowIndex];
    // console.log('== dataset ==', dataSet);

    setMode('EDIT');
    setSelectedPatientsName(dataSet?.firstName + ' ' + dataSet?.lastName);
    setSelectedPatientId(dataSet?.id);
    setSelectedSection('demographics');
    fetchPatientById(dataSet?.id, 'demographics');
    getCaseOptionsData(dataSet?.id);
  };

  const onEditQueryParams = (id: any) => {
    setSelectedChild(1);
    setMode('EDIT');
    setSelectedPatientId(id);
    setSelectedSection('demographics');
    fetchPatientById(id, 'demographics');
    getCaseOptionsData(id);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedChild(1);
    const dataSet = dataSource[rowIndex];
    setMode('VIEW');
    setSelectedPatientId(dataSet?.id);
    setSelectedSection('demographics');
    fetchPatientById(dataSet?.id, 'demographics');
    getCaseOptionsData(dataSet?.id);
  };

  const getCaseOptionsData = async (id: any) => {
    dispatch(getPatientCaseOptionsRequest({ patientId: id }));
  };

  const onChangePatientCreateForm = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setCreateFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onSelectDOB = (date: Date) => {
    // console.log('=== dob ===', moment(date).format('YYYY-MM-DD'), date);

    if (errorObj?.hasOwnProperty('dob')) {
      delete errorObj['dob'];
    }
    setCreateFormData((prev: any) => ({
      ...prev,
      dob: moment(date).format('YYYY-MM-DD'),
    }));
  };

  const onSubmit = () => {
    // console.log('=== edit on submit ===', mode, selectedSection);

    const editObj = {
      patientId: selectedPatientId,
      demographicData: createFormData,
    };

    if (mode === 'CREATE') {
      const validationDetails = createPatientValidation(createFormData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);
        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(addPatientRequest(createFormData));
      }
    } else {
      if (selectedSection === 'demographics') {
        const validationDetails = createPatientValidation(editObj?.demographicData);
        if (Object.keys(validationDetails?.newErrors)?.length > 0) {
          setErrorObj(validationDetails?.newErrors);
          const errorMessages = Object.values(validationDetails.newErrors);
          setVisibleAlert(true);
          setAlertObj({
            color: 'failure',
            message: (
              <div>
                {errorMessages.map((msg: any, index) => (
                  <div key={index} className="flex items-center">
                    <IoIosInformationCircleOutline />
                    <span style={{ marginLeft: '8px' }}>{msg}</span>
                  </div>
                ))}
              </div>
            ),
            error: true,
          });
        } else {
          dispatch(editPatientDemographicRequest(editObj));
        }
      }
    }

    // mode === 'CREATE' ? dispatch(addPatientRequest(createFormData)) : dispatch(editPatientDemographicRequest(editObj));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleClickStepper = (id: any) => {
    dispatch(clearFetchPatientByIdResponse());
    setSelectedChild(id);
    setSelectedSection(stepperData[id - 1]?.requestParam.toLowerCase());
    setStepperId(stepperData[id - 1]?.id);
    fetchPatientById(null, stepperData[id - 1]?.requestParam.toLowerCase());
  };

  const handleVisibleAlert = () => {
    setVisibleAlert(true);
  };

  const renderTab = () => {
    switch (selectedChild) {
      case 1:
        return (
          <Demographics
            onChange={onChangePatientCreateForm}
            onSelectDOB={onSelectDOB}
            demographicData={createFormData}
            mode={mode}
            errorObject={errorObj}
          />
        );
      case 2:
        return (
          <PatientAddress
            selectedId={selectedPatientId}
            fetchPatientById={fetchPatientById}
            mode={mode}
            fetchDataLoading={fetchPatientByIdLoading}
            addDataLoading={addPatientLoading}
          />
        );
      case 3:
        return (
          <Insurance
            selectedId={selectedPatientId}
            fetchPatientById={fetchPatientById}
            mode={mode}
            fetchDataLoading={fetchPatientByIdLoading}
            addDataLoading={addPatientLoading}
            selectedPatientName={selectedPatientsName}
          />
        );
      case 4:
        return (
          <Case
            selectedId={selectedPatientId}
            fetchPatientById={fetchPatientById}
            mode={mode}
            fetchDataLoading={fetchPatientByIdLoading}
          />
        );
      case 5:
        return (
          <Visit
            selectedId={selectedPatientId}
            fetchPatientById={fetchPatientById}
            mode={mode}
            fetchDataLoading={fetchPatientByIdLoading}
          />
        );
      case 6:
        return (
          <Claim
            selectedId={selectedPatientId}
            fetchPatientById={fetchPatientById}
            mode={mode}
            fetchDataLoading={fetchPatientByIdLoading}
          />
        );
      case 7:
        return (
          <Transaction
            mode={mode}
            selectedId={selectedPatientId}
            fetchPatientById={fetchPatientById}
            fetchDataLoading={fetchPatientByIdLoading}
          />
        );
      default:
        break;
    }
  };

  // console.log('=== dataSource ==', dataSource);

  const onPageChange = (page: any) => {
    // console.log('=== changed page ===', page);
    setCurrentPage(page - 1);
    fetchPatients(PAGE_SIZE, page - 1);
  };

  const onClickNotes = () => {
    setVisibleNoteModal(true);
  };

  const handleNoteModalClose = (close: boolean) => {
    setVisibleNoteModal(close);
  };

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic onClick={handleModalVisible} mainTitle="Patients / Guarantors" breadCrumbArr={breadCrumbArr} />
      {/* <TableAdvanceSearch /> */}
      <PatientTableAdvanceSearch onSubmit={handleSearch} />
      {loading ? (
        <Spinner />
      ) : (
        <>
          {!loading && dataSource?.length > 0 ? (
            <TableContent
              enableActions={true}
              columns={columns}
              dataSource={dataSource}
              enableView={true}
              enableEdit={true}
              onEdit={onEdit}
              onView={onView}
            />
          ) : (
            <EmptyContent />
          )}

          {dataSource?.length > 0 && (
            <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
          )}
        </>
      )}

      {visibleModal ? (
        <RightSheet
          onClose={handleOnClose}
          title="Create Patient/Guarantors"
          submitButtonTitle="Create"
          cancelButtonTitle="Cancel"
          children={
            <Demographics
              onChange={onChangePatientCreateForm}
              onSelectDOB={onSelectDOB}
              errorObject={errorObj}
              mode={mode}
              demographicData={createFormData}
            />
          }
          onSubmit={onSubmit}
          loading={addPatientLoading}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
        />
      ) : (
        <></>
      )}

      {visibleEditForm ? (
        <RightSheetLarge
          title="Patient Profile"
          onSubmit={onSubmit}
          onClose={handleOnClose}
          submitButtonTitle="Update"
          cancelButtonTitle="Cancel"
          clickStepper={handleClickStepper}
          children={<>{renderTab()}</>}
          enableFooterButtons={mode === 'VIEW' ? false : stepperId === 1 ? true : false}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
          enableNotes={true}
          onClickNotes={onClickNotes}
        />
      ) : (
        <></>
      )}

      {visibleNotesModal && <NotesModal openNote={visibleNotesModal} setOpenNote={handleNoteModalClose} />}
    </div>
  );
};
