import { FetchClaimEraAutoPostInterface } from '../../interfaces/paymentInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getClaimEraAutoPostAPIRequest = async (searchParams: FetchClaimEraAutoPostInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/claim-era-auto-post/search?page=${searchParams?.page}&size=${searchParams?.size}`,
    'get',
    {
      data: {},
    }
  );
};
