import { CommonButtonPropsInterface } from '../../interfaces/atomInterfaces';
import { LoadingIcon } from './LoadingIcon';

const primaryClass =
  'px-4 py-2 text-white bg-primaryDefault border-primaryDefault hover:bg-primaryHover hover:text-white focus:text-white focus:hover:text-white focus:z-10 focus:ring-2 focus:ring-primaryFocus rounded-lg font-medium flex items-center w-full justify-center';
const secondaryClass =
  'px-4 py-2 text-gray9 hover:text-gray9 focus:text-gray9 focus:hover:text-gray9 dark:text-white dark:hover:text-white dark:focus:text-white bg-white hover:bg-gray1 dark:bg-gray9 dark:hover:bg-gray8 dark:focus:bg-gray7 dark:active:bg-gray7 border border-gray3 dark:border-gray8 focus:z-10 focus:ring-2 focus:ring-primaryFocus rounded-lg font-medium flex items-center w-full justify-center';
const iconClass =
  'px-2 py-2 text-gray9 hover:text-gray9 focus:text-gray9 focus:hover:text-gray9 dark:text-white dark:hover:text-white dark:focus:text-white bg-white hover:bg-gray1 dark:bg-gray9 dark:hover:bg-gray8 dark:focus:bg-gray7 dark:active:bg-gray7 border border-gray3 dark:border-gray8 focus:z-10 focus:ring-2 focus:ring-primaryFocus rounded-lg font-medium flex items-center justify-center';
const disbaledClass =
  'px-4 py-2 text-white bg-primaryDefault border-primaryDefault focus:text-white focus:hover:text-white focus:z-10 focus:ring-2 focus:ring-primaryFocus rounded-lg font-medium flex items-center w-full justify-center';

export const CommonButton: React.FC<CommonButtonPropsInterface> = ({
  type = 'button',
  label = 'Submit',
  onClick = () => {},
  disabled = false,
  buttonType = 'primary',
  loading = false,
  style,
}) => {
  const renderClassName = () => {
    let baseClassName;
    switch (buttonType) {
      case 'primary':
        baseClassName = primaryClass;
        break;
      case 'secondary':
        baseClassName = secondaryClass;
        break;
      case 'disabled':
        baseClassName = disbaledClass;
        break;
      case 'icon':
        baseClassName = iconClass;
        break;
      default:
        break;
    }
    return `${baseClassName} ${disabled ? 'cursor-not-allowed opacity-50' : ''}`;
  };

  const renderBody = () => {
    if (buttonType === 'primary' || buttonType === 'secondary' || buttonType === 'disabled') {
      return (
        <>
          {loading && <LoadingIcon />}
          {label}
        </>
      );
    } else if (buttonType === 'detailed') {
      return (
        <button type="button" className="primary-btn" onClick={onClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            <path
              fill-rule="evenodd"
              d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
              clip-rule="evenodd"
            />
          </svg>
          {label}
        </button>
      );
    } else {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          data-slot="icon"
          className="w-4 h-4"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      );
    }
  };

  return (
    <>
      <button className={renderClassName()} type={type} onClick={onClick} disabled={disabled} style={style}>
        {renderBody()}
      </button>
    </>
  );
};
