import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientVisitFetchByIdInitialPropsInterface,
  PatientVisitGetByIdInterface,
} from '../../../interfaces/patientInterface';
import { getPatientVisitByIdAPIRequest } from '../../../api/patient/patientVisit';

const initialState: PatientVisitFetchByIdInitialPropsInterface = {
  fetchPatientVisitByIdLoading: false,
  fetchPatientVisitByIdData: {},
  fetchPatientVisitByIdStatus: 'IDLE',
  fetchPatientVisitByIdError: null,
};

export const getPatientVisitByIdRequest: any = createAsyncThunk(
  'patients/get/visit/id',
  async (searchParams: PatientVisitGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getPatientVisitByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchPatientVisitByIdSlice = createSlice({
  name: 'fetchPatientVisitById',
  initialState,
  reducers: {
    clearFetchPatientVisitByIdResponse: (state) => {
      state.fetchPatientVisitByIdLoading = false;
      state.fetchPatientVisitByIdData = {};
      state.fetchPatientVisitByIdStatus = 'IDLE';
      state.fetchPatientVisitByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientVisitByIdRequest.pending, (state, action) => {
        state.fetchPatientVisitByIdLoading = true;
        state.fetchPatientVisitByIdStatus = 'PENDING';
      })
      .addCase(getPatientVisitByIdRequest.fulfilled, (state, action) => {
        state.fetchPatientVisitByIdLoading = false;
        state.fetchPatientVisitByIdStatus = 'SUCCESS';
        state.fetchPatientVisitByIdData = action.payload;
      })
      .addCase(getPatientVisitByIdRequest.rejected, (state, action) => {
        state.fetchPatientVisitByIdLoading = false;
        state.fetchPatientVisitByIdError = action.payload;
        state.fetchPatientVisitByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchPatientVisitByIdResponse } = fetchPatientVisitByIdSlice.actions;

export const getPatientVisitByIdSelector = (state: RootState) => state.FetchPatientVisitById;

export default fetchPatientVisitByIdSlice.reducer;
