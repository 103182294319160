import { Radio } from 'flowbite-react';
import { employmentStatus, providerDegree, sexStatus, userStatus } from '../../../constants/userConstants';
import { ProfileFormProps } from '../../../interfaces/providersInterface';
import { Checkbox } from '../../atoms/Checkbox';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';
import { Calendar } from '../../atoms/Calendar';
import { providerType } from '../../../constants/providerConstants';
import { usStates } from '../../../constants/systemConstants';
import { formatDate } from '../../../utils/commonFunctions';
import { DatePickerField } from '../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../utils/commonFunctions';

export const Profile: React.FC<ProfileFormProps> = ({
  onChange,
  onSelectDOB,
  mode = 'CREATE',
  onSelectDrivingLicenseExDate,
  onSelectStartDate,
  onSelectTerminationDate,
  profileData,
  errorObject,
}) => {
  // console.log('=== profileData ===', profileData);

  return (
    <>
      {mode !== 'EDIT' && <h6 className="sub-heading mt-6">Profile</h6>}
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="First Name"
          name="firstName"
          onChange={onChange}
          defaultValue={profileData?.firstName ?? undefined}
          error={errorObject && errorObject?.firstName}
          errorMessage={errorObject && errorObject?.firstName}
          disabled={mode === 'VIEW' ? true : false}
          required={true}
        />
        <InputField
          label="Middle Name"
          name="middleName"
          onChange={onChange}
          defaultValue={profileData?.middleName ?? undefined}
          error={errorObject && errorObject?.middleName}
          errorMessage={errorObject && errorObject?.middleName}
          disabled={mode === 'VIEW' ? true : false}
        />
        <InputField
          label="Last Name"
          name="lastName"
          onChange={onChange}
          defaultValue={profileData?.lastName ?? undefined}
          error={errorObject && errorObject?.lastName}
          errorMessage={errorObject && errorObject?.lastName}
          required={true}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label={'Type'}
          name="providerType"
          onChange={onChange}
          options={providerType}
          defaultValue={profileData && profileData?.providerType ? profileData?.providerType.toString() : undefined}
          error={errorObject && errorObject?.providerType}
          errorMessage={errorObject && errorObject?.providerType}
          disabled={mode === 'VIEW' ? true : false}
        />
        <SelectInput
          label={'Status'}
          name="providerStatus"
          onChange={onChange}
          options={userStatus}
          defaultValue={profileData && profileData?.providerStatus ? profileData?.providerStatus.toString() : undefined}
          error={errorObject && errorObject?.providerStatus}
          errorMessage={errorObject && errorObject?.providerStatus}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label={'Degree'}
          name="degreeId"
          onChange={onChange}
          options={providerDegree}
          defaultValue={profileData && profileData?.degreeId ? profileData?.degreeId.toString() : undefined}
          error={errorObject && errorObject?.degreeId}
          errorMessage={errorObject && errorObject?.degreeId}
          disabled={mode === 'VIEW' ? true : false}
        />
        <InputField
          label="Taxonomy"
          name="taxonomy"
          onChange={onChange}
          defaultValue={profileData?.taxonomy ?? undefined}
          error={errorObject && errorObject?.taxonomy}
          errorMessage={errorObject && errorObject?.taxonomy}
          disabled={mode === 'VIEW' ? true : false}
          required={true}
          tooltip="Character length must be 10"
        />
        <InputField
          label="EIN"
          name="ein"
          onChange={onChange}
          defaultValue={profileData?.ein ?? undefined}
          error={errorObject && errorObject?.ein}
          errorMessage={errorObject && errorObject?.ein}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label={'Gender'}
          name="gender"
          onChange={onChange}
          options={sexStatus}
          defaultValue={profileData && profileData?.gender ? profileData?.gender.toString() : undefined}
          error={errorObject && errorObject?.gender}
          errorMessage={errorObject && errorObject?.gender}
          disabled={mode === 'VIEW' ? true : false}
        />
        {/* <Calendar
          label="Date of Birth"
          onSelectedDateChanged={onSelectDOB}
          // defaultDate={profileData?.dob ? new Date(profileData?.dob) : undefined}
          defaultDate={formatDate(profileData?.dob)}
          error={errorObject && errorObject?.dob}
          errorMessage={errorObject && errorObject?.dob}
          disabled={mode === 'VIEW' ? true : false}
        /> */}
        <DatePickerField
          label="Date of Birth"
          selectedDate={formatDatePickerDate(profileData?.dob)}
          onChange={onSelectDOB}
          disabled={mode === 'VIEW' ? true : false}
        />
        <InputField
          label="SSN"
          name="ssn"
          onChange={onChange}
          defaultValue={profileData?.ssn ?? undefined}
          error={errorObject && errorObject?.ssn}
          errorMessage={errorObject && errorObject?.ssn}
          disabled={mode === 'VIEW' ? true : false}
          tooltip="Character length must be 9 digits"
        />
      </div>
      <div className="grid md:grid-cols-4 gap-3 mt-6">
        <InputField
          label="Home Phone"
          name="homePhone"
          onChange={onChange}
          defaultValue={profileData?.homePhone ?? undefined}
          error={errorObject && errorObject?.homePhone}
          errorMessage={errorObject && errorObject?.homePhone}
          disabled={mode === 'VIEW' ? true : false}
          tooltip="10-digit mobile number required"
        />
        <InputField
          label="Mobile Phone"
          name="mobilePhone"
          onChange={onChange}
          defaultValue={profileData?.mobilePhone ?? undefined}
          error={errorObject && errorObject?.mobilePhone}
          errorMessage={errorObject && errorObject?.mobilePhone}
          disabled={mode === 'VIEW' ? true : false}
          tooltip="10-digit mobile number required"
        />
        <InputField
          label="Work Email"
          name="workEmail"
          onChange={onChange}
          defaultValue={profileData?.workEmail ?? undefined}
          error={errorObject && errorObject?.workEmail}
          errorMessage={errorObject && errorObject?.workEmail}
          disabled={mode === 'VIEW' ? true : false}
        />
        <InputField
          label="Personal Email"
          name="personalEmail"
          onChange={onChange}
          defaultValue={profileData?.personalEmail ?? undefined}
          error={errorObject && errorObject?.personalEmail}
          errorMessage={errorObject && errorObject?.personalEmail}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <div className="grid md:grid-cols-4 gap-3 mt-6">
        {/* <Calendar
          label="Start Date"
          onSelectedDateChanged={onSelectStartDate}
          // defaultDate={profileData?.startDate ? new Date(profileData?.startDate) : undefined}
          defaultDate={formatDate(profileData?.startDate)}
          error={errorObject && errorObject?.startDate}
          errorMessage={errorObject && errorObject?.startDate}
          disabled={mode === 'VIEW' ? true : false}
        /> */}
        <DatePickerField
          label="Start Date"
          selectedDate={formatDatePickerDate(profileData?.startDate)}
          onChange={onSelectStartDate}
          disabled={mode === 'VIEW' ? true : false}
        />
        {/* <Calendar
          label="Termination Date"
          onSelectedDateChanged={onSelectTerminationDate}
          // defaultDate={profileData?.terminationDate ? new Date(profileData?.terminationDate) : undefined}
          defaultDate={formatDate(profileData?.terminationDate)}
          error={errorObject && errorObject?.terminationDate}
          errorMessage={errorObject && errorObject?.terminationDate}
          disabled={mode === 'VIEW' ? true : false}
        /> */}
        <DatePickerField
          label="Termination Date"
          selectedDate={formatDatePickerDate(profileData?.terminationDate)}
          onChange={onSelectTerminationDate}
          disabled={mode === 'VIEW' ? true : false}
        />
        <SelectInput
          label={'Employment Type'}
          name="employmentType"
          onChange={onChange}
          options={employmentStatus}
          defaultValue={profileData && profileData?.employmentType ? profileData?.employmentType.toString() : undefined}
          error={errorObject && errorObject?.employmentType}
          errorMessage={errorObject && errorObject?.employmentType}
          disabled={mode === 'VIEW' ? true : false}
        />
        <InputField
          label="Language Spoken"
          name="languagesSpoken"
          onChange={onChange}
          defaultValue={profileData?.languagesSpoken ?? undefined}
          error={errorObject && errorObject?.languagesSpoken}
          errorMessage={errorObject && errorObject?.languagesSpoken}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Driver's License"
          name="driverLicense"
          onChange={onChange}
          defaultValue={profileData?.driverLicense}
          error={errorObject && errorObject?.driverLicense}
          errorMessage={errorObject && errorObject?.driverLicense}
          disabled={mode === 'VIEW' ? true : false}
        />
        <SelectInput
          label={'State'}
          name="state"
          onChange={onChange}
          options={usStates}
          defaultValue={profileData && profileData?.state ? profileData?.state.toString() : undefined}
          error={errorObject && errorObject?.state}
          errorMessage={errorObject && errorObject?.state}
          disabled={mode === 'VIEW' ? true : false}
        />
        {/* <Calendar
          label="DL Expiration Date"
          onSelectedDateChanged={onSelectDrivingLicenseExDate}
          // defaultDate={profileData?.dlExpirationDate ? new Date(profileData?.dlExpirationDate) : undefined}
          defaultDate={formatDate(profileData?.dlExpirationDate)}
          error={errorObject && errorObject?.dlExpirationDate}
          errorMessage={errorObject && errorObject?.dlExpirationDate}
          disabled={mode === 'VIEW' ? true : false}
        /> */}
        <DatePickerField
          label="DL Expiration Date"
          // selectedDate={licenseFormEditData?.effectiveDate ? new Date(licenseFormEditData?.effectiveDate) : null}
          selectedDate={formatDatePickerDate(profileData?.dlExpirationDate)}
          onChange={onSelectDrivingLicenseExDate}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
    </>
  );
};
