import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addUsersAPIRequest, getUserByIdAPIRequest } from '../../../api/user/user';
import { AddUserInitialInterface, UserAddInterface } from '../../../interfaces/userInterface';
import { RootState } from '../../store';

const initialState: AddUserInitialInterface = {
  addUserLoading: false,
  addUserData: {},
  addUserError: null,
  success: false,
  addUserStatus: 'IDLE',
};

export const addUserRequest: any = createAsyncThunk('users/add', async (user: UserAddInterface, thunkAPI: any) => {
  try {
    const response: any = await addUsersAPIRequest(user);
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const addUserSlice = createSlice({
  name: 'addUser',
  initialState,
  reducers: {
    clearAddUserResponse: (state) => {
      state.addUserLoading = false;
      state.addUserStatus = 'IDLE';
      state.addUserData = {};
      state.addUserError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUserRequest.pending, (state, action) => {
        state.addUserLoading = true;
        state.addUserStatus = 'PENDING';
      })
      .addCase(addUserRequest.fulfilled, (state, action) => {
        state.addUserLoading = false;
        state.addUserData = action.payload;
        state.addUserStatus = 'SUCCESS';
      })
      .addCase(addUserRequest.rejected, (state, action) => {
        state.addUserLoading = false;
        state.addUserError = action.payload;
        state.addUserStatus = 'FAILED';
      });
  },
});

export const { clearAddUserResponse } = addUserSlice.actions;

export const addUserSelectore = (state: RootState) => state.AddUser;

export default addUserSlice.reducer;
