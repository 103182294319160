import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import { BatchTable } from './BatchTable';

import {
  getBatchPaymentsRequest,
  getBatchPaymentsSelector,
  clearFetchBatchPaymentsResponse,
} from '../../../redux/slices/payments/batchPayments/getBatchPaymentSlice';

const dummyDataSource = [
  {
    id: 375,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
  {
    id: 2,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
  {
    id: 3,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
  {
    id: 4,
    batchType: 'LOCKBOX',
    checkDate: '12/28/2023',
    description: 'Lock Box 6579.06',
    checkNumber: '817143425',
    paymentAmount: '$6,579.06',
    unappliedAmount: '$6430.75',
  },
];

const PAGE_SIZE = 10;

export const ClosedBatches = ({ closedBatchesData, onEdit, onView, onApply, onLock, fetchBatchPayments }: any) => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    setDataSource(closedBatchesData?.items);
    setCurrentPage(closedBatchesData?.currentPage);
    setTotalPages(closedBatchesData?.totalPages);
  }, [closedBatchesData]);

  const handlePageChange = (page: number) => {
    // setCurrentPage(page - 1);
    // fetchBatchPayments('closed', page - 1);
  };

  return (
    <>
      <BatchTable
        dataSource={dummyDataSource}
        onView={onView}
        onEdit={onEdit}
        onApply={onApply}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
};
