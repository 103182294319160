import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AddVisitNoteInitialPropsInterface, AddVisitNotePropsInterface } from '../../../interfaces/patientInterface';
import { addVisitNoteAPIRequest } from '../../../api/patient/patientVisit';

const initialState: AddVisitNoteInitialPropsInterface = {
  addVisitNoteLoading: false,
  addVisitNoteData: {},
  addVisitNoteError: null,
  addVisitNoteStatus: 'IDLE',
};

export const addVisitNoteRequest: any = createAsyncThunk(
  'add/visit/note',
  async (patient: AddVisitNotePropsInterface, thunkAPI: any) => {
    try {
      const response: any = await addVisitNoteAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addVisitNoteSlice = createSlice({
  name: 'addVisitNote',
  initialState,
  reducers: {
    clearAddVisitNoteResponse: (state) => {
      state.addVisitNoteLoading = false;
      state.addVisitNoteStatus = 'IDLE';
      state.addVisitNoteData = {};
      state.addVisitNoteError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addVisitNoteRequest.pending, (state, action) => {
        state.addVisitNoteLoading = true;
        state.addVisitNoteStatus = 'PENDING';
      })
      .addCase(addVisitNoteRequest.fulfilled, (state, action) => {
        state.addVisitNoteData = action.payload;
        state.addVisitNoteStatus = 'SUCCESS';
        state.addVisitNoteLoading = false;
      })
      .addCase(addVisitNoteRequest.rejected, (state, action) => {
        state.addVisitNoteLoading = false;
        state.addVisitNoteError = action.payload;
        state.addVisitNoteStatus = 'FAILED';
      });
  },
});

export const { clearAddVisitNoteResponse } = addVisitNoteSlice.actions;

export const addVisitNoteSelector = (state: RootState) => state.AddVisitNote;

export default addVisitNoteSlice.reducer;
