import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  ProviderFectchByIdInitialPropsInterface,
  ProviderFetchByIdInterface,
} from '../../../interfaces/providersInterface';
import { getProviderByIdAPIRequest } from '../../../api/provider/provider';

const initialState: ProviderFectchByIdInitialPropsInterface = {
  fetchProviderByIdLoading: false,
  fetchProviderByIdData: {},
  fetchProviderByIdStatus: 'IDLE',
  fetchProviderByIdError: null,
};

export const getProviderByIdRequest: any = createAsyncThunk(
  'provider/get/id',
  async (searchParams: ProviderFetchByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getProviderByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchProviderByIdSlice = createSlice({
  name: 'fetchProviderById',
  initialState,
  reducers: {
    clearFetchProviderByIdResponse: (state) => {
      state.fetchProviderByIdLoading = false;
      state.fetchProviderByIdData = {};
      state.fetchProviderByIdStatus = 'IDLE';
      state.fetchProviderByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderByIdRequest.pending, (state, action) => { 
        state.fetchProviderByIdLoading = true;
        state.fetchProviderByIdStatus = 'PENDING';
      })
      .addCase(getProviderByIdRequest.fulfilled, (state, action) => {
        state.fetchProviderByIdStatus = 'SUCCESS';
        state.fetchProviderByIdData = action.payload; 
        state.fetchProviderByIdLoading = false;
      })
      .addCase(getProviderByIdRequest.rejected, (state, action) => {
        state.fetchProviderByIdLoading = false;
        state.fetchProviderByIdError = action.payload;
        state.fetchProviderByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchProviderByIdResponse } = fetchProviderByIdSlice.actions;

export const getProviderByIdSelector = (state: RootState) => state.FetchProviderById;

export default fetchProviderByIdSlice.reducer;
