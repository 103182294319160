import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { ICDModal } from './ICDModal';
import { ICDMOdalNew } from './ICDModalNew';
import {
  deleteVisitICDCodeRequest,
  deleteVisitCPTCodeSelector,
  clearDeleteVisitICDCodeResponse,
} from '../../../../redux/slices/patient/deleteVisitICDCodeSlice';
import { ConfirmModal } from './ConfirmModal';

const diagnosisCodesColumn = [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Description',
    dataIndex: 'desc',
    key: 'desc',
  },
];

const diagnosisCodes = [
  {
    code: 'A41.9',
    description: 'Septicemia, unspec organism',
  },
  {
    code: 'M62.81',
    description: 'Muscle Weakness',
  },
  {
    code: 'N39.0',
    description: 'Urinary Tract Infect UTI',
  },
];

export const DiagnosisCode = ({
  onAddDiagnosisCode,
  visitId,
  icdDataSource,
  icdCodesDataSource,
  getICDCodeList,
  handleRemoveICDCodeDataSource,
  childMode,
}: any) => {
  const dispatch = useAppDispatch();

  const { deleteVisitICDCodeStatus } = useAppSelector(deleteVisitCPTCodeSelector);

  const [visibleICDModal, setVisibleICDModal] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [selectedICD, setSelectedICD] = useState<number>();

  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });
  // console.log('=== visitId ===', visitId);

  useEffect(() => {
    if (deleteVisitICDCodeStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Deleted Successfully!',
        error: false,
      });
      setVisibleAlert(true);
      getICDCodeList(visitId);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
        setOpenConfirm(false);
      }, 3000);
    } else if (deleteVisitICDCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearDeleteVisitICDCodeResponse());
  }, [deleteVisitICDCodeStatus]);

  const deleteICD = (event: any, rowIndex: any) => {
    event?.preventDefault();
    const dataSet = icdDataSource[rowIndex];
    setSelectedICD(dataSet?.id);
    setOpenConfirm(true);
  };

  const confirmDelete = () => {
    dispatch(deleteVisitICDCodeRequest({ visitId: visitId, icd: selectedICD }));
  };

  const onCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  return (
    <>
      <div
        style={{
          borderRadius: '5px',
          border: '1px solid',
          padding: '6px',
          background: 'rgb(13,200,222)',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <div style={{ marginLeft: '5px' }}>ICD/Diagnosis Codes</div>
        {childMode === 'EDIT' && (
          <div>
            <CommonButton
              type="button"
              label={'Add New'}
              buttonType="secondary"
              onClick={() => setVisibleICDModal(true)}
            />
          </div>
        )}
      </div>
      <div className="grid md:grid-cols-1 gap-5">
        <TableContent
          enableActions={childMode === 'EDIT' ? true : false}
          columns={diagnosisCodesColumn}
          dataSource={icdDataSource}
          enableDelete={true}
          onDelete={deleteICD}
        />
      </div>
      {visibleICDModal && (
        <ICDModal
          icdModalOpen={visibleICDModal}
          setOpenICDModal={setVisibleICDModal}
          icdCodesDataSource={icdCodesDataSource}
          icdDataSource={icdDataSource}
          visitId={visitId}
          getICDCodeList={getICDCodeList}
          handleRemoveICDCodeDataSource={handleRemoveICDCodeDataSource}
          deleteICD={deleteICD}
        />
        // <ICDMOdalNew
        //   icdModalOpen={visibleICDModal}
        //   setOpenICDModal={setVisibleICDModal}
        //   icdCodesDataSource={icdCodesDataSource}
        //   visitId={visitId}
        //   getICDCodeList={getICDCodeList}
        //   handleRemoveICDCodeDataSource={handleRemoveICDCodeDataSource}
        // />
      )}
      {openConfirm && (
        <ConfirmModal
          openConfirm={openConfirm}
          setOpenConfirm={onCloseConfirm}
          title="Delete ICD/Diagnosis Code"
          content="Are you sure you want to delete this ICD/Diagnosis Code?"
          submitButtonTitle="Yes"
          cancelButtonTitle="No"
          handleSubmit={confirmDelete}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          alertClassName="w-1/2"
          handleAlertClose={handleAlertClose}
        />
      )}
    </>
  );
};
