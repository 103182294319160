import moment from 'moment';
import { validDateFormat } from '../../../utils/commonFunctions';
import { TableContent } from '../../table/TableContent';
import { Pagination } from '../../pagination/Pagination';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Locality',
    dataIndex: 'locality',
    key: 'locality',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Effective date',
    dataIndex: 'effectiveDate',
    key: 'effectiveDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
];

export const FeeList = ({ feeAmountList, currentPage, onPageChange, totalPages }: any) => {
  return (
    <>
      <TableContent columns={columns} dataSource={feeAmountList} />
      <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
    </>
  );
};
