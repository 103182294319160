import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

import {
  ProviderAddressAddInterface,
  ProviderAddressAddPropsInterface,
} from "../../../interfaces/providersInterface";
import { addProviderAddressAPIRequest } from "../../../api/provider/providerAddress";

const initialState: ProviderAddressAddPropsInterface = {
  addProviderAddressLoading: false,
  addProviderAddressData: {},
  addProviderAddressError: null,
  addProviderAddressStatus: "IDLE",
};

export const addProviderAddressRequest: any = createAsyncThunk(
  "provider/address/add",
  async (provider: ProviderAddressAddInterface, thunkAPI: any) => {
    try {
      const response: any = await addProviderAddressAPIRequest(provider);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addProviderAddressSlice = createSlice({
  name: "addProviderAddress",
  initialState,
  reducers: {
    clearAddProviderAddressResponse: (state) => {
      state.addProviderAddressLoading = false;
      state.addProviderAddressStatus = "IDLE";
      state.addProviderAddressData = {};
      state.addProviderAddressError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProviderAddressRequest.pending, (state, action) => {
        state.addProviderAddressLoading = true;
        state.addProviderAddressStatus = "PENDING";
      })
      .addCase(addProviderAddressRequest.fulfilled, (state, action) => {
        state.addProviderAddressData = action.payload;
        state.addProviderAddressStatus = "SUCCESS";
        state.addProviderAddressLoading = false;
      })
      .addCase(addProviderAddressRequest.rejected, (state, action) => {
        state.addProviderAddressLoading = false;
        state.addProviderAddressError = action.payload;
        state.addProviderAddressStatus = "FAILED";
      });
  },
});

export const { clearAddProviderAddressResponse } =
  addProviderAddressSlice.actions;

export const addProviderAddressSelector = (state: RootState) =>
  state.AddProviderAddress;

export default addProviderAddressSlice.reducer;
