import { UploadInputPropsInterface } from '../../interfaces/atomInterfaces';

export const UploadInput: React.FC<UploadInputPropsInterface> = ({ disabled }) => {
  return (
    <>
      <div>
        <label htmlFor="input_field" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Upload
        </label>
        <div className="flex items-center justify-center w-full">
          <label
            htmlFor="dropzone-file"
            className={`
              flex 
              flex-col 
              items-center 
              justify-center 
              w-full 
              h-56 
              border-2 
              border-gray3
              dark:border-gray8 
              dark:hover:border-gray7
              border-dashed 
              rounded-lg 
              cursor-pointer 
              bg-white
              hover:bg-gray1
              dark:bg-gray9 
              dark:hover:bg-gray8
              ${disabled ? 'cursor-not-allowed opacity-50 pointer-events-none' : ''}
            `}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray5 dark:text-gray2">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-gray5 dark:text-gray2">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
            </div>
            <input id="dropzone-file" type="file" className="hidden" disabled={disabled} />
          </label>
        </div>
      </div>
    </>
  );
};
