import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientInsuranceDeleteByIdInitialPropsInterface,
  PatientInsuranceGetByIdInterface,
} from '../../../interfaces/patientInterface';
import { deletePatientInsuranceByIdAPIRequest } from '../../../api/patient/patientInsurance';

const initialState: PatientInsuranceDeleteByIdInitialPropsInterface = {
  deletePatientInsuranceByIdLoading: false,
  deletePatientInsuranceByIdData: {},
  deletePatientInsuranceByIdStatus: 'IDLE',
  deletePatientInsuranceByIdError: null,
};

export const deletePatientInsuranceByIdRequest: any = createAsyncThunk(
  'patients/insurance/delete/id',
  async (searchParams: PatientInsuranceGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await deletePatientInsuranceByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deletePatientInsuranceByIdSlice = createSlice({
  name: 'deletePatientInsuranceById',
  initialState,
  reducers: {
    clearDeletePatientInsuranceByIdResponse: (state) => {
      state.deletePatientInsuranceByIdLoading = false;
      state.deletePatientInsuranceByIdData = {};
      state.deletePatientInsuranceByIdStatus = 'IDLE';
      state.deletePatientInsuranceByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deletePatientInsuranceByIdRequest.pending, (state, action) => {
        state.deletePatientInsuranceByIdLoading = true;
        state.deletePatientInsuranceByIdStatus = 'PENDING';
      })
      .addCase(deletePatientInsuranceByIdRequest.fulfilled, (state, action) => {
        state.deletePatientInsuranceByIdLoading = false;
        state.deletePatientInsuranceByIdStatus = 'SUCCESS';
        state.deletePatientInsuranceByIdData = action.payload;
      })
      .addCase(deletePatientInsuranceByIdRequest.rejected, (state, action) => {
        state.deletePatientInsuranceByIdLoading = false;
        state.deletePatientInsuranceByIdError = action.payload;
        state.deletePatientInsuranceByIdStatus = 'FAILED';
      });
  },
});

export const { clearDeletePatientInsuranceByIdResponse } = deletePatientInsuranceByIdSlice.actions;

export const deletePatientInsuranceByIdSelector = (state: RootState) => state.DeletePatientInsurance;

export default deletePatientInsuranceByIdSlice.reducer;
