import { claimStatus, patientFinancialClass } from '../../../../constants/patientConstants';
import { useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { PatientVisitEditFormPropsInterface } from '../../../../interfaces/patientInterface';
import { getPatientsByIdDemoSelector } from '../../../../redux/slices/patient/getPatientByIdDemoSlice';
import { getPatientCaseOptionsSelector } from '../../../../redux/slices/patient/getPatientCaseOptionSlice';
import { Calendar } from '../../../atoms/Calendar';
import { CommonButton } from '../../../atoms/CommonButton';
import { InputField } from '../../../atoms/InputField';
import { SelectInput } from '../../../atoms/SelectInput';
import { TextArea } from '../../../atoms/TextArea';
import { TableContent } from '../../../table/TableContent';
import { DiagnosisCode } from './DiagnosisCode';
import { ProcedureCode } from './ProcedureCode';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';

export const VisitEditForm: React.FC<PatientVisitEditFormPropsInterface> = ({
  onChange,
  visitFormEditData,
  errorObject,
  onChangeVisitData,
  mode,
  onSaveEdit,
  onAddDiagnosisCode,
  onAddProcedureCode,
  onEditProcedureCode,
  visitId,
  icdDataSource,
  cptDataSource,
  icdCodesDataSource,
  getICDCodeList,
  cptCodesDataSource,
  modifiersDataSource,
  getCPTCodeList,
  patientInsurance,
  handleRemoveCPTCodeDataSource,
  handleRemoveICDCodeDataSource,
}) => {
  const { caseOptionPatientData } = useAppSelector(getPatientCaseOptionsSelector);

  const filterByInsuranceType = (patientInsuranceArr = patientInsurance, type = '') => {
    return patientInsuranceArr
      ?.filter((el: any) => el?.type === type)
      .map((el: any) => ({
        id: el?.insuranceId,
        name: el?.name,
      }));
  };

  // console.log('=== patientInsurance ===', filterByInsuranceType(patientInsurance, 'PRIMARY'));

  return (
    <>
      <div
        style={{
          borderRadius: '5px',
          border: '1px solid',
          padding: '6px',
          background: 'rgb(13,200,222)',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ marginLeft: '5px' }}>
          Visit ID {visitFormEditData?.id} - {visitFormEditData?.patientName}
        </div>
        {mode === 'EDIT' && (
          <div>
            <CommonButton type="button" label={'Save'} buttonType="secondary" onClick={onSaveEdit} />
          </div>
        )}
      </div>
      <div className="grid md:grid-cols-3 gap-5 mt-6">
        {/* <Calendar
          onSelectedDateChanged={onChangeVisitData}
          name="visitDate"
          label="Visit Date"
          defaultDate={visitFormEditData?.visitDate ? new Date(visitFormEditData?.visitDate) : undefined}
          disabled={mode === 'VIEW' ? true : false}
        /> */}
        <DatePickerField
          label="Visit Date"
          name="visitDate"
          selectedDate={formatDatePickerDate(visitFormEditData?.visitDate)}
          onChange={onChangeVisitData}
          disabled={mode === 'VIEW' ? true : false}
        />
        <SelectInput
          label={'Status'}
          name="patientVisitStatus"
          onChange={onChange}
          options={claimStatus}
          defaultValue={visitFormEditData?.status ? visitFormEditData?.status.toString() : undefined}
          error={errorObject && errorObject?.patientVisitStatus}
          errorMessage={errorObject && errorObject?.patientVisitStatus}
          disabled={mode === 'VIEW' ? true : false}
        />
        <InputField
          label="Attending Provider"
          name="attendingProviderName"
          onChange={onChange}
          disabled={true}
          defaultValue={visitFormEditData?.attendingProvider?.name ?? undefined}
          error={errorObject && errorObject?.attendingProviderName}
          errorMessage={errorObject && errorObject?.attendingProviderName}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-5 mt-6">
        <SelectInput
          label={'Case'}
          name="caseId"
          onChange={onChange}
          options={caseOptionPatientData}
          defaultValue={visitFormEditData?.caseId ? visitFormEditData?.caseId.toString() : undefined}
          error={errorObject && errorObject?.caseId}
          errorMessage={errorObject && errorObject?.caseId}
          disabled={mode === 'VIEW' ? true : false}
        />
        <SelectInput
          label={'Financial Class'}
          name="visitFinancialClass"
          onChange={onChange}
          options={patientFinancialClass}
          defaultValue={
            visitFormEditData?.visitFinancialClass ? visitFormEditData?.visitFinancialClass.toString() : undefined
          }
          error={errorObject && errorObject?.visitFinancialClass}
          errorMessage={errorObject && errorObject?.visitFinancialClass}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <div className="grid md:grid-cols-1 mt-6">
        <TextArea
          onChange={onChange}
          name="noteToBiller"
          label="Notes"
          defaultValue={visitFormEditData?.noteToBiller ?? undefined}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-5 mt-6">
        <InputField
          label="Facility"
          name="facilityName"
          onChange={onChange}
          disabled={true}
          defaultValue={visitFormEditData?.facility?.name ?? undefined}
          error={errorObject && errorObject?.facilityName}
          errorMessage={errorObject && errorObject?.facilityName}
        />
        <InputField
          label="Referring Provider"
          name="refProvider"
          onChange={onChange}
          disabled={true}
          defaultValue={visitFormEditData?.refProvider?.name ?? undefined}
          error={errorObject && errorObject?.refProvider}
          errorMessage={errorObject && errorObject?.refProvider}
        />
        <InputField
          label="Assigned Provider"
          name="assProvider"
          onChange={onChange}
          disabled={true}
          defaultValue={visitFormEditData?.assProvider?.name ?? undefined}
          error={errorObject && errorObject?.assProvider}
          errorMessage={errorObject && errorObject?.assProvider}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-5 mt-6">
        <SelectInput
          label={'Primary Insurance'}
          name="primaryInsuranceId"
          onChange={onChange}
          options={filterByInsuranceType(patientInsurance, 'PRIMARY')}
          defaultValue={
            visitFormEditData?.primaryInsurance ? visitFormEditData?.primaryInsurance?.id.toString() : undefined
          }
          error={errorObject && errorObject?.primaryInsuranceId}
          errorMessage={errorObject && errorObject?.primaryInsuranceId}
          disabled={mode === 'VIEW' ? true : false}
        />
        <SelectInput
          label={'Secondary Insurance'}
          name="secondaryInsuranceId"
          onChange={onChange}
          options={filterByInsuranceType(patientInsurance, 'SECONDARY')}
          defaultValue={
            visitFormEditData?.secondaryInsurance ? visitFormEditData?.secondaryInsurance?.id.toString() : undefined
          }
          error={errorObject && errorObject?.secondaryInsuranceId}
          errorMessage={errorObject && errorObject?.secondaryInsuranceId}
          disabled={mode === 'VIEW' ? true : false}
        />
        <SelectInput
          label={'Tertiary Insurance'}
          name="tertiaryInsuranceId"
          onChange={onChange}
          options={filterByInsuranceType(patientInsurance, 'TERTIARY')}
          defaultValue={
            visitFormEditData?.tertiaryInsurance ? visitFormEditData?.tertiaryInsurance?.id.toString() : undefined
          }
          error={errorObject && errorObject?.tertiaryInsuranceId}
          errorMessage={errorObject && errorObject?.tertiaryInsuranceId}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <DiagnosisCode
        visitId={visitId}
        icdDataSource={icdDataSource}
        icdCodesDataSource={icdCodesDataSource}
        getICDCodeList={getICDCodeList}
        handleRemoveICDCodeDataSource={handleRemoveICDCodeDataSource}
        childMode={mode}
      />
      <ProcedureCode
        cptDataSource={cptDataSource}
        visitId={visitId}
        icdCodesDataSource={icdCodesDataSource}
        cptCodesDataSource={cptCodesDataSource}
        modifiersDataSource={modifiersDataSource}
        getCPTCodeList={getCPTCodeList}
        getICDCodeList={getICDCodeList}
        icdDataSource={icdDataSource}
        handleRemoveCPTCodeDataSource={handleRemoveCPTCodeDataSource}
        childMode={mode}
      />
    </>
  );
};
