import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  ProviderFacilitiesDeleteByIdInitialPropsInterface,
  ProviderFacilitiesGetByIdInterface,
} from '../../../interfaces/providersInterface';
import { deleteProviderFacilitiesByIdAPIRequest } from '../../../api/provider/providerFacilities';

const initialState: ProviderFacilitiesDeleteByIdInitialPropsInterface = {
  deleteProviderFacilitiesByIdLoading: false,
  deleteProviderFacilitiesByIdData: {},
  deleteProviderFacilitiesByIdStatus: 'IDLE',
  deleteProviderFacilitiesByIdError: null,
};

export const deleteProviderFacilitiesByIdRequest: any = createAsyncThunk(
  'providers/facilities/delete/id',
  async (searchParams: ProviderFacilitiesGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await deleteProviderFacilitiesByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deleteProviderFacilitiesByIdSlice = createSlice({
  name: 'deleteProviderFacilitiesById',
  initialState,
  reducers: {
    clearDeleteProviderFacilitiesByIdResponse: (state) => {
      state.deleteProviderFacilitiesByIdLoading = false;
      state.deleteProviderFacilitiesByIdData = {};
      state.deleteProviderFacilitiesByIdStatus = 'IDLE';
      state.deleteProviderFacilitiesByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProviderFacilitiesByIdRequest.pending, (state, action) => {
        state.deleteProviderFacilitiesByIdLoading = true;
        state.deleteProviderFacilitiesByIdStatus = 'PENDING';
      })
      .addCase(deleteProviderFacilitiesByIdRequest.fulfilled, (state, action) => {
        state.deleteProviderFacilitiesByIdLoading = false;
        state.deleteProviderFacilitiesByIdStatus = 'SUCCESS';
        state.deleteProviderFacilitiesByIdData = action.payload;
      })
      .addCase(deleteProviderFacilitiesByIdRequest.rejected, (state, action) => {
        state.deleteProviderFacilitiesByIdLoading = false;
        state.deleteProviderFacilitiesByIdError = action.payload;
        state.deleteProviderFacilitiesByIdStatus = 'FAILED';
      });
  },
});

export const { clearDeleteProviderFacilitiesByIdResponse } = deleteProviderFacilitiesByIdSlice.actions;

export const deleteProviderFacilitiesByIdSelector = (state: RootState) => state.DeleteProviderFacilitiesById;

export default deleteProviderFacilitiesByIdSlice.reducer;
