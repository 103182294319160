import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchVisitsStatsPropsInterface } from '../../../interfaces/billingInterface';
import { getVisitsStatsAPIRequest } from '../../../api/billing/visits';

const initialState: FetchVisitsStatsPropsInterface = {
  visitsStatsLoading: false,
  visitsStatsData: {},
  visitsStatsError: null,
  visitsStatsStatus: 'IDLE',
};

export const getVisitsStatsRequest: any = createAsyncThunk('vists/stats/get', async (filters: any, thunkAPI: any) => {
  try {
    const response: any = await getVisitsStatsAPIRequest(filters);
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getVisitsStatsSlice = createSlice({
  name: 'fetchVisitsStats',
  initialState,
  reducers: {
    clearFetchVisitsStatsResponse: (state) => {
      state.visitsStatsLoading = false;
      state.visitsStatsStatus = 'IDLE';
      state.visitsStatsData = {};
      state.visitsStatsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisitsStatsRequest.pending, (state, action) => {
        state.visitsStatsLoading = true;
        state.visitsStatsStatus = 'PENDING';
      })
      .addCase(getVisitsStatsRequest.fulfilled, (state, action) => {
        state.visitsStatsLoading = false;
        state.visitsStatsData = action.payload;
        state.visitsStatsStatus = 'SUCCESS';
      })
      .addCase(getVisitsStatsRequest.rejected, (state, action) => {
        state.visitsStatsLoading = false;
        state.visitsStatsError = action.payload;
        state.visitsStatsStatus = 'FAILED';
      });
  },
});

export const { clearFetchVisitsStatsResponse } = getVisitsStatsSlice.actions;

export const getVisitsStatsSelector = (state: RootState) => state.GetVisitsStats;

export default getVisitsStatsSlice.reducer;
