import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientViewEIByIdInitialPropsInterface,
  PatientViewEIByIdInterface,
  PatientVisitFetchByIdInitialPropsInterface,
  PatientVisitGetByIdInterface,
} from '../../../interfaces/patientInterface';
import { getPatientVisitByIdAPIRequest, getViewEIByIdAPIRequest } from '../../../api/patient/patientVisit';

const initialState: PatientViewEIByIdInitialPropsInterface = {
  fetchViewEIByIdLoading: false,
  fetchViewEIByIdData: {},
  fetchViewEIByIdStatus: 'IDLE',
  fetchViewEIByIdError: null,
};

export const getViewEIByIdRequest: any = createAsyncThunk(
  'patients/get/view/EI/id',
  async (searchParams: PatientViewEIByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getViewEIByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchViewEIByIdSlice = createSlice({
  name: 'fetchViewEIById',
  initialState,
  reducers: {
    clearFetchViewEIByIdResponse: (state) => {
      state.fetchViewEIByIdLoading = false;
      state.fetchViewEIByIdData = {};
      state.fetchViewEIByIdStatus = 'IDLE';
      state.fetchViewEIByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getViewEIByIdRequest.pending, (state, action) => {
        state.fetchViewEIByIdLoading = true;
        state.fetchViewEIByIdStatus = 'PENDING';
      })
      .addCase(getViewEIByIdRequest.fulfilled, (state, action) => {
        state.fetchViewEIByIdLoading = false;
        state.fetchViewEIByIdStatus = 'SUCCESS';
        state.fetchViewEIByIdData = action.payload;
      })
      .addCase(getViewEIByIdRequest.rejected, (state, action) => {
        state.fetchViewEIByIdLoading = false;
        state.fetchViewEIByIdError = action.payload;
        state.fetchViewEIByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchViewEIByIdResponse } = fetchViewEIByIdSlice.actions;

export const getViewEIByIdSelector = (state: RootState) => state.FetchViewEIById;

export default fetchViewEIByIdSlice.reducer;
