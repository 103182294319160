import { authenticatedRequest } from '../../utils/commonAxios';
import {
  ProviderFacilitiesAddInterface,
  ProviderFacilitiesUpdateInterface,
  ProviderFacilitiesGetByIdInterface,
} from '../../interfaces/providersInterface';

export const addProviderFacilitiesAPIRequest = async (provider: ProviderFacilitiesAddInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${provider?.providerId}/facility`,
    'post',
    {
      data: { ...provider?.facilitiesData },
    }
  );
};

export const editProviderFacilitiesAPIRequest = async (provider: ProviderFacilitiesUpdateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${provider?.providerId}/facility/${provider?.facilitiesId}`,
    'put',
    {
      data: { ...provider?.facilitiesData },
    }
  );
};

export const deleteProviderFacilitiesByIdAPIRequest = async (provider: ProviderFacilitiesGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${provider?.providerId}/facility/${provider?.facilitiesId}`,
    'delete',
    {}
  );
};
