import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ModifierCodesEditPropsInterface } from '../../../interfaces/codesInterfaces';
import { UpdateModifierInterface } from '../../../interfaces/systemInterfaces';
import { updateModifiersAPIRequest } from '../../../api/system/code';

const initialState: ModifierCodesEditPropsInterface = {
  editModifierCodeLoading: false,
  editModifierCodeData: {},
  editModifierCodeStatus: 'IDLE',
  editModifierCodeError: null,
};

export const updateModifiersRequest: any = createAsyncThunk(
  'codes/modifier/edit',
  async (code: UpdateModifierInterface, thunkAPI: any) => {
    try {
      const response: any = await updateModifiersAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editModifierCodeSlice = createSlice({
  name: 'editModifierCode',
  initialState,
  reducers: {
    clearEditModifierCodeResponse: (state) => {
      state.editModifierCodeLoading = false;
      state.editModifierCodeStatus = 'IDLE';
      state.editModifierCodeData = {};
      state.editModifierCodeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateModifiersRequest.pending, (state, action) => {
        state.editModifierCodeLoading = true;
        state.editModifierCodeStatus = 'PENDING';
      })
      .addCase(updateModifiersRequest.fulfilled, (state, action) => {
        state.editModifierCodeLoading = false;
        state.editModifierCodeStatus = 'SUCCESS';
        state.editModifierCodeData = action.payload;
      })
      .addCase(updateModifiersRequest.rejected, (state, action) => {
        state.editModifierCodeLoading = false;
        state.editModifierCodeStatus = 'FAILED';
        state.editModifierCodeError = action.payload;
      });
  },
});

export const { clearEditModifierCodeResponse } = editModifierCodeSlice.actions;

export const editModifierCodeSelector = (state: RootState) => state.EditModifierCode;

export default editModifierCodeSlice.reducer;
