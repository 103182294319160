import { useState } from 'react';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { CrossoverCarrierReportHeader } from '../../../components/reports/CrossoverCarrierReportHeader';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'ERA ID',
    dataIndex: 'eraId',
    key: 'eraId',
  },
  {
    title: 'Crossover Carrier',
    dataIndex: 'crossoverCarrier',
    key: 'crossoverCarrier',
  },
  {
    title: 'Crossover Id',
    dataIndex: 'crossoverId',
    key: 'crossoverId',
  },
  {
    title: 'Patient',
    dataIndex: 'patient',
    key: 'patient',
  },
  {
    title: 'Posted Amount',
    dataIndex: 'postedAmount',
    key: 'postedAmount',
  },
  {
    title: 'Claim Id',
    dataIndex: 'claimId',
    key: 'claimId',
  },
  {
    title: 'Case Id',
    dataIndex: 'caseId',
    key: 'caseId',
  },
  {
    title: 'Sec. Insurance',
    dataIndex: 'secInsurance',
    key: 'secInsurance',
  },
  {
    title: 'Pri payer Id',
    dataIndex: 'priPayerId',
    key: 'priPayerId',
  },
  {
    title: 'Pri payer Name',
    dataIndex: 'priPayerName',
    key: 'priPayerName',
  },
];

const dataSource = [
  {
    id: '1',
    eraId: 'ERA123',
    crossoverCarrier: 'Carrier A',
    crossoverId: 'Crossover123',
    patient: 'John Doe',
    postedAmount: '$100.00',
    claimId: 'Claim123',
    caseId: 'Case123',
    secInsurance: 'Insurance B',
    priPayerId: 'Payer123',
    priPayerName: 'Payer Name',
  },
];

const reportFilters = {
  payer: 'AETNA',
  filter: 'All',
};

export const payers = [{ id: 'AETNA', name: 'AETNA' }];
export const filters = [{ id: 'All', name: 'All' }];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'crossover-carrier-report', label: 'Crossover Carrier', status: 'active', link: 'crossover-carrier-report' },
];

export const CrossoverCarrierReportPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  // const [dataSource, setDataSource] = useState<any>([]);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Crossover Carrier" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
      <TableSearch />
      <CrossoverCarrierReportHeader reportFilters={reportFilters} payers={payers} filters={filters} />
      {dataSource?.length > 0 ? (
        <div className="overflow-x-auto">
          <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
        </div>
      ) : (
        <EmptyContent enableCreateButton={false} />
      )}

      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      ) : (
        <></>
      )}
    </div>
  );
};
