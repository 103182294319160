import { useState } from 'react';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { DashboardBankReconciliationReportHeader } from '../../../components/reports/DashboardBankReconciliationReportHeader';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Batch Type',
    dataIndex: 'batchType',
    key: 'batchType',
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNo',
    key: 'checkNo',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Payment Amount',
    dataIndex: 'paymentAmount',
    key: 'paymentAmount',
  },
  {
    title: 'Posted Amount',
    dataIndex: 'postedAmount',
    key: 'postedAmount',
  },
  {
    title: 'Miscellaneous Amount',
    dataIndex: 'miscellaneousAmount',
    key: 'miscellaneousAmount',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
  },
  {
    title: 'Payment Received',
    dataIndex: 'paymentReceived',
    key: 'paymentReceived',
  },
  {
    title: 'Payer Posted date',
    dataIndex: 'payerPostedDate',
    key: 'payerPostedDate',
  },
  {
    title: 'Bank Posting Date',
    dataIndex: 'bankPostingDate',
    key: 'bankPostingDate',
  },
  {
    title: 'Bank Transaction Type',
    dataIndex: 'bankTransactionType',
    key: 'bankTransactionType',
  },

  {
    title: 'Reconciled',
    dataIndex: 'reconciled',
    key: 'reconciled',
  },
];

const dataSource = [
  {
    id: '1',
    batchType: 'Type A',
    checkNo: '12345',
    description: 'Description A',
    paymentAmount: '$100.00',
    postedAmount: '$90.00',
    miscellaneousAmount: '$10.00',
    balance: '$10.00',
    paymentReceived: 'Yes',
    payerPostedDate: '2024-05-01',
    bankPostingDate: '2024-05-02',
    bankTransactionType: 'Deposit',
    reconciled: 'Yes',
  },
];

const reportFilters = {
  year: '2024',
  month: 'May',
};

export const years = [
  { id: '2024', name: '2024' },
  { id: '2023', name: '2023' },
];

export const months = [{ id: 'May', name: 'May' }];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'dashboard-bank-reconciliation-snapshot',
    label: 'Dashboard & Bank Reconciliation Snapshot',
    status: 'active',
    link: 'dashboard-bank-reconciliation-snapshot',
  },
];

export const DashboardBankReconciliationSnapshotPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  // const [dataSource, setDataSource] = useState<any>([]);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle="Dashboard & Bank Reconciliation Snapshot"
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />
      <TableSearch />
      <DashboardBankReconciliationReportHeader reportFilters={reportFilters} years={years} months={months} />
      {dataSource?.length > 0 ? (
        <div className="overflow-x-auto">
          <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
        </div>
      ) : (
        <EmptyContent enableCreateButton={false} />
      )}

      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      ) : (
        <></>
      )}
    </div>
  );
};
