import { useState } from 'react';
import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { TableContent } from '../../components/table/TableContent';
import { ClaimsTableAdvanceSearch } from '../../components/tableSearch/ClaimsTableAdvanceSearch';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { ClaimsTableActionArea } from '../../components/tableActionArea/TableActionArea';
import moment from 'moment';
import { validDateFormat } from '../../utils/commonFunctions';

const columns = [
  {
    title: 'Claim ID',
    dataIndex: 'claimId',
    key: 'claimId',
  },
  {
    title: 'Visit ID',
    dataIndex: 'visitID',
    key: 'visitID',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'CPTs',
    dataIndex: 'cpts',
    key: 'cpts',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Patient',
    dataIndex: 'patient',
    key: 'patient',
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'Carrier',
    dataIndex: 'carrier',
    key: 'carrier',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const dataSource = [
  {
    claimId: '1',
    visitID: 'Type A',
    visitDate: '12345',
    cpts: 'Description A',
    amount: '$100.00',
    patient: '$90.00',
    provider: '$10.00',
    carrier: '$10.00',
    status: 'Yes',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'secondaryClaims', label: 'Claims', status: 'active', link: 'secondary-claims' },
];

export const SecondaryClaimsPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  // const [dataSource, setDataSource] = useState<any>([]);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="main-content">
        <MainHeader />
        <PageTopic mainTitle="Claims" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
        <ClaimsTableAdvanceSearch />
        <ClaimsTableActionArea />
        {dataSource?.length > 0 ? (
          <TableContent
            enableActions={true}
            columns={columns}
            dataSource={dataSource}
            enablePrint
            enableDots
            enableOrdering={false}
          />
        ) : (
          <EmptyContent enableCreateButton={false} />
        )}
        {totalPages > 1 ? (
          <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
