import { addressType, usStates } from '../../../../constants/systemConstants';
import { ProviderAddressFormPropsInterface } from '../../../../interfaces/providersInterface';
import { Checkbox } from '../../../atoms/Checkbox';
import { InputField } from '../../../atoms/InputField';
import { SelectInput } from '../../../atoms/SelectInput';

export const ProviderAddressForm: React.FC<ProviderAddressFormPropsInterface> = ({
  onChange,
  addressFormEditData,
  errorObject,
}) => {
  return (
    <>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="Address Type"
          options={addressType}
          enableDefaultPlaceholder={true}
          name="addressType"
          onChange={onChange}
          value={addressFormEditData?.addressType ?? undefined}
        />
        <Checkbox
          label="Is Primary"
          name="primary"
          onChange={onChange}
          defaultChecked={addressFormEditData?.primary ?? undefined}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Address 1"
          name="address1"
          onChange={onChange}
          value={addressFormEditData?.address1 ?? undefined}
          error={errorObject && errorObject?.address1}
          errorMessage={errorObject && errorObject?.address1}
          required={true}
        />
        <InputField
          label="Address 2"
          name="address2"
          onChange={onChange}
          value={addressFormEditData?.address2 ?? undefined}
          error={errorObject && errorObject?.address2}
          errorMessage={errorObject && errorObject?.address2}
        />
        <InputField
          label="City"
          name="city"
          onChange={onChange}
          value={addressFormEditData?.city ?? undefined}
          error={errorObject && errorObject?.city}
          errorMessage={errorObject && errorObject?.city}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="state"
          onChange={onChange}
          value={addressFormEditData?.state ?? undefined}
          error={errorObject && errorObject?.state}
          errorMessage={errorObject && errorObject?.state}
          required={true}
        />
        <InputField
          label="Zip Code"
          name="zipCode"
          onChange={onChange}
          value={addressFormEditData?.zipCode ?? undefined}
          error={errorObject && errorObject?.zipCode}
          errorMessage={errorObject && errorObject?.zipCode}
          required={true}
          tooltip="Character length must be 5"
        />
        <InputField label="Extn" name="extn" onChange={onChange} value={addressFormEditData?.extn ?? undefined} />
      </div>
    </>
  );
};
