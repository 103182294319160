export const patientRelationship = [
  { id: 'SELF', name: 'Self' },
  { id: 'SPOUSE', name: 'Spouse' },
  { id: 'CHILD', name: 'Child' },
];

export const patientStatus = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
  { id: 'INACTIVE_DECEASED', name: 'Inactive Deceased' },
];

export const npiType = [
  { id: 'GROUP', name: 'Group' },
  { id: 'INDIVIDUAL', name: 'Individual' },
];

export const claimType = [
  { id: 'PRIMARY', name: 'Primary' },
  { id: 'SECONDARY', name: 'Secondary' },
];

export const printType = [
  { id: 'CMS_1500_FORM', name: 'CMS-1500-FORM' },
  { id: 'CMS_1500_LETTER', name: 'CMS-1500-LETTER' },
];

export const claimStatus = [
  { id: 'CLAIM_CREATED', name: 'Claim Created' },
  { id: 'APPROVED', name: 'Approved' },
  { id: 'DRAFT', name: 'Draft' },
  { id: 'ON_HOLD', name: 'On Hold' },
  { id: 'REJECTED', name: 'Rejected' },
  { id: 'REVIEWED', name: 'Reviewed' },
  { id: 'ELIGIBILITY_FAIL', name: 'Eligibility Failed' },
  { id: 'ISSUER_PENDING', name: 'Issues Pending' },
  { id: 'ALERT', name: 'Alert' },
  { id: 'MISSING_FACE_SHEET', name: 'Missing Facesheet' },
  { id: 'PENDING_TOUCH', name: 'Pending Touched' },
  { id: 'CANCELED', name: 'Cancelled' },
  { id: 'QUALITY_CHECK', name: 'Quality Check' },
];

export const patientFinancialClass = [
  { id: 'COMMERCIAL', name: 'Commercial' },
  { id: 'MEDICAID', name: 'Medicaid' },
  { id: 'MEDICARE', name: 'Medicare' },
  { id: 'MEDICAID_ADV_PL', name: 'Medicaid Advantage Plan' },
  { id: 'MEDICARE_ADV_PL', name: 'Medicare Advantage Plan' },
  { id: 'TRICARE', name: 'Tricare / Champus' },
  { id: 'WORKER_CAMP', name: `Worker's Comp` },
  { id: 'SELF_PAY', name: 'Self Pay' },
];

export const statusReason = [
  { id: 1, name: 'Inactive Insurance' },
  { id: 2, name: 'MCO Bill Option Code - C' },
  { id: 3, name: 'Birth date does not match that for the patient on the database' },
  { id: 4, name: 'CCM service paid to another provider' },
  { id: 5, name: 'CCM and PCM not covered' },
  { id: 6, name: 'Credentialing Hold' },
  { id: 7, name: 'Coordination of benefits insurance information missing' },
  { id: 8, name: 'Veterans Administration Patient' },
  { id: 9, name: 'Client Request' },
  { id: 10, name: 'MI Medicare Adjustment' },
  { id: 11, name: 'DOS Prior to provider participation effective date' },
  { id: 12, name: 'CPT is missing' },
  { id: 13, name: 'Deductible not met for the year' },
  { id: 14, name: 'Demographics and Insurance Information missing' },
  { id: 15, name: 'Facesheet is not clear/not legible/blurred' },
  { id: 16, name: 'ICD is missing' },
  { id: 17, name: 'Insurance name is missing on facesheet' },
  { id: 18, name: 'Insured ID is missing on facesheet' },
  { id: 19, name: 'Insurance information (Name/Insured ID) is missing' },
  { id: 20, name: 'Invalid / Missing Subscriber / Insured ID' },
  { id: 21, name: 'Need SSN, DOI, claim number and claims mailing address to submit paper claims' },
  { id: 22, name: 'Patient is deceased per eligibility' },
  { id: 23, name: 'Facesheet Review' },
  { id: 24, name: 'Two E/M codes' },
  { id: 25, name: 'Other' },
];

export const maritalStatus = [
  { id: 'SINGLE', name: 'Single' },
  { id: 'MARRIED', name: 'Married' },
];

export const patientEmploymentStatus = [
  { id: 'FULL_TIME', name: 'Full time' },
  { id: 'PART_TIME', name: 'Part time' },
];

export const illnessIndicatior = [
  { id: 'ILLNESS', name: 'Illness' },
  { id: 'INJURY', name: 'Injury' },
  { id: 'LMP', name: 'LMP' },
];

export const patientClaimEmploymentStatus = [
  { id: 'NOT_EMPLOYED', name: 'Unemployed' },
  { id: 'FULL_TIME', name: 'Full time' },
  { id: 'PART_TIME', name: 'Part time' },
  { id: 'RETIRED', name: 'Retired' },
  { id: 'UNKNOWN', name: 'Unknown' },
];

export const feeAmountClass = [
  { id: 'COMMERCIAL', name: 'Commercial' },
  { id: 'MEDICAID', name: 'Medicaid' },
  { id: 'MEDICARE', name: 'Medicare' },
];

export const billingCodes = [
  { id: '302', name: '302 - Zero payment entry' },
  { id: '303', name: '303 - Bad debt' },
  { id: '304', name: '304 - Charity payment entry' },
];

export const whoPaid = [
  { id: 'Medicare ML', name: 'Medicare ML' },
  { id: 'Patrick James', name: 'Patrick James' },
];
