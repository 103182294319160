import { useState } from 'react';

import { useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { PrimaryButton } from '../atoms/PrimaryButton';
import { CommonButton } from '../atoms/CommonButton';
import { DatePickerField } from '../atoms/DatePickerField';
import { SelectInput } from '../atoms/SelectInput';
import { FaFileExcel } from 'react-icons/fa';

import { getSystemProvidersSelector } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilitiesSelector } from '../../redux/slices/system/getSystemFacilitiesSlice';

import { visitBilledProviderType } from '../../constants/managementConstants';
import { formatDatePickerDate } from '../../utils/commonFunctions';

export const VisitBilledCountAdvanceSearch = ({ onSubmit }: any) => {
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);

  const currentYear = moment().year();

  const [filterObject, setFilterObject] = useState<any>({ year: currentYear.toString() });

  const yearOptions = Array.from({ length: currentYear - 2021 + 2 }, (_, i) => {
    const year = 2021 + i;
    return { id: year.toString(), name: year.toString() };
  });

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeDate = (name: string, date: any) => {
    setFilterObject((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  // console.log('filterObject', filterObject);

  return (
    <div className="table-content__adv-search">
      <form>
        <div className="table-content__adv-search-content">
          <div className="grid md:grid-cols-3 gap-4 items-end">
            <SelectInput
              enableDefaultPlaceholder={false}
              name="year"
              options={yearOptions}
              onChange={onChangeFilter}
              value={filterObject?.year ?? ''}
            />
            <DatePickerField
              selectedDate={formatDatePickerDate(filterObject?.fromDate)}
              onChange={(date: Date | null) => {
                if (date) {
                  onChangeDate('fromDate', date);
                }
              }}
            />
            <DatePickerField
              selectedDate={formatDatePickerDate(filterObject?.toDate)}
              onChange={(date: Date | null) => {
                if (date) {
                  onChangeDate('toDate', date);
                }
              }}
            />
          </div>
          <div className="grid md:grid-cols-4 gap-4 mb-4 mt-4 items-center">
            <SelectInput
              defaultPlaceholder="Select Provider Type"
              name="providerType"
              options={visitBilledProviderType}
              onChange={onChangeFilter}
              value={filterObject?.providerType ?? ''}
            />
            <SelectInput
              defaultPlaceholder="Select Provider / PA"
              name="providerId"
              options={systemProvidersProviderData}
              onChange={onChangeFilter}
              value={filterObject?.providerId ?? ''}
            />
            <SelectInput
              defaultPlaceholder="All Facility"
              name="facilityId"
              options={systemFacilityData}
              onChange={onChangeFilter}
              value={filterObject?.facilityId ?? ''}
            />
            <div className="flex gap-2 justify-end">
              <PrimaryButton
                type="button"
                label="Submit"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => onSubmit(filterObject)}
              />
              <CommonButton
                label="Reset"
                buttonType="secondary"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => {
                  setFilterObject({ year: currentYear.toString() });
                  onSubmit({ year: currentYear.toString() });
                }}
              />
              <PrimaryButton
                type="button"
                label="Export to Excel"
                icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                style={{ height: '40px', maxWidth: '100px' }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
