import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { ChargeDetailVisitReportAdvanceSearch } from '../../components/tableSearch/ChargeDetailVisitReportAdvanceSearch';

import {
  getChargeDetailEncounterReportRequest,
  getChargeDetailEncounterReportSelector,
  clearFetchChargeDetailEncounterReportResponse,
} from '../../redux/slices/management/chargeDetailEncounterReport/getChargeDetailEncounterReportSlice';
import { getClientsListRequest } from '../../redux/slices/system/getClientsListSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Patient Name',
    dataIndex: 'patientName',
    key: 'patientName',
  },
  {
    title: 'Client Name',
    dataIndex: 'clientName',
    key: 'clientName',
  },
  {
    title: 'Provider Name',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'Insurance Name',
    dataIndex: 'insurance',
    key: 'insurance',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'CPT Code',
    dataIndex: 'cpts',
    key: 'cpts',
    render: (text: any, record: any) => {
      return record.cpts ? record.cpts.split(',').join(', ') : '';
    },
  },
  {
    title: 'Charges',
    dataIndex: 'charges',
    key: 'charges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Payments',
    dataIndex: 'payments',
    key: 'payments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Adjustments',
    dataIndex: 'adjustments',
    key: 'adjustments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    patientId: 233,
    patientName: 'Aanaid Al Jebary',
    clientName: 'Dr. Sami AbuFarha, MD PC',
    provider: 'Mohammed Yazbek',
    insurance: 'Mi Molina Healthcare',
    visitDate: '2024-05-02',
    cpts: '99439,99490',
    charges: 170.0,
    payments: 0.0,
    adjustments: 0.0,
    balance: 170.0,
  },
  {
    patientId: 489,
    patientName: 'Aanaid Al Jebary',
    clientName: 'Dr. Sami AbuFarha, MD PC',
    provider: 'Mohammed Yazbek',
    insurance: 'Mi Molina Healthcare',
    visitDate: '2024-07-23',
    cpts: '99490',
    charges: 102.0,
    payments: 43.24,
    adjustments: 58.76,
    balance: 0.0,
  },
  {
    patientId: 343,
    patientName: 'Aanaid Al Jebary',
    clientName: 'Dr. Sami AbuFarha, MD PC',
    provider: 'Mohammed Yazbek',
    insurance: 'Mi Molina Healthcare',
    visitDate: '2024-06-07',
    cpts: '99490',
    charges: 102.0,
    payments: 43.24,
    adjustments: 58.76,
    balance: 0.0,
  },
  {
    patientId: 5396,
    patientName: 'Aaron Hogan',
    clientName: 'Pulse Primary Care',
    provider: 'Abdulla Abdel Hafeez',
    insurance: 'Mi Bcbs',
    visitDate: '2024-07-30',
    cpts: '99490',
    charges: 102.0,
    payments: 72.6,
    adjustments: 9.38,
    balance: 20.02,
  },
  {
    patientId: 5396,
    patientName: 'Aaron Hogan',
    clientName: 'Pulse Primary Care',
    provider: 'Abdulla Abdel Hafeez',
    insurance: 'Mi Bcbs',
    visitDate: '2024-08-12',
    cpts: '99439,99490',
    charges: 170.0,
    payments: 121.9,
    adjustments: 14.48,
    balance: 33.62,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'chargeDetailEncounterReport',
    label: 'Charge Detail Report - Encounter',
    status: 'active',
    link: 'charge-detail-encounter',
  },
];

export const ChargeDetailEncounterReportPage = () => {
  const dispatch = useAppDispatch();

  const {
    fetchChargeDetailEncounterReportStatus,
    fetchChargeDetailEncounterReportData,
    fetchChargeDetailEncounterReportLoading,
  } = useAppSelector(getChargeDetailEncounterReportSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const initialFromDate = moment().startOf('month').format('YYYY-MM-DD');
  const initialToDate = moment().endOf('month').format('YYYY-MM-DD');

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({
    filterBy: 'CREATED',
    fromDate: initialFromDate,
    toDate: initialToDate,
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (systemProvidersProviderData?.length === 0) {
      fetchSystemProviders();
    }
    fetchClientsList();
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchChargeDetailEncounterReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchChargeDetailEncounterReportStatus === 'SUCCESS') {
      setDataSource(fetchChargeDetailEncounterReportData?.items);
      setCurrentPage(fetchChargeDetailEncounterReportData?.currentPage);
      setTotalPages(fetchChargeDetailEncounterReportData?.totalPages);
    } else if (fetchChargeDetailEncounterReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchChargeDetailEncounterReportResponse());
    }
  }, [fetchChargeDetailEncounterReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchChargeDetailEncounterReport(page - 1);
  };

  const fetchChargeDetailEncounterReport = async (pageNumber = currentPage) => {
    dispatch(getChargeDetailEncounterReportRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const fetchClientsList = async () => {
    dispatch(getClientsListRequest());
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.clientId || updatedData?.clientId === '') {
      delete updatedData.clientId;
    } else {
      updatedData.clientId = parseInt(updatedData?.clientId, 10);
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle="Charge Detail Report - Encounter"
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />
      <ChargeDetailVisitReportAdvanceSearch onSubmit={handleSearch} />
      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      {fetchChargeDetailEncounterReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <div className="flex justify-end mb-4">
                <div className="flex items-center">
                  <label htmlFor="search" className="mr-2 text-gray-600">
                    Search:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded px-2 py-1"
                    name="search"
                    title="Search"
                    disabled
                  />
                </div>
              </div>

              <div className={`overflow-x-auto ${totalPages > 1 ? '' : 'pb-7'}`}>
                <TableContent columns={columns} dataSource={dummyDataSource} enableActions={false} />
              </div>
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
