import { Modal } from 'flowbite-react';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';

import { CommonAlert } from '../../../atoms/Alert';
import { InputField } from '../../../atoms/InputField';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { Spinner } from '../../../atoms/Spinner';
import { Pagination } from '../../../pagination/Pagination';

import { getCPTCodesRequest } from '../../../../redux/slices/system/getCPTCodesSlice';
import { getModifiersRequest, getModifiersSelector } from '../../../../redux/slices/system/getModifiersSlice';

const PAGE_SIZE = 5;

const cptColumns = [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Description',
    dataIndex: 'desc',
    key: 'desc',
  },
];

export const ModifierSearchModal = ({
  modifierModalOpen,
  setOpenModifierModal,
  modifiersDataSource,
  visitId,
  getModifierCodeList,
  handleAddedModifierRow,
}: any) => {
  const dispatch = useAppDispatch();
  const { modifiersStatus, modifiersData } = useAppSelector(getModifiersSelector);

  const [formData, setFormData] = useState<any>({});
  const [fetchDataLoading, setFetchDataLoading] = useState<boolean>(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false);

  useEffect(() => {
    setFetchDataLoading(true);
    dispatch(
      getModifiersRequest({
        code: formData?.code ?? '',
        desc: formData?.desc ?? '',
        page: currentPage,
        size: PAGE_SIZE,
      })
    );
  }, []);

  useEffect(() => {
    if (modifiersStatus === 'SUCCESS') {
      setCurrentPage(modifiersData?.currentPage);
      setTotalPages(modifiersData?.totalPages);
      setTotalItems(modifiersData?.totalItems);
      setFetchDataLoading(false);
    } else if (modifiersStatus === 'FAILED') {
      setFetchDataLoading(false);
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [modifiersStatus]);

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleFieldValueChange = (e: any) => {
    // setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));

    const { name, value } = e.target;

    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    if (
      (!updatedFormData?.code || updatedFormData?.code?.trim() === '') &&
      (!updatedFormData?.desc || updatedFormData?.desc?.trim() === '')
    ) {
      setIsSearchEnabled(false);
    } else {
      setIsSearchEnabled(true);
    }
  };

  const handleSubmit = () => {
    dispatch(
      getModifiersRequest({
        code: formData?.code ?? '',
        desc: formData?.desc ?? '',
        page: 0,
        size: PAGE_SIZE,
      })
    );
  };

  const handleReset = () => {
    setFormData({});
    setIsSearchEnabled(false);
    dispatch(getModifiersRequest({ code: '', desc: '', page: 0, size: PAGE_SIZE }));
  };

  const onSearchPageChange = (page: any) => {
    setCurrentPage(page - 1);
    dispatch(
      getModifiersRequest({ code: formData?.code ?? '', desc: formData?.desc ?? '', page: page - 1, size: PAGE_SIZE })
    );
  };

  const handleAddRow = (event: any, rowIndex: any) => {
    event.preventDefault();
    handleAddedModifierRow(modifiersDataSource[rowIndex]);
    setAlertObj({
      color: 'success',
      message: 'Modifier Selected !',
      error: false,
    });
    setVisibleAlert(true);
    setTimeout(() => {
      setAlertObj({
        color: '',
        message: '',
        error: false,
      });
      setVisibleAlert(false);
      setOpenModifierModal(false);
    }, 1000);
  };

  const handleOnClose = () => {
    setOpenModifierModal(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <Modal show={modifierModalOpen} onClose={handleOnClose}>
      <Modal.Header>Search Modifier</Modal.Header>
      <Modal.Body>
        {visibleAlert && !alertObj?.error && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-1/2"
          />
        )}
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <InputField label="Code" name="code" onChange={handleFieldValueChange} value={formData?.code ?? ''} />
          <InputField onChange={handleFieldValueChange} name="desc" label="Description" value={formData?.desc ?? ''} />
        </div>
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <div className="col-span-1"></div>
          <div className="col-span-1 flex justify-end space-x-2">
            <CommonButton type="button" label={'Reset'} buttonType="secondary" onClick={handleReset} />
            <CommonButton
              type="button"
              label={'Search'}
              buttonType="primary"
              onClick={handleSubmit}
              disabled={!isSearchEnabled}
            />
          </div>
        </div>
        {fetchDataLoading ? (
          <Spinner />
        ) : (
          <>
            {modifiersDataSource && modifiersDataSource?.length > 0 && (
              <>
                <TableContent
                  enableActions={true}
                  columns={cptColumns}
                  dataSource={modifiersDataSource}
                  enableAdd={true}
                  onHandleAdd={handleAddRow}
                />
                <Pagination
                  currentPage={currentPage}
                  totalItems={totalItems}
                  totalPages={totalPages}
                  onPageChange={onSearchPageChange}
                />
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
