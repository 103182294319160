import { useEffect, useState } from 'react';
import { CommonAlert } from '../../../atoms/Alert';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { RightSheet } from '../../../rightSheet/RightSheet';
import { InsuranceForm } from './InsuranceeForm';
import { PatientInsuranceInterface } from '../../../../interfaces/patientInterface';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import {
  addPatientInsuranceRequest,
  addPatientInsuranceSelector,
  clearAddPatientInsuranceResponse,
} from '../../../../redux/slices/patient/addPatientInsuranceSlice';
import { getPatientsByIdSelector } from '../../../../redux/slices/patient/getPatientByIdSlice';
import moment from 'moment';
import { convertDate, validDateFormat } from '../../../../utils/commonFunctions';
import { Spinner } from '../../../atoms/Spinner';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import {
  clearDeletePatientInsuranceByIdResponse,
  deletePatientInsuranceByIdRequest,
  deletePatientInsuranceByIdSelector,
} from '../../../../redux/slices/patient/deletePatientInsuranceSlice';
import {
  clearFetchPatientInsuranceByIdResponse,
  getPatientInsuranceByIdRequest,
  getPatientInsuranceByIdSelector,
} from '../../../../redux/slices/patient/getPatientInsuranceByIdSlice';
import {
  clearEditPatientInsuranceResponse,
  editPatientInsuranceRequest,
  editPatientInsuranceSelector,
} from '../../../../redux/slices/patient/editPatientInsuranceSlice';
import { createPatientInsurance } from '../../../../utils/patientValidation';
import { IoIosInformationCircleOutline } from 'react-icons/io';

const columns = [
  {
    title: 'Primary',
    dataIndex: 'insuranceType',
    key: 'insuranceType',
  },
  {
    title: 'Insurance',
    dataIndex: 'insurance',
    key: 'insurance',
  },
  {
    title: 'Payer ID',
    dataIndex: 'payerId',
    key: 'payerId',
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'Policy #',
    dataIndex: 'policyId',
    key: 'policyId',
  },
  {
    title: 'Group #',
    dataIndex: 'group',
    key: 'group',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const dataSource = [
  {
    primary: 'A / A / 1 / 11',
    address: 'Ranpokunagama',
    city: 'Nittambuwa',
    state: 'Gampaha',
    zip: '11880',
  },
  {
    primary: 'A / A / 1 / 11',
    address: 'Ranpokunagama',
    city: 'Nittambuwa',
    state: 'Gampaha',
    zip: '11880',
  },
  {
    primary: 'A / A / 1 / 11',
    address: 'Ranpokunagama',
    city: 'Nittambuwa',
    state: 'Gampaha',
    zip: '11880',
  },
  {
    primary: 'A / A / 1 / 11',
    address: 'Ranpokunagama',
    city: 'Nittambuwa',
    state: 'Gampaha',
    zip: '11880',
  },
];

export const Insurance: React.FC<PatientInsuranceInterface> = ({
  selectedId,
  fetchPatientById,
  fetchDataLoading,
  addDataLoading,
  selectedPatientName,
  mode,
}) => {
  const dispatch = useAppDispatch();
  const { addPatientInsuranceStatus, addPatientInsuranceLoading } = useAppSelector(addPatientInsuranceSelector);
  const { fetchPatientByIdData, fetchPatientByIdStatus } = useAppSelector(getPatientsByIdSelector);
  const { deletePatientInsuranceByIdLoading, deletePatientInsuranceByIdStatus } = useAppSelector(
    deletePatientInsuranceByIdSelector
  );
  const { fetchPatientInsuranceByIdData, fetchPatientInsuranceByIdStatus } = useAppSelector(
    getPatientInsuranceByIdSelector
  );
  const { editPatientInsuranceStatus, editPatientInsuranceData, editPatientInsuranceLoading } =
    useAppSelector(editPatientInsuranceSelector);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [dataSource, setDataSource] = useState<any>([]);
  const [patientInsuranceFormData, setPatientInsuranceFormData] = useState<any>({});
  const [childMode, setChildMode] = useState('CREATE');
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [selectedInsuranceId, setSelectedInsuranceId] = useState<number>();
  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    setVisibleModal(false);
    setVisibleAlert(false);
  }, []);

  useEffect(() => {
    if (fetchPatientByIdStatus === 'SUCCESS') {
      const convertedDataSet = convertDataSet(fetchPatientByIdData);
      setDataSource(convertedDataSet);
    }
  }, [fetchPatientByIdStatus]);

  useEffect(() => {
    if (addPatientInsuranceStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'insurance');
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setPatientInsuranceFormData({});
      setErrorObj({});
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setChildMode('CREATE');
      }, 3000);
      dispatch(clearAddPatientInsuranceResponse());
    } else if (addPatientInsuranceStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      // setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddPatientInsuranceResponse());
    }
  }, [addPatientInsuranceStatus]);

  useEffect(() => {
    if (deletePatientInsuranceByIdStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'insurance');
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearDeletePatientInsuranceByIdResponse());
    } else if (deletePatientInsuranceByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearDeletePatientInsuranceByIdResponse());
    }
  }, [deletePatientInsuranceByIdStatus]);

  useEffect(() => {
    if (fetchPatientInsuranceByIdStatus === 'SUCCESS') {
      setPatientInsuranceFormData(convertToEdit(fetchPatientInsuranceByIdData));
      setVisibleModal(true);
    } else if (fetchPatientInsuranceByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchPatientInsuranceByIdResponse());
  }, [fetchPatientInsuranceByIdStatus]);

  useEffect(() => {
    if (editPatientInsuranceStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'insurance');
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setPatientInsuranceFormData({});
      setErrorObj({});
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditPatientInsuranceResponse());
    } else if (editPatientInsuranceStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      // setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditPatientInsuranceResponse());
    }
  }, [editPatientInsuranceStatus]);

  const convertDataSet = (dataSet: []) => {
    let newArr = [];

    for (let index = 0; index < dataSet.length; index++) {
      const element: any = dataSet[index];
      let obj = {
        insuranceType: element?.insuranceType,
        endDate: convertDate(element?.endDate),
        group: element?.group,
        id: element?.id,
        insurance: element?.insurance,
        payerId: element?.payerId,
        policyId: element?.policyId,
        startDate: convertDate(element?.startDate),
        status: element?.status,
      };
      newArr[newArr.length] = obj;
    }

    return newArr;
  };

  const convertToEdit = (dataSet: any) => {
    // console.log('=== data set - before convert ===', dataSet);

    let obj = {
      id: dataSet?.id,
      insuranceType: dataSet?.insuranceType,
      insuranceId: dataSet?.insuranceId,
      policyId: dataSet?.policyId,
      group: dataSet?.group,
      relationship: dataSet?.invoiceRelationship,
      subscriberId: dataSet?.invoiceRelationship === 'SELF' ? selectedPatientName : dataSet?.subscriberName,
      policyStartDate: dataSet?.startDate,
      policyEndDate: dataSet?.endDate,
      note: dataSet?.note,
    };

    return obj;
  };

  const handleAddressFormChange = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setPatientInsuranceFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    setErrorObj({});
    getInsuranceDetailById(dataSource[rowIndex]?.id);
    setSelectedInsuranceId(dataSource[rowIndex]?.id);
    setChildMode('EDIT');
    setSelectedToEdit(dataSource[rowIndex]);
  };

  const getInsuranceDetailById = (id: number) => {
    dispatch(getPatientInsuranceByIdRequest({ patientId: selectedId, insuranceId: id }));
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedInsuranceId(dataSource[rowIndex]?.id);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedInsuranceId(dataSet?.id);
    dispatch(deletePatientInsuranceByIdRequest({ patientId: selectedId, insuranceId: dataSet?.id }));
  };

  const handleAddNewModal = () => {
    setPatientInsuranceFormData({});
    setErrorObj({});
    setChildMode('CREATE');
    setVisibleModal(true);
  };

  const onCloseModal = () => {
    setPatientInsuranceFormData({});
    setErrorObj({});
    setChildMode('CREATE');
    setVisibleModal(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const onChangeEndDate = (date: Date) => {
    setPatientInsuranceFormData((prev: any) => ({ ...prev, policyEndDate: moment(date).format('YYYY-MM-DD') }));
  };

  const onChangeStartDate = (date: Date) => {
    setPatientInsuranceFormData((prev: any) => ({ ...prev, policyStartDate: moment(date).format('YYYY-MM-DD') }));
  };

  const convertBeforeSubmit = (formData: any) => {
    let obj = {
      insuranceType: formData?.insuranceType,
      insuranceId: formData?.insuranceId,
      policyId: formData?.policyId,
      groupNumber: formData?.group,
      relationship: formData?.relationship,
      policyStartDate: formData?.policyStartDate
        ? convertDate(formData?.policyStartDate)
        : convertDate(formData?.startDate),
      policyEndDate: formData?.policyEndDate ? convertDate(formData?.policyEndDate) : convertDate(formData?.endDate),
      note: formData?.note,
      subscriberName: formData?.subscriberId ?? formData?.subscriberName,
    };

    return obj;
  };

  const onSubmit = () => {
    if (childMode === 'CREATE') {
      let formObject = patientInsuranceFormData;
      if (patientInsuranceFormData.insuranceId) {
        formObject.insuranceId = parseInt(patientInsuranceFormData.insuranceId);
      }

      formObject.relationship = patientInsuranceFormData?.relationship ?? 'SELF';
      formObject.subscriberId = patientInsuranceFormData?.subscriberId ?? selectedPatientName;
      formObject.groupNumber = patientInsuranceFormData?.group;

      let obj = {
        patientId: selectedId,
        insuranceData: formObject,
      };

      const validationDetails = createPatientInsurance(obj?.insuranceData);
      // console.log('=== validationDetails ===', validationDetails);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);

        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(addPatientInsuranceRequest(obj));
      }
    } else if (childMode === 'EDIT') {
      let obj = {
        patientId: selectedId,
        insuranceData: convertBeforeSubmit(patientInsuranceFormData),
        insuranceId: selectedInsuranceId,
      };
      const validationDetails = createPatientInsurance(obj?.insuranceData);

      // console.log('=== validationDetails ==', validationDetails);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);

        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(editPatientInsuranceRequest(obj));
      }
    }
  };

  const handleSubscriber = (data: any) => {
    setPatientInsuranceFormData((prev: any) => ({ ...prev, subscriberId: data?.id, subscriberName: data?.name }));
  };

  // console.log('=== fetchPatientByIdData ==', fetchPatientByIdData);

  return (
    <>
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className="w-64 ml-auto">
        {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            onClick={handleAddNewModal}
          />
        )}
      </div>
      {fetchDataLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <TableContent
              enableActions={mode === 'VIEW' ? false : true}
              columns={columns}
              dataSource={dataSource}
              enableDelete={true}
              enableEdit={true}
              enableView={false}
              onDelete={onDelete}
              onEdit={onEdit}
              onView={onView}
            />
          ) : (
            <EmptyContent onClick={handleAddNewModal} enableCreateButton={mode === 'VIEW' ? false : true} mode={mode} />
          )}
        </>
      )}

      {visibleModal && (
        <RightSheet
          title="Patient Insurance"
          submitButtonTitle={childMode === 'CREATE' ? 'Submit' : 'Update'}
          cancelButtonTitle="Cancel"
          children={
            <InsuranceForm
              onChange={handleAddressFormChange}
              onChangeEndDate={onChangeEndDate}
              onChangeStartData={onChangeStartDate}
              insuranceFormEditData={patientInsuranceFormData}
              errorObject={errorObj}
              mode={childMode}
              selectedPatientName={selectedPatientName}
              handleSubscriber={handleSubscriber}
            />
          }
          onClose={onCloseModal}
          onSubmit={onSubmit}
          loading={addPatientInsuranceLoading || editPatientInsuranceLoading}
          enableAlert={visibleAlert}
          alertDetails={alertObj}
          alertOnClose={errorAlertOnClose}
        />
      )}
    </>
  );
};
