import { Modal } from 'flowbite-react';

export const ViewERAModal = ({ visibleClaimERA, setVisibleClaimERA }: any) => {
  return (
    <Modal show={visibleClaimERA} onClose={() => setVisibleClaimERA(false)}>
      <Modal.Header>Claim ERA</Modal.Header>
      <Modal.Body></Modal.Body>
      <Modal.Footer>
        {/* <CommonButton
          type="button"
          label={'Close'}
          buttonType="secondary"
          onClick={() => setVisibleNotesModal(false)}
        />
        <CommonButton type="button" label={'Save'} buttonType="primary" onClick={onSubmit} /> */}
      </Modal.Footer>
    </Modal>
  );
};
