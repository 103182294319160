import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GetCPTCodeByIdInitialInterface, GetCPTCodesByIdInterface } from '../../../interfaces/systemInterfaces';
import { getCPTCodeByIdAPIRequest } from '../../../api/system/code';

const initialState: GetCPTCodeByIdInitialInterface = {
  cptCodeByIdLoading: false,
  cptCodeByIdData: {},
  cptCodeByIdError: null,
  cptCodeByIdStatus: 'IDLE',
};

export const getCPTCodeByIdRequest: any = createAsyncThunk(
  'codes/cpt/get/id',
  async (code: GetCPTCodesByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getCPTCodeByIdAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getCPTCodeByIdSlice = createSlice({
  name: 'getCPTCodeById',
  initialState,
  reducers: {
    clearGetCPTCodeByIdResponse: (state) => {
      state.cptCodeByIdLoading = false;
      state.cptCodeByIdStatus = 'IDLE';
      state.cptCodeByIdData = {};
      state.cptCodeByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCPTCodeByIdRequest.pending, (state, action) => {
        state.cptCodeByIdLoading = true;
        state.cptCodeByIdStatus = 'PENDING';
      })
      .addCase(getCPTCodeByIdRequest.fulfilled, (state, action) => {
        state.cptCodeByIdLoading = false;
        state.cptCodeByIdData = action.payload;
        state.cptCodeByIdStatus = 'SUCCESS';
      })
      .addCase(getCPTCodeByIdRequest.rejected, (state, action) => {
        state.cptCodeByIdLoading = false;
        state.cptCodeByIdError = action.payload;
        state.cptCodeByIdStatus = 'FAILED';
      });
  },
});

export const { clearGetCPTCodeByIdResponse } = getCPTCodeByIdSlice.actions;

export const getCPTCodeByIdSelector = (state: RootState) => state.GetCPTCodeById;

export default getCPTCodeByIdSlice.reducer;
