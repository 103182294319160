import { ProviderProfessionalUpdateInterface } from "../../interfaces/providersInterface";
import { authenticatedRequest } from "../../utils/commonAxios";

export const updateProviderProfessionalAPIRequest = async (
  provider: ProviderProfessionalUpdateInterface
) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${provider?.providerId}/professional`,
    "put",
    {
      data: { ...provider?.professionData },
    }
  );
};
