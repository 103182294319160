import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  CPTCodePropsInterface,
  CPTFetchInitialInterface,
  ICDFetchInitialInterface,
  PatientFetchInitialInterface,
  PatientFetchInterface,
} from '../../../interfaces/patientInterface';
import { getPatientsAPIRequest } from '../../../api/patient/patient';
import { getCPTCodeListAPIRequest, getICDCodeListAPIRequest } from '../../../api/patient/patientVisit';

const initialState: ICDFetchInitialInterface = {
  loadingICD: false,
  icdData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  errorICD: null,
  statusICD: 'IDLE',
};

export const getICDCodeListRequest: any = createAsyncThunk(
  'icd/get',
  async (searchParams: CPTCodePropsInterface, thunkAPI: any) => {
    try {
      const response: any = await getICDCodeListAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getICDListSlice = createSlice({
  name: 'fetchICDList',
  initialState,
  reducers: {
    clearFetchICDResponse: (state) => {
      state.loadingICD = false;
      state.statusICD = 'IDLE';
      state.icdData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.errorICD = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getICDCodeListRequest.pending, (state, action) => {
        state.loadingICD = true;
        state.statusICD = 'PENDING';
      })
      .addCase(getICDCodeListRequest.fulfilled, (state, action) => {
        state.loadingICD = false;
        state.statusICD = 'SUCCESS';
        state.icdData = action.payload;
      })
      .addCase(getICDCodeListRequest.rejected, (state, action) => {
        state.loadingICD = false;
        state.errorICD = action.payload;
        state.statusICD = 'FAILED';
      });
  },
});

export const { clearFetchICDResponse } = getICDListSlice.actions;

export const getICDListSelector = (state: RootState) => state.ICDList;

export default getICDListSlice.reducer;
