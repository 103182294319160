import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  DeleteVisitCPTCodeInitialPropsInterface,
  PatientVisitDeleteCPTInterface,
  PatientVisitGetByIdInterface,
} from '../../../interfaces/patientInterface';
import { deleteVisitCPTCodeAPIRequest } from '../../../api/patient/patientVisit';

const initialState: DeleteVisitCPTCodeInitialPropsInterface = {
  deleteVisitCPTCodeLoading: false,
  deleteVisitCPTCodeData: {},
  deleteVisitCPTCodeStatus: 'IDLE',
  deleteVisitCPTCodeError: null,
};

export const deleteVisitCPTCodeRequest: any = createAsyncThunk(
  'delete/cpt',
  async (searchParams: PatientVisitDeleteCPTInterface, thunkAPI: any) => {
    try {
      const response: any = await deleteVisitCPTCodeAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deleteVisitCPTCodeSlice = createSlice({
  name: 'deleteVisitCPTCode',
  initialState,
  reducers: {
    clearDeleteVisitCPTCodeResponse: (state) => {
      state.deleteVisitCPTCodeLoading = false;
      state.deleteVisitCPTCodeData = {};
      state.deleteVisitCPTCodeStatus = 'IDLE';
      state.deleteVisitCPTCodeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteVisitCPTCodeRequest.pending, (state, action) => {
        state.deleteVisitCPTCodeLoading = true;
        state.deleteVisitCPTCodeStatus = 'PENDING';
      })
      .addCase(deleteVisitCPTCodeRequest.fulfilled, (state, action) => {
        state.deleteVisitCPTCodeLoading = false;
        state.deleteVisitCPTCodeStatus = 'SUCCESS';
        state.deleteVisitCPTCodeData = action.payload;
      })
      .addCase(deleteVisitCPTCodeRequest.rejected, (state, action) => {
        state.deleteVisitCPTCodeLoading = false;
        state.deleteVisitCPTCodeError = action.payload;
        state.deleteVisitCPTCodeStatus = 'FAILED';
      });
  },
});

export const { clearDeleteVisitCPTCodeResponse } = deleteVisitCPTCodeSlice.actions;

export const deleteVisitCPTCodeSelector = (state: RootState) => state.DeleteVisitCPTCode;

export default deleteVisitCPTCodeSlice.reducer;
