import { npiType } from '../../../../constants/patientConstants';
import { useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { PatientVisitEIFormProps } from '../../../../interfaces/patientInterface';
import { getPatientsByIdDemoSelector } from '../../../../redux/slices/patient/getPatientByIdDemoSlice';
import { getSystemInsuranceSelector } from '../../../../redux/slices/system/getSystemInsuranceSlice';
import { Calendar } from '../../../atoms/Calendar';
import { InputField } from '../../../atoms/InputField';
import { SelectInput } from '../../../atoms/SelectInput';
import { formatDate } from '../../../../utils/commonFunctions';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';

export const EligibilityForm: React.FC<PatientVisitEIFormProps> = ({
  onChange,
  eligibilityFormEditData,
  eligibilityMode,
  mode,
  errorObject,
  onEligibilityFormDates,
  patientInsurance,
}) => {
  const { systemInsuranceInsuranceData } = useAppSelector(getSystemInsuranceSelector);
  const { fetchPatientByIdDemoData } = useAppSelector(getPatientsByIdDemoSelector);

  // console.log('=== eligibilityFormEditData ===', eligibilityFormEditData);

  const filterByInsuranceType = (patientInsuranceArr = patientInsurance) => {
    // console.log('=== patientInsurance ===', patientInsurance);

    return patientInsuranceArr?.map((el: any) => {
      return {
        id: el?.insuranceId,
        name: el?.name,
      };
    });
  };

  return (
    <>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Last Name"
          name="lastName"
          onChange={onChange}
          defaultValue={eligibilityFormEditData?.lastName ?? undefined}
          error={errorObject && errorObject?.lastName}
          errorMessage={errorObject && errorObject?.lastName}
          required={true}
        />
        <InputField
          label="First Name"
          name="firstName"
          onChange={onChange}
          defaultValue={eligibilityFormEditData?.firstName ?? undefined}
          error={errorObject && errorObject?.firstName}
          errorMessage={errorObject && errorObject?.firstName}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Policy #"
          name="policy"
          onChange={onChange}
          defaultValue={eligibilityFormEditData?.policy ?? undefined}
          error={errorObject && errorObject?.policy}
          errorMessage={errorObject && errorObject?.policy}
          required={true}
        />
        <InputField
          label="Group #"
          name="group"
          onChange={onChange}
          defaultValue={eligibilityFormEditData?.group ?? undefined}
          error={errorObject && errorObject?.group}
          errorMessage={errorObject && errorObject?.group}
          // required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="Insurance Name"
          options={filterByInsuranceType(patientInsurance)}
          enableDefaultPlaceholder={true}
          name="insuranceId"
          onChange={onChange}
          defaultValue={eligibilityFormEditData?.insuranceId ?? undefined}
          error={errorObject && errorObject?.insuranceId}
          errorMessage={errorObject && errorObject?.insuranceId}
          required={true}
        />
        <InputField
          label="Payer ID"
          name="payerId"
          onChange={onChange}
          defaultValue={eligibilityFormEditData?.payerId ?? undefined}
          error={errorObject && errorObject?.payerId}
          errorMessage={errorObject && errorObject?.payerId}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Last Name or Group Name"
          name="receiverLastNameOrOrganizationName"
          onChange={onChange}
          defaultValue={eligibilityFormEditData?.receiverLastNameOrOrganizationName ?? undefined}
          error={errorObject && errorObject?.receiverLastNameOrOrganizationName}
          errorMessage={errorObject && errorObject?.receiverLastNameOrOrganizationName}
        />
        <InputField
          label="First Name"
          name="receiverFirstName"
          onChange={onChange}
          defaultValue={eligibilityFormEditData?.receiverFirstName ?? undefined}
          error={errorObject && errorObject?.receiverFirstName}
          errorMessage={errorObject && errorObject?.receiverFirstName}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="NPI Type"
          options={npiType}
          enableDefaultPlaceholder={true}
          name="npiType"
          onChange={onChange}
          defaultValue={eligibilityFormEditData?.npiType ?? undefined}
          error={errorObject && errorObject?.npiType}
          errorMessage={errorObject && errorObject?.npiType}
          required={true}
        />
        <InputField
          label="NPI"
          name="npi"
          onChange={onChange}
          defaultValue={eligibilityFormEditData?.npi ?? undefined}
          error={errorObject && errorObject?.npi}
          errorMessage={errorObject && errorObject?.npi}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-5 mt-6">
        {/* <Calendar
          onSelectedDateChanged={(date) => onEligibilityFormDates(date, 'patientDoB')}
          name="patientDoB"
          label="Date of Birth"
          //defaultDate={eligibilityFormEditData?.patientDoB ? new Date(eligibilityFormEditData?.patientDoB) : undefined}
          defaultDate={formatDate(eligibilityFormEditData?.patientDoB)}
          error={errorObject && errorObject?.patientDoB}
          errorMessage={errorObject && errorObject?.patientDoB}
          required={true}
        /> */}
        <DatePickerField
          label="Date of Birth"
          name="patientDoB"
          selectedDate={formatDatePickerDate(eligibilityFormEditData?.patientDoB)}
          onChange={(date: Date | null) => {
            if (date) {
              onEligibilityFormDates(date, 'patientDoB');
            }
          }}
          required={true}
        />
        {/* <Calendar
          onSelectedDateChanged={(date) => onEligibilityFormDates(date, 'dateOfService')}
          name="dateOfService"
          label="Date of Service"
          // defaultDate={
          //   eligibilityFormEditData?.dateOfService ? new Date(eligibilityFormEditData?.dateOfService) : undefined
          // }
          defaultDate={formatDate(eligibilityFormEditData?.dateOfService)}
          error={errorObject && errorObject?.dateOfService}
          errorMessage={errorObject && errorObject?.dateOfService}
          required={true}
        /> */}
        <DatePickerField
          label="Date of Service"
          name="dateOfService"
          selectedDate={formatDatePickerDate(eligibilityFormEditData?.dateOfService)}
          onChange={(date: Date | null) => {
            if (date) {
              onEligibilityFormDates(date, 'dateOfService');
            }
          }}
          required={true}
        />
      </div>
    </>
  );
};
