import { Modal } from 'flowbite-react';
import { InputField } from '../../../atoms/InputField';
import { useEffect, useState } from 'react';
import { TextArea } from '../../../atoms/TextArea';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { Pagination } from '../../../pagination/Pagination';
import { Spinner } from '../../../atoms/Spinner';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { getICDCodesRequest, getICDCodesSelector } from '../../../../redux/slices/system/getICDCodesSlice';
import {
  addVisitICDCodeRequest,
  addVisitICDCodeSelector,
  clearAddVisitICDCodeResponse,
} from '../../../../redux/slices/patient/addVisitICDCodeSlice';
import { CommonAlert } from '../../../atoms/Alert';

const PAGE_SIZE = 5;

const icdColumns = [
  {
    title: 'ICD Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'ICD Name',
    dataIndex: 'desc',
    key: 'desc',
  },
  {
    title: 'ICD Version',
    dataIndex: 'version',
    key: 'version',
  },
];

const icdData = [
  {
    icdCode: 'A41.9',
    icdName: 'Septicemia, unspec organism',
    icdVersion: 10,
  },
  {
    icdCode: 'M62.81',
    icdName: 'Muscle Weakness',
    icdVersion: 10,
  },
  {
    icdCode: 'N39.0',
    icdName: 'Urinary Tract Infect UTI',
    icdVersion: 10,
  },
];

export const ICDModal = ({
  icdModalOpen,
  setOpenICDModal,
  icdCodesDataSource,
  visitId,
  getICDCodeList,
  handleRemoveICDCodeDataSource,
  icdDataSource,
  deleteICD,
}: any) => {
  // console.log('=== icdCodesDataSource ==', icdCodesDataSource);

  const dispatch = useAppDispatch();
  const { addVisitICDCodeStatus, addVisitICDCodeError } = useAppSelector(addVisitICDCodeSelector);
  const { icdCodesStatus, icdCodesData } = useAppSelector(getICDCodesSelector);

  const [formData, setFormData] = useState<any>({});
  const [fetchDataLoading, setFetchDataLoading] = useState<boolean>(false);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false);

  useEffect(() => {
    setFetchDataLoading(true);
    dispatch(
      getICDCodesRequest({ code: formData?.code ?? '', desc: formData?.desc ?? '', page: currentPage, size: PAGE_SIZE })
    );
  }, []);

  useEffect(() => {
    if (addVisitICDCodeStatus === 'SUCCESS') {
      getICDCodeList(visitId);
      setAlertObj({
        color: 'success',
        message: 'Successfully Added!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    } else if (addVisitICDCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addVisitICDCodeError?.message,
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearAddVisitICDCodeResponse());
  }, [addVisitICDCodeStatus]);

  useEffect(() => {
    if (icdCodesStatus === 'SUCCESS') {
      setCurrentPage(icdCodesData?.currentPage);
      setTotalPages(icdCodesData?.totalPages);
      setTotalItems(icdCodesData?.totalItems);
      setFetchDataLoading(false);
    } else if (icdCodesStatus === 'FAILED') {
      setFetchDataLoading(false);
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [icdCodesStatus]);

  const handleFieldValueChange = (e: any) => {
    // setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));

    const { name, value } = e.target;

    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    if (
      (!updatedFormData?.code || updatedFormData?.code?.trim() === '') &&
      (!updatedFormData?.desc || updatedFormData?.desc?.trim() === '')
    ) {
      setIsSearchEnabled(false);
    } else {
      setIsSearchEnabled(true);
    }
  };

  const handleReset = () => {
    setFormData({});
    setIsSearchEnabled(false);
    dispatch(getICDCodesRequest({ code: '', desc: '', page: 0, size: PAGE_SIZE }));
  };

  const handleSubmit = () => {
    dispatch(getICDCodesRequest({ code: formData?.code ?? '', desc: formData?.desc ?? '', page: 0, size: PAGE_SIZE }));
  };

  const onSearchPageChange = (page: any) => {
    setCurrentPage(page - 1);
    dispatch(
      getICDCodesRequest({ code: formData?.code ?? '', desc: formData?.desc ?? '', page: page - 1, size: PAGE_SIZE })
    );
  };

  const handleAddRow = (event: any, rowIndex: any) => {
    event.preventDefault();
    const isDuplicate = icdDataSource.some((item: any) => item.code === icdCodesDataSource[rowIndex]?.code);

    if (isDuplicate) {
      setAlertObj({
        color: 'failure',
        message: 'Code already added!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    } else {
      dispatch(addVisitICDCodeRequest({ visitId: visitId, visitData: { code: icdCodesDataSource[rowIndex]?.code } }));
    }
  };

  const handleOnClose = () => {
    setOpenICDModal(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    handleRemoveICDCodeDataSource && handleRemoveICDCodeDataSource();
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleDeleteClick = (event: any, rowIndex: any) => {
    event.preventDefault();
    deleteICD(event, rowIndex);
  };

  return (
    <>
      <Modal show={icdModalOpen} size="7xl" onClose={handleOnClose}>
        <Modal.Header>Search ICD Code</Modal.Header>
        <Modal.Body className="grid md:grid-cols-2 gap-5">
          <div className="border px-2 pt-1">
            {visibleAlert && !alertObj?.error && (
              <CommonAlert
                color={alertObj?.color}
                message={alertObj?.message}
                onClose={handleAlertClose}
                alertClassName="w-1/2"
              />
            )}
            <div className="grid md:grid-cols-2 gap-5 mt-6">
              <InputField label="Code" name="code" onChange={handleFieldValueChange} value={formData?.code ?? ''} />
              <InputField
                onChange={handleFieldValueChange}
                name="desc"
                label="Description"
                value={formData?.desc ?? ''}
              />
            </div>
            <div className="grid md:grid-cols-2 gap-5 mt-6">
              <div className="col-span-1"></div>
              <div className="col-span-1 flex justify-end space-x-2">
                <CommonButton type="button" label={'Reset'} buttonType="secondary" onClick={handleReset} />
                <CommonButton
                  type="button"
                  label={'Search'}
                  buttonType="primary"
                  onClick={handleSubmit}
                  disabled={!isSearchEnabled}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-1 gap-5 mt-6 pb-1">
              {fetchDataLoading ? (
                <Spinner />
              ) : (
                <>
                  {icdCodesDataSource && icdCodesDataSource?.length > 0 && (
                    <>
                      <TableContent
                        enableActions={true}
                        columns={icdColumns}
                        dataSource={icdCodesDataSource}
                        enableAdd={true}
                        onHandleAdd={handleAddRow}
                      />
                      <Pagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        totalPages={totalPages}
                        onPageChange={onSearchPageChange}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="border px-2 pt-1 pb-1">
            <TableContent
              enableActions={true}
              columns={icdColumns}
              dataSource={icdDataSource}
              enableDelete={true}
              onDelete={handleDeleteClick}
            />
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <CommonButton type="button" label={'Close'} buttonType="secondary" onClick={() => setOpenICDModal(false)} />
          <CommonButton type="button" label={'Submit'} buttonType="primary" onClick={handleSubmit} />
        </Modal.Footer> */}
      </Modal>
    </>
  );
};
