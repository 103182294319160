import { TogglePropsInterface } from '../../interfaces/atomInterfaces';

export const Toggle: React.FC<TogglePropsInterface> = ({ label = 'Toggle', value }) => {
  return (
    <>
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" value={value} className="sr-only peer" />
        <div className="w-11 h-6 bg-gray2 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-primaryActive dark:peer-focus:ring-primaryDefault rounded-full peer dark:bg-gray7 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray3 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray6 peer-checked:bg-primaryDefault"></div>
        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</span>
      </label>
    </>
  );
};
