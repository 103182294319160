import { useEffect, useState } from 'react';
import { CommonButton } from '../../atoms/CommonButton';
import { FeeList } from './FeeList';
import { RightSheetForm } from './RightSheetForm';
import { FeeListForm } from './FeeListForm';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import {
  addFeeAmountRequest,
  addFeeAmountSelector,
  clearAddFeeAmountResponse,
} from '../../../redux/slices/codes/addFeeAmountSlice';
import moment from 'moment';
import { feeAmountValidation } from '../../../utils/codeValidation';
import { IoIosInformationCircleOutline } from 'react-icons/io';

export const CPTEditSheet = ({
  onSaveEdit,
  onChange,
  codeData,
  mode,
  selectedId,
  errorObject,
  modifiers,
  feeAmountList,
  fetchFeeAmount,
  currentPage,
  onPageChange,
  totalPages,
}: any) => {
  const dispatch = useAppDispatch();
  const { addFeeAmountData, addFeeAmountError, addFeeAmountStatus } = useAppSelector(addFeeAmountSelector);
  const [feeFormOpen, setOpenForm] = useState<boolean>(false);
  const [feeFormData, setFeeFormData] = useState<any>({});
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    if (addFeeAmountStatus === 'SUCCESS') {
      fetchFeeAmount && fetchFeeAmount(selectedId);
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setOpenForm(false);
        setFeeFormData({});
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (addFeeAmountStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addFeeAmountError?.message ?? 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearAddFeeAmountResponse());
  }, [addFeeAmountStatus]);

  const onAddFee = () => {
    let obj = {
      cptId: selectedId,
      state: feeFormData?.state,
      locality: feeFormData?.locality,
      type: feeFormData?.type,
      amount: feeFormData?.amount,
      effectiveFrom: feeFormData?.effectiveFrom,
      effectiveTo: feeFormData?.effectiveTo,
    };

    const validationResults = feeAmountValidation(obj);

    if (Object.keys(validationResults?.newErrors)?.length > 0) {
      setErrorObj(validationResults?.newErrors);
      const errorMessages = Object.values(validationResults?.newErrors);
      setVisibleAlert(true);
      setAlertObj({
        color: 'failure',
        message: (
          <div>
            {errorMessages.map((msg: any, index) => (
              <div key={index} className="flex items-center">
                <IoIosInformationCircleOutline />
                <span style={{ marginLeft: '8px' }}>{msg}</span>
              </div>
            ))}
          </div>
        ),
        error: false,
      });
    } else {
      setVisibleAlert(false);
      setAlertObj({
        color: '',
        message: '',
        error: false,
      });
      dispatch(addFeeAmountRequest(obj));
    }
  };

  const onOpenFee = () => {
    setFeeFormData({});
    setOpenForm(true);
  };

  const handleOnClose = () => {
    setFeeFormData({});
    setOpenForm(false);
  };

  const onChangeForm = (e: any) => {
    const { name, value } = e.target;
    setFeeFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const onChangeDate = (date: any, name: string) => {
    setFeeFormData((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <>
      <div
        style={{
          borderRadius: '5px',
          border: '1px solid',
          padding: '6px',
          background: 'rgb(13,200,222)',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <CommonButton type="button" label={'Update'} buttonType="secondary" onClick={onSaveEdit} />
        </div>
      </div>
      <RightSheetForm onChange={onChange} codeData={codeData} modifiers={modifiers} />
      <div
        style={{
          borderRadius: '5px',
          border: '1px solid',
          padding: '6px',
          background: 'rgb(13,200,222)',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <div style={{ marginLeft: '5px' }}>Fee Amount</div>
        <div>
          <CommonButton type="button" label={'Add New'} buttonType="secondary" onClick={onOpenFee} />
        </div>
      </div>
      <FeeList
        feeAmountList={feeAmountList}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      {feeFormOpen && (
        <FeeListForm
          feeFormOpen={feeFormOpen}
          handleOnClose={handleOnClose}
          onAddFee={onAddFee}
          onChange={onChangeForm}
          feeData={feeFormData}
          onChangeDate={onChangeDate}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          handleAlertClose={handleAlertClose}
        />
      )}
    </>
  );
};
