import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { addPatientAPIRequest } from '../../../api/patient/patient';
import { PatientInsuranceAddInterface, PatientInsuranceAddPropsInterface } from '../../../interfaces/patientInterface';
import { addPatientInsuranceAPIRequest } from '../../../api/patient/patientInsurance';

const initialState: PatientInsuranceAddPropsInterface = {
  addPatientInsuranceLoading: false,
  addPatientInsuranceData: {},
  addPatientInsuranceError: null,
  addPatientInsuranceStatus: 'IDLE',
};

export const addPatientInsuranceRequest: any = createAsyncThunk(
  'patient/insurance/add',
  async (patient: PatientInsuranceAddInterface, thunkAPI: any) => {
    try {
      const response: any = await addPatientInsuranceAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addPatientInsuranceSlice = createSlice({
  name: 'addPatientInsurance',
  initialState,
  reducers: {
    clearAddPatientInsuranceResponse: (state) => {
      state.addPatientInsuranceLoading = false;
      state.addPatientInsuranceStatus = 'IDLE';
      state.addPatientInsuranceData = {};
      state.addPatientInsuranceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPatientInsuranceRequest.pending, (state, action) => {
        state.addPatientInsuranceLoading = true;
        state.addPatientInsuranceStatus = 'PENDING';
      })
      .addCase(addPatientInsuranceRequest.fulfilled, (state, action) => {
        state.addPatientInsuranceData = action.payload;
        state.addPatientInsuranceStatus = 'SUCCESS';
        state.addPatientInsuranceLoading = false;
      })
      .addCase(addPatientInsuranceRequest.rejected, (state, action) => {
        state.addPatientInsuranceLoading = false;
        state.addPatientInsuranceError = action.payload;
        state.addPatientInsuranceStatus = 'FAILED';
      });
  },
});

export const { clearAddPatientInsuranceResponse } = addPatientInsuranceSlice.actions;

export const addPatientInsuranceSelector = (state: RootState) => state.AddPatientInsurance;

export default addPatientInsuranceSlice.reducer;
