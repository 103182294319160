import { Modal } from 'flowbite-react';
import { TextArea } from '../../atoms/TextArea';
import { TableContent } from '../../table/TableContent';
import { CommonButton } from '../../atoms/CommonButton';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
  },
];

export const NotesModal = ({
  visibleNotesModal,
  setVisibleNotesModal,
  noteHistoryDataSource,
  onChangeNotesFields,
  editNotesFormData,
  onSubmit,
}: any) => {
  return (
    <Modal show={visibleNotesModal} onClose={() => setVisibleNotesModal(false)}>
      <Modal.Header>View Notes</Modal.Header>
      <Modal.Body>
        <div className="grid md:grid-cols-1 gap-5 mt-2">
          <TextArea
            onChange={onChangeNotesFields}
            name="note"
            label="Note"
            defaultValue={editNotesFormData?.note ?? undefined}
            //   error={errorObject && errorObject?.patientVisitStatus}
            //   errorMessage={errorObject && errorObject?.patientVisitStatus}
          />
        </div>
        {<TableContent columns={columns} dataSource={noteHistoryDataSource} />}
      </Modal.Body>
      <Modal.Footer>
        <CommonButton
          type="button"
          label={'Close'}
          buttonType="secondary"
          onClick={() => setVisibleNotesModal(false)}
        />
        <CommonButton type="button" label={'Save'} buttonType="primary" onClick={onSubmit} />
      </Modal.Footer>
    </Modal>
  );
};
