import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  CPTCodeUpdateInterface,
  UpdateVisitCPTCodePropsInterface,
  UpdateVisitStatusPropsInterface,
  VisitStatusUpdateInterface,
} from '../../../interfaces/patientInterface';
import { updateVisitCPTCodeAPIRequest, updateVisitStatusAPIRequest } from '../../../api/patient/patientVisit';

const initialState: UpdateVisitStatusPropsInterface = {
  updateVisitStatusLoading: false,
  updateVisitStatusData: {},
  updateVisitStatusError: null,
  updateVisitStatus: 'IDLE',
};

export const updateVisitStatusRequest: any = createAsyncThunk(
  'update/visit/status',
  async (patient: VisitStatusUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updateVisitStatusAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateVisitStatusSlice = createSlice({
  name: 'editVisitStatus',
  initialState,
  reducers: {
    clearUpdateVisitStatusResponse: (state) => {
      state.updateVisitStatusLoading = false;
      state.updateVisitStatus = 'IDLE';
      state.updateVisitStatusData = {};
      state.updateVisitStatusError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateVisitStatusRequest.pending, (state, action) => {
        state.updateVisitStatusLoading = true;
        state.updateVisitStatus = 'PENDING';
      })
      .addCase(updateVisitStatusRequest.fulfilled, (state, action) => {
        state.updateVisitStatusData = action.payload;
        state.updateVisitStatus = 'SUCCESS';
        state.updateVisitStatusLoading = false;
      })
      .addCase(updateVisitStatusRequest.rejected, (state, action) => {
        state.updateVisitStatusLoading = false;
        state.updateVisitStatusError = action.payload;
        state.updateVisitStatus = 'FAILED';
      });
  },
});

export const { clearUpdateVisitStatusResponse } = updateVisitStatusSlice.actions;

export const updateVisitStatusSelector = (state: RootState) => state.UpdateVisitStatus;

export default updateVisitStatusSlice.reducer;
