import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { Modal } from 'flowbite-react';
import { Calendar } from '../../../atoms/Calendar';
import { TextArea } from '../../../atoms/TextArea';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';
import { Pagination } from '../../../pagination/Pagination';
import { Spinner } from '../../../atoms/Spinner';
import { CommonAlert } from '../../../atoms/Alert';
import { Checkbox } from '../../../atoms/Checkbox';
import {
  addProviderCarrierFollowUpRequest,
  addProviderCarrierFollowUpSelector,
  clearAddProviderCarrierFollowUp,
} from '../../../../redux/slices/provider/addProviderCarrierFollowUpSlice';
import moment from 'moment';

const columns = [
  {
    title: 'Date',
    dataIndex: 'followUpDate',
    key: 'followUpDate',
    render: (text: any) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
  },
];

const followUpData = [
  {
    followUpDate: '2024-07-18',
    notes: 'Admin: Test Note for: Zurski',
  },
];

export const ProviderCarriersFollowUp = ({
  addFollowUpModalVisible,
  setAddFollowUpVisible,
  selectedCarriersId,
  providerId,
  fetchProviderById,
}: any) => {
  const dispatch = useAppDispatch();

  const [addFollowUpFormData, setAddFollowUpFormData] = useState<any>({
    followUpDate: '',
    completed: false,
    note: '',
  });
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });

  const { addProviderCarrierFollowUpStatus } = useAppSelector(addProviderCarrierFollowUpSelector);

  useEffect(() => {
    if (addProviderCarrierFollowUpStatus === 'SUCCESS') {
      fetchProviderById(providerId, 'carrier');
      setAlertObj({
        color: 'success',
        message: 'Successfully added !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setAddFollowUpVisible(false);
      }, 3000);
      dispatch(clearAddProviderCarrierFollowUp());
    } else if (addProviderCarrierFollowUpStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddProviderCarrierFollowUp());
    }
  }, [addProviderCarrierFollowUpStatus]);

  const onChangeFollowUpDate = (date: Date | null) => {
    setAddFollowUpFormData((prev: any) => ({ ...prev, followUpDate: moment(date).format('YYYY-MM-DD') }));
  };

  const onChangeFollowFormData = (e: any) => {
    const { name, value, type, checked } = e.target;

    const convertedValue = type === 'checkbox' ? checked : value;

    setAddFollowUpFormData((prev: any) => ({
      ...prev,
      [name]: convertedValue,
    }));
  };

  const handleSubmit = () => {
    dispatch(
      addProviderCarrierFollowUpRequest({
        providerId: providerId,
        carriersId: selectedCarriersId,
        carrierFollowUpData: addFollowUpFormData,
      })
    );
  };

  const handleEditFollowUp = () => {};

  const onCloseModal = () => {
    setAddFollowUpVisible(false);
    setAddFollowUpFormData({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <Modal show={addFollowUpModalVisible} onClose={onCloseModal}>
      <Modal.Header>Add Follow Up</Modal.Header>
      <Modal.Body>
        {visibleAlert && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={() => setVisibleAlert(false)}
            alertClassName="w-5/12"
          />
        )}
        <div className="grid md:grid-cols-1 gap-6 mt-4">
          {/* <Calendar label="Follow up Date" name="followUpDate" onSelectedDateChanged={onChangeFollowUpDate} /> */}
          <DatePickerField
            label="Follow up Date"
            name="followUpDate"
            selectedDate={formatDatePickerDate(addFollowUpFormData?.followUpDate)}
            onChange={onChangeFollowUpDate}
          />
          <TextArea label="Note" name="note" onChange={onChangeFollowFormData} />
          <Checkbox label="Complete" name="completed" onChange={onChangeFollowFormData} />
        </div>
        <div className="grid md:grid-cols-2 gap-5 mt-8">
          <CommonButton type="button" label="Close" buttonType="secondary" onClick={onCloseModal} />
          <CommonButton type="button" label="Submit" buttonType="primary" onClick={handleSubmit} />
        </div>
        <div className="mt-8">
          <TableContent
            columns={columns}
            dataSource={followUpData}
            enableActions
            enableEdit
            onEdit={handleEditFollowUp}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
