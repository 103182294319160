import { FetchFacilitiesInterface } from '../../interfaces/facilitiesInterface';
import { AddFacilitiesInterface } from '../../interfaces/systemInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';

export const addFacilitiesAPIRequest = async (facilityData: AddFacilitiesInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/facility/`, 'post', {
    data: { ...facilityData },
  });
};

export const getFacilitiesAPIRequest = async (facilityData: FetchFacilitiesInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/facility/search?page=${facilityData?.page}&size=${facilityData?.size}`,
    'get',
    {
      data: {},
    }
  );
};
