import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  ProviderLicenseDeleteByIdInitialPropsInterface,
  ProviderLicenseGetByIdInterface,
} from "../../../interfaces/providersInterface";
import { deleteProviderLicenseByIdAPIRequest } from "../../../api/provider/providerLicense";
 

const initialState: ProviderLicenseDeleteByIdInitialPropsInterface = {
  deleteProviderLicenseByIdLoading: false,
  deleteProviderLicenseByIdData: {},
  deleteProviderLicenseByIdStatus: "IDLE",
  deleteProviderLicenseByIdError: null,
};

export const deleteProviderLicenseByIdRequest: any = createAsyncThunk(
  "providers/license/delete/id",
  async (searchParams: ProviderLicenseGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await deleteProviderLicenseByIdAPIRequest(
        searchParams
      );
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deleteProviderLicenseByIdSlice = createSlice({
  name: "deleteProviderLicenseById",
  initialState,
  reducers: {
    clearDeleteProviderLicenseByIdResponse: (state) => {
      state.deleteProviderLicenseByIdLoading = false;
      state.deleteProviderLicenseByIdData = {};
      state.deleteProviderLicenseByIdStatus = "IDLE";
      state.deleteProviderLicenseByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProviderLicenseByIdRequest.pending, (state, action) => {
        state.deleteProviderLicenseByIdLoading = true;
        state.deleteProviderLicenseByIdStatus = "PENDING";
      })
      .addCase(deleteProviderLicenseByIdRequest.fulfilled, (state, action) => {
        state.deleteProviderLicenseByIdLoading = false;
        state.deleteProviderLicenseByIdStatus = "SUCCESS";
        state.deleteProviderLicenseByIdData = action.payload;
      })
      .addCase(deleteProviderLicenseByIdRequest.rejected, (state, action) => {
        state.deleteProviderLicenseByIdLoading = false;
        state.deleteProviderLicenseByIdError = action.payload;
        state.deleteProviderLicenseByIdStatus = "FAILED";
      });
  },
});

export const { clearDeleteProviderLicenseByIdResponse } =
  deleteProviderLicenseByIdSlice.actions;

export const deleteProviderLicenseByIdSelector = (state: RootState) =>
  state.DeleteProviderLicenseById;

export default deleteProviderLicenseByIdSlice.reducer;
