import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchVisitCPTInterface, FetchVisitsCPTByIdInitialInterface } from '../../../interfaces/billingInterface';
import { getVisitCPTByIdAPIRequest } from '../../../api/billing/visits';

const initialState: FetchVisitsCPTByIdInitialInterface = {
  visitCPTLoading: false,
  visitCPTData: {},
  visitCPTError: null,
  visitCPTStatus: 'IDLE',
};

export const getVisitCPTByIdRequest: any = createAsyncThunk(
  'visits/cpt/get',
  async (visitData: FetchVisitCPTInterface, thunkAPI: any) => {
    try {
      const response: any = await getVisitCPTByIdAPIRequest(visitData);
      // console.log(response);
      return response;
    } catch (error: any) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getVisitCPTByIdSlice = createSlice({
  name: 'fetchVisitCPTById',
  initialState,
  reducers: {
    clearFetchVisitCPTByIdResponse: (state) => {
      state.visitCPTLoading = false;
      state.visitCPTStatus = 'IDLE';
      state.visitCPTData = {};
      state.visitCPTError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisitCPTByIdRequest.pending, (state, action) => {
        state.visitCPTLoading = true;
        state.visitCPTStatus = 'PENDING';
      })
      .addCase(getVisitCPTByIdRequest.fulfilled, (state, action) => {
        state.visitCPTLoading = false;
        state.visitCPTData = action.payload;
        state.visitCPTStatus = 'SUCCESS';
      })
      .addCase(getVisitCPTByIdRequest.rejected, (state, action) => {
        state.visitCPTLoading = false;
        state.visitCPTError = action.payload;
        state.visitCPTStatus = 'FAILED';
      });
  },
});

export const { clearFetchVisitCPTByIdResponse } = getVisitCPTByIdSlice.actions;

export const getVisitCPTByIdSelector = (state: RootState) => state.GetVisitCPTById;

export default getVisitCPTByIdSlice.reducer;
