import { useEffect, useState } from 'react';
import { CommonAlert } from '../../../atoms/Alert';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { RightSheet } from '../../../rightSheet/RightSheet';
import { ProviderAddressForm } from './ProviderAddressForm';
import {
  addProviderAddressRequest,
  addProviderAddressSelector,
  clearAddProviderAddressResponse,
} from '../../../../redux/slices/provider/addProviderAddressSlice';
import { useAppSelector } from '../../../../hooks/storeHooks/hooks';
import {
  deleteProviderAddressByIdRequest,
  deleteProviderAddressByIdSelector,
} from '../../../../redux/slices/provider/deleteProviderAddressSlice';
import {
  clearFetchProviderAddressByIdResponse,
  getProviderAddressByIdRequest,
  getProviderAddressByIdSelector,
} from '../../../../redux/slices/provider/getProviderAddressByIdSlice';
import {
  clearEditProviderAddressResponse,
  editProviderAddressRequest,
  editProviderAddressSelector,
} from '../../../../redux/slices/provider/editProviderAddressSlice';
import {
  updateProviderAddressPrimaryRequest,
  updateProviderAddressPrimarySelector,
  clearUpdateProviderAddressPrimary,
} from '../../../../redux/slices/provider/editProviderAddressPrimaryAddressSlice';
import { getProviderByIdSelector } from '../../../../redux/slices/provider/getProviderByIdSlice';
import { ProviderAddressInterface } from '../../../../interfaces/providersInterface';
import { useAppDispatch } from '../../../../redux/store';
import { createProviderAddressValidation } from '../../../../utils/providerValidation';
import { Spinner } from '../../../atoms/Spinner';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { FaStar } from 'react-icons/fa6';

const columns = (mode: any, handlePrimaryProviderAddress: any) => [
  {
    title: 'Primary',
    dataIndex: 'primary',
    key: 'primary',
    render: (data: any, row: any) => {
      return (
        <>
          {data ? (
            <FaStar
              color="#FFBF00"
              size={30}
              style={{ borderColor: 'black', cursor: mode === 'VIEW' ? 'default' : 'pointer' }}
              onClick={mode !== 'VIEW' ? () => handlePrimaryProviderAddress(row) : undefined}
            />
          ) : (
            <FaStar
              size={20}
              style={{ cursor: mode === 'VIEW' ? 'default' : 'pointer' }}
              onClick={mode !== 'VIEW' ? () => handlePrimaryProviderAddress(row) : undefined}
            />
          )}
        </>
      );
    },
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: (text: any, record: any) => {
      return (
        <span>
          {record?.address1 ?? ''}
          {record?.address2 ? `, ${record?.address2}` : ''}
        </span>
      );
    },
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
  },
];

export const ProviderAddress: React.FC<ProviderAddressInterface> = ({
  selectedId,
  mode,
  addDataLoading,
  fetchDataLoading,
  fetchProviderById,
}) => {
  const dispatch = useAppDispatch();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [providerAddressFormData, setProviderAddressFormData] = useState<any>({});
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [dataSource, setDataSource] = useState<any>([]);
  const [fetchLoading, setFetchLoading] = useState<any>([]);

  const [errorObj, setErrorObj] = useState<any>({});
  const [addressMode, setAddressMode] = useState<any>('CREATE');
  const [selectedAddressId, setSelectedAddressId] = useState<number>();
  const { fetchProviderByIdStatus, fetchProviderByIdData } = useAppSelector(getProviderByIdSelector);
  const { addProviderAddressStatus, addProviderAddressLoading } = useAppSelector(addProviderAddressSelector);

  const { deleteProviderAddressByIdLoading, deleteProviderAddressByIdStatus } = useAppSelector(
    deleteProviderAddressByIdSelector
  );

  const { fetchProviderAddressByIdData, fetchProviderAddressByIdStatus } =
    useAppSelector(getProviderAddressByIdSelector);

  const { editProviderAddressLoading, editProviderAddressStatus } = useAppSelector(editProviderAddressSelector);
  const { updateProviderAddressPrimaryStatus } = useAppSelector(updateProviderAddressPrimarySelector);

  useEffect(() => {
    setFetchLoading(fetchDataLoading);
  }, [fetchDataLoading]);

  useEffect(() => {
    if (fetchProviderByIdStatus === 'SUCCESS') {
      setDataSource(fetchProviderByIdData);
      setVisibleModal(false);
    }
  }, [fetchProviderByIdStatus, selectedId]);

  useEffect(() => {
    if (addProviderAddressStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'address');
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setProviderAddressFormData({});
        setErrorObj({});
        setAddressMode('CREATE');
      }, 3000);
      dispatch(clearAddProviderAddressResponse());
    } else if (addProviderAddressStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddProviderAddressResponse());
    }
  }, [addProviderAddressStatus]);

  useEffect(() => {
    if (deleteProviderAddressByIdStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'address');
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    } else if (deleteProviderAddressByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [deleteProviderAddressByIdStatus]);

  useEffect(() => {
    if (editProviderAddressStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'address');
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setErrorObj({});
        setProviderAddressFormData({});
        setAddressMode('CREATE');
      }, 3000);
      dispatch(clearEditProviderAddressResponse());
    } else if (editProviderAddressStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditProviderAddressResponse());
    }
  }, [editProviderAddressStatus]);

  useEffect(() => {
    if (updateProviderAddressPrimaryStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'address');
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearUpdateProviderAddressPrimary());
    } else if (updateProviderAddressPrimaryStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearUpdateProviderAddressPrimary());
    }
  }, [updateProviderAddressPrimaryStatus]);

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    setAddressMode('EDIT');
    setSelectedAddressId(dataSet?.id);
    let obj = {
      address1: dataSet?.address1,
      address2: dataSet?.address2,
      city: dataSet?.city,
      country: dataSet?.country,
      id: dataSet?.id,
      primary: dataSet?.primary,
      state: dataSet?.state,
      zipCode: dataSet?.zip,
      extn: dataSet?.extn,
      type: dataSet?.type,
    };
    setProviderAddressFormData(obj);
    setVisibleModal(true);
  };

  const getAddressDetailsGetById = (id: number) => {
    dispatch(getProviderAddressByIdRequest({ providerId: selectedId, addressId: id }));
  };

  const handleAddressFormChange = (e: any) => {
    const { name, type, value, checked } = e.target;

    const convertedValue = type === 'checkbox' ? checked : value;
    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }
    setProviderAddressFormData((prev: any) => ({
      ...prev,
      [name]: convertedValue,
    }));
  };

  const handlePrimaryProviderAddress = (row: any) => {
    const obj = {
      providerId: selectedId,
      addressId: row?.id,
      addressData: { primary: row?.primary ? false : true },
    };

    dispatch(updateProviderAddressPrimaryRequest(obj));
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const onDelete = (event: any, rowIndex: any) => {
    const dataSet = dataSource[rowIndex];
    setSelectedAddressId(dataSet?.id);
    dispatch(
      deleteProviderAddressByIdRequest({
        providerId: selectedId,
        addressId: dataSet?.id,
      })
    );
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleAddNewModal = () => {
    setProviderAddressFormData({ primary: false });
    setAddressMode('CREATE');
    setVisibleModal(true);
    setErrorObj({});
  };

  const onCloseModal = () => {
    setProviderAddressFormData({});
    setAddressMode('CREATE');
    setVisibleModal(false);
    setErrorObj({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const onSubmit = () => {
    if (addressMode === 'CREATE') {
      let obj = {
        providerId: selectedId,
        addressData: providerAddressFormData,
      };

      const validationDetails = createProviderAddressValidation(obj?.addressData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);

        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(addProviderAddressRequest(obj));
      }
    } else if (addressMode === 'EDIT') {
      if (providerAddressFormData?.zipCode) {
        providerAddressFormData['zip'] = providerAddressFormData?.zipCode;
      }
      let obj = {
        providerId: selectedId,
        addressData: providerAddressFormData,
        addressId: selectedAddressId,
      };

      const validationDetails = createProviderAddressValidation(obj?.addressData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);

        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(editProviderAddressRequest(obj));
      }
    }
  };

  //console.log('fetchLoading', fetchLoading);
  return (
    <>
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className="w-64 ml-auto">
        {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            onClick={handleAddNewModal}
          />
        )}
      </div>

      {fetchLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <TableContent
              enableActions={mode === 'VIEW' ? false : true}
              columns={columns(mode, handlePrimaryProviderAddress)}
              dataSource={dataSource}
              enableDelete={true}
              enableEdit={true}
              enableView={false}
              onDelete={onDelete}
              onEdit={onEdit}
              onView={onView}
            />
          ) : (
            <EmptyContent onClick={handleAddNewModal} enableCreateButton={mode === 'VIEW' ? false : true} mode={mode} />
          )}
        </>
      )}

      {visibleModal && (
        <RightSheet
          title="Add / Edit Address"
          submitButtonTitle="Submit"
          cancelButtonTitle="Cancel"
          children={
            <ProviderAddressForm
              addressFormEditData={providerAddressFormData}
              onChange={handleAddressFormChange}
              addressMode={addressMode}
              errorObject={errorObj}
            />
          }
          onClose={onCloseModal}
          onSubmit={onSubmit}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
        />
      )}
    </>
  );
};
