import {
  FetchBatchPaymentInterface,
  AddBatchPaymentInterface,
  UpdateBatchPaymentInterface,
  FetchBatchPaymentDetailsByIdInterface,
} from '../../interfaces/paymentInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getBatchPaymentsAPIRequest = async (searchParams: FetchBatchPaymentInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/batch-payment/${searchParams?.section}?page=${searchParams?.page}&size=${searchParams?.size}`,
    'get',
    {
      data: {},
    }
  );
};

export const addBatchPaymentAPIRequest = async (payment: AddBatchPaymentInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/batch-payment/`, 'post', {
    data: { ...payment },
  });
};

export const updatebatchPaymentAPIRequest = async (payment: UpdateBatchPaymentInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/batch-payment/${payment?.paymentId}`, 'put', {
    data: { ...payment?.paymentData },
  });
};

export const getBatchPaymentDetailsByIdAPIRequest = async (searchParams: FetchBatchPaymentDetailsByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/batch-payment/${searchParams?.paymentId}`,
    'get',
    {
      data: {},
    }
  );
};
