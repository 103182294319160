import {
  validNPILength,
  validTaxIdLength,
  validZipCodeLength,
  validateEmail,
  validatePhoneNumber,
} from './commonFunctions';

export const createFacilityValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.name) {
    newErrors.name = 'Name is required';
    isValid = false;
  }

  if (!formData?.address) {
    newErrors.address = 'Address is required';
    isValid = false;
  }

  // if (!formData?.city) {
  //   newErrors.city = 'City is required';
  //   isValid = false;
  // }

  if (!formData?.state) {
    newErrors.state = 'State is required';
    isValid = false;
  }

  if (!formData?.zipcode || formData?.zipcode.toString().length !== validZipCodeLength) {
    newErrors.zipcode = 'Valid zip code required';
    isValid = false;
  }

  if (!formData?.npi || formData?.npi.toString().length !== validNPILength) {
    newErrors.npi = 'Valid NPI required';
    isValid = false;
  }

  // if (!formData?.tin || formData?.tin.toString().length !== validTaxIdLength) {
  //   newErrors.tin = 'Enter a valid TIN';
  //   isValid = false;
  // }

  if (formData?.phone) {
    if (!validatePhoneNumber(formData?.phone)) {
      newErrors.phone = 'Enter a valid phone number';
      isValid = false;
    }
  }

  if (formData?.fax) {
    if (!validatePhoneNumber(formData?.fax)) {
      newErrors.fax = 'Enter a valid fax number';
      isValid = false;
    }
  }

  if (formData.email) {
    if (!validateEmail(formData?.email)) {
      newErrors.email = 'Enter a valid Email';
      isValid = false;
    }
  }

  return { newErrors, isValid };
};
