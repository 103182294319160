import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  AddClaimsApplyNoteInitialPropsInterface,
  AddClaimApplyNotePropsInterface,
} from '../../../interfaces/patientInterface';
import { addClaimApplyNoteAPIRequest } from '../../../api/patient/patientClaim';

const initialState: AddClaimsApplyNoteInitialPropsInterface = {
  addClaimsApplyNoteLoading: false,
  addClaimsApplyNoteData: {},
  addClaimsApplyNoteError: null,
  addClaimsApplyNoteStatus: 'IDLE',
};

export const addClaimApplyNoteRequest: any = createAsyncThunk(
  'add/claim/applynote',
  async (patient: AddClaimApplyNotePropsInterface, thunkAPI: any) => {
    try {
      const response: any = await addClaimApplyNoteAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addClaimApplyNoteSlice = createSlice({
  name: 'addClaimApplyNote',
  initialState,
  reducers: {
    clearAddClaimApplyNoteResponse: (state) => {
      state.addClaimsApplyNoteLoading = false;
      state.addClaimsApplyNoteStatus = 'IDLE';
      state.addClaimsApplyNoteData = {};
      state.addClaimsApplyNoteError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addClaimApplyNoteRequest.pending, (state, action) => {
        state.addClaimsApplyNoteLoading = true;
        state.addClaimsApplyNoteStatus = 'PENDING';
      })
      .addCase(addClaimApplyNoteRequest.fulfilled, (state, action) => {
        state.addClaimsApplyNoteData = action.payload;
        state.addClaimsApplyNoteStatus = 'SUCCESS';
        state.addClaimsApplyNoteLoading = false;
      })
      .addCase(addClaimApplyNoteRequest.rejected, (state, action) => {
        state.addClaimsApplyNoteLoading = false;
        state.addClaimsApplyNoteError = action.payload;
        state.addClaimsApplyNoteStatus = 'FAILED';
      });
  },
});

export const { clearAddClaimApplyNoteResponse } = addClaimApplyNoteSlice.actions;

export const addClaimApplyNoteSelector = (state: RootState) => state.AddClaimApplyNote;

export default addClaimApplyNoteSlice.reducer;
