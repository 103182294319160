import { Button, Modal } from 'flowbite-react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import { useEffect, useState } from 'react';
import {
  clearFetchCPTCodesResponse,
  getCPTCodesRequest,
  getCPTCodesSelector,
} from '../../../redux/slices/system/getCPTCodesSlice';
import { CommonAlert } from '../../atoms/Alert';
import { InputField } from '../../atoms/InputField';
import { CommonButton } from '../../atoms/CommonButton';
import { TableContent } from '../../table/TableContent';

const PAGE_SIZE = 10;

const cptColumns = [
  {
    title: 'CPT Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'CPT Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'CPT Desc',
    dataIndex: 'desc',
    key: 'desc',
  },
];

export const ClaimCPTSearch = ({ openClaimCPTSearch, setOpenClaimCPTSearch, handleAddedCPTRow }: any) => {
  const dispatch = useAppDispatch();
  const { cptCodesData, cptCodesStatus, cptCodesError } = useAppSelector(getCPTCodesSelector);

  const [formData, setFormData] = useState<any>({});
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [cptCodesDataSource, setCPTCodesDataSource] = useState<any>([]);

  useEffect(() => {
    getCPTCodes();
  }, []);

  useEffect(() => {
    if (cptCodesStatus === 'SUCCESS') {
      setCPTCodesDataSource(cptCodesData?.items);
    } else if (cptCodesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong in CPT Codes Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchCPTCodesResponse());
  }, [cptCodesStatus]);

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleFieldValueChange = (e: any) => {
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const getCPTCodes = async (code = '', desc = '') => {
    dispatch(getCPTCodesRequest({ code, desc, page: currentPage, size: PAGE_SIZE }));
  };

  const handleSubmit = () => {
    getCPTCodes(formData?.code, formData?.desc);
  };

  const handleAddRow = (event: any, rowIndex: any) => {
    event.preventDefault();
    handleAddedCPTRow(cptCodesDataSource[rowIndex]);
    setOpenClaimCPTSearch(false);
  };

  const handleRemoveCPTCodeDataSource = () => {
    setCPTCodesDataSource([]);
  };

  return (
    <Modal show={openClaimCPTSearch} onClose={() => setOpenClaimCPTSearch(false)}>
      <Modal.Header>Search ICD Code</Modal.Header>
      <Modal.Body>
        {visibleAlert && !alertObj?.error && (
          <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
        )}
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <InputField label="Code" name="code" onChange={handleFieldValueChange} value={formData?.code ?? ''} />
          <InputField onChange={handleFieldValueChange} name="desc" label="Description" value={formData?.desc ?? ''} />
        </div>
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <CommonButton
            type="button"
            label={'Reset'}
            buttonType="secondary"
            onClick={() => {
              setFormData({});
              handleRemoveCPTCodeDataSource && handleRemoveCPTCodeDataSource();
            }}
          />
          <CommonButton type="button" label={'Search'} buttonType="primary" onClick={handleSubmit} />
        </div>
        <TableContent
          enableActions={true}
          columns={cptColumns}
          dataSource={cptCodesDataSource}
          enableAdd={true}
          onHandleAdd={handleAddRow}
        />
      </Modal.Body>
    </Modal>
  );
};
