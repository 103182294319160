import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { FetchFacilitiesInitialInterface } from "../../../interfaces/facilitiesInterface";
import { getFacilitiesAPIRequest } from "../../../api/facility/facility";
import { FetchFacilitiesInterface } from "../../../interfaces/providersInterface";

const initialState: FetchFacilitiesInitialInterface = {
  facilityLoading: false,
  facilityData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  facilityError: null,
  facilityStatus: "IDLE",
};

export const getFacilitySearchRequest: any = createAsyncThunk(
  "provider/get/facilities/id",
  async (facilityData: FetchFacilitiesInterface, thunkAPI: any) => {
    try {
      const response: any = await getFacilitiesAPIRequest(facilityData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getFacilitiesSlice = createSlice({
  name: "fetchSearchFacilities",
  initialState,
  reducers: {
    clearFetchFacilitiesResponse: (state) => {
      state.facilityLoading = false;
      state.facilityStatus = "IDLE";
      state.facilityData = {
        currentPage: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
      };
      state.facilityError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFacilitySearchRequest.pending, (state, action) => {
        state.facilityLoading = true;
        state.facilityStatus = "PENDING";
      })
      .addCase(getFacilitySearchRequest.fulfilled, (state, action) => {
        state.facilityLoading = false;
        state.facilityData = action.payload;
        state.facilityStatus = "SUCCESS";
      })
      .addCase(getFacilitySearchRequest.rejected, (state, action) => {
        state.facilityLoading = false;
        state.facilityError = action.payload;
        state.facilityStatus = "FAILED";
      });
  },
});

export const { clearFetchFacilitiesResponse } = getFacilitiesSlice.actions;

export const getFacilitiesSelector = (state: RootState) =>
  state.GetSearchFacilites;

export default getFacilitiesSlice.reducer;
