import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { CPTCodeUpdateInterface, UpdateVisitCPTCodePropsInterface } from '../../../interfaces/patientInterface';
import { updateVisitCPTCodeAPIRequest } from '../../../api/patient/patientVisit';

const initialState: UpdateVisitCPTCodePropsInterface = {
  updateVisitCPTCodeLoading: false,
  updateVisitCPTCodeData: {},
  updateVisitCPTCodeError: null,
  updateVisitCPTCodeStatus: 'IDLE',
};

export const updateVisitCPTCodeRequest: any = createAsyncThunk(
  'update/visit/cpt',
  async (patient: CPTCodeUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updateVisitCPTCodeAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateVisitCPTCodeSlice = createSlice({
  name: 'editVisitCPTCode',
  initialState,
  reducers: {
    clearUpdateVisitCPTCodeResponse: (state) => {
      state.updateVisitCPTCodeLoading = false;
      state.updateVisitCPTCodeStatus = 'IDLE';
      state.updateVisitCPTCodeData = {};
      state.updateVisitCPTCodeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateVisitCPTCodeRequest.pending, (state, action) => {
        state.updateVisitCPTCodeLoading = true;
        state.updateVisitCPTCodeStatus = 'PENDING';
      })
      .addCase(updateVisitCPTCodeRequest.fulfilled, (state, action) => {
        state.updateVisitCPTCodeData = action.payload;
        state.updateVisitCPTCodeStatus = 'SUCCESS';
        state.updateVisitCPTCodeLoading = false;
      })
      .addCase(updateVisitCPTCodeRequest.rejected, (state, action) => {
        state.updateVisitCPTCodeLoading = false;
        state.updateVisitCPTCodeError = action.payload;
        state.updateVisitCPTCodeStatus = 'FAILED';
      });
  },
});

export const { clearUpdateVisitCPTCodeResponse } = updateVisitCPTCodeSlice.actions;

export const updateVisitCPTCodeSelector = (state: RootState) => state.UpdateVisitCPTCode;

export default updateVisitCPTCodeSlice.reducer;
