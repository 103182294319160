import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { AddBatchPaymentInterface, AddBatchPaymentPropsInterface } from '../../../../interfaces/paymentInterfaces';
import { addBatchPaymentAPIRequest } from '../../../../api/payments/batchPayments';

const initialState: AddBatchPaymentPropsInterface = {
  addBatchPaymentLoading: false,
  addBatchPaymentData: {},
  addBatchPaymentError: null,
  addBatchPaymentStatus: 'IDLE',
};

export const addBatchPaymentRequest: any = createAsyncThunk(
  'batch-payment/add',
  async (payment: AddBatchPaymentInterface, thunkAPI: any) => {
    try {
      const response: any = await addBatchPaymentAPIRequest(payment);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addBatchPaymentSlice = createSlice({
  name: 'addBatchPayment',
  initialState,
  reducers: {
    clearAddBatchPaymentResponse: (state) => {
      state.addBatchPaymentLoading = false;
      state.addBatchPaymentStatus = 'IDLE';
      state.addBatchPaymentData = {};
      state.addBatchPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBatchPaymentRequest.pending, (state, action) => {
        state.addBatchPaymentLoading = true;
        state.addBatchPaymentStatus = 'PENDING';
      })
      .addCase(addBatchPaymentRequest.fulfilled, (state, action) => {
        state.addBatchPaymentData = action.payload;
        state.addBatchPaymentStatus = 'SUCCESS';
        state.addBatchPaymentLoading = false;
      })
      .addCase(addBatchPaymentRequest.rejected, (state, action) => {
        state.addBatchPaymentLoading = false;
        state.addBatchPaymentError = action.payload;
        state.addBatchPaymentStatus = 'FAILED';
      });
  },
});

export const { clearAddBatchPaymentResponse } = addBatchPaymentSlice.actions;

export const addBatchPaymentSelector = (state: RootState) => state.AddBatchPayment;

export default addBatchPaymentSlice.reducer;
