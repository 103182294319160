import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { CPTCodesEditPropsInterface } from '../../../interfaces/codesInterfaces';
import { editCPTCodeAPIRequest } from '../../../api/system/code';
import { EditCPTCodeInterface } from '../../../interfaces/systemInterfaces';

const initialState: CPTCodesEditPropsInterface = {
  editCPTCodeLoading: false,
  editCPTCodeData: {},
  editCPTCodeStatus: 'IDLE',
  editCPTCodeError: null,
};

export const editCPTCodeRequest: any = createAsyncThunk(
  'codes/cpt/edit',
  async (code: EditCPTCodeInterface, thunkAPI: any) => {
    try {
      const response: any = await editCPTCodeAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editCPTCodeSlice = createSlice({
  name: 'editCPTCode',
  initialState,
  reducers: {
    clearEditCPTCodeResponse: (state) => {
      state.editCPTCodeLoading = false;
      state.editCPTCodeStatus = 'IDLE';
      state.editCPTCodeData = {};
      state.editCPTCodeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editCPTCodeRequest.pending, (state, action) => {
        state.editCPTCodeLoading = true;
        state.editCPTCodeStatus = 'PENDING';
      })
      .addCase(editCPTCodeRequest.fulfilled, (state, action) => {
        state.editCPTCodeLoading = false;
        state.editCPTCodeStatus = 'SUCCESS';
        state.editCPTCodeData = action.payload;
      })
      .addCase(editCPTCodeRequest.rejected, (state, action) => {
        state.editCPTCodeLoading = false;
        state.editCPTCodeStatus = 'FAILED';
        state.editCPTCodeError = action.payload;
      });
  },
});

export const { clearEditCPTCodeResponse } = editCPTCodeSlice.actions;

export const editCPTCodeSelector = (state: RootState) => state.EditCPTCode;

export default editCPTCodeSlice.reducer;
