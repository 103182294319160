import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  AddVisitApplyNoteInitialPropsInterface,
  AddVisitApplyNotePropsInterface,
} from '../../../interfaces/patientInterface';
import { updateVisitApplyNoteHistoryAPIRequest } from '../../../api/patient/patientVisit';

const initialState: AddVisitApplyNoteInitialPropsInterface = {
  addVisitApplyNoteLoading: false,
  addVisitApplyNoteData: {},
  addVisitApplyNoteError: null,
  addVisitApplyNoteStatus: 'IDLE',
};

export const addVisitApplyNoteRequest: any = createAsyncThunk(
  'add/visit/applynote',
  async (patient: AddVisitApplyNotePropsInterface, thunkAPI: any) => {
    try {
      const response: any = await updateVisitApplyNoteHistoryAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addVisitApplyNoteSlice = createSlice({
  name: 'addVisitApplyNote',
  initialState,
  reducers: {
    clearAddVisitApplyNoteResponse: (state) => {
      state.addVisitApplyNoteLoading = false;
      state.addVisitApplyNoteStatus = 'IDLE';
      state.addVisitApplyNoteData = {};
      state.addVisitApplyNoteError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addVisitApplyNoteRequest.pending, (state, action) => {
        state.addVisitApplyNoteLoading = true;
        state.addVisitApplyNoteStatus = 'PENDING';
      })
      .addCase(addVisitApplyNoteRequest.fulfilled, (state, action) => {
        state.addVisitApplyNoteData = action.payload;
        state.addVisitApplyNoteStatus = 'SUCCESS';
        state.addVisitApplyNoteLoading = false;
      })
      .addCase(addVisitApplyNoteRequest.rejected, (state, action) => {
        state.addVisitApplyNoteLoading = false;
        state.addVisitApplyNoteError = action.payload;
        state.addVisitApplyNoteStatus = 'FAILED';
      });
  },
});

export const { clearAddVisitApplyNoteResponse } = addVisitApplyNoteSlice.actions;

export const addVisitApplyNoteSelector = (state: RootState) => state.AddVisitApplyNote;

export default addVisitApplyNoteSlice.reducer;
