import { SelectInputPropsInterface } from '../../interfaces/atomInterfaces';

export const SelectInput: React.FC<SelectInputPropsInterface> = ({
  label,
  options,
  enableDefaultPlaceholder = true,
  defaultPlaceholder = 'Please Select',
  name,
  onChange,
  value,
  defaultValue,
  disabled,
  error = false,
  errorMessage,
  required = false,
}) => {
  // console.log('=== options ===', options);

  return (
    <>
      <div>
        <label htmlFor="options" className="block mb-2 text-sm font-normal text-gray-900 dark:text-white">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
        <select
          id="options"
          className="
        bg-gray1 
        border 
        border-gray2
        text-gray9
        text-sm 
        rounded-lg 
        focus:ring-1
        focus:ring-primaryDefault 
        focus:border-primaryDefault 
        p-2.5 
        w-full
        outline-0
        dark:bg-gray9
        dark:border-gray8
        dark:placeholder-gray4
        dark:text-white 
        dark:focus:ring-primaryDefault 
        dark:focus:border-primaryDefault"
          value={value}
          name={name}
          onChange={onChange}
          defaultValue={defaultValue}
          disabled={disabled}
        >
          {enableDefaultPlaceholder && (
            <option selected value="">
              {defaultPlaceholder}
            </option>
          )}
          {options &&
            options?.map((item: any, key: any) => {
              return (
                <option key={key} value={item?.id}>
                  {item.name}
                </option>
              );
            })}
          {/* <option value="1">Option 1</option>
          <option value="2">Option 2</option>
          <option value="3">Option 3</option>
          <option value="4">Option 4</option> */}
        </select>
        {/* {error && errorMessage ? (
          <label htmlFor="input_field" className="block mt-2 mb-2 text-sm font-normal text-red-900 dark:text-white">
            {errorMessage}
          </label>
        ) : (
          <></>
        )} */}
      </div>
    </>
  );
};
