import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { addPatientAPIRequest } from '../../../api/patient/patient';
import {
  PatientAddressEditPropsInterface,
  PatientAddressUpdateInterface,
  PatientInsuranceEditPropsInterface,
  PatientInsuranceUpdateInterface,
} from '../../../interfaces/patientInterface';
import { updatePAtientAddressAPIRequest } from '../../../api/patient/patientAddress';
import { updatePAtientInsuranceAPIRequest } from '../../../api/patient/patientInsurance';

const initialState: PatientInsuranceEditPropsInterface = {
  editPatientInsuranceLoading: false,
  editPatientInsuranceData: {},
  editPatientInsuranceError: null,
  editPatientInsuranceStatus: 'IDLE',
};

export const editPatientInsuranceRequest: any = createAsyncThunk(
  'patient/insurance/edit',
  async (patient: PatientInsuranceUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updatePAtientInsuranceAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editPatientInsuranceSlice = createSlice({
  name: 'editPatientInsurance',
  initialState,
  reducers: {
    clearEditPatientInsuranceResponse: (state) => {
      state.editPatientInsuranceLoading = false;
      state.editPatientInsuranceStatus = 'IDLE';
      state.editPatientInsuranceData = {};
      state.editPatientInsuranceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editPatientInsuranceRequest.pending, (state, action) => {
        state.editPatientInsuranceLoading = true;
        state.editPatientInsuranceStatus = 'PENDING';
      })
      .addCase(editPatientInsuranceRequest.fulfilled, (state, action) => {
        state.editPatientInsuranceData = action.payload;
        state.editPatientInsuranceStatus = 'SUCCESS';
        state.editPatientInsuranceLoading = false;
      })
      .addCase(editPatientInsuranceRequest.rejected, (state, action) => {
        state.editPatientInsuranceLoading = false;
        state.editPatientInsuranceError = action.payload;
        state.editPatientInsuranceStatus = 'FAILED';
      });
  },
});

export const { clearEditPatientInsuranceResponse } = editPatientInsuranceSlice.actions;

export const editPatientInsuranceSelector = (state: RootState) => state.EditPatientInsurance;

export default editPatientInsuranceSlice.reducer;
