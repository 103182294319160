import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ProviderFetchInitialInterface } from '../../../interfaces/systemInterfaces';
import { getProvidersAPIRequest } from '../../../api/system/provider';

const initialState: ProviderFetchInitialInterface = {
  systemProvidersLoading: false,
  systemProvidersProviderData: [],
  systemProvidersError: null,
  systemProvidersStatus: 'IDLE',
};

export const getSystemProvidersRequest: any = createAsyncThunk('system/providers/get', async (thunkAPI: any) => {
  try {
    const response: any = await getProvidersAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getSystemProvidersSlice = createSlice({
  name: 'fetchSystemProviders',
  initialState,
  reducers: {
    clearFetchProvidersResponse: (state) => {
      state.systemProvidersLoading = false;
      state.systemProvidersStatus = 'IDLE';
      state.systemProvidersProviderData = [];
      state.systemProvidersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemProvidersRequest.pending, (state, action) => {
        state.systemProvidersLoading = true;
        state.systemProvidersStatus = 'PENDING';
      })
      .addCase(getSystemProvidersRequest.fulfilled, (state, action) => {
        state.systemProvidersLoading = false;
        state.systemProvidersProviderData = action.payload;
        state.systemProvidersStatus = 'SUCCESS';
      })
      .addCase(getSystemProvidersRequest.rejected, (state, action) => {
        state.systemProvidersLoading = false;
        state.systemProvidersError = action.payload;
        state.systemProvidersStatus = 'FAILED';
      });
  },
});

export const { clearFetchProvidersResponse } = getSystemProvidersSlice.actions;

export const getSystemProvidersSelector = (state: RootState) => state.FetchSystemProviders;

export default getSystemProvidersSlice.reducer;
