import { Button, Modal } from 'flowbite-react';
import { CommonAlert } from '../../atoms/Alert';
import { useState } from 'react';
import { SelectInput } from '../../atoms/SelectInput';
import { TextArea } from '../../atoms/TextArea';
import { CommonButton } from '../../atoms/CommonButton';

const selectOptions = [
  { id: 1, name: 'Patient' },
  { id: 2, name: 'Claim' },
  { id: 3, name: 'Visit' },
];

export const NotesModal = ({ openNote, setOpenNote }: any) => {
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });
  const [formData, setformData] = useState<any>({});

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const onChangeFields = (e: any) => {
    setformData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {};

  return (
    <Modal show={openNote} onClose={() => setOpenNote(false)}>
      <Modal.Header>Notes</Modal.Header>
      <Modal.Body>
        {visibleAlert && !alertObj?.error && (
          <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
        )}
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <SelectInput
            // label="Insurance Name"
            options={selectOptions}
            enableDefaultPlaceholder={true}
            name="type"
            onChange={onChangeFields}
          />
        </div>
        <div className="grid md:grid-cols-1 gap-5 mt-6">
          <TextArea
            onChange={onChangeFields}
            name="note"
            label="Notes"
            // defaultValue={insuranceFormEditData?.note ?? undefined}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CommonButton type="button" label={'No'} buttonType="secondary" onClick={() => setOpenNote(false)} />
        <CommonButton type="button" label={'Save'} buttonType="primary" onClick={handleSubmit} />
      </Modal.Footer>
    </Modal>
  );
};
