import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FeeAmountAddPropsInterface } from '../../../interfaces/codesInterfaces';
import { AddFeeAmountInterface } from '../../../interfaces/systemInterfaces';
import { addFeeAmountAPIRequest } from '../../../api/system/code';

const initialState: FeeAmountAddPropsInterface = {
  addFeeAmountLoading: false,
  addFeeAmountData: {},
  addFeeAmountStatus: 'IDLE',
  addFeeAmountError: null,
};

export const addFeeAmountRequest: any = createAsyncThunk(
  'codes/cpt/fee/add',
  async (code: AddFeeAmountInterface, thunkAPI: any) => {
    try {
      const response: any = await addFeeAmountAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addFeeAmountSlice = createSlice({
  name: 'addFeeAmount',
  initialState,
  reducers: {
    clearAddFeeAmountResponse: (state) => {
      state.addFeeAmountLoading = false;
      state.addFeeAmountStatus = 'IDLE';
      state.addFeeAmountData = {};
      state.addFeeAmountError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFeeAmountRequest.pending, (state, action) => {
        state.addFeeAmountLoading = true;
        state.addFeeAmountStatus = 'PENDING';
      })
      .addCase(addFeeAmountRequest.fulfilled, (state, action) => {
        state.addFeeAmountLoading = false;
        state.addFeeAmountStatus = 'SUCCESS';
        state.addFeeAmountData = action.payload;
      })
      .addCase(addFeeAmountRequest.rejected, (state, action) => {
        state.addFeeAmountLoading = false;
        state.addFeeAmountStatus = 'FAILED';
        state.addFeeAmountError = action.payload;
      });
  },
});

export const { clearAddFeeAmountResponse } = addFeeAmountSlice.actions;

export const addFeeAmountSelector = (state: RootState) => state.AddFeeAmountReducer;

export default addFeeAmountSlice.reducer;
