import { useEffect, useState } from 'react';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { RightSheet } from '../../../components/rightSheet/RightSheet';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
// import {
//   addICDCodeRequest,
//   addICDCodeSelectore,
//   clearAddCodeResponse,
// } from "../../redux/slices/code/addICDCodeSlice";
import {
  getICDCodesRequest,
  getICDCodesSelector,
  clearFetchICDCodesResponse,
} from '../../../redux/slices/system/getICDCodesSlice';
// import {
//   getICDCodeSelector,
//   getICDCodesRequest,
// } from "../../redux/slices/code/getICDCodeSlice";
import { CommonAlert } from '../../../components/atoms/Alert';
import { RightSheetLarge } from '../../../components/rightSheet/RightSheetLarge';
import { AddressList } from '../../../components/account/users/AddressList';
// import {
//   editICDCodeSelector,
//   updateICDCodeRequest,
// } from "../../redux/slices/code/updateICDCodeSlice";
// import {
//   getICDCodeByIdRequest,
//   getICDCodeByIdSelector,
// } from "../../redux/slices/code/getICDCodeByIdSlice";
import { Spinner } from '../../../components/atoms/Spinner';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { userValidation } from '../../../utils/userValidation';
import { ICDRightSheetForm } from '../../../components/account/codes/ICDRightSheetForm';
import {
  addICDCodeRequest,
  addICDCodeSelector,
  clearAddICDCodeResponse,
} from '../../../redux/slices/codes/addICDCodeSlice';
import { icdValidation } from '../../../utils/codeValidation';
import { IoIosInformationCircleOutline } from 'react-icons/io';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Description',
    dataIndex: 'desc',
    key: 'desc',
  },
  {
    title: 'Version',
    dataIndex: 'version',
    key: 'version',
  },
];

const dataSourceDummy = [
  {
    id: '1',
    icdCode: '99304',
    description: 'Skilled Nursing Facility - Initial Visit - Low Complexity',
    descriptionLong: '31',
    version: '10',
  },
];

export const versions = [{ id: '10', name: '10' }];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'icdCodes', label: 'Diagnosis Code', status: 'active', link: 'icd-codes' },
];

export const ICDCodesPage = () => {
  const dispatch = useAppDispatch();
  const { icdCodesData, icdCodesStatus } = useAppSelector(getICDCodesSelector);
  const { addICDCodeStatus, addICDCodeError, addICDCodeData } = useAppSelector(addICDCodeSelector);
  const [visibleModal, setVisibleModal] = useState(false);
  const [onFormChange, setOnFormChange] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [mode, setMode] = useState('CREATE');
  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    fetchICDCodes();
  }, []);

  useEffect(() => {
    if (icdCodesStatus === 'SUCCESS') {
      setDataSource(icdCodesData?.items);
      setCurrentPage(icdCodesData?.currentPage);
      setTotalPages(icdCodesData?.totalPages);
    }
    dispatch(clearFetchICDCodesResponse());
  }, [icdCodesStatus]);

  useEffect(() => {
    if (addICDCodeStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      fetchICDCodes();
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (addICDCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addICDCodeError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }

    dispatch(clearAddICDCodeResponse());
  }, [addICDCodeStatus]);

  const fetchICDCodes = async (pageSize = PAGE_SIZE, pageNumber = currentPage) => {
    dispatch(getICDCodesRequest({ page: pageNumber, size: pageSize, code: '', desc: '' }));
  };

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchICDCodes(PAGE_SIZE, page - 1);
  };

  const handleModalVisible = () => {
    errorAlertOnClose();
    setOnFormChange({});
    setErrorObj({});
    setMode('CREATE');
    setVisibleModal(true);
  };

  const handleOnClose = () => {
    errorAlertOnClose();
    setOnFormChange({});
    setErrorObj({});
    setVisibleModal(false);
    setMode('CREATE');
  };

  const handleFormChanges = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setOnFormChange((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  // console.log('=== onFormChange ===', onFormChange);

  const onSubmit = () => {
    const validationDetails = icdValidation(onFormChange);

    if (Object.keys(validationDetails?.newErrors)?.length > 0) {
      setErrorObj(validationDetails?.newErrors);
      const errorMessages = Object.values(validationDetails.newErrors);
      setAlertObj({
        color: 'failure',
        message: (
          <div>
            {errorMessages.map((msg: any, index) => (
              <div key={index} className="flex items-center">
                <IoIosInformationCircleOutline />
                <span style={{ marginLeft: '8px' }}>{msg}</span>
              </div>
            ))}
          </div>
        ),
        error: true,
      });
      setVisibleAlert(true);
    } else {
      dispatch(addICDCodeRequest(onFormChange));
    }
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  // console.log('=== addICDCodeLoading ===', addICDCodeLoading);

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic onClick={handleModalVisible} mainTitle="Diagnosis Code" breadCrumbArr={breadCrumbArr} />
      <TableSearch />
      {
        //loading
        false ? (
          <Spinner />
        ) : (
          <>
            {dataSource?.length > 0 ? (
              <>
                <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              </>
            ) : (
              <EmptyContent />
            )}
          </>
        )
      }

      {visibleModal ? (
        <RightSheet
          onClose={handleOnClose}
          title="Create Diagnosis Code"
          submitButtonTitle="Create"
          cancelButtonTitle="Cancel"
          onSubmit={onSubmit}
          children={
            <ICDRightSheetForm onChange={handleFormChanges} mode={mode} errorObject={errorObj} versions={versions} />
          }
          // loading={addICDCodeLoading}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
