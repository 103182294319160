import { Modal } from 'flowbite-react';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';

import { CommonAlert } from '../../../atoms/Alert';
import { InputField } from '../../../atoms/InputField';
import { CommonButton } from '../../../atoms/CommonButton';
import { Spinner } from '../../../atoms/Spinner';
import { TableContent } from '../../../table/TableContent';
import { Pagination } from '../../../pagination/Pagination';

import { getCPTCodesRequest, getCPTCodesSelector } from '../../../../redux/slices/system/getCPTCodesSlice';

const PAGE_SIZE = 5;

const cptColumns = [
  {
    title: 'CPT Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'CPT Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'CPT Desc',
    dataIndex: 'desc',
    key: 'desc',
  },
];

export const CPTSearchModal = ({
  cptModalOpen,
  setOpenCPTModal,
  cptCodesDataSource,
  visitId,
  getCPTCodeList,
  handleAddedCPTRow,
  handleRemoveCPTCodeDataSource,
}: any) => {
  const dispatch = useAppDispatch();
  const { cptCodesStatus, cptCodesData } = useAppSelector(getCPTCodesSelector);

  const [formData, setFormData] = useState<any>({});
  const [fetchDataLoading, setFetchDataLoading] = useState<boolean>(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false);

  useEffect(() => {
    setFetchDataLoading(true);
    dispatch(
      getCPTCodesRequest({ code: formData?.code ?? '', desc: formData?.desc ?? '', page: currentPage, size: PAGE_SIZE })
    );
  }, []);

  useEffect(() => {
    if (cptCodesStatus === 'SUCCESS') {
      setCurrentPage(cptCodesData?.currentPage);
      setTotalPages(cptCodesData?.totalPages);
      setTotalItems(cptCodesData?.totalItems);
      setFetchDataLoading(false);
    } else if (cptCodesStatus === 'FAILED') {
      setFetchDataLoading(false);
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [cptCodesStatus]);

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleFieldValueChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    if (
      (!updatedFormData?.code || updatedFormData?.code?.trim() === '') &&
      (!updatedFormData?.desc || updatedFormData?.desc?.trim() === '')
    ) {
      setIsSearchEnabled(false);
    } else {
      setIsSearchEnabled(true);
    }
  };

  const handleSubmit = () => {
    dispatch(getCPTCodesRequest({ code: formData?.code ?? '', desc: formData?.desc ?? '', page: 0, size: PAGE_SIZE }));
  };

  const handleReset = () => {
    setFormData({});
    setIsSearchEnabled(false);
    dispatch(getCPTCodesRequest({ code: '', desc: '', page: 0, size: PAGE_SIZE }));
  };

  const onSearchPageChange = (page: any) => {
    setCurrentPage(page - 1);
    dispatch(
      getCPTCodesRequest({ code: formData?.code ?? '', desc: formData?.desc ?? '', page: page - 1, size: PAGE_SIZE })
    );
  };

  const handleAddRow = (event: any, rowIndex: any) => {
    event.preventDefault();
    handleAddedCPTRow(cptCodesDataSource[rowIndex]);
    setAlertObj({
      color: 'success',
      message: 'CPT Code Selected !',
      error: false,
    });
    setVisibleAlert(true);
    setTimeout(() => {
      setAlertObj({
        color: '',
        message: '',
        error: false,
      });
      setVisibleAlert(false);
      setOpenCPTModal(false);
    }, 1000);
  };

  const handleOnClose = () => {
    setOpenCPTModal(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    handleRemoveCPTCodeDataSource && handleRemoveCPTCodeDataSource();
  };

  return (
    <Modal show={cptModalOpen} onClose={handleOnClose}>
      <Modal.Header>Search CPT Code</Modal.Header>
      <Modal.Body>
        {visibleAlert && !alertObj?.error && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-1/2"
          />
        )}
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <InputField label="Code" name="code" onChange={handleFieldValueChange} value={formData?.code ?? ''} />
          <InputField onChange={handleFieldValueChange} name="desc" label="Description" value={formData?.desc ?? ''} />
        </div>
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <div className="col-span-1"></div>
          <div className="col-span-1 flex justify-end space-x-2">
            <CommonButton type="button" label={'Reset'} buttonType="secondary" onClick={handleReset} />
            <CommonButton
              type="button"
              label={'Search'}
              buttonType="primary"
              onClick={handleSubmit}
              disabled={!isSearchEnabled}
            />
          </div>
        </div>
        {fetchDataLoading ? (
          <Spinner />
        ) : (
          <>
            {cptCodesDataSource && cptCodesDataSource?.length > 0 && (
              <>
                <TableContent
                  enableActions={true}
                  columns={cptColumns}
                  dataSource={cptCodesDataSource}
                  enableAdd={true}
                  onHandleAdd={handleAddRow}
                />
                <Pagination
                  currentPage={currentPage}
                  totalItems={totalItems}
                  totalPages={totalPages}
                  onPageChange={onSearchPageChange}
                />
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
