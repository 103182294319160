export const claimStatus = [
  { id: 'READY_TO_SENT', name: 'Ready to be sent' },
  { id: 'DENIAL', name: 'Denial' },
  { id: 'COMPLETED', name: 'Completed - Payment Received' },
  { id: 'RE_SEND', name: 'Resend' },
  { id: 'PENDING', name: 'Pending' },
  { id: 'HOLD', name: 'Hold' },
  { id: 'ALERT', name: 'Alert' },
  { id: 'REJECTED', name: 'Rejected' },
  { id: 'SENT', name: 'Sent - Transmitted' },
  { id: 'PENDING_TOUCH', name: 'Pending Touched' },
  { id: 'PAPER_CLAIMS', name: 'Paper Claims' },
];
