import { useEffect, useState } from 'react';
import { MainHeader } from '../../components/mainHeader/MainHeader';
import { CommonAlert } from '../../components/atoms/Alert';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import { Spinner } from '../../components/atoms/Spinner';
import { Pagination } from '../../components/pagination/Pagination';
import { getFacilitiesSelector, getFacilityRequest } from '../../redux/slices/facility/getFacilitiesSlice';
import { RightSheet } from '../../components/rightSheet/RightSheet';
import { FacilityForm } from '../../components/list/facilities/FacililtyForm';
import {
  addFacilityRequest,
  addFacilitySelector,
  clearAddFacilityResponse,
} from '../../redux/slices/facility/addFacilitySlice';
import { createFacilityValidation } from '../../utils/facilityValidation';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';
import { getSystemInsuranceRequest } from '../../redux/slices/system/getSystemInsuranceSlice';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
];

const dataSource = [
  {
    id: 1,
    name: 'John',
    address: 'A / A / 1 / 11, Ranpokunagama',
    city: 'Nittambuwa',
    lastName: 'Doe',
    state: 'MI',
  },
  {
    id: 2,
    name: 'John',
    address: 'A / A / 1 / 11, Ranpokunagama',
    city: 'Nittambuwa',
    lastName: 'Doe',
    state: 'MI',
  },
  {
    id: 3,
    name: 'John',
    address: 'A / A / 1 / 11, Ranpokunagama',
    city: 'Nittambuwa',
    lastName: 'Doe',
    state: 'MI',
  },
  {
    id: 4,
    name: 'John',
    address: 'A / A / 1 / 11, Ranpokunagama',
    city: 'Nittambuwa',
    lastName: 'Doe',
    state: 'MI',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'facilities', label: 'Facilities', status: 'active', link: 'facilities' },
];

export const FacilitiesPage = () => {
  const dispatch = useAppDispatch();
  const { facilityLoading, facilityData, facilityStatus } = useAppSelector(getFacilitiesSelector);
  const { addFacilityLoading, addFacilityStatus, addFacilityError } = useAppSelector(addFacilitySelector);
  const [visibleModal, setVisibleModal] = useState(false);
  const [createFormData, setCreateFormData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [visibleEditForm, setVisibleEditForm] = useState(false);
  const [selectedChild, setSelectedChild] = useState(1);
  const [mode, setMode] = useState('CREATE');
  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    fetchFacilities();
    setVisibleModal(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setErrorObj({});
  }, []);

  useEffect(() => {
    if (facilityStatus === 'SUCCESS' && !facilityLoading) {
      setDataSource(facilityData?.items);
      setCurrentPage(facilityData?.currentPage);
      setTotalPages(facilityData?.totalPages);
    }
  }, [facilityStatus]);

  useEffect(() => {
    if (addFacilityStatus === 'SUCCESS') {
      fetchFacilities();
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      getDropdwonData();
      setTimeout(() => {
        setMode('CREATE');
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setCreateFormData({});
      }, 3000);
    } else if (addFacilityStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      // setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }

    dispatch(clearAddFacilityResponse());
  }, [addFacilityStatus]);

  const getDropdwonData = () => {
    dispatch(getSystemFacilityRequest());
  };

  const fetchFacilities = async (pageSize = PAGE_SIZE, pageNumber = currentPage) => {
    dispatch(getFacilityRequest({ size: pageSize, page: pageNumber }));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleModalVisible = () => {
    setCreateFormData({
      doNotCallFacility: 0,
      countryCode: 'US',
    });
    setMode('CREATE');
    setVisibleModal(true);
  };

  const onEdit = () => {};

  const onView = () => {};

  const convertBeforeSubmit = (formData: any) => {
    let obj = {
      ...formData,
      extension: formData?.extension ? parseInt(formData?.extension, 10) : undefined,
      primaryBusinessEntity: formData?.primaryBusinessEntity
        ? parseInt(formData?.primaryBusinessEntity, 10)
        : undefined,
      serviceLocation: formData?.serviceLocation ? parseInt(formData?.serviceLocation, 10) : undefined,
    };
    return obj;
  };

  const onSubmit = () => {
    if (mode === 'CREATE') {
      const validationDetails = createFacilityValidation(createFormData);
      // console.log('==== validationDetails ===', validationDetails);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);
        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(addFacilityRequest(convertBeforeSubmit(createFormData)));
      }
    }
  };

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchFacilities(PAGE_SIZE, page - 1);
  };

  const handleOnClose = () => {
    setCreateFormData({});
    setMode('CREATE');
    setVisibleModal(false);
    setErrorObj({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const onChangeFormData = (e: any) => {
    const { name, value, type, checked } = e.target;

    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }

    const convertedValue = type === 'checkbox' ? (checked ? 1 : 0) : value;

    setCreateFormData((prev: any) => ({ ...prev, [name]: convertedValue }));
  };

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic onClick={handleModalVisible} mainTitle="Facilities" breadCrumbArr={breadCrumbArr} />
      {facilityLoading ? (
        <Spinner />
      ) : (
        <>
          {!facilityLoading && dataSource?.length > 0 ? (
            <TableContent
              enableActions={true}
              columns={columns}
              dataSource={dataSource}
              enableView={true}
              enableEdit={true}
              onEdit={onEdit}
              onView={onView}
            />
          ) : (
            <EmptyContent />
          )}

          {dataSource?.length > 0 && (
            <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
          )}
        </>
      )}

      {visibleModal ? (
        <RightSheet
          onClose={handleOnClose}
          title="Create Facility"
          submitButtonTitle="Create"
          cancelButtonTitle="Cancel"
          children={<FacilityForm onChange={onChangeFormData} errorObject={errorObj} />}
          onSubmit={onSubmit}
          loading={addFacilityLoading}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
