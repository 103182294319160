import { TextAreaPropsInterface } from '../../interfaces/atomInterfaces';
import { Tooltip } from 'flowbite-react';
import { Textarea } from 'flowbite-react';
import { BsInfoCircle } from 'react-icons/bs';

export const TextArea: React.FC<TextAreaPropsInterface> = ({
  label,
  onChange,
  placeholder,
  rows,
  name,
  required,
  defaultValue,
  tooltip = '',
  disabled,
}) => {
  return (
    <div>
      <label
        htmlFor="input_field"
        className="block mb-2 text-sm font-normal text-gray-900 dark:text-white flex items-center"
      >
        {label} {required && <span className="text-red-500">*</span>}
        {tooltip && (
          <span className="ml-1">
            <Tooltip content={tooltip} placement="top">
              <BsInfoCircle className="text-gray-500 cursor-pointer" />
            </Tooltip>
          </span>
        )}
      </label>
      <Textarea
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        name={name}
        defaultValue={defaultValue}
        disabled={disabled}
        required={required}
        className="
          bg-gray1 
          border 
          border-gray2 
          text-gray9 
          text-sm 
          rounded-lg
          focus:ring-1
          focus:ring-primaryDefault 
          focus:border-primaryDefault 
          outline-0
          block 
          w-full 
          p-2.5 
          dark:bg-gray9 
          dark:border-gray8
          dark:placeholder-gray4
          dark:text-white 
          dark:focus:ring-primaryDefault
          dark:focus:border-primaryDefault"
      />
    </div>
  );
};
