let globalLogoutFunction: (() => void) | null = null;

export const setGlobalLogout = (logout: () => void) => {
  globalLogoutFunction = logout;
};

export const triggerGlobalLogout = () => {
  if (globalLogoutFunction) {
    globalLogoutFunction();
  } else {
    console.error('Global logout not set.');
  }
};
