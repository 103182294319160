import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { updatePatientAPIRequest } from '../../../api/patient/patient';
import {
  PatientDemographicEditPropsInterface,
  PatientDemogrphicUpdateInterface,
} from '../../../interfaces/patientInterface';

const initialState: PatientDemographicEditPropsInterface = {
  editPatientDemographicLoading: false,
  editPatientDemographicData: {},
  editPatientDemographicError: null,
  editPatientDemographicStatus: 'IDLE',
};

export const editPatientDemographicRequest: any = createAsyncThunk(
  'patient/demographic/edit',
  async (patient: PatientDemogrphicUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updatePatientAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editPatientDemographicSlice = createSlice({
  name: 'editPatientDemographic',
  initialState,
  reducers: {
    clearEditPatientDemographicResponse: (state) => {
      state.editPatientDemographicLoading = false;
      state.editPatientDemographicStatus = 'IDLE';
      state.editPatientDemographicData = {};
      state.editPatientDemographicError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editPatientDemographicRequest.pending, (state, action) => {
        state.editPatientDemographicLoading = true;
        state.editPatientDemographicStatus = 'PENDING';
      })
      .addCase(editPatientDemographicRequest.fulfilled, (state, action) => {
        state.editPatientDemographicData = action.payload;
        state.editPatientDemographicStatus = 'SUCCESS';
        state.editPatientDemographicLoading = false;
      })
      .addCase(editPatientDemographicRequest.rejected, (state, action) => {
        state.editPatientDemographicLoading = false;
        state.editPatientDemographicError = action.payload;
        state.editPatientDemographicStatus = 'FAILED';
      });
  },
});

export const { clearEditPatientDemographicResponse } = editPatientDemographicSlice.actions;

export const editPatientDemographicSelector = (state: RootState) => state.EditPatientDemographic;

export default editPatientDemographicSlice.reducer;
