import { Modal } from 'flowbite-react';
import { TableContent } from '../../../table/TableContent';
import { TableSearch } from '../../../tableSearch/TableSearch';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Entry',
    dataIndex: 'entry',
    key: 'entry',
  },
  {
    title: 'Event',
    dataIndex: 'event',
    key: 'event',
  },
];

export const ClearingHouseResponseModal = ({
  clearingHouseResponseModal,
  setClearingHouseResponseModal,
  dataSource,
  claimId,
}: any) => {
  return (
    <Modal show={clearingHouseResponseModal} onClose={() => setClearingHouseResponseModal(false)}>
      <Modal.Header>Claim History for Claim ID {claimId}</Modal.Header>
      <Modal.Body>
        <div className="flex justify-end">
          <TableSearch />
        </div>
        <TableContent dataSource={dataSource} columns={columns} />
      </Modal.Body>
    </Modal>
  );
};
