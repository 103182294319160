import { Modal } from 'flowbite-react';
import { useAppDispatch } from '../../../../hooks/storeHooks/hooks';
import { SelectInput } from '../../../atoms/SelectInput';
import { TextArea } from '../../../atoms/TextArea';
import { TableContent } from '../../../table/TableContent';
import { CommonButton } from '../../../atoms/CommonButton';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/storeHooks/hooks';
import {
  addVisitApplyNoteRequest,
  addVisitApplyNoteSelector,
  clearAddVisitApplyNoteResponse,
} from '../../../../redux/slices/patient/addVisitApplyNoteSlice';
import { getPatientCaseOptionsSelector } from '../../../../redux/slices/patient/getPatientCaseOptionSlice';
import { claimStatus } from '../../../../constants/claimConstants';
import { statusReason } from '../../../../constants/patientConstants';
import moment from 'moment';
import { validDateFormat } from '../../../../utils/commonFunctions';
import { Checkbox } from '../../../atoms/Checkbox';
import { getSystemVisitStatusSelector } from '../../../../redux/slices/system/getSystemVisitStatusSlice';
import { getIpDataSelector } from '../../../../redux/slices/system/getIPDataSlice';
import { updateApplyNotesValidation } from '../../../../utils/patientValidation';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { CommonAlert } from '../../../atoms/Alert';

const columns = (handleSelect: any, handleSelectAll: any, selectedRecords: any, selectAllHeader: boolean) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => moment(text).format(validDateFormat),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Provider',
    dataIndex: 'providerName',
    key: 'providerName',
  },
  {
    title: 'Select All',
    dataIndex: 'select',
    key: 'select',
    renderHeader: (text: any, record: any) => {
      return <Checkbox onChange={(e: any) => handleSelectAll(e?.target?.checked, record)} checked={selectAllHeader} />;
    },
    render: (text: any, record: any) => {
      return (
        <div className="flex items-center justify-center">
          <Checkbox
            onChange={(e: any) => handleSelect(e?.target?.checked, record)}
            checked={selectedRecords[record?.id] || false}
          />
        </div>
      );
    },
  },
];

export const ApplyNotesModal = ({
  applyBotesModalOpen,
  setOpenApplyNotesModal,
  applyNoteHistoryDataSource,
  selectedId,
  setVisibleApplyNotes,
  fetchPatientById,
}: any) => {
  const dispatch = useAppDispatch();
  const { caseOptionPatientData } = useAppSelector(getPatientCaseOptionsSelector);
  const { systemVisitData } = useAppSelector(getSystemVisitStatusSelector);
  const { addVisitApplyNoteStatus, addVisitApplyNoteError } = useAppSelector(addVisitApplyNoteSelector);
  const { ipDataPayload } = useAppSelector(getIpDataSelector);

  const [formData, setFormData] = useState<any>({});
  const [selectAllHeader, setSelectAllHeader] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<{ [key: number]: boolean }>({});
  const [errorObj, setErrorObj] = useState<any>({});
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (addVisitApplyNoteStatus === 'SUCCESS') {
      fetchPatientById && fetchPatientById(selectedId, 'visit');
      setAlertObj({
        color: 'success',
        message: 'Successfully Added !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setFormData({});
        setErrorObj({});
        setVisibleApplyNotes(false);
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (addVisitApplyNoteStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addVisitApplyNoteError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearAddVisitApplyNoteResponse());
  }, [addVisitApplyNoteStatus]);

  const onChangeFields = (e: any) => {
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    const beforeSubmit = {
      caseId: formData?.case ? parseInt(formData?.case) : null,
      statusId: formData?.status ? parseInt(formData?.status) : null,
      reason: formData?.reason ? parseInt(formData?.reason) : null,
      note: formData?.note ?? '',
      ip: ipDataPayload?.ip,
      visitIdList: Object.keys(selectedRecords),
    };
    const obj = {
      patientId: selectedId,
      visitData: beforeSubmit,
    };

    const validationDetails = updateApplyNotesValidation(beforeSubmit);

    // console.log('=== obj ===', obj);

    if (Object.keys(validationDetails?.newErrors)?.length > 0) {
      setErrorObj(validationDetails?.newErrors);
      const errorMessages = Object.values(validationDetails.newErrors);
      setVisibleAlert(true);
      setAlertObj({
        color: 'failure',
        message: (
          <div>
            {errorMessages.map((msg: any, index) => (
              <div key={index} className="flex items-center">
                <IoIosInformationCircleOutline />
                <span style={{ marginLeft: '8px' }}>{msg}</span>
              </div>
            ))}
          </div>
        ),
        error: false,
      });
    } else {
      dispatch(addVisitApplyNoteRequest(obj));
    }
  };

  const handleSelect = (value: boolean, record: any) => {
    setSelectedRecords((prevSelected) => {
      const updatedSelected = { ...prevSelected };

      if (value) {
        // Add the record if not already selected
        updatedSelected[record.id] = true;
      } else {
        // Remove the record if it's deselected
        delete updatedSelected[record.id];
        setSelectAllHeader(false); // Uncheck "Select All" if any deselection occurs
      }

      return updatedSelected;
    });
  };

  const handleSelectAll = (value: boolean) => {
    setSelectAllHeader(value);

    if (value) {
      // Select all records
      const allSelected = applyNoteHistoryDataSource.reduce((acc: any, item: any) => {
        acc[item.id] = true;
        return acc;
      }, {});
      setSelectedRecords(allSelected);
    } else {
      // Deselect all records
      setSelectedRecords({});
    }
  };

  const transformVisitStatus = (data: any) => {
    return [
      ...data?.map((el: any) => ({
        id: el?.value,
        name: el?.label,
      })),
    ];
  };

  // console.log('=== selectedRecords ===', selectedRecords);
  // console.log('caseOptionPatientData', caseOptionPatientData);

  return (
    <Modal
      show={applyBotesModalOpen}
      onClose={() => {
        setOpenApplyNotesModal(false);
      }}
    >
      <Modal.Header>Apply Note to Multiple Visits</Modal.Header>
      <Modal.Body>
        {visibleAlert && !alertObj?.error && (
          <CommonAlert
            alertClassName={'w-3/3'}
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={() => setVisibleAlert(false)}
          />
        )}
        <div className="grid md:grid-cols-3 gap-4 mt-6">
          <SelectInput
            label="Case"
            options={caseOptionPatientData}
            enableDefaultPlaceholder={true}
            name="case"
            onChange={onChangeFields}
            //   error={errorObject && errorObject?.patientState}
            //   errorMessage={errorObject && errorObject?.patientState}
          />
          <SelectInput
            label="Status"
            options={transformVisitStatus(systemVisitData)}
            enableDefaultPlaceholder={true}
            name="status"
            onChange={onChangeFields}
            //   error={errorObject && errorObject?.patientState}
            //   errorMessage={errorObject && errorObject?.patientState}
          />
          <SelectInput
            label="Reason"
            options={statusReason}
            enableDefaultPlaceholder={true}
            name="reason"
            onChange={onChangeFields}
            //   error={errorObject && errorObject?.patientState}
            //   errorMessage={errorObject && errorObject?.patientState}
          />
        </div>
        <div className="grid md:grid-cols-1 mt-6">
          <TextArea onChange={onChangeFields} name="note" label="Notes" />
        </div>
        <TableContent
          enableActions={false}
          columns={columns(handleSelect, handleSelectAll, selectedRecords, selectAllHeader)}
          dataSource={applyNoteHistoryDataSource}
        />
      </Modal.Body>
      <Modal.Footer>
        <CommonButton
          type="button"
          label={'Close'}
          buttonType="secondary"
          onClick={() => {
            setFormData({});
          }}
        />
        <CommonButton type="button" label={'Submit'} buttonType="primary" onClick={handleSubmit} />
      </Modal.Footer>
    </Modal>
  );
};
