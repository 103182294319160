import { useState } from 'react';
import { CommonAlert } from '../../components/atoms/Alert';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Spinner } from '../../components/atoms/Spinner';
import { TableContent } from '../../components/table/TableContent';
import { Pagination } from '../../components/pagination/Pagination';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { RightSheet } from '../../components/rightSheet/RightSheet';
import { AddPracticesForm } from '../../components/account/practices/AddPracticesForm';
import { MainHeader } from '../../components/mainHeader/MainHeader';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Tax ID',
    dataIndex: 'taxId',
    key: 'taxId',
  },
  {
    title: 'NPI',
    dataIndex: 'npi',
    key: 'npi',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
];

const dataSource = [
  {
    id: 1,
    name: 'John',
    taxId: '(121) 234-567',
    npi: '1234567890',
    state: 'IL',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'practices', label: 'Practice - Update', status: 'active', link: 'practices' },
];

export const PracticesPage = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [onFormChange, setOnFormChange] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({ color: '', message: '', error: false });
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [visibleEditForm, setVisibleEditForm] = useState(false);
  const [selectedChild, setSelectedChild] = useState(1);
  const [mode, setMode] = useState('CREATE');
  const [errorObj, setErrorObj] = useState<any>({});

  const handleModalVisible = () => {
    setMode('CREATE');
    setVisibleModal(true);
  };

  const handleOnClose = () => {
    setVisibleModal(false);
    setVisibleEditForm(false);
    setMode('CREATE');
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleFormChanges = () => {};

  const onSubmit = () => {};

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic onClick={handleModalVisible} mainTitle="Practice - Update" breadCrumbArr={breadCrumbArr} />

      {/* {loading ? (
        <Spinner />
      ) : ( */}
      <>
        {dataSource?.length > 0 ? (
          <>
            <TableContent
              enableActions={true}
              columns={columns}
              dataSource={dataSource}
              enableDelete={false}
              enableEdit={true}
              enableView={false}
            />
            <Pagination />
          </>
        ) : (
          <EmptyContent />
        )}
      </>
      {/* )} */}
      {visibleModal ? (
        <RightSheet
          onClose={handleOnClose}
          title="Add Practice"
          submitButtonTitle="Create"
          cancelButtonTitle="Cancel"
          onSubmit={onSubmit}
          children={<AddPracticesForm onChange={handleFormChanges} mode={mode} errorObject={errorObj} />}
          // loading={addUserLoading}
          enableAlert={visibleAlert}
          alertDetails={alertObj}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
