import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  ProviderAddressDeleteByIdInitialPropsInterface,
  ProviderAddressGetByIdInterface,
} from "../../../interfaces/providersInterface";
import { deleteProviderAddressByIdAPIRequest } from "../../../api/provider/providerAddress";

const initialState: ProviderAddressDeleteByIdInitialPropsInterface = {
  deleteProviderAddressByIdLoading: false,
  deleteProviderAddressByIdData: {},
  deleteProviderAddressByIdStatus: "IDLE",
  deleteProviderAddressByIdError: null,
};

export const deleteProviderAddressByIdRequest: any = createAsyncThunk(
  "providers/address/delete/id",
  async (searchParams: ProviderAddressGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await deleteProviderAddressByIdAPIRequest(
        searchParams
      );
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deleteProviderAddressByIdSlice = createSlice({
  name: "deleteProviderAddressById",
  initialState,
  reducers: {
    clearDeleteProviderAddressByIdResponse: (state) => {
      state.deleteProviderAddressByIdLoading = false;
      state.deleteProviderAddressByIdData = {};
      state.deleteProviderAddressByIdStatus = "IDLE";
      state.deleteProviderAddressByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProviderAddressByIdRequest.pending, (state, action) => {
        state.deleteProviderAddressByIdLoading = true;
        state.deleteProviderAddressByIdStatus = "PENDING";
      })
      .addCase(deleteProviderAddressByIdRequest.fulfilled, (state, action) => {
        state.deleteProviderAddressByIdLoading = false;
        state.deleteProviderAddressByIdStatus = "SUCCESS";
        state.deleteProviderAddressByIdData = action.payload;
      })
      .addCase(deleteProviderAddressByIdRequest.rejected, (state, action) => {
        state.deleteProviderAddressByIdLoading = false;
        state.deleteProviderAddressByIdError = action.payload;
        state.deleteProviderAddressByIdStatus = "FAILED";
      });
  },
});

export const { clearDeleteProviderAddressByIdResponse } =
  deleteProviderAddressByIdSlice.actions;

export const deleteProviderAddressByIdSelector = (state: RootState) =>
  state.DeleteProviderAddressById;

export default deleteProviderAddressByIdSlice.reducer;
