import { validZipCodeLength, validateEmail, validatePhoneNumber } from './commonFunctions';

export const createPatientValidation = (formData: any) => {
  // console.log('==== create patient ====', formData);

  let isValid = true;
  const newErrors: any = {};

  if (!formData?.firstName?.trim()) {
    newErrors.firstName = 'First name is required';
    isValid = false;
  }

  if (!formData?.lastName?.trim()) {
    newErrors.lastName = 'Last name is required';
    isValid = false;
  }

  if (!formData.facilityId) {
    newErrors.facilityId = 'Facility is required';
    isValid = false;
  }

  if (!formData.providerId) {
    newErrors.providerId = 'Provider is required';
    isValid = false;
  }

  // Validate DOB
  if (!formData.dob) {
    newErrors.dob = 'Date of Birth is required';
    isValid = false;
  }

  // Validate Gender
  if (!formData.sexStatus) {
    newErrors.sexStatus = 'Gender is required';
    isValid = false;
  }

  if (formData.ssn) {
    const cleaned = formData.ssn.replace(/[^0-9]/g, '');
    // console.log('==== cleaned ===', cleaned, /^[0-9]{9}$/.test(cleaned));

    if (!/^[0-9]{9}$/.test(cleaned)) {
      newErrors.ssn = 'Enter a valid SSN';
      isValid = false;
    }
  }

  if (formData.email) {
    if (!validateEmail(formData?.email)) {
      newErrors.email = 'Enter a valid Email';
      isValid = false;
    }
  }

  if (formData?.cellPhone) {
    if (!validatePhoneNumber(formData?.cellPhone)) {
      newErrors.cellPhone = 'Enter a valid cell phone number';
      isValid = false;
    }
  }

  if (formData?.homePhone) {
    if (!validatePhoneNumber(formData?.homePhone)) {
      newErrors.homePhone = 'Enter a valid home phone number';
      isValid = false;
    }
  }

  if (formData?.fax) {
    if (!validatePhoneNumber(formData?.fax)) {
      newErrors.fax = 'Enter a valid fax number';
      isValid = false;
    }
  }

  //   console.log('=== patient create validation ====', newErrors);

  return { newErrors, isValid };
};

export const createPatientInsurance = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.insuranceId) {
    newErrors.insuranceId = 'Insurance is required';
    isValid = false;
  }

  if (!formData?.policyId) {
    newErrors.policyId = 'Policy ID is required';
    isValid = false;
  }

  if (!formData.relationship) {
    newErrors.relationship = 'Relationship is required';
    isValid = false;
  }

  if (!formData?.policyStartDate) {
    newErrors.policyStartDate = 'Policy Start Date is required';
    isValid = false;
  }

  if (!formData?.policyEndDate) {
    newErrors.policyEndDate = 'Policy End Date is required';
    isValid = false;
  }

  return { newErrors, isValid };
};

export const createPatientAddressValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  console.log(
    '=== formData ===',
    formData,
    formData?.zipCode?.length !== validZipCodeLength,
    formData?.zipCode?.length,
    validZipCodeLength
  );

  if (!formData?.address1) {
    newErrors.address1 = 'Address 1 is required';
    isValid = false;
  }

  if (!formData?.city) {
    newErrors.city = 'City is required';
    isValid = false;
  }

  if (!formData?.state) {
    newErrors.state = 'State is required';
    isValid = false;
  }

  if (!formData?.zipCode || formData?.zipCode.toString().length !== validZipCodeLength) {
    newErrors.zipCode = 'Valid zip code required';
    isValid = false;
  }

  return { newErrors, isValid };
};

export const createPatientCaseValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.facilityId) {
    newErrors.facilityId = 'Facility is required';
    isValid = false;
  }

  if (!formData?.providerId) {
    newErrors.providerId = 'Referring Provider is required';
    isValid = false;
  }

  // if (!formData?.noteToBiller) {
  //   newErrors.noteToBiller = 'Note is required';
  //   isValid = false;
  // }

  return { newErrors, isValid };
};

export const createPatientVisitValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.caseId) {
    newErrors.caseId = 'Case is required';
    isValid = false;
  }

  if (!formData?.visitDate) {
    newErrors.visitDate = 'Visit Date is required';
    isValid = false;
  }

  // if (!formData?.noteToBiller) {
  //   newErrors.noteToBiller = 'Note is required';
  //   isValid = false;
  // }

  return { newErrors, isValid };
};

export const patientVisitEditCPTValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  // console.log('=== formData ===', formData);
  if (!formData?.cptCode) {
    newErrors.cptCode = 'CPT Code is required';
    isValid = false;
  }

  if (!formData?.unit || Number.isNaN(formData.unit)) {
    newErrors.unit = 'Unit is required';
    isValid = false;
  }

  if (!formData?.icdCodes) {
    newErrors.icdCodes = 'ICD Codes are required';
    isValid = false;
  }

  if (formData?.icdCodes?.length > 0) {
    if (formData?.icdCodes?.every((el: any) => Object.keys(el)?.length === 0)) {
      newErrors.icdCodes = 'ICD Codes are required';
      isValid = false;
    } else if (formData?.icdCodes?.every((el: any) => el?.value === '' || el?.value === undefined)) {
      newErrors.icdCodes = 'Values are required for selected ICD codes';
      isValid = false;
    }
  }

  return { newErrors, isValid };
};

export const createPatientVisitEIValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.lastName?.trim()) {
    newErrors.lastName = 'Last name is required';
    isValid = false;
  }

  if (!formData?.firstName?.trim()) {
    newErrors.firstName = 'First name is required';
    isValid = false;
  }

  if (!formData?.policy?.trim()) {
    newErrors.policy = 'Policy number is required';
    isValid = false;
  }

  // if (!formData?.group?.trim()) {
  //   newErrors.group = 'Group number is required';
  //   isValid = false;
  // }

  if (!formData?.insuranceId) {
    newErrors.insuranceId = 'Insurance name is required';
    isValid = false;
  }

  if (!formData?.payerId?.trim()) {
    newErrors.payerId = 'Payer ID is required';
    isValid = false;
  }

  // if (!formData?.receiverLastNameOrOrganizationName?.trim()) {
  //   newErrors.receiverLastNameOrOrganizationName = 'Group Name is required';
  //   isValid = false;
  // }

  if (!formData?.npiType?.trim()) {
    newErrors.npiType = 'NPI Type is required';
    isValid = false;
  }

  if (!formData?.npi?.trim()) {
    newErrors.npi = 'NPI is required';
    isValid = false;
  }

  if (!formData?.patientDoB?.trim()) {
    newErrors.patientDoB = 'DOB is required';
    isValid = false;
  }

  if (!formData?.dateOfService?.trim()) {
    newErrors.dateOfService = 'Date of Service is required';
    isValid = false;
  }

  return { newErrors, isValid };
};

export const updateApplyNotesValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.caseId) {
    newErrors.caseId = 'Case is required';
    isValid = false;
  }

  if (!formData?.statusId) {
    newErrors.statusId = 'Status is required';
    isValid = false;
  }

  if (!formData?.visitIdList || formData?.visitIdList?.length === 0) {
    newErrors.visitIdList = 'Select a record';
    isValid = false;
  }

  return { newErrors, isValid };
};

export const updateClaimApplyNotesValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.categoryId) {
    newErrors.categoryId = 'Category is required';
    isValid = false;
  }

  if (!formData?.statusId) {
    newErrors.statusId = 'Status is required';
    isValid = false;
  }

  if (!formData?.claimIdList || formData?.claimIdList?.length === 0) {
    newErrors.claimIdList = 'Select a record';
    isValid = false;
  }

  return { newErrors, isValid };
};
