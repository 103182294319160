import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { addProviderCarriersAPIRequest } from '../../../api/provider/providerCarrier';
import {
  ProviderCarriersAddPropsInterface,
  ProviderCarriersAddInterface,
} from '../../../interfaces/providersInterface';

const initialState: ProviderCarriersAddPropsInterface = {
  addProviderCarriersLoading: false,
  addProviderCarriersData: {},
  addProviderCarriersError: null,
  addProviderCarriersStatus: 'IDLE',
};

export const addProviderCarriersRequest: any = createAsyncThunk(
  'provider/carriers/add',
  async (carrier: ProviderCarriersAddInterface, thunkAPI: any) => {
    try {
      const response: any = await addProviderCarriersAPIRequest(carrier);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addProviderCarriersSlice = createSlice({
  name: 'addProviderCarriers',
  initialState,
  reducers: {
    clearAddProviderCarriersResponse: (state) => {
      state.addProviderCarriersLoading = false;
      state.addProviderCarriersStatus = 'IDLE';
      state.addProviderCarriersData = {};
      state.addProviderCarriersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProviderCarriersRequest.pending, (state, action) => {
        state.addProviderCarriersLoading = true;
        state.addProviderCarriersStatus = 'PENDING';
      })
      .addCase(addProviderCarriersRequest.fulfilled, (state, action) => {
        state.addProviderCarriersData = action.payload;
        state.addProviderCarriersStatus = 'SUCCESS';
        state.addProviderCarriersLoading = false;
      })
      .addCase(addProviderCarriersRequest.rejected, (state, action) => {
        state.addProviderCarriersLoading = false;
        state.addProviderCarriersError = action.payload;
        state.addProviderCarriersStatus = 'FAILED';
      });
  },
});

export const { clearAddProviderCarriersResponse } = addProviderCarriersSlice.actions;

export const addProviderCarriersSelector = (state: RootState) => state.AddProviderCarriers;

export default addProviderCarriersSlice.reducer;
