import { useEffect, useState } from 'react';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { DashboardBankReconciliationReportHeader } from '../../../components/reports/DashboardBankReconciliationReportHeader';
import { DenialReportHeader } from '../../../components/billing/denialReport/DenialReportHeader';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import {
  getSystemInsuranceRequest,
  getSystemInsuranceSelector,
} from '../../../redux/slices/system/getSystemInsuranceSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../../redux/slices/system/getSystemProvidersSlice';

import {
  getDenialReportsRequest,
  getDenialReportsSelector,
  clearDenialReportsResponse,
} from '../../../redux/slices/payments/denialReports/getDenialReportsSlice';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Patient',
    dataIndex: 'patient',
    key: 'patient',
  },
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
  },
  {
    title: 'Claim ID',
    dataIndex: 'claimId',
    key: 'claimId',
  },
  {
    title: 'Attending Provider',
    dataIndex: 'attendingProvider',
    key: 'attendingProvider',
  },
  {
    title: 'Insurance',
    dataIndex: 'insurance',
    key: 'insurance',
  },
  {
    title: 'Remark Code',
    dataIndex: 'remarkCode',
    key: 'remarkCode',
  },
  {
    title: 'Denial Code',
    dataIndex: 'denialCode',
    key: 'denialCode',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Payment Date',
    dataIndex: 'paymentDate',
    key: 'paymentDate',
  },
];

const dummyDataSource = [
  {
    patient: 'John Doe',
    visitId: 'V123456',
    visitDate: '2024-05-01',
    claimId: 'C123456',
    attendingProvider: 'Dr. Smith',
    insurance: 'Insurance A',
    remarkCode: 'R001',
    denialCode: 'D001',
    description: 'Routine check-up',
    paymentDate: '2024-05-05',
  },
];

const reportFilters = {
  denialCode: undefined,
  remarkCode: undefined,
  provider: undefined,
  insurance: undefined,
};

export const denialCodes = [{ id: 'Select Denial Code', name: 'Select Denial Code' }];
export const remarkCodes = [{ id: 'Select Remark Code', name: 'Select Remark Code' }];

export const providers = [{ id: 'Select Provider / PA', name: 'Select Provider / PA' }];

export const insurances = [{ id: 'Select Insurance', name: 'Select Insurance' }];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'denailReport', label: 'Denial Reports', status: 'active', link: 'denial-reports' },
];

export const DenialPage = () => {
  const dispatch = useAppDispatch();
  const { systemInsuranceStatus, systemInsuranceInsuranceData } = useAppSelector(getSystemInsuranceSelector);
  const { systemProvidersStatus, systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);
  const { fetchDenialReportsStatus, fetchDenialReportsData } = useAppSelector(getDenialReportsSelector);

  const [dataSource, setDataSource] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  // const [dataSource, setDataSource] = useState<any>([]);

  useEffect(() => {
    // fetchDenialReports();
    if (systemInsuranceInsuranceData?.length === 0 || systemProvidersProviderData?.length === 0) {
      fetchInsurance();
      fetchSystemProviders();
    }
  }, []);

  useEffect(() => {
    if (fetchDenialReportsStatus === 'SUCCESS') {
      setDataSource(fetchDenialReportsData?.items);
      setCurrentPage(fetchDenialReportsData?.currentPage);
      setTotalPages(fetchDenialReportsData?.totalPages);
    }
  }, [fetchDenialReportsStatus]);

  const fetchDenialReports = async (pageSize = PAGE_SIZE, pageNumber = currentPage) => {
    // dispatch(getDenialReportsRequest({ size: pageSize, page: pageNumber }));
  };

  const fetchInsurance = async () => {
    dispatch(getSystemInsuranceRequest());
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Denial Reports" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <DenialReportHeader
        reportFilters={reportFilters}
        denialCodes={denialCodes}
        remarkCodes={remarkCodes}
        providers={systemProvidersProviderData}
        insurances={systemInsuranceInsuranceData}
      />
      <div className="flex justify-end">
        <TableSearch />
      </div>
      {dummyDataSource?.length > 0 ? (
        <div className="overflow-x-auto">
          <TableContent enableActions={false} columns={columns} dataSource={dummyDataSource} />
        </div>
      ) : (
        <EmptyContent enableCreateButton={false} />
      )}

      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      ) : (
        <></>
      )}
    </div>
  );
};
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
