import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { EditClaimInitialInterface, EditClaimInterface } from '../../../interfaces/billingInterface';
import { editClaimAPIRequest } from '../../../api/billing/claims';

const initialState: EditClaimInitialInterface = {
  claimEditLoading: false,
  claimEditData: {},
  claimEditError: null,
  claimEditStatus: 'IDLE',
};

export const editClaimRequest: any = createAsyncThunk(
  'edit/claim/billing',
  async (claimData: EditClaimInterface, thunkAPI: any) => {
    try {
      const response: any = await editClaimAPIRequest(claimData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const editClaimSlice = createSlice({
  name: 'editClaim',
  initialState,
  reducers: {
    clearEditClaimResponse: (state) => {
      state.claimEditLoading = false;
      state.claimEditStatus = 'IDLE';
      state.claimEditData = {};
      state.claimEditError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editClaimRequest.pending, (state, action) => {
        state.claimEditLoading = true;
        state.claimEditStatus = 'PENDING';
      })
      .addCase(editClaimRequest.fulfilled, (state, action) => {
        state.claimEditLoading = false;
        state.claimEditData = action.payload;
        state.claimEditStatus = 'SUCCESS';
      })
      .addCase(editClaimRequest.rejected, (state, action) => {
        state.claimEditLoading = false;
        state.claimEditError = action.payload;
        state.claimEditStatus = 'FAILED';
      });
  },
});

export const { clearEditClaimResponse } = editClaimSlice.actions;

export const editClaimSelector = (state: RootState) => state.EditClaim;

export default editClaimSlice.reducer;
