import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FacilityFetchInitialInterface } from '../../../interfaces/systemInterfaces';
import { getSystemFacilitiesAPIRequest } from '../../../api/system/facility';

const initialState: FacilityFetchInitialInterface = {
  systemFacilityLoading: false,
  systemFacilityData: [],
  systemFacilityError: null,
  systemFacilityStatus: 'IDLE',
};

export const getSystemFacilityRequest: any = createAsyncThunk('system/facilities/get', async (thunkAPI: any) => {
  try {
    const response: any = await getSystemFacilitiesAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getSystemFacilitiesSlice = createSlice({
  name: 'fetchSystemFacilities',
  initialState,
  reducers: {
    clearFetchSystemFacilitiesResponse: (state) => {
      state.systemFacilityLoading = false;
      state.systemFacilityStatus = 'IDLE';
      state.systemFacilityData = [];
      state.systemFacilityError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemFacilityRequest.pending, (state, action) => {
        state.systemFacilityLoading = true;
        state.systemFacilityStatus = 'PENDING';
      })
      .addCase(getSystemFacilityRequest.fulfilled, (state, action) => {
        // console.log('=== fac - system action.payload ===', action.payload);

        state.systemFacilityLoading = false;
        state.systemFacilityData = action.payload;
        state.systemFacilityStatus = 'SUCCESS';
      })
      .addCase(getSystemFacilityRequest.rejected, (state, action) => {
        state.systemFacilityLoading = false;
        state.systemFacilityError = action.payload;
        state.systemFacilityStatus = 'FAILED';
      });
  },
});

export const { clearFetchSystemFacilitiesResponse } = getSystemFacilitiesSlice.actions;

export const getSystemFacilitiesSelector = (state: RootState) => state.GetSystemFacilities;

export default getSystemFacilitiesSlice.reducer;
