import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientFectchByIdDemoInitialPropsInterface,
  PatientFectchByIdInitialPropsInterface,
  PatientFetchByIdInterface,
  PatientFetchInitialInterface,
  PatientFetchInterface,
} from '../../../interfaces/patientInterface';
import {
  getPatientByIdAPIRequest,
  getPatientByIdForDemoAPIRequest,
  getPatientsAPIRequest,
} from '../../../api/patient/patient';
import { handlePatientInsurance } from '../../../utils/commonFunctions';

const initialState: PatientFectchByIdDemoInitialPropsInterface = {
  fetchPatientByIdDemoLoading: false,
  fetchPatientByIdDemoData: {},
  fetchPatientByIdDemoStatus: 'IDLE',
  fetchPatientByIdDemoError: null,
};

export const getPatientByIdDemoRequest: any = createAsyncThunk(
  'patients/get/id',
  async (searchParams: PatientFetchByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getPatientByIdForDemoAPIRequest(searchParams);
      const handledResponse = handlePatientInsurance(response);
      return handledResponse;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchPatientByIdDemoSlice = createSlice({
  name: 'fetchPatientByIdDemo',
  initialState,
  reducers: {
    clearFetchPatientByIdDemoResponse: (state) => {
      state.fetchPatientByIdDemoLoading = false;
      state.fetchPatientByIdDemoData = {};
      state.fetchPatientByIdDemoStatus = 'IDLE';
      state.fetchPatientByIdDemoError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientByIdDemoRequest.pending, (state, action) => {
        state.fetchPatientByIdDemoLoading = true;
        state.fetchPatientByIdDemoStatus = 'PENDING';
      })
      .addCase(getPatientByIdDemoRequest.fulfilled, (state, action) => {
        state.fetchPatientByIdDemoLoading = false;
        state.fetchPatientByIdDemoStatus = 'SUCCESS';
        state.fetchPatientByIdDemoData = action.payload;
      })
      .addCase(getPatientByIdDemoRequest.rejected, (state, action) => {
        state.fetchPatientByIdDemoLoading = false;
        state.fetchPatientByIdDemoError = action.payload;
        state.fetchPatientByIdDemoStatus = 'FAILED';
      });
  },
});

export const { clearFetchPatientByIdDemoResponse } = fetchPatientByIdDemoSlice.actions;

export const getPatientsByIdDemoSelector = (state: RootState) => state.FetchPatientByIdDemo;

export default fetchPatientByIdDemoSlice.reducer;
