import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { TableContent } from '../../components/table/TableContent';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Checkbox } from '../../components/atoms/Checkbox';
import { PatientStatementsHeader } from '../../components/billing/patientStatements/PatientStatementsHeader';

import { patientStatementsStatus } from '../../constants/billingConstants';

const columns = [
  {
    title: 'Patients ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Patient Responsible',
    dataIndex: 'patientResponsible',
    key: 'patientResponsible',
  },
  {
    title: ' Carrier',
    dataIndex: 'carrier',
    key: 'carrier',
  },
  {
    title: 'Last Payment Date',
    dataIndex: 'lastPaymentDate',
    key: 'lastPaymentDate',
  },
  {
    title: 'Last Payment Amount',
    dataIndex: 'lastPaymentAmount',
    key: 'lastPaymentAmount',
  },
  {
    title: 'Last Visit Date',
    dataIndex: 'lastVisitDate',
    key: 'lastVisitDate',
  },
  {
    title: ' Sent Count',
    dataIndex: 'sentCount',
    key: 'sentCount',
  },
  {
    title: 'Last stmt sent date',
    dataIndex: 'lastStmtDate',
    key: 'lastStmtDate',
  },
  {
    title: (
      <div className="flex items-center space-x-2">
        <span>Select All</span>
        <Checkbox />
      </div>
    ),
  },
];
const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'billingPatientStatements',
    label: 'Patient Statement Management',
    status: 'active',
    link: 'patient-statements',
  },
];

export const PatientsStatements = () => {
  const [filterObject, setFilterObject] = useState<any>({});

  useEffect(() => {
    setFilterObject({});
  }, []);

  const onChangeFilter = (e: any) => {
    e.preventDefault();
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSearch = () => {
    const searchObj = filterObject;
    if (searchObj?.patientId) {
      searchObj.patientId = parseInt(searchObj.patientId, 10);
    }

    if (searchObj?.status && searchObj?.status === 'ALL') {
      searchObj.status = null;
    }
  };

  return (
    <>
      <div className="main-content">
        <MainHeader />
        <PageTopic mainTitle="Patient Statement Management" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
        <PatientStatementsHeader
          statusList={patientStatementsStatus}
          onChange={onChangeFilter}
          onSubmit={handleSearch}
        />

        <div className="overflow-x-auto mt-2">
          <TableContent columns={columns} enableActions={true} />
        </div>
      </div>
    </>
  );
};
