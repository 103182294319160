import { useEffect, useState } from 'react';
import { CommonAlert } from '../../../atoms/Alert';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { RightSheet } from '../../../rightSheet/RightSheet';
import { useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { getProviderByIdSelector } from '../../../../redux/slices/provider/getProviderByIdSlice';
import { useAppDispatch } from '../../../../redux/store';
import { Spinner } from '../../../atoms/Spinner';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import { ProviderFacilitiesAssignModal } from './ProviderFacilitiesAssignmodal';
import { ProviderFacilitiesEditModal } from './ProviderFacilitiesEditModal';
import { ProviderFacilityInterface } from '../../../../interfaces/providersInterface';
import {
  addProviderFacilitiesSelector,
  clearAddProviderFacilitiesResponse,
} from '../../../../redux/slices/provider/assignProviderFacilitySlice';
import {
  deleteProviderFacilitiesByIdRequest,
  deleteProviderFacilitiesByIdSelector,
  clearDeleteProviderFacilitiesByIdResponse,
} from '../../../../redux/slices/provider/deleteProviderFacilitySlice';
import { getProviderFacilitiesByIdSelector } from '../../../../redux/slices/provider/getProviderFacilityByIdSlice';
import { ProviderFacilitiesForm } from './ProviderFacilitiesForm';
import {
  getFacilitiesSelector,
  getFacilitySearchRequest,
  clearFetchFacilitiesResponse,
} from '../../../../redux/slices/provider/getSearchFacilitiesSlice';
import moment from 'moment';

const columns = [
  {
    title: 'Hospital/Group Practice',
    dataIndex: 'hospitalGroupPractice',
    key: 'hospitalGroupPractice',
    render: (text: any, record: any) => {
      const isEndDateValid = moment(record?.endDate).isValid();

      if (!isEndDateValid) {
        return record?.facilityItem?.name || '';
      }

      const isExpired = new Date(record?.endDate) < new Date();

      return (
        <span>
          {isExpired ? (
            <span className="line-through text-red-600">
              <span className="text-gray-500">{record?.facilityItem?.name}</span>
            </span>
          ) : (
            record?.facilityItem?.name
          )}
          {isExpired && <span className="ml-2 text-gray-400">Expired</span>}
        </span>
      );
    },
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    render: (text: any) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    render: (text: any) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
];
export const ProviderFacilities: React.FC<ProviderFacilityInterface> = ({
  selectedId,
  mode,
  addDataLoading,
  fetchDataLoading,
  fetchProviderById,
}) => {
  const dispatch = useAppDispatch();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModaAssign, setVisibleModalAssign] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [editFacilityFormData, setEditFacilityFormData] = useState<any>({});
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });
  const [dataSource, setDataSource] = useState<any>([]);

  const [fetchLoading, setFetchLoading] = useState<any>([]);

  const [errorObj, setErrorObj] = useState<any>({});
  const [facilitiesMode, setFacilitiesMode] = useState<any>('CREATE');
  const [selectedFacilitiesId, setSelectedFacilitiesId] = useState<number>();
  const { fetchProviderByIdStatus, fetchProviderByIdData } = useAppSelector(getProviderByIdSelector);
  const { addProviderFacilitiesStatus, addProviderFacilitiesLoading } = useAppSelector(addProviderFacilitiesSelector);

  const [searchText, setSearchText] = useState('');
  const PAGE_SIZE = 10;

  const { facilityStatus, facilityData, facilityLoading } = useAppSelector(getFacilitiesSelector);
  const [dataSourceSearch, setDataSourceSearch] = useState<any>([]);
  const [currentPageSearch, setCurrentPageSearch] = useState(0);
  const [totalPagesSearch, setTotalPagesSearch] = useState(0);

  useEffect(() => {
    if (facilityStatus === 'SUCCESS' && !facilityLoading) {
      setDataSourceSearch(facilityData?.items);
      setCurrentPageSearch(facilityData?.currentPage);
      setTotalPagesSearch(facilityData?.totalPages);
    }
  }, [facilityStatus]);

  const { deleteProviderFacilitiesByIdStatus } = useAppSelector(deleteProviderFacilitiesByIdSelector);

  const { fetchProviderFacilitiesByIdData, fetchProviderFacilitiesByIdStatus } = useAppSelector(
    getProviderFacilitiesByIdSelector
  );

  useEffect(() => {
    setFetchLoading(fetchDataLoading);
  }, [fetchDataLoading]);

  useEffect(() => {
    if (fetchProviderByIdStatus === 'SUCCESS') {
      setDataSource(fetchProviderByIdData);
      // setVisibleModal(false);
    }
  }, [fetchProviderByIdStatus, selectedId]);

  useEffect(() => {
    if (deleteProviderFacilitiesByIdStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'facility');
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearDeleteProviderFacilitiesByIdResponse());
    } else if (deleteProviderFacilitiesByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearDeleteProviderFacilitiesByIdResponse());
    }
  }, [deleteProviderFacilitiesByIdStatus]);

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setFacilitiesMode('EDIT');
    setSelectedFacilitiesId(dataSet?.id);
    let obj = {
      facilityItem: dataSet?.facilityItem,
      startDate: dataSet?.startDate,
      endDate: dataSet?.endDate,
    };
    setEditFacilityFormData(obj);
    setVisibleEditModal(true);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const onDelete = (event: any, rowIndex: any) => {
    const dataSet = dataSource[rowIndex];
    setSelectedFacilitiesId(dataSet?.id);
    dispatch(
      deleteProviderFacilitiesByIdRequest({
        providerId: selectedId,
        facilitiesId: dataSet?.id,
      })
    );
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleAddNewModal = () => {
    setFacilitiesMode('CREATE');
    setVisibleModal(true);
    setErrorObj({});
  };

  const onCloseModal = () => {
    setFacilitiesMode('CREATE');
    setVisibleModal(false);
    setErrorObj({});
    setDataSourceSearch({});
    dispatch(clearFetchFacilitiesResponse());
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setVisibleAlert(false);
  };

  const onCloseEditFacilityModal = () => {
    setVisibleEditModal(false);
    setFacilitiesMode('CREATE');
    setEditFacilityFormData({});
    setDataSourceSearch({});
    dispatch(clearFetchFacilitiesResponse());
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setVisibleAlert(false);
  };

  const onChangeFacilityEditForm = (data: any) => {
    setEditFacilityFormData((prev: any) => ({ ...prev, facilityItem: { id: data?.id, name: data?.name } }));
  };

  const onChangeFacilityEditDateChange = (name: string, date: Date) => {
    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }
    setEditFacilityFormData((prev: any) => ({
      ...prev,
      [name]: moment(date).format('YYYY-MM-DD'),
    }));
  };

  return (
    <>
      {visibleAlert && <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />}
      <div className="w-64 ml-auto">
        {mode !== 'VIEW' && (
          <CommonButton
            label="Assign Facility"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            onClick={handleAddNewModal}
          />
        )}
      </div>

      {fetchLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <TableContent
              enableActions={mode === 'VIEW' ? false : true}
              columns={columns}
              dataSource={dataSource}
              enableDelete={true}
              enableEdit={true}
              enableView={false}
              onDelete={onDelete}
              onEdit={onEdit}
              onView={onView}
            />
          ) : (
            <EmptyContent onClick={handleAddNewModal} enableCreateButton={mode === 'VIEW' ? false : true} mode={mode} />
          )}
        </>
      )}

      {visibleModal && (
        <ProviderFacilitiesAssignModal
          isFacilityAssignModalVisible={visibleModal}
          onCloseModal={onCloseModal}
          dataSource={dataSourceSearch}
          facilityData={facilityData}
          dataLoading={facilityLoading}
          facilityDataFetchStatus={facilityStatus}
          providerId={selectedId}
          fetchProviderById={fetchProviderById}
        />
      )}

      {visibleEditModal && (
        <ProviderFacilitiesEditModal
          editfacilityData={editFacilityFormData}
          isFacilityEditModalVisible={visibleEditModal}
          onCloseEditModal={onCloseEditFacilityModal}
          onChangeFacilityEdit={onChangeFacilityEditForm}
          onChangeFacilityEditDate={onChangeFacilityEditDateChange}
          assignFacilityDataSource={dataSourceSearch}
          facilityData={facilityData}
          dataLoading={facilityLoading}
          facilityDataFetchStatus={facilityStatus}
          providerId={selectedId}
          facilitiesMode={facilitiesMode}
          selectedFacilityId={selectedFacilitiesId}
          fetchProviderById={fetchProviderById}
        />
      )}
    </>
  );
};
