import { userRoles, userStatus } from '../../../constants/userConstants';
import { UserFormPropsInterface } from '../../../interfaces/userInterface';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';
import { UploadInput } from '../../atoms/UploadInput';

export const RightSheetForm: React.FC<UserFormPropsInterface> = ({
  onChange,
  personalData,
  mode,
  selectedId,
  errorObject,
}) => {
  // console.log('=== personalData ===', personalData);

  return (
    <>
      <>
        {mode === 'CREATE' && <h6 className="sub-heading">Personal Details</h6>}
        <hr />
        <div className="grid md:grid-cols-3 gap-4 mt-6">
          <InputField
            label="First Name"
            name="firstName"
            onChange={onChange}
            defaultValue={personalData?.firstName ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            required={true}
            error={errorObject && errorObject?.firstName}
            errorMessage={errorObject && errorObject?.firstName}
          />
          <InputField
            label="Middle Name"
            name="middleName"
            onChange={onChange}
            defaultValue={personalData?.middleName ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            // error={errorObject && errorObject?.firstName}
            // errorMessage={errorObject && errorObject?.firstName}
          />
          <InputField
            label="Last Name"
            name="lastName"
            onChange={onChange}
            disabled={mode === 'VIEW' ? true : false}
            defaultValue={personalData?.lastName ?? undefined}
            required={true}
            error={errorObject && errorObject?.lastName}
            errorMessage={errorObject && errorObject?.lastName}
          />
        </div>
        <div className="grid grid-cols-3 gap-4 mt-6">
          <div className="col-span-2">
            <UploadInput disabled={mode === 'VIEW' || mode === 'EDIT' ? true : false} />
          </div>
          <div className="col-span-1">
            <InputField
              label="Email"
              name="email"
              onChange={onChange}
              disabled={mode === 'VIEW' || mode === 'EDIT' ? true : false}
              defaultValue={personalData?.email ?? undefined}
              required={true}
              error={errorObject && errorObject?.email}
              errorMessage={errorObject && errorObject?.email}
            />
          </div>
        </div>
        <h6 className="sub-heading mt-6">Account Details</h6>
        <hr />
        <div className="grid md:grid-cols-3 gap-4 mt-6">
          <InputField
            type="password"
            label="Password"
            name="password"
            onChange={onChange}
            disabled={mode === 'VIEW' || mode === 'EDIT' ? true : false}
            required={mode === 'CREATE'}
            error={errorObject && errorObject?.password}
            errorMessage={errorObject && errorObject?.password}
            tooltip={
              <div>
                Password must include:
                <br />
                - Minimum of 8 characters
                <br />
                - At least one uppercase letter
                <br />
                - At least one number
                <br />- At least one symbol
              </div>
            }
          />
          <InputField
            type="password"
            label="Confirm Password"
            name="confPassword"
            onChange={onChange}
            disabled={mode === 'VIEW' || mode === 'EDIT' ? true : false}
            required={mode === 'CREATE'}
            error={errorObject && errorObject?.confPassword}
            errorMessage={errorObject && errorObject?.confPassword}
          />
          <SelectInput
            label="User Role"
            options={userRoles}
            enableDefaultPlaceholder={true}
            name="roleId"
            onChange={onChange}
            required={mode === 'CREATE'}
            defaultValue={personalData?.roleId ? personalData?.roleId.toString() : undefined}
            disabled={mode === 'VIEW' || mode === 'EDIT' ? true : false}
          />
          <SelectInput
            label="Status"
            options={userStatus}
            enableDefaultPlaceholder={true}
            name="status"
            onChange={onChange}
            required={true}
            disabled={mode === 'VIEW' ? true : false}
            defaultValue={personalData?.status ?? undefined}
          />
        </div>
      </>
    </>
  );
};
