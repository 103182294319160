export const modifierValidation = (formData: { code: string; desc: string }) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.code?.trim()) {
    newErrors.code = 'Code is required';
    isValid = false;
  }

  if (formData?.code !== null && formData?.code?.length > 2) {
    newErrors.code = 'Code should not exceed 2 characters';
    isValid = false;
  }

  if (!formData?.desc?.trim()) {
    newErrors.desc = 'Description is required';
    isValid = false;
  }

  return { newErrors, isValid };
};

export const icdValidation = (formData: { code: string; shortDesc: string }) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.code?.trim()) {
    newErrors.code = 'Code is required';
    isValid = false;
  }

  if (!formData?.shortDesc?.trim()) {
    newErrors.shortDesc = 'Short Description is required';
    isValid = false;
  }

  return { newErrors, isValid };
};

export const cptValidation = (formData: {
  code: string;
  shortDesc: string;
  amount: any;
  rvuWeight: any;
  pos: any;
  tos: any;
}) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.code?.trim()) {
    newErrors.code = 'Code is required';
    isValid = false;
  }

  if (!formData?.shortDesc?.trim()) {
    newErrors.shortDesc = 'Short Description is required';
    isValid = false;
  }

  if (!formData?.amount) {
    newErrors.amount = 'Amount is required';
    isValid = false;
  }

  if (formData?.amount !== null && isNaN(Number(formData?.amount))) {
    newErrors.amount = 'Amount should only be numbers';
    isValid = false;
  }

  if (formData?.rvuWeight !== null && isNaN(Number(formData?.rvuWeight))) {
    newErrors.rvuWeight = 'Work RVU should only be numbers';
    isValid = false;
  }

  if (formData?.pos !== null && formData?.pos?.length > 2) {
    newErrors.pos = 'POS should not exceed 2 characters';
    isValid = false;
  }

  if (formData?.tos !== null && formData?.tos?.length > 2) {
    newErrors.tos = 'TOS should not exceed 2 characters';
    isValid = false;
  }

  return { newErrors, isValid };
};

export const feeAmountValidation = (formData: { locality: string }) => {
  let isValid = true;
  const newErrors: any = {};

  if (formData?.locality !== null && formData?.locality?.length > 2) {
    newErrors.locality = 'Locality should not exceed 2 characters';
    isValid = false;
  }

  return { newErrors, isValid };
};
