import { FetchInsuranceInterface } from '../../interfaces/insuranceInterface';
import { AddInsuranceInterface } from '../../interfaces/systemInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';

export const addInsuranceAPIRequest = async (insuranceData: AddInsuranceInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/insurance/`, 'post', {
    data: { ...insuranceData },
  });
};

export const getInsuranceAPIRequest = async (insuranceData: FetchInsuranceInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/insurance/search?page=${insuranceData?.page}&size=${insuranceData?.size}`,
    'get',
    {
      data: {},
    }
  );
};
