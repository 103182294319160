import { batchType } from '../../../constants/billingConstants';
import { Calendar } from '../../atoms/Calendar';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';
import { DatePickerField } from '../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../utils/commonFunctions';

export const CreateBatchPayment = ({ onChange, onChangeDate, createFormData, mode }: any) => {
  return (
    <>
      <div className="grid md:grid-cols-3 gap-4 mt-6 mb-10">
        <SelectInput
          label={'Batch Type'}
          name="batchType"
          onChange={onChange}
          options={batchType}
          required={true}
          defaultValue={createFormData?.batchType ?? undefined}
        />
        {/* <Calendar
          onSelectedDateChanged={(date) => onChangeDate(date, 'checkDate')}
          name="checkDate"
          label="Check Date"
          required={false}
          defaultDate={createFormData?.checkDate ? new Date(createFormData?.checkDate) : undefined}
        /> */}
        <DatePickerField
          label="Check Date"
          name="checkDate"
          selectedDate={formatDatePickerDate(createFormData?.checkDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onChangeDate(date, 'checkDate');
            }
          }}
        />
        <InputField label="Check Number" name="checkNumber" onChange={onChange} />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6 mb-10">
        <InputField
          label="Description"
          name="description"
          onChange={onChange}
          defaultValue={createFormData?.description ?? undefined}
        />
        <InputField
          type="number"
          label="Payment Amount"
          name="amount"
          onChange={onChange}
          defaultValue={createFormData?.amount ?? undefined}
        />
        {/* <Calendar
          onSelectedDateChanged={(date) => onChangeDate(date, 'postingDate')}
          name="postingDate"
          label="Bank Posting Date"
          required={false}
          defaultDate={createFormData?.postingDate ? new Date(createFormData?.postingDate) : undefined}
        /> */}
        <DatePickerField
          label="Bank Posting Date"
          name="postingDate"
          selectedDate={formatDatePickerDate(createFormData?.postingDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onChangeDate(date, 'postingDate');
            }
          }}
        />
      </div>
    </>
  );
};
