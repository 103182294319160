import { useEffect, useState } from 'react';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { RightSheet } from '../../../components/rightSheet/RightSheet';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
// import {
//   addPaymentCodeRequest,
//   addPaymentCodeSelectore,
//   clearAddCodeResponse,
// } from "../../redux/slices/code/addPaymentCodeSlice";
// import {
//   getPaymentCodeSelector,
//   getPaymentCodesRequest,
// } from "../../redux/slices/code/getPaymentCodeSlice";
import { CommonAlert } from '../../../components/atoms/Alert';
import { RightSheetLarge } from '../../../components/rightSheet/RightSheetLarge';
import { AddressList } from '../../../components/account/users/AddressList';
// import {
//   editPaymentCodeSelector,
//   updatePaymentCodeRequest,
// } from "../../redux/slices/code/updatePaymentCodeSlice";
// import {
//   getPaymentCodeByIdRequest,
//   getPaymentCodeByIdSelector,
// } from "../../redux/slices/code/getPaymentCodeByIdSlice";
import { Spinner } from '../../../components/atoms/Spinner';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { userValidation } from '../../../utils/userValidation';
import { PaymentRightSheetForm } from '../../../components/account/codes/PaymentRightSheetForm';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Code',
    dataIndex: 'paymentCode',
    key: 'paymentCode',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

const dataSourceDummy = [
  {
    paymentCode: '99304',
    type: 'Comment',
    description: 'Zero Payment Entry',
    active: true,
    displayStatement: true,
  },
];

export const types = [{ id: 'comment', name: 'Comment' }];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'paymentAdjustment', label: 'Payment / Adjustment Code', status: 'active', link: 'payment-codes' },
];

export const PaymentCodesPage = () => {
  const dispatch = useAppDispatch();
  //const { status, codeData, loading } = useAppSelector(getPaymentCodeSelector);
  //  const { addPaymentCodeStatus, addPaymentCodeLoading } = useAppSelector(addPaymentCodeSelectore);
  //const { editPaymentCodeStatus, editPaymentCodeLoading } = useAppSelector(editPaymentCodeSelector);
  //const { codeByIdStatus, codeByIdPaymentCodeData } =
  // useAppSelector(getPaymentCodeByIdSelector);
  const [visibleModal, setVisibleModal] = useState(false);
  const [onFormChange, setOnFormChange] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [visibleEditForm, setVisibleEditForm] = useState(false);
  const [selectedChild, setSelectedChild] = useState(1);
  const [mode, setMode] = useState('CREATE');
  const [errorObj, setErrorObj] = useState<any>({});

  // console.log('=== mode ===', mode);

  useEffect(() => {
    //fetchCodes();
    setDataSource(dataSourceDummy);
  }, []);

  // useEffect(() => {
  //   if (status === "SUCCESS" && !loading) {
  //     setDataSource(codeData?.items);
  //   }
  // }, [status]);

  // useEffect(() => {
  //   if (addPaymentCodeStatus === "SUCCESS" && !addPaymentCodeLoading) {
  //     setAlertObj({
  //       color: "success",
  //       message: "Successfully created !",
  //       error: false,
  //     });
  //     setVisibleAlert(true);
  //     dispatch(getPaymentCodesRequest({ page: currentPage, size: PAGE_SIZE }));
  //     setVisibleModal(false);
  //     setTimeout(() => {
  //       setVisibleAlert(false);
  //       setAlertObj({
  //         color: "",
  //         message: "",
  //         error: false,
  //       });
  //     }, 3000);
  //   } else if (addPaymentCodesStatus === "FAILED") {
  //     setAlertObj({
  //       color: "failure",
  //       message: "Something went wrong !",
  //       error: true,
  //     });
  //     setVisibleAlert(true);
  //     setTimeout(() => {
  //       setVisibleAlert(false);
  //       setAlertObj({
  //         color: "",
  //         message: "",
  //         error: false,
  //       });
  //     }, 3000);
  //   }

  //   dispatch(clearAddPaymentCodeResponse());
  // }, [addPaymentCodeStatus]);

  // useEffect(() => {
  //   if (editPaymentCodeStatus === "SUCCESS" && !editPaymentCodeLoading) {
  //     setAlertObj({
  //       color: "success",
  //       message: "Successfully updated !",
  //       error: false,
  //     });
  //     setVisibleAlert(true);
  //     dispatch(getPaymentCodesRequest({ page: currentPage, size: PAGE_SIZE }));
  //     setVisibleModal(false);
  //     setTimeout(() => {
  //       setVisibleAlert(false);
  //       setAlertObj({
  //         color: "",
  //         message: "",
  //         error: false,
  //       });
  //     }, 3000);
  //   } else if (editPaymentCodeStatus === "FAILED") {
  //     setAlertObj({
  //       color: "failure",
  //       message: "Something went wrong !",
  //       error: true,
  //     });
  //     setVisibleAlert(true);
  //     setVisibleModal(false);
  //     setTimeout(() => {
  //       setVisibleAlert(false);
  //       setAlertObj({
  //         color: "",
  //         message: "",
  //         error: false,
  //       });
  //     }, 3000);
  //   }

  //   dispatch(clearAddPaymentCodeResponse());
  // }, [editPaymentCodeStatus]);

  // useEffect(() => {
  //   if (codeByIdStatus === "SUCCESS") {
  //     setSelectedToEdit(codeByIdPaymentCodeData);
  //   }
  // }, [codeByIdStatus]);

  // const fetchPaymentCodes = async () => {
  //   dispatch(getPaymentCodesRequest({ page: currentPage, size: PAGE_SIZE }));
  // };

  // const fetchPaymentCodeById = async (id: number) => {
  //   dispatch(getPaymentCodeByIdRequest({ codeId: id }));
  // };

  const handleModalVisible = () => {
    setMode('CREATE');
    setVisibleModal(true);
  };

  const handleOnClose = () => {
    setVisibleModal(false);
    setVisibleEditForm(false);
    setMode('CREATE');
  };

  const handleFormChanges = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setOnFormChange((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  // console.log('=== onFormChange ===', onFormChange);

  const onSubmit = () => {
    if (selectedToEdit) {
      onFormChange['id'] = selectedToEdit?.id;
    }

    const validationDetails = userValidation(onFormChange);

    if (Object.keys(validationDetails?.newErrors)?.length > 0) {
      setErrorObj(validationDetails?.newErrors);
    } else {
      // mode === "CREATE"
      //   ? dispatch(addPaymentCodeRequest(onFormChange))
      //   : dispatch(updatePaymentCodeRequest(onFormChange));
    }
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    setMode('EDIT');
    setSelectedToEdit(dataSet);
    setOnFormChange(dataSet);
    setVisibleEditForm(true);
    setSelectedChild(1);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    setMode('VIEW');
    const dataSet = dataSource[rowIndex];
    // console.log('=== view code ===', dataSet);
    setSelectedToEdit(dataSet);
    //fetchCodeById(dataSet?.id);
    setVisibleEditForm(true);
    setSelectedChild(1);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleClickStepper = (id: any) => {
    setSelectedChild(id);
  };

  const renderTab = () => {
    switch (selectedChild) {
      case 1:
        return (
          <PaymentRightSheetForm
            codeData={selectedToEdit}
            mode={mode}
            selectedId={selectedToEdit?.id}
            onChange={handleFormChanges}
            errorObject={errorObj}
            types={types}
          />
        );
      case 2:
        return <AddressList selectedId={selectedToEdit?.id} mode={mode} />;
      default:
        break;
    }
  };

  // console.log('=== addPaymentCodeLoading ===', addPaymentCodeLoading);

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic
        onClick={handleModalVisible}
        mainTitle="Payment/Adjustment Code"
        enableOptionalButton={true}
        optionalButtonTitle="Export"
        breadCrumbArr={breadCrumbArr}
      />
      <TableSearch />
      {
        //loading
        false ? (
          <Spinner />
        ) : (
          <>
            {dataSource?.length > 0 ? (
              <>
                <TableContent
                  enableActions={true}
                  columns={columns}
                  dataSource={dataSource}
                  enableDelete={false}
                  enableEdit={true}
                  enableView={false}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onView={onView}
                />
                <Pagination />
              </>
            ) : (
              <EmptyContent />
            )}
          </>
        )
      }

      {visibleModal ? (
        <RightSheet
          onClose={handleOnClose}
          title="Create Payment Payment Code"
          submitButtonTitle="Create"
          cancelButtonTitle="Cancel"
          onSubmit={onSubmit}
          children={
            <PaymentRightSheetForm onChange={handleFormChanges} mode={mode} errorObject={errorObj} types={types} />
          }
          // loading={addPaymentCodeLoading}
          enableAlert={visibleAlert}
          alertDetails={alertObj}
        />
      ) : (
        <></>
      )}
      {visibleEditForm ? (
        <RightSheetLarge
          title={mode === 'EDIT' ? 'Update Payment Code' : 'View Payment Code'}
          onSubmit={onSubmit}
          onClose={handleOnClose}
          submitButtonTitle="Update"
          cancelButtonTitle="Cancel"
          enableStepper={false}
          clickStepper={handleClickStepper}
          enableFooterButtons={selectedChild == 1 ? true : false}
          children={<>{renderTab()}</>}
          // loading={editPaymentCodeLoading}
        />
      ) : (
        <></>
      )}
      {/* <RightSheet /> */}
    </div>
  );
};
