import { Button, Modal } from 'flowbite-react';
import { PatientVisitEIViewProps } from '../../../../interfaces/patientInterface';

export const ViewEligibilityInquiry: React.FC<PatientVisitEIViewProps> = ({
  openModal = false,
  setOpenModal,
  details = {},
}) => {
  return (
    <Modal show={openModal} onClose={() => setOpenModal(false)}>
      <Modal.Header>View Eligibility</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <div
              style={{
                borderRadius: '5px',
                border: '1px solid',
                padding: '5px',
                background: 'rgb(13,200,222)',
                color: 'white',
              }}
            >
              <div>{details?.mainObject?.insuranceName}</div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">Insured or Subscriber</div>
                <div className="">{details?.mainObject?.insured}</div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>Policy #</div>
                <div className="">{details?.mainObject?.policyNo}</div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>Group Name</div>
                <div className="">{details?.mainObject?.groupName}</div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>Group #</div>
                <div className="">{details?.mainObject?.groupNo}</div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>Insured DOB</div>
                <div className="">{details?.mainObject?.insuredDOB}</div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>Insured Gender</div>
                <div className="">{details?.mainObject?.insuredGender}</div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>Insured Address</div>
                <div className="">
                  {details?.mainObject?.insuredAddressPOBox} <div>{details?.mainObject?.insuredAddressPostalCode}</div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>Eligibility Begin</div>
                <div className="">{details?.mainObject?.eligibilityBegin}</div>
              </div>
            </div>
          </p>
          {details?.detailList?.length > 0 ? (
            details?.detailList?.map((item: any, key: any) => {
              // console.log('=== item ===', item);

              return (
                <p
                  className="text-base leading-relaxed text-gray-500 dark:text-gray-400"
                  style={{ border: '1px grey solid', borderRadius: '5px', padding: '4px' }}
                  key={key}
                >
                  <div></div>
                  <div>{item?.npiType}</div>
                  <div>{item?.groupName}</div>
                  <div>
                    {item?.description} {renderCoverage(item?.coverage, item?.visitDetails, item?.amount)}
                  </div>
                  <div>{item?.individualName}</div>
                  <div>{item?.postBox}</div>
                  <div>{item?.postalCode}</div>
                </p>
              );
            })
          ) : (
            <div style={{ color: '#FF0000', fontWeight: '700' }}>{details?.mainObject?.errorMessage}</div>
          )}
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={() => setOpenModal(false)}>I accept</Button>
        <Button color="gray" onClick={() => setOpenModal(false)}>
          Decline
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

const renderCoverage = (coverage: string, visitDetails: string, amount: any) => {
  if (coverage === 'Active Coverage') {
    return (
      <span style={{ color: 'white', background: '#1ECA7B', borderRadius: '10px', fontSize: '14px', padding: '4px' }}>
        Active Coverage
      </span>
    );
  } else if (coverage === 'Inactive') {
    return (
      <span style={{ color: 'white', background: '#F14B4B', borderRadius: '10px', fontSize: '14px', padding: '4px' }}>
        Inactive
      </span>
    );
  } else {
    return (
      <>
        {visitDetails} {amount ? '$' : ''} {amount}
      </>
    );
  }
};
