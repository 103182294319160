import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchCarrierListInitialInterface,
  FetchCarrierTypeListInitialInterface,
  FetchReasonListInitialInterface,
} from '../../../interfaces/systemInterfaces';
import {
  getCarrierListAPIRequest,
  getCarrierTypeListAPIRequest,
  getReasonListAPIRequest,
} from '../../../api/system/patient';

const initialState: FetchCarrierTypeListInitialInterface = {
  carrierTypeListLoading: false,
  carrierTypeListData: [],
  carrierTypeListError: null,
  carrierTypeListStatus: 'IDLE',
};

export const getCarrierTypeListRequest: any = createAsyncThunk('carrier-type/get', async (thunkAPI: any) => {
  try {
    const response: any = await getCarrierTypeListAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getCarrierTypeListSlice = createSlice({
  name: 'fetchCarrierTypes',
  initialState,
  reducers: {
    clearFetchCarrierTypeListResponse: (state) => {
      state.carrierTypeListLoading = false;
      state.carrierTypeListStatus = 'IDLE';
      state.carrierTypeListData = [];
      state.carrierTypeListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCarrierTypeListRequest.pending, (state, action) => {
        state.carrierTypeListLoading = true;
        state.carrierTypeListStatus = 'PENDING';
      })
      .addCase(getCarrierTypeListRequest.fulfilled, (state, action) => {
        state.carrierTypeListLoading = false;
        state.carrierTypeListData = action.payload;
        state.carrierTypeListStatus = 'SUCCESS';
      })
      .addCase(getCarrierTypeListRequest.rejected, (state, action) => {
        state.carrierTypeListLoading = false;
        state.carrierTypeListError = action.payload;
        state.carrierTypeListStatus = 'FAILED';
      });
  },
});

export const { clearFetchCarrierTypeListResponse } = getCarrierTypeListSlice.actions;

export const getCarrierTypeListSelector = (state: RootState) => state.GetCarrierTypeList;

export default getCarrierTypeListSlice.reducer;
