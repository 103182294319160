import {
  ProviderAddressAddInterface,
  ProviderAddressGetByIdInterface,
  ProviderAddressUpdateInterface,
  ProviderAddressPrimaryUpdateInterface,
} from '../../interfaces/providersInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const addProviderAddressAPIRequest = async (providerData: ProviderAddressAddInterface) => {
  // console.log('== providerData ==', providerData);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${providerData?.providerId}/address`,
    'post',
    {
      data: { ...providerData?.addressData },
    }
  );
};

export const updateProviderAddressAPIRequest = async (addressData: ProviderAddressUpdateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${addressData?.providerId}/address/${addressData?.addressId}`,
    'put',
    {
      data: { ...addressData?.addressData },
    }
  );
};

export const getProviderAddressByIdAPIRequest = async (addressData: ProviderAddressGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${addressData?.providerId}/address/${addressData?.addressId}`,
    'get',
    {}
  );
};

export const deleteProviderAddressByIdAPIRequest = async (addressData: ProviderAddressGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${addressData?.providerId}/address/${addressData?.addressId}`,
    'delete',
    {}
  );
};

export const updateProviderAddressPrimaryAPIRequest = async (addressData: ProviderAddressPrimaryUpdateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${addressData?.providerId}/address/${addressData?.addressId}/change-primary`,
    'put',
    {
      data: { ...addressData?.addressData },
    }
  );
};
