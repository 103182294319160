import { createContext, useContext, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/localStorageHook';
import { useAppDispatch } from '../../hooks/storeHooks/hooks';
import { clearLoginResponse } from '../../redux/slices/auth/authSlice';
import { setGlobalLogout } from '../../utils/authUtils';

const AuthContext = createContext({});

export type AuthPropType = {
  login?(loginResponse: any): {};
  user?: {
    access_token: string;
    expires_in: number;
    'not-before-policy': number;
    refresh_expires_in: number;
    refresh_token: string;
    scope: string;
    session_state: string;
    token_type: string;
  };
  logout?(): {};
};

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useLocalStorage('user', null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    if (data) {
      setUser(data);
      //   navigate('/dashboard');
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    dispatch(clearLoginResponse());
    setUser(null);
    navigate('/', { replace: true });
  };

  useEffect(() => {
    setGlobalLogout(logout);
  }, [logout]);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
