import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchVisitApplyNoteHistoryInitialPropsInterface,
  FetchVisitsApplyNoteHistoryInterface,
} from '../../../interfaces/patientInterface';
import { getVisitApplyNoteHistoryAPIRequest } from '../../../api/patient/patientVisit';

const initialState: FetchVisitApplyNoteHistoryInitialPropsInterface = {
  loadingApplyNoteHistory: false,
  applyNoteHistoryData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  errorApplyNoteHistory: null,
  statusApplyNoteHistory: 'IDLE',
};

export const getVisitApplyNoteHistoryRequest: any = createAsyncThunk(
  'visit/apply-note-history/get',
  async (searchParams: FetchVisitsApplyNoteHistoryInterface, thunkAPI: any) => {
    try {
      const response: any = await getVisitApplyNoteHistoryAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getVisitApplyNoteHistorySlice = createSlice({
  name: 'fetchVisitNoteHistory',
  initialState,
  reducers: {
    clearFetchVisitApplyNoteHistoryResponse: (state) => {
      state.loadingApplyNoteHistory = false;
      state.statusApplyNoteHistory = 'IDLE';
      state.applyNoteHistoryData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.errorApplyNoteHistory = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisitApplyNoteHistoryRequest.pending, (state, action) => {
        state.loadingApplyNoteHistory = true;
        state.statusApplyNoteHistory = 'PENDING';
      })
      .addCase(getVisitApplyNoteHistoryRequest.fulfilled, (state, action) => {
        state.loadingApplyNoteHistory = false;
        state.statusApplyNoteHistory = 'SUCCESS';
        state.applyNoteHistoryData = action.payload;
      })
      .addCase(getVisitApplyNoteHistoryRequest.rejected, (state, action) => {
        state.loadingApplyNoteHistory = false;
        state.errorApplyNoteHistory = action.payload;
        state.statusApplyNoteHistory = 'FAILED';
      });
  },
});

export const { clearFetchVisitApplyNoteHistoryResponse } = getVisitApplyNoteHistorySlice.actions;

export const getVisitApplyNoteHistorySelector = (state: RootState) => state.FetchVisitApplyNoteHistory;

export default getVisitApplyNoteHistorySlice.reducer;
