import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchInsuranceByIdPropsInterface, FetchInsuranceByIdInterface } from '../../../interfaces/insuranceInterface';

const initialState: FetchInsuranceByIdPropsInterface = {
  fetchInsuranceByIdLoading: false,
  fetchInsuranceByIdData: {},
  fetchInsuranceByIdStatus: 'IDLE',
  fetchInsuranceByIdError: null,
};

export const getInsuranceByIdRequest: any = createAsyncThunk(
  'insurance/get/id',
  async (searchParams: FetchInsuranceByIdInterface, thunkAPI: any) => {
    try {
      // const response: any = await getInsuranceByIdAPIRequest(searchParams);
      // return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchInsuranceByIdSlice = createSlice({
  name: 'fetchInsuranceById',
  initialState,
  reducers: {
    clearFetchInsuranceByIdResponse: (state) => {
      state.fetchInsuranceByIdLoading = false;
      state.fetchInsuranceByIdData = {};
      state.fetchInsuranceByIdStatus = 'IDLE';
      state.fetchInsuranceByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInsuranceByIdRequest.pending, (state, action) => {
        state.fetchInsuranceByIdLoading = true;
        state.fetchInsuranceByIdStatus = 'PENDING';
      })
      .addCase(getInsuranceByIdRequest.fulfilled, (state, action) => {
        state.fetchInsuranceByIdLoading = false;
        state.fetchInsuranceByIdStatus = 'SUCCESS';
        state.fetchInsuranceByIdData = action.payload;
      })
      .addCase(getInsuranceByIdRequest.rejected, (state, action) => {
        state.fetchInsuranceByIdLoading = false;
        state.fetchInsuranceByIdStatus = 'FAILED';
        state.fetchInsuranceByIdError = action.payload;
      });
  },
});

export const { clearFetchInsuranceByIdResponse } = fetchInsuranceByIdSlice.actions;

export const getInsuranceByIdSelector = (state: RootState) => state.FetchInsuranceById;

export default fetchInsuranceByIdSlice.reducer;
