import { useState } from 'react';
import { BankReconciliationReportInterface, ClaimERAAutoPostInterface } from '../../../interfaces/userInterface';
import { Calendar } from '../../atoms/Calendar';
import { CommonButton } from '../../atoms/CommonButton';
import { DateRangeCalendar } from '../../atoms/DateRangeCalendar';
import { InputField } from '../../atoms/InputField';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { SelectInput } from '../../atoms/SelectInput';
import { FaFileExcel } from 'react-icons/fa';

export const ClaimERAAutoPostHeader: React.FC<ClaimERAAutoPostInterface> = ({
  onSubmit,
  onExport,
  onDownload,
  reportFilters,
}) => {
  const [filterObject, setFilterObject] = useState<any>({});

  const onChangeFilter = (e: any) => {
    e.preventDefault();
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <>
      <>
        <div className="grid md:grid-cols-4 gap-3 mt-6">
          <InputField
            label=""
            placeholder="Search"
            name="description"
            onChange={onChangeFilter}
            defaultValue={reportFilters?.description ?? undefined}
          />

          <DateRangeCalendar />
          <div className="flex gap-2 justify-end pt-2">
            <PrimaryButton
              type="button"
              label="Submit"
              style={{ height: '40px', maxWidth: '120px' }}
              onClick={() => onSubmit(filterObject)}
            />
            <PrimaryButton
              label="Export to Excel"
              icon={<FaFileExcel style={{ fontSize: '18px' }} />}
              style={{ height: '40px', maxWidth: '100px' }}
            />
          </div>
          <div className="flex gap-2 justify-end pt-2 ml-20">
            <CommonButton
              label="Download ERA"
              buttonType="primary"
              style={{ height: '40px' }}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path
                    fill-rule="evenodd"
                    d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                    clip-rule="evenodd"
                  />
                </svg>
              }
              onClick={onDownload}
            />
          </div>
          <div />
        </div>
      </>
    </>
  );
};
