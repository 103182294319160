import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  ProviderCarriersDeleteByIdInitialPropsInterface,
  ProviderCarriersGetByIdInterface,
} from '../../../interfaces/providersInterface';
import { deleteProviderCarriersByIdAPIRequest } from '../../../api/provider/providerCarrier';

const initialState: ProviderCarriersDeleteByIdInitialPropsInterface = {
  deleteProviderCarriersByIdLoading: false,
  deleteProviderCarriersByIdData: {},
  deleteProviderCarriersByIdStatus: 'IDLE',
  deleteProviderCarriersByIdError: null,
};

export const deleteProviderCarriersByIdRequest: any = createAsyncThunk(
  'providers/carriers/delete/id',
  async (carrier: ProviderCarriersGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await deleteProviderCarriersByIdAPIRequest(carrier);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deleteProviderCarriersByIdSlice = createSlice({
  name: 'deleteProviderCarriersById',
  initialState,
  reducers: {
    clearDeleteProviderCarriersByIdResponse: (state) => {
      state.deleteProviderCarriersByIdLoading = false;
      state.deleteProviderCarriersByIdData = {};
      state.deleteProviderCarriersByIdStatus = 'IDLE';
      state.deleteProviderCarriersByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProviderCarriersByIdRequest.pending, (state, action) => {
        state.deleteProviderCarriersByIdLoading = true;
        state.deleteProviderCarriersByIdStatus = 'PENDING';
      })
      .addCase(deleteProviderCarriersByIdRequest.fulfilled, (state, action) => {
        state.deleteProviderCarriersByIdLoading = false;
        state.deleteProviderCarriersByIdStatus = 'SUCCESS';
        state.deleteProviderCarriersByIdData = action.payload;
      })
      .addCase(deleteProviderCarriersByIdRequest.rejected, (state, action) => {
        state.deleteProviderCarriersByIdLoading = false;
        state.deleteProviderCarriersByIdError = action.payload;
        state.deleteProviderCarriersByIdStatus = 'FAILED';
      });
  },
});

export const { clearDeleteProviderCarriersByIdResponse } = deleteProviderCarriersByIdSlice.actions;

export const deleteProviderCarriersByIdSelector = (state: RootState) => state.DeleteProviderCarriersById;

export default deleteProviderCarriersByIdSlice.reducer;
