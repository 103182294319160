import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AddInsuranceInitialInterface, AddInsuranceInterface } from '../../../interfaces/systemInterfaces';
import { addInsuranceAPIRequest } from '../../../api/system/insurance';

const initialState: AddInsuranceInitialInterface = {
  addInsuranceLoading: false,
  addInsuranceData: {},
  addInsuranceError: null,
  success: false,
  addInsuranceStatus: 'IDLE',
};

export const addInsuranceRequest: any = createAsyncThunk(
  'insurance/add',
  async (insuranceData: AddInsuranceInterface, thunkAPI: any) => {
    try {
      const response: any = await addInsuranceAPIRequest(insuranceData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addInsuranceSlice = createSlice({
  name: 'addFacility',
  initialState,
  reducers: {
    clearAddInsuranceResponse: (state) => {
      state.addInsuranceLoading = false;
      state.addInsuranceStatus = 'IDLE';
      state.addInsuranceData = {};
      state.addInsuranceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addInsuranceRequest.pending, (state, action) => {
        state.addInsuranceLoading = true;
        state.addInsuranceStatus = 'PENDING';
      })
      .addCase(addInsuranceRequest.fulfilled, (state, action) => {
        state.addInsuranceData = action.payload;
        state.addInsuranceStatus = 'SUCCESS';
        state.addInsuranceLoading = false;
      })
      .addCase(addInsuranceRequest.rejected, (state, action) => {
        state.addInsuranceLoading = false;
        state.addInsuranceError = action.payload;
        state.addInsuranceStatus = 'FAILED';
      });
  },
});

export const { clearAddInsuranceResponse } = addInsuranceSlice.actions;

export const addInsuranceSelector = (state: RootState) => state.AddInsurance;

export default addInsuranceSlice.reducer;
