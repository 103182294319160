import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AddProviderInitialInterface, ProviderAddInterface } from '../../../interfaces/providersInterface';
import { addProviderAPIRequest } from '../../../api/provider/provider';

const initialState: AddProviderInitialInterface = {
  addProviderLoading: false,
  addProviderData: {},
  addProviderError: null,
  addProviderStatus: 'IDLE',
};

export const addProviderRequest: any = createAsyncThunk(
  'provider/add',
  async (provider: ProviderAddInterface, thunkAPI: any) => {
    try {
      const response: any = await addProviderAPIRequest(provider);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addProviderSlice = createSlice({
  name: 'addProvider',
  initialState,
  reducers: {
    clearAddProviderResponse: (state) => {
      state.addProviderLoading = false;
      state.addProviderStatus = 'IDLE';
      state.addProviderData = {};
      state.addProviderError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProviderRequest.pending, (state, action) => {
        state.addProviderLoading = true;
        state.addProviderStatus = 'PENDING';
      })
      .addCase(addProviderRequest.fulfilled, (state, action) => {
        state.addProviderData = action.payload;
        state.addProviderStatus = 'SUCCESS';
        state.addProviderLoading = false;
      })
      .addCase(addProviderRequest.rejected, (state, action) => {
        state.addProviderLoading = false;
        state.addProviderError = action.payload;
        state.addProviderStatus = 'FAILED';
      });
  },
});

export const { clearAddProviderResponse } = addProviderSlice.actions;

export const addProviderSelector = (state: RootState) => state.AddProvider;

export default addProviderSlice.reducer;
