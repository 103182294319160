import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  ProviderCarrierPendingInfoUpdateProposInterface,
  ProviderCarrierPendingInfoUpdateInferface,
} from '../../../interfaces/providersInterface';
import { editProviderCarrierPendingInfoAPIRequest } from '../../../api/provider/providerCarrier';

const initialState: ProviderCarrierPendingInfoUpdateProposInterface = {
  editProviderCarrierPendingInfoLoading: false,
  editProviderCarrierPendingInfoData: {},
  editProviderCarrierPendingInfoError: null,
  editProviderCarrierPendingInfoStatus: 'IDLE',
};

export const editProviderCarrierPendingInfoRequest: any = createAsyncThunk(
  'provider/carriers/updatePendingInfo',
  async (carrier: ProviderCarrierPendingInfoUpdateInferface, thunkAPI: any) => {
    try {
      const response: any = await editProviderCarrierPendingInfoAPIRequest(carrier);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editProviderCarrierPendingInfoSlice = createSlice({
  name: 'editProviderCarrierPendingInfo',
  initialState,
  reducers: {
    clearEditProviderCarrierPendingInfo: (state) => {
      state.editProviderCarrierPendingInfoLoading = false;
      state.editProviderCarrierPendingInfoData = {};
      state.editProviderCarrierPendingInfoError = null;
      state.editProviderCarrierPendingInfoStatus = 'IDLE';
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(editProviderCarrierPendingInfoRequest.pending, (state, action) => {
        state.editProviderCarrierPendingInfoLoading = true;
        state.editProviderCarrierPendingInfoStatus = 'PENDING';
      })
      .addCase(editProviderCarrierPendingInfoRequest.fulfilled, (state, action) => {
        state.editProviderCarrierPendingInfoData = action.payload;
        state.editProviderCarrierPendingInfoLoading = false;
        state.editProviderCarrierPendingInfoStatus = 'SUCCESS';
      })
      .addCase(editProviderCarrierPendingInfoRequest.rejected, (state, action) => {
        state.editProviderCarrierPendingInfoLoading = false;
        state.editProviderCarrierPendingInfoStatus = 'FAILED';
        state.editProviderCarrierPendingInfoError = action.payload;
      });
  },
});

export const { clearEditProviderCarrierPendingInfo } = editProviderCarrierPendingInfoSlice.actions;

export const editProviderCarrierPendingInfoSelector = (state: RootState) => state.EditProviderCarrierPendingInfo;

export default editProviderCarrierPendingInfoSlice.reducer;
