import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchICDCodesInitialInterface, GetICDCodesInterface } from '../../../interfaces/systemInterfaces';
import { getICDCodesAPIRequest } from '../../../api/system/code';

const initialState: FetchICDCodesInitialInterface = {
  icdCodesLoading: false,
  icdCodesData: [],
  icdCodesError: null,
  icdCodesStatus: 'IDLE',
};

export const getICDCodesRequest: any = createAsyncThunk(
  'codes/icd/get',
  async (code: GetICDCodesInterface, thunkAPI: any) => {
    try {
      const response: any = await getICDCodesAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getICDCodesSlice = createSlice({
  name: 'fetchICDCodes',
  initialState,
  reducers: {
    clearFetchICDCodesResponse: (state) => {
      state.icdCodesLoading = false;
      state.icdCodesStatus = 'IDLE';
      state.icdCodesData = [];
      state.icdCodesError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getICDCodesRequest.pending, (state, action) => {
        state.icdCodesLoading = true;
        state.icdCodesStatus = 'PENDING';
      })
      .addCase(getICDCodesRequest.fulfilled, (state, action) => {
        state.icdCodesLoading = false;
        state.icdCodesData = action.payload;
        state.icdCodesStatus = 'SUCCESS';
      })
      .addCase(getICDCodesRequest.rejected, (state, action) => {
        state.icdCodesLoading = false;
        state.icdCodesError = action.payload;
        state.icdCodesStatus = 'FAILED';
      });
  },
});

export const { clearFetchICDCodesResponse } = getICDCodesSlice.actions;

export const getICDCodesSelector = (state: RootState) => state.GetICDCodes;

export default getICDCodesSlice.reducer;
