import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { ClaimERAAutoPostHeader } from '../../../components/billing/claimERAAutoPost/ClaimERAAutoPostHeader';
import { DownloadERAModal } from '../../../components/billing/claimERAAutoPost/DownloadERAModal';
import { SelectInput } from '../../../components/atoms/SelectInput';
import { batchType } from '../../../constants/billingConstants';
import { Calendar } from '../../../components/atoms/Calendar';
import { Checkbox } from '../../../components/atoms/Checkbox';
import { DatePickerField } from '../../../components/atoms/DatePickerField';
import { formatDatePickerDate } from '../../../utils/commonFunctions';
import moment from 'moment';
import { validDateFormat } from '../../../utils/commonFunctions';

import {
  getClaimEraAutoPostRequest,
  getClaimEraAutoPostSelector,
  clearFetchClaimEraAutoPostResponse,
} from '../../../redux/slices/payments/claimEraAutoPost/getClaimEraAutoPostSlice';

const PAGE_SIZE = 10;

const columns = (handleBankTransactionTypeChange: any, handleBankPostingDate: any, handleisFwd: any) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Received Date',
    dataIndex: 'receivedDate',
    key: 'receivedDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'Paid Date',
    dataIndex: 'paidDate',
    key: 'paidDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
  {
    title: 'ERA ID',
    dataIndex: 'eraId',
    key: 'eraId',
  },
  {
    title: 'Payer Name',
    dataIndex: 'payerName',
    key: 'payerName',
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
  {
    title: 'Paid Amount',
    dataIndex: 'paidAmount',
    key: 'paidAmount',
  },
  {
    title: 'Bank Posting Date',
    dataIndex: 'bankPostingDate',
    key: 'bankPostingDate',
    render: (text: any, record: any) => (
      // <Calendar
      //   onSelectedDateChanged={(date) => handleBankPostingDate(date, 'bankPostingDate', record)}
      //   name="bankPostingDate"
      //   required={false}
      // />
      <DatePickerField
        name="bankPostingDate"
        selectedDate={formatDatePickerDate(record.bankPostingDate)}
        onChange={(date: Date | null) => {
          if (date) {
            handleBankPostingDate(date, 'bankPostingDate', date);
          }
        }}
      />
    ),
  },
  {
    title: 'Bank Transaction Type',
    dataIndex: 'bankTransactionType',
    key: 'bankTransactionType',
    render: (text: any, record: any) => (
      <SelectInput
        options={batchType}
        enableDefaultPlaceholder={true}
        name="bankTransactionType"
        // defaultValue={record?.applicationStatus ?? undefined}
        onChange={(e: any) => handleBankTransactionTypeChange(record?.id, e.target.value)}
      />
    ),
  },
  {
    title: 'Is Fwd',
    dataIndex: 'isFwd',
    key: 'isFwd',
    render: (text: any, record: any) => (
      <Checkbox onChange={(e: any) => handleisFwd('isFwd', e.target.checked, record)} />
    ),
  },
];

const dummyDataSource = [
  {
    id: '1',
    receivedDate: '2024-05-01',
    paidDate: '2024-05-02',
    eraId: 'ERA123456',
    payerName: 'Payer ABC',
    checkNumber: '12345',
    paidAmount: '$100.00',
    bankPostingDate: '2024-05-03',
    bankTransactionType: 'Deposit',
    isFwd: 'No',
  },
];

const reportFilters = {
  searchText: '',
  startDate: new Date(),
  endDate: new Date(),
};

export const months = [{ id: 'May', name: 'May' }];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'claimAutoPost', label: 'Claim ERA Auto Post', status: 'active', link: 'claim-auto-post' },
];

export const ClaimERAAutoPostPage = () => {
  const dispatch = useAppDispatch();

  const { fetchClaimEraAutoPostStatus, fetchClaimEraAutoPostData } = useAppSelector(getClaimEraAutoPostSelector);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleDownloadERA, setVisibleDownloadERA] = useState(false);
  // const [dataSource, setDataSource] = useState<any>([]);

  // useEffect(() => {
  //   fetchClaimEraAutoPost();
  // }, []);

  useEffect(() => {
    if (fetchClaimEraAutoPostStatus === 'SUCCESS') {
      setDataSource(fetchClaimEraAutoPostData?.items);
      setCurrentPage(fetchClaimEraAutoPostData?.currentPage);
      setTotalPages(fetchClaimEraAutoPostData?.totalPages);
    }
  }, [fetchClaimEraAutoPostStatus]);

  const fetchClaimEraAutoPost = async (pageSize = PAGE_SIZE, pageNumber = currentPage) => {
    // dispatch(getClaimEraAutoPostRequest({ size: pageSize, page: pageNumber }));
  };

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  const onDownloadERA = () => {
    setVisibleDownloadERA(true);
  };

  const onCloseDownloadERA = (close: boolean) => {
    setVisibleDownloadERA(false);
  };

  const handleBankTransactionTypeChange = (record: any, value: any) => {};

  const handleBankPostingDate = (date: any, name: string, record: any) => {};

  const handleisFwd = (name: string, value: any, record: any) => {};

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Claim ERA Auto Post" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <ClaimERAAutoPostHeader reportFilters={reportFilters} onDownload={onDownloadERA} />
      <div className="flex justify-end">
        <TableSearch />
      </div>
      {dummyDataSource?.length > 0 ? (
        <div className="overflow-x-auto">
          <TableContent
            enableActions={true}
            columns={columns(handleBankTransactionTypeChange, handleBankPostingDate, handleisFwd)}
            dataSource={dummyDataSource}
            enableDownload={true}
            enablePrimaryButton={true}
            primaryButtonLabel="Post"
          />
        </div>
      ) : (
        <EmptyContent enableCreateButton={false} />
      )}

      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      ) : (
        <></>
      )}

      {visibleDownloadERA && (
        <DownloadERAModal openDownloadERA={visibleDownloadERA} setOpenDownloadERA={onCloseDownloadERA} />
      )}
    </div>
  );
};
