import {
  AddPatientCaseInterface,
  EditPatientCaseInterface,
  PatientCaseByIdInterface,
  PatientCaseOptionsInterface,
} from '../../interfaces/patientInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getPatientCaseOptionsAPIRequest = async (patientCase: PatientCaseOptionsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientCase?.patientId}/case-dropdown`,
    'get',
    {}
  );
};

export const getPatientCasesAPIRequest = async (patientCase: PatientCaseOptionsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientCase?.patientId}/case`,
    'get',
    {}
  );
};

export const getPatientCaseByIdAPIRequest = async (patientCase: PatientCaseByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientCase?.patientId}/case/${patientCase?.caseId}`,
    'get',
    {}
  );
};

export const addPatientCaseAPIRequest = async (patientCase: AddPatientCaseInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientCase?.patientId}/case`,
    'post',
    {
      data: { ...patientCase?.caseData },
    }
  );
};

export const editPatientCaseAPIRequest = async (patientCase: EditPatientCaseInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientCase?.patientId}/case/${patientCase?.caseId}`,
    'put',
    {
      data: { ...patientCase?.caseData },
    }
  );
};
