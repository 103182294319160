import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchClaimsInitialInterface,
  FetchClaimsInterface,
  FetchVisitsInitialInterface,
  FetchVisitsInterface,
} from '../../../interfaces/billingInterface';
import { getVisitsAPIRequest } from '../../../api/billing/visits';
import { getClaimsAPIRequest } from '../../../api/billing/claims';

const initialState: FetchClaimsInitialInterface = {
  claimsLoading: false,
  claimsData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  claimsError: null,
  claimsStatus: 'IDLE',
};

export const getClaimsRequest: any = createAsyncThunk(
  'primaryClaims/getClaims',
  async (claimData: FetchClaimsInterface, thunkAPI: any) => {
    try {
      const response: any = await getClaimsAPIRequest(claimData);
      // console.log(response);
      return response;
    } catch (error: any) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getClaimsSlice = createSlice({
  name: 'fetchClaims',
  initialState,
  reducers: {
    clearFetchClaimsResponse: (state) => {
      state.claimsLoading = false;
      state.claimsStatus = 'IDLE';
      state.claimsData = {
        currentPage: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
      };
      state.claimsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimsRequest.pending, (state, action) => {
        state.claimsLoading = true;
        state.claimsStatus = 'PENDING';
      })
      .addCase(getClaimsRequest.fulfilled, (state, action) => {
        state.claimsLoading = false;
        state.claimsData = action.payload;
        state.claimsStatus = 'SUCCESS';
      })
      .addCase(getClaimsRequest.rejected, (state, action) => {
        state.claimsLoading = false;
        state.claimsError = action.payload;
        state.claimsStatus = 'FAILED';
      });
  },
});

export const { clearFetchClaimsResponse } = getClaimsSlice.actions;

export const getClaimsSelector = (state: RootState) => state.GetClaims;

export default getClaimsSlice.reducer;
