import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientVisitDeleteByIdInitialPropsInterface,
  PatientVisitGetByIdInterface,
} from '../../../interfaces/patientInterface';
import { deletePatientVisitByIdAPIRequest } from '../../../api/patient/patientVisit';

const initialState: PatientVisitDeleteByIdInitialPropsInterface = {
  deletePatientVisitByIdLoading: false,
  deletePatientVisitByIdData: {},
  deletePatientVisitByIdStatus: 'IDLE',
  deletePatientVisitByIdError: null,
};

export const deletePatientVisitByIdRequest: any = createAsyncThunk(
  'patients/visit/delete/id',
  async (searchParams: PatientVisitGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await deletePatientVisitByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deletePatientVisitByIdSlice = createSlice({
  name: 'deletePatientVisitById',
  initialState,
  reducers: {
    clearDeletePatientVisitByIdResponse: (state) => {
      state.deletePatientVisitByIdLoading = false;
      state.deletePatientVisitByIdData = {};
      state.deletePatientVisitByIdStatus = 'IDLE';
      state.deletePatientVisitByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deletePatientVisitByIdRequest.pending, (state, action) => {
        state.deletePatientVisitByIdLoading = true;
        state.deletePatientVisitByIdStatus = 'PENDING';
      })
      .addCase(deletePatientVisitByIdRequest.fulfilled, (state, action) => {
        state.deletePatientVisitByIdLoading = false;
        state.deletePatientVisitByIdStatus = 'SUCCESS';
        state.deletePatientVisitByIdData = action.payload;
      })
      .addCase(deletePatientVisitByIdRequest.rejected, (state, action) => {
        state.deletePatientVisitByIdLoading = false;
        state.deletePatientVisitByIdError = action.payload;
        state.deletePatientVisitByIdStatus = 'FAILED';
      });
  },
});

export const { clearDeletePatientVisitByIdResponse } = deletePatientVisitByIdSlice.actions;

export const deletePatientVisitByIdSelector = (state: RootState) => state.DeletePatientVisit;

export default deletePatientVisitByIdSlice.reducer;
