import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import {
  ProviderFacilitiesAddInterface,
  ProviderFacilitiesAddPropsInterface,
} from '../../../interfaces/providersInterface';
import { addProviderFacilitiesAPIRequest } from '../../../api/provider/providerFacilities';

const initialState: ProviderFacilitiesAddPropsInterface = {
  addProviderFacilitiesLoading: false,
  addProviderFacilitiesData: {},
  addProviderFacilitiesError: null,
  addProviderFacilitiesStatus: 'IDLE',
};

export const addProviderFacilitiesRequest: any = createAsyncThunk(
  'providers/facilities/add',
  async (provider: ProviderFacilitiesAddInterface, thunkAPI: any) => {
    try {
      const response: any = await addProviderFacilitiesAPIRequest(provider);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addProviderFacilitiesSlice = createSlice({
  name: 'addProviderFacilities',
  initialState,
  reducers: {
    clearAddProviderFacilitiesResponse: (state) => {
      state.addProviderFacilitiesLoading = false;
      state.addProviderFacilitiesStatus = 'IDLE';
      state.addProviderFacilitiesData = {};
      state.addProviderFacilitiesError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProviderFacilitiesRequest.pending, (state, action) => {
        state.addProviderFacilitiesLoading = true;
        state.addProviderFacilitiesStatus = 'PENDING';
      })
      .addCase(addProviderFacilitiesRequest.fulfilled, (state, action) => {
        state.addProviderFacilitiesData = action.payload;
        state.addProviderFacilitiesStatus = 'SUCCESS';
        state.addProviderFacilitiesLoading = false;
      })
      .addCase(addProviderFacilitiesRequest.rejected, (state, action) => {
        state.addProviderFacilitiesLoading = false;
        state.addProviderFacilitiesError = action.payload;
        state.addProviderFacilitiesStatus = 'FAILED';
      });
  },
});

export const { clearAddProviderFacilitiesResponse } = addProviderFacilitiesSlice.actions;

export const addProviderFacilitiesSelector = (state: RootState) => state.AddProviderFacilities;

export default addProviderFacilitiesSlice.reducer;
