import { createAsyncThunk, createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import { LoginInitialInterface, LoginPageInterface } from '../../../interfaces/loginInterface';
import { loginAPIRequest } from '../../../api/auth/auth';
import { RootState } from '../../store';

const initialState: LoginInitialInterface = {
  loading: false,
  userInfo: {},
  error: null,
  status: 'IDLE',
};

export const loginUserRequest: any = createAsyncThunk(
  'users/login',
  async (credData: LoginPageInterface, thunkAPI: any) => {
    try {
      const encodedEmail = btoa(credData?.username);
      const response: any = await loginAPIRequest(credData, encodedEmail);
      return response?.data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearLoginResponse: (state) => {
      state.loading = false;
      state.status = 'IDLE';
      state.userInfo = {};
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserRequest.pending, (state, action) => {
        state.loading = true;
        state.status = 'PENDING';
      })
      .addCase(loginUserRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
        state.status = 'SUCCESS';
      })
      .addCase(loginUserRequest.rejected, (state, action) => {
        console.log('=== error ===', action);

        state.loading = false;
        state.error = action.payload;
        state.status = 'FAILED';
      });
  },
});

export const { clearLoginResponse } = authSlice.actions;

export const authSelector = (state: RootState) => state.Auth;

export default authSlice.reducer;
