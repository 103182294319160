import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  CPTCodePropsInterface,
  CPTFetchInitialInterface,
  FetchVisitNoteHistoryInitialInterface,
  PatientFetchInitialInterface,
  PatientFetchInterface,
} from '../../../interfaces/patientInterface';
import { getPatientsAPIRequest } from '../../../api/patient/patient';
import { getCPTCodeListAPIRequest, getVisitNoteHistoryAPIRequest } from '../../../api/patient/patientVisit';

const initialState: FetchVisitNoteHistoryInitialInterface = {
  loadingNoteHistory: false,
  noteHistoryData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  errorNoteHistory: null,
  statusNoteHistory: 'IDLE',
};

export const getVisitNoteHistoryRequest: any = createAsyncThunk(
  'note-history/get',
  async (searchParams: CPTCodePropsInterface, thunkAPI: any) => {
    try {
      const response: any = await getVisitNoteHistoryAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getVisitNoteHistorySlice = createSlice({
  name: 'fetchVisitNoteHistory',
  initialState,
  reducers: {
    clearFetchVisitNoteHistoryResponse: (state) => {
      state.loadingNoteHistory = false;
      state.statusNoteHistory = 'IDLE';
      state.noteHistoryData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.errorNoteHistory = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisitNoteHistoryRequest.pending, (state, action) => {
        state.loadingNoteHistory = true;
        state.statusNoteHistory = 'PENDING';
      })
      .addCase(getVisitNoteHistoryRequest.fulfilled, (state, action) => {
        state.loadingNoteHistory = false;
        state.statusNoteHistory = 'SUCCESS';
        state.noteHistoryData = action.payload;
      })
      .addCase(getVisitNoteHistoryRequest.rejected, (state, action) => {
        state.loadingNoteHistory = false;
        state.errorNoteHistory = action.payload;
        state.statusNoteHistory = 'FAILED';
      });
  },
});

export const { clearFetchVisitNoteHistoryResponse } = getVisitNoteHistorySlice.actions;

export const getVisitNoteHistorySelector = (state: RootState) => state.FetchVisitNoteHistory;

export default getVisitNoteHistorySlice.reducer;
