import { useState } from 'react';
import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { TableContent } from '../../components/table/TableContent';
import { TableSearch } from '../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';

const columns = [
  {
    title: 'Insurance Name',
    dataIndex: 'insuranceName',
    key: 'insuranceName',
  },
  {
    title: 'Current',
    dataIndex: 'current',
    key: 'current',
    render: (text: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href="primary-insurance-report-sub/1">
        {text}
      </a>
    ),
  },
  {
    title: 'Past 1 - 31',
    dataIndex: 'past1',
    key: 'past1',
    render: (text: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href="dashboard">
        {text}
      </a>
    ),
  },
  {
    title: 'Past 31 - 60',
    dataIndex: 'past2',
    key: 'past2',
    render: (text: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href="dashboard">
        {text}
      </a>
    ),
  },
  {
    title: 'Past 61 - 90',
    dataIndex: 'past3',
    key: 'past3',
    render: (text: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href="dashboard">
        {text}
      </a>
    ),
  },
  {
    title: 'Past 91 - 120',
    dataIndex: 'past4',
    key: 'past4',
    render: (text: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href="dashboard">
        {text}
      </a>
    ),
  },
  {
    title: 'Past  > 120',
    dataIndex: 'past5',
    key: 'past5',
    render: (text: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href="dashboard">
        {text}
      </a>
    ),
  },
  {
    title: 'Total Balance',
    dataIndex: 'totalBalance',
    key: 'totalBalance',
    render: (text: any) => (
      <a style={{ color: '#1761fd', textDecoration: 'none' }} href="dashboard">
        {text}
      </a>
    ),
  },
];

const dataSource = [
  {
    insuranceName: 'ADC 1 WELFARE FUND',
    current: '$109.00',
    past1: '$0.00	',
    past2: '$0.00',
    past3: '$0.00	',
    past4: '$109.00',
    past5: '$0.00',
    totalBalance: '$0.00',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'primaryInsuranceReport',
    label: 'Primary Insurance Aging',
    status: 'active',
    link: 'primary-insurance-report',
  },
];

export const PrimaryInsuranceAgingReportPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  // const [dataSource, setDataSource] = useState<any>([]);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Primary Insurance Aging" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
      {/* <TableSearch /> */}
      {dataSource?.length > 0 ? (
        <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
      ) : (
        <EmptyContent enableCreateButton={false} />
      )}

      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      ) : (
        <></>
      )}
    </div>
  );
};
