import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { InsuranceFetchInitialInterface } from '../../../interfaces/systemInterfaces';
import { getSystemInsuranceAPIRequest } from '../../../api/system/insurance';

const initialState: InsuranceFetchInitialInterface = {
  systemInsuranceLoading: false,
  systemInsuranceInsuranceData: [],
  systemInsuranceError: null,
  systemInsuranceStatus: 'IDLE',
};

export const getSystemInsuranceRequest: any = createAsyncThunk('system/insurance/get', async (thunkAPI: any) => {
  try {
    const response: any = await getSystemInsuranceAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getSystemInsuranceSlice = createSlice({
  name: 'fetchSystemInsurance',
  initialState,
  reducers: {
    clearFetchSystemInsuranceResponse: (state) => {
      state.systemInsuranceLoading = false;
      state.systemInsuranceStatus = 'IDLE';
      state.systemInsuranceInsuranceData = [];
      state.systemInsuranceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemInsuranceRequest.pending, (state, action) => {
        state.systemInsuranceLoading = true;
        state.systemInsuranceStatus = 'PENDING';
      })
      .addCase(getSystemInsuranceRequest.fulfilled, (state, action) => {
        state.systemInsuranceLoading = false;
        state.systemInsuranceInsuranceData = action.payload;
        state.systemInsuranceStatus = 'SUCCESS';
      })
      .addCase(getSystemInsuranceRequest.rejected, (state, action) => {
        state.systemInsuranceLoading = false;
        state.systemInsuranceError = action.payload;
        state.systemInsuranceStatus = 'FAILED';
      });
  },
});

export const { clearFetchSystemInsuranceResponse } = getSystemInsuranceSlice.actions;

export const getSystemInsuranceSelector = (state: RootState) => state.GetSystemInsurance;

export default getSystemInsuranceSlice.reducer;
