import { usStates } from '../../../constants/systemConstants';
import { providerDegree, userRoles } from '../../../constants/userConstants';
import { AddressFormPropsInterface } from '../../../interfaces/userInterface';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';

export const AddressForm: React.FC<AddressFormPropsInterface> = ({
  onChange,
  addressFormEditData,
  errorObject,
  mode,
}) => {
  return (
    <>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Address 1"
          name="address1"
          onChange={onChange}
          defaultValue={addressFormEditData?.address1 ?? undefined}
          disabled={mode === 'VIEW'}
          error={errorObject && errorObject?.address1}
          errorMessage={errorObject && errorObject?.address1}
          required={true}
        />
        <InputField
          label="Address 2"
          name="address2"
          onChange={onChange}
          defaultValue={addressFormEditData?.address2 ?? undefined}
          disabled={mode === 'VIEW'}
          error={errorObject && errorObject?.address2}
          errorMessage={errorObject && errorObject?.address2}
        />
        <InputField
          label="City"
          name="city"
          onChange={onChange}
          defaultValue={addressFormEditData?.city ?? undefined}
          disabled={mode === 'VIEW'}
          error={errorObject && errorObject?.city}
          errorMessage={errorObject && errorObject?.city}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="state"
          onChange={onChange}
          defaultValue={addressFormEditData?.state ?? undefined}
          disabled={mode === 'VIEW'}
          error={errorObject && errorObject?.state}
          errorMessage={errorObject && errorObject?.state}
          required={true}
        />
        <InputField
          label="Zip Code"
          name="zipCode"
          onChange={onChange}
          defaultValue={addressFormEditData?.zipCode ?? undefined}
          disabled={mode === 'VIEW'}
          error={errorObject && errorObject?.zipCode}
          errorMessage={errorObject && errorObject?.zipCode}
          tooltip="Character length must be 5"
        />
        <InputField
          label="Extension"
          name="extension"
          onChange={onChange}
          defaultValue={addressFormEditData?.extension ?? undefined}
          disabled={mode === 'VIEW'}
          error={errorObject && errorObject?.extension}
          errorMessage={errorObject && errorObject?.extension}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Phone"
          name="phone"
          onChange={onChange}
          defaultValue={addressFormEditData?.phone ?? undefined}
          disabled={mode === 'VIEW'}
          error={errorObject && errorObject?.phone}
          errorMessage={errorObject && errorObject?.phone}
          required={true}
          tooltip="10-digit mobile number required"
        />
        <InputField
          label="Fax"
          name="fax"
          onChange={onChange}
          defaultValue={addressFormEditData?.fax ?? undefined}
          disabled={mode === 'VIEW'}
          error={errorObject && errorObject?.fax}
          errorMessage={errorObject && errorObject?.fax}
          tooltip="10-digit fax number required"
        />
      </div>
    </>
  );
};
