import Axios from 'axios';
import {
  UserAddInterface,
  UserFetchInterface,
  UsersFetchInterface,
  UserUpdateInterface,
} from '../../interfaces/userInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getUsersAPIRequest = async (searchParams: UsersFetchInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/user/search?page=${searchParams?.page}&size=${searchParams?.size}`,
    'get',
    {
      data: {},
    }
  );
};

export const addUsersAPIRequest = async (userData: UserAddInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/user/`, 'post', {
    data: { ...userData },
  });
};

export const updateUsersAPIRequest = async (userData: UserUpdateInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/user/`, 'put', {
    data: { ...userData },
  });
};

export const deleteUsersAPIRequest = () => {
  return Axios.delete('http://localhost:8081/api/v1/');
};

export const getUserByIdAPIRequest = async (user: UserFetchInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/user/${user?.userId}`, 'get', {
    data: {},
  });
};
