import { usStates } from '../../../../constants/systemConstants';
import { providerDegree } from '../../../../constants/userConstants';
import { PatientAddressFormPropsInterface } from '../../../../interfaces/patientInterface';
import { InputField } from '../../../atoms/InputField';
import { SelectInput } from '../../../atoms/SelectInput';

export const PatientsAddressForm: React.FC<PatientAddressFormPropsInterface> = ({
  onChange,
  addressFormEditData,
  addressMode,
  mode,
  errorObject,
}) => {
  return (
    <>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Address 1"
          name="address1"
          onChange={onChange}
          defaultValue={addressFormEditData?.address1 ?? undefined}
          error={errorObject && errorObject?.address1}
          errorMessage={errorObject && errorObject?.address1}
          required={true}
        />
        <InputField
          label="Address 2"
          name="address2"
          onChange={onChange}
          defaultValue={addressFormEditData?.address2 ?? undefined}
        />
        <InputField
          label="City"
          name="city"
          onChange={onChange}
          defaultValue={addressFormEditData?.city ?? undefined}
          error={errorObject && errorObject?.city}
          errorMessage={errorObject && errorObject?.city}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="state"
          onChange={onChange}
          defaultValue={addressFormEditData?.state ?? undefined}
          error={errorObject && errorObject?.state}
          errorMessage={errorObject && errorObject?.state}
          required={true}
        />
        <InputField
          label="Zip Code"
          name="zipCode"
          onChange={onChange}
          defaultValue={addressFormEditData?.zipCode ?? undefined}
          error={errorObject && errorObject?.zipCode}
          errorMessage={errorObject && errorObject?.zipCode}
          required={true}
          tooltip="Character length must be 5"
        />
      </div>
    </>
  );
};
