import { usStates } from '../../../../constants/systemConstants';
import { ProviderLicenseFormPropsInterface } from '../../../../interfaces/providersInterface';
import { Calendar } from '../../../atoms/Calendar';
import { InputField } from '../../../atoms/InputField';
import { SelectInput } from '../../../atoms/SelectInput';
import { formatDate } from '../../../../utils/commonFunctions';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';

export const LicenseForm: React.FC<ProviderLicenseFormPropsInterface> = ({
  onChange,
  licenseFormEditData,
  errorObject,
  onDateChange,
}) => {
  return (
    <>
      <div className="grid md:grid-cols-2 gap-6 mt-6">
        <InputField
          label="License Number"
          name="licenseNum"
          onChange={onChange}
          value={licenseFormEditData?.licenseNum ?? undefined}
          error={errorObject && errorObject?.licenseNum}
          errorMessage={errorObject && errorObject?.licenseNum}
          required={true}
        />
        <SelectInput
          label="State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="state"
          onChange={onChange}
          value={licenseFormEditData?.state ?? undefined}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-6 mt-6">
        {/* <Calendar
          label="Effective Date"
          name="effectiveDate"
          // defaultDate={licenseFormEditData?.effectiveDate ? new Date(licenseFormEditData?.effectiveDate) : undefined}
          defaultDate={formatDate(licenseFormEditData?.effectiveDate)}
          onSelectedDateChanged={(date) => {
            onDateChange('effectiveDate', date);
          }}
        /> */}
        <DatePickerField
          label="Effective Date"
          name="effectiveDate"
          // selectedDate={licenseFormEditData?.effectiveDate ? new Date(licenseFormEditData?.effectiveDate) : null}
          selectedDate={formatDatePickerDate(licenseFormEditData?.effectiveDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('effectiveDate', date);
            }
          }}
        />
        {/* <Calendar
          label="Expiry Date"
          name="expDate"
          // defaultDate={licenseFormEditData?.expDate ? new Date(licenseFormEditData?.expDate) : undefined}
          defaultDate={formatDate(licenseFormEditData?.expDate)}
          onSelectedDateChanged={(date) => {
            onDateChange('expDate', date);
          }}
        /> */}
        <DatePickerField
          label="Expiry Date"
          name="expDate"
          // selectedDate={licenseFormEditData?.effectiveDate ? new Date(licenseFormEditData?.effectiveDate) : null}
          selectedDate={formatDatePickerDate(licenseFormEditData?.expDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('expDate', date);
            }
          }}
        />
      </div>
    </>
  );
};
