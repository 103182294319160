import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { Modal } from 'flowbite-react';
import { InputField } from '../../../atoms/InputField';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { Pagination } from '../../../pagination/Pagination';
import { Spinner } from '../../../atoms/Spinner';
import { CommonAlert } from '../../../atoms/Alert';

import { getFacilitySearchRequest } from '../../../../redux/slices/provider/getSearchFacilitiesSlice';
import {
  addProviderFacilitiesRequest,
  addProviderFacilitiesSelector,
  clearAddProviderFacilitiesResponse,
} from '../../../../redux/slices/provider/assignProviderFacilitySlice';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
];

export const ProviderFacilitiesAssignModal = ({
  isFacilityAssignModalVisible,
  onCloseModal,
  dataSource,
  facilityData,
  dataLoading,
  facilityDataFetchStatus,
  providerId,
  fetchProviderById,
  onChangeFacilityEdit,
  facilitiesMode,
}: any) => {
  const dispatch = useAppDispatch();
  const { addProviderFacilitiesStatus } = useAppSelector(addProviderFacilitiesSelector);

  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const PAGE_SIZE = 5;

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });

  const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getFacilitySearchRequest({ page: 0, size: PAGE_SIZE }));
  }, []);

  useEffect(() => {
    if (facilityDataFetchStatus === 'SUCCESS') {
      setCurrentPage(facilityData?.currentPage);
      setTotalPages(facilityData?.totalPages);
      setTotalItems(facilityData?.totalItems);
    } else if (facilityDataFetchStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [facilityDataFetchStatus, facilityData]);

  useEffect(() => {
    if (addProviderFacilitiesStatus === 'SUCCESS') {
      fetchProviderById(providerId, 'facility');
      setAlertObj({
        color: 'success',
        message: 'Successfully Added!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearAddProviderFacilitiesResponse());
    } else if (addProviderFacilitiesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearAddProviderFacilitiesResponse());
    }
  }, [addProviderFacilitiesStatus]);

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.trim() === '') {
      setIsSearchEnabled(false);
    } else {
      setIsSearchEnabled(true);
    }
  };

  const handleAddFacility = (event: any, rowIndex: any) => {
    event.preventDefault();
    dispatch(
      addProviderFacilitiesRequest({ providerId: providerId, facilitiesData: { facilityId: dataSource[rowIndex]?.id } })
    );
  };

  const handleEditFacility = (event: any, rowIndex: any) => {
    event.preventDefault();
    onChangeFacilityEdit(dataSource[rowIndex]);
    onCloseModal();
  };

  const handleSearchFacilities = () => {
    dispatch(getFacilitySearchRequest({ page: 0, size: PAGE_SIZE }));
  };

  const onSearchPageChange = (page: any) => {
    setCurrentPage(page - 1);
    dispatch(getFacilitySearchRequest({ page: page - 1, size: PAGE_SIZE }));
  };

  const handleReset = () => {
    setSearchText('');
    setIsSearchEnabled(false);
    dispatch(getFacilitySearchRequest({ page: 0, size: PAGE_SIZE }));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  return (
    <Modal show={isFacilityAssignModalVisible} onClose={onCloseModal}>
      <Modal.Header>Search Facility</Modal.Header>
      <Modal.Body>
        {visibleAlert && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-5/12"
          />
        )}
        <InputField
          name="facilityName"
          label="Name"
          placeholder="Facility Name"
          value={searchText ?? ''}
          disabled
          onChange={handleSearchInputChange}
        ></InputField>
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <div className="col-span-1"></div>
          <div className="col-span-1 flex justify-end space-x-2">
            <CommonButton type="button" label="Reset" buttonType="secondary" onClick={handleReset} />
            <CommonButton
              type="button"
              label="Search"
              buttonType="primary"
              onClick={handleSearchFacilities}
              disabled={!isSearchEnabled}
            />
          </div>
        </div>
        <div className="mt-6">
          {dataLoading ? (
            <Spinner />
          ) : (
            <>
              {dataSource && dataSource?.length > 0 && (
                <>
                  <TableContent
                    columns={columns}
                    dataSource={dataSource}
                    enableActions={true}
                    enableAdd
                    onHandleAdd={facilitiesMode === 'EDIT' ? handleEditFacility : handleAddFacility}
                  ></TableContent>

                  <Pagination
                    currentPage={currentPage}
                    totalItems={totalItems}
                    totalPages={totalPages}
                    onPageChange={onSearchPageChange}
                  />
                </>
              )}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
