import { Button, Modal } from 'flowbite-react';
import { CommonButton } from '../../../atoms/CommonButton';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { copyVisitRecordRequest, copyVisitSelector } from '../../../../redux/slices/patient/CopyVisitSlice';

export const CopyAlertModal = ({ visitData, openCopyAlert, setOpenCopyAlert, patientId, visitId }: any) => {
  const dispatch = useAppDispatch();
  const { copyVisitLoading } = useAppSelector(copyVisitSelector);

  // console.log('=== visitData - open alert===', visitData);

  const handleSubmit = () => {
    const obj = {
      lastName: visitData?.lastName ?? '',
      firstName: visitData?.firstName ?? '',
      policy: visitData?.policy ?? '',
      group: visitData?.group ?? '',
      insuranceId: visitData?.insuranceId ?? null,
      payerId: visitData?.payerId ?? '',
      receiverFirstName: visitData?.receiverFirstName ?? '',
      receiverLastNameOrOrganizationName: visitData?.receiverLastNameOrOrganizationName ?? '',
      npiType: visitData?.npiType ?? '',
      npi: visitData?.npi ?? '',
      patientDoB: visitData.patientDoB ?? '',
      dateOfService: visitData.dateOfService ?? '',
    };

    dispatch(copyVisitRecordRequest({ patientId: patientId, visitId: visitId, visitData: obj }));
  };

  return (
    <Modal show={openCopyAlert} onClose={() => setOpenCopyAlert(false)}>
      <Modal.Header>Copy Visit</Modal.Header>
      <Modal.Body>Are you sure you want to make a copy of this visit {visitId} and create a new visit?</Modal.Body>
      <Modal.Footer>
        <CommonButton type="button" label={'No'} buttonType="secondary" onClick={() => setOpenCopyAlert(false)} />
        <CommonButton
          type="button"
          label={'Yes'}
          buttonType="primary"
          onClick={handleSubmit}
          disabled={copyVisitLoading}
        />
      </Modal.Footer>
    </Modal>
  );
};
