import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUsersAPIRequest } from '../../../api/user/user';
import { AddressFetchInitialInterface, FetchAddressListPropsInterface } from '../../../interfaces/userInterface';
import { RootState } from '../../store';
import { getAddressListAPIRequest } from '../../../api/user/address';

const initialState: AddressFetchInitialInterface = {
  loading: false,
  addressData: [],
  error: null,
  success: false,
  status: 'IDLE',
};

export const getAddressRequest: any = createAsyncThunk(
  'users/address/get',
  async (searchParams: FetchAddressListPropsInterface, thunkAPI: any) => {
    try {
      const response: any = await getAddressListAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getAddressSlice = createSlice({
  name: 'fetchAddress',
  initialState,
  reducers: {
    clearFetchAddressResponse: (state) => {
      state.loading = false;
      state.status = 'IDLE';
      state.addressData = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddressRequest.pending, (state, action) => {
        state.loading = true;
        state.status = 'PENDING';
      })
      .addCase(getAddressRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.addressData = action.payload;
        state.status = 'SUCCESS';
      })
      .addCase(getAddressRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = 'FAILED';
      });
  },
});

export const { clearFetchAddressResponse } = getAddressSlice.actions;

export const getUserAddressSelector = (state: RootState) => state.GetAddress;

export default getAddressSlice.reducer;
